//////////////////////////////////////////////////////////////////////////////////////////

// _ALERT.SCSS

// This file includes all rules which affect the generic alert messages. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

   
	#results-page .alert {
		clear: both;
	}
	.alert {
		text-align: left;
		margin: 10px 0 10px;
		border: none;
		
			.interior {
				padding: 7px 10px 5px;
			}
			
			u {
				text-decoration: none;
				font-family: Arial, Helvetica, sans-serif;
			}
		}	
	
	#alert {
	
		.modal-content {
			
			p {
				padding-left: 15px;
			}
				
			img {
				display: block;
				margin: 0 auto;
			}			
		}
		

	}
	.alert-text-icon{
		@extend .d-flex;
		@extend .d-flex;
		@extend .flex-row;

		.highlight-text{
			font-weight: $font-weight-bold;
			padding-right: 5px;
		}
		svg{
			@extend .mr-2;
		}
		.alert-icon-fill{
			fill: currentColor;
		}
		p{
			font-size: 1rem;
			margin-bottom: 0;
		}

	}
	.warning {
		padding: 20px;
		background: $bkgrdWarningAlert;

	}
	.loading-warning{
		@extend .bg-warning;
		@extend .text-dark;
		@extend .d-flex;	

		p{
			margin-bottom: 0;
			font-size: 1rem;
		}
	}
	
	.error {
		padding: 20px;
		background: $bkgrdErrorAlert;
		color: $textAlert;
	}
	.notification-info {
		padding: 10px;
		background-color: #a9dcff !important;
		color: #00568f !important;
		border: 1px solid #5cbeff !important;
		border-radius: 4px; 
	}
	.success {
		padding:20px;
		background: $bkgrdSuccessAlert;
	}
