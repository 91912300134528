// --------------------------------------------------
//
//		TILES
//		Framework 2.0.0+
//
// --------------------------------------------------

// Look and Feel
.tile-flat {
	background: $white;
	border: 1px solid $gray-500;
	border-radius: $border-radius;
}

// Structure
.tile-container {
	@extend %col;
	@extend .align-items-stretch;
	margin: $grid-gutter-width-sm;
	@extend .rm-p;
}

.tile-inner {
	@extend %col;
	@extend %d-flex;
	@extend .align-self-stretch;
	@extend .rm-p;
	@extend %flex-column;
}

.tile-row {
	@extend %row;
	padding: $grid-gutter-width-md;
	@extend .rm-m-hor;
	width: 100%;
	padding: $grid-gutter-width-md;
}