// --------------------------------------------------
//
//		BUTTONS
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

// --------------------------------------------------
//	Containers
// --------------------------------------------------

// Full width no margin
.af-container-fluid {
	@extend %container-fluid;
}

.af-container {
	max-width: 1260px;
	margin: auto;
	@extend .px-3;
} 

// --------------------------------------------------
//	Rows
// --------------------------------------------------

// Migrated from Candidate Search
.filter-panel {
	position: relative;
}

.applied-filters {
	// More than one line of filters
	&-section {
		overflow: hidden;
	}

	// Only one line of filters
	&-row {
		
		@extend .align-items-start;
		margin-top: 0;
		@extend .mb-3;
		min-height: $chip-height;

		.filter-title {
			@extend %col;
			@extend %col-auto;
			@extend .h6;
			margin-top: ($chip-height * 0.25);
			margin-bottom: 0;

			small {
				font-size: 1em;
				color: $gray-600;
				font-weight: 500;
			}
		}

		.filter-panel {
			@extend %col;
			@extend %col-xl-9;
			@extend %col-lg-8;
			min-height: $chip-height;
			@extend .flex-nowrap;
			white-space: wrap;
			overflow: hidden;

			// Gradient to background hide effect
			&:not(.show-all-chips) {
				white-space: nowrap;
				
				&::after {
					content: " ";
					display: block;
					position: absolute;
					height: 100%;
					width: 40px;
					right: 0;
					top: 0;
					background: linear-gradient(to right,rgba(255,255,255,0) 46%,#fff 97%);
				}
			} // End :not(.show-all-chips)

			//Extend height when showing all chips
			&.show-all-chips {
				height: auto;
				white-space: unset;
				overflow: visible;
				@extend %flex-wrap;
			}
		} // End Filter Panel

		.filter-panel-nav {
			@extend %col;
			@extend %col-auto;
			background: #fff;
			margin-top: ($chip-height * 0.25);

			a {
				padding: 0 $grid-gutter-width-sm;
			}

			a:nth-child(2) {
				border-left: 1px solid $gray-600;
			}
		}
	}
}

// ----------------------------------------------------
//	BREAKPOINTS
// ----------------------------------------------------

//@media (min-width: map-get($grid-breakpoints, "md")) { 
//	.applied-filters-row {
//		.filter-panel {
//			@extend %col-8;
//		}
//	}
//}