.info-card-container {
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;

  .info-card-header {
    display: flex;
    align-items: flex-end;
    gap: 0.625rem;
    align-self: stretch;

    .title-subtitle-icon {
      display: flex;
      align-items: flex-start;
      gap: 0.25rem;
      flex: 1 0 0;

      .info-card-icon {
        font-size: 1rem;
        color: #222222;
        &::before {
          vertical-align: top;
        }
      }

      .info-card-title-subtitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.375rem;
        flex: 1 0 0;

        .info-card-title {
          display: flex;
          align-items: flex-start;
          gap: 0.25rem;
          align-self: stretch;
          color: #222;
          font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          .info-icon-container {
            display: flex;
            padding-top: 1px;
            align-items: center;
            gap: 0.625rem;
            align-self: stretch;

            .info-icon {
              font-size: 0.75rem;
              color: #222222;
            }
          }
        }

        .info-card-subtitle {
          color: #5B6770;
          font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .index-value {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 0.0625rem;
      align-self: stretch;

      .actual-value {
        color: #0469cd;
        font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .out-of-value {
      color: #5B6770;
      font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .compare-index-value {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 0.0625rem;
      align-self: stretch;

      .actual-from-value {
        color: #fc74a8;
        font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .actual-to-value {
        color: #0469cd;
        font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .compare-value-indicator {
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }

    .compare-value-from {
      background-color: #fc74a8;
    }

    .compare-value-to {
      background-color: #0469cd;
    }

    .single-value {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 0.625rem;
      align-self: stretch;
      color: #0469cd;
      font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .compare-range-value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.375rem;
      align-self: stretch;

      .compare-value {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        align-self: stretch;
        color: #9ba3b0;
        font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .compare-single-value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.375rem;
      align-self: stretch;

      .difference-value {
        align-self: stretch;
        text-align: right;
        font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .difference-positive {
        color: rgba(6, 189, 112, 0.75);
      }

      .difference-negative {
        color: rgba(255, 34, 34, 0.7);
      }

      .compare-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 0.625rem;

        .compare-value {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          color: #9ba3b0;
          font-family: Roboto, "Nunito Sans", Helvetica, Arial, sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  .info-card-progress-bar {
    width: 100%;
  }
}
