// -------------------------------------------------------------------
//
//		TAGS TYPE COMPONENTS
//		Created for Framework 2.1.0+
//
//		
//
// -------------------------------------------------------------------

.tag{
  background-color: #ddd;
  color: $textMdGrey;
  font-size: $font-size-sm;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: .0178571429em;
  min-height: 24px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 6px;
  border-width: 0;
  outline: none;
  margin: 4px;
  border-radius: .25rem;

  &:focus{
    outline: #000 2px dotted;
  }
}


@mixin tag-variant($background, $color-text) {
background-color: $background;
color: $color-text;
}

@each $color, $value in $colors {
.tag-#{$color} {
  @include tag-variant(lighten( $value, 40% ), darken( $value, 0% ));

}
}   

// Career advice topic tags//
@each $advice-colors, $color in $advice-colors {
  .tag-link-#{$advice-colors} {
    background-color: #{$color};
    color: #fff;
    border: 1px solid transparent;
    &:hover{
      background-color: #fff;
      color: #{$color};
      border: 1px solid #{$color};
    }
  }
}