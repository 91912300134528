// -------------------------------------------------------------------
//
//		APP BAR TYPE COMPONENTS
//		Created for Framework 2.1.0+
//
//		In the case there is no documentation yet, please see the material
//		ui documentation at https://material.io/components/
//
// -------------------------------------------------------------------

// 'Region' denotes an area of the app that lives and acts accordingly to screen sizes
.region_ {

	&top-bar {
		background: $white; // This is set so it isn't transparent - a utility class should be more specific
		background-color: inherit; // Calls inherit to default to white or so we can swap out utility classes on the element with JS for partner specific colors in the future
		border-bottom: 1px solid $border-color;
		@extend .shadow-sm;
		height: auto; //set so content can animate the bar larger
	}

}