//////////////////////////////////////////////////////////////////////////////////////////

// _MEMBERS-ONLY.SCSS

// This file includes all rules which affect the generic members-only listing found within 
// job-listings. The rules in this file are not to be modified to accommmodate custom partner 
// sites. Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


		
.members-only {
	background: $white;
	padding: 15px;
	margin-bottom: 10px;
				
	i, em {
		color: $miscLtGrey;
		float: right;
	}
	
	label {
		color: $miscLtGrey;
		font-family: "Arial Black", arial, sans-serif;
		font-size: 1.7em;
		font-weight: 900;
		text-transform: uppercase;
		float: right;
	}
	
	strong {
		color: $miscLtGrey;
		font-size: 1.7em;
		font-weight: 400;
		line-height: 100%;
		display:block;
		padding-bottom: 20px;

	}					
}