// --------------------------------------------------
//
//		WIREFRAME CONTENT
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

.wf-row-col {
	@extend %row;
	@extend %flex-column;
}

.wf-line {
	//@extend %col;
	display: block;
	background-color: $gray-500;
	border-radius: $border-radius;
	height: 1em;

	&.wf-75 {
		width: 75%;
	}

	&.wf-50 {
		width: 50%;
	}

	&.wf-25 {
		width: 25%;
	}

	+ .wf-line {
		margin-top: $grid-gutter-width-sm;
	}
}