//////////////////////////////////////////////////////////////////////////////////////////

// _CAREER-PLANNING-NAV.SCSS

// This file includes all styles which affect the career planning nav. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact the Product Design Team

//////////////////////////////////////////////////////////////////////////////////////////

   
.career-planning-nav{
	background-color: $black;
	width: 100%;
	position: relative;
	@extend .pt-2;
	@extend .pt-sm-0;

	.career-planning-nav-branding-header{
		font-size: $font-size-base;
		color: #fff;
		@extend .pt-2;
		@extend .pt-md-0;
		
	}
	nav{
		@extend .navbar;
		@extend .career-planning-max-body-width;
		background-color: transparent;
		@extend .py-0;
		@extend .px-0;
		@extend .px-sm-3;
		@extend .flex-column;
		@extend .flex-md-row;
		@extend .justify-content-center;
		@extend .justify-content-sm-between;
		min-height: 40px;
		position: unset;

		
	
		@include media-breakpoint-down(xs) { 
			height: auto;
		}
		
		
		.career-planning-nav-ul{
			@extend .navbar-nav;
			@extend .mr-0;
			@extend .d-flex;
			@extend .flex-row;
			@extend .justify-content-center;
			@extend .pt-2;
			@extend .pt-sm-0;
			@include media-breakpoint-down(xs) { 
				width: 100%;
				flex-wrap: wrap;
			}

			.career-planning-nav-li{
				@extend .nav-item;
                position: relative;
                margin: 0 3px;
              
				a:focus{
					outline-color: #fff;
				}

				&.active, &:hover{
					.career-planning-nav-link{
						border-color:currentColor;

						svg{
					    	opacity: 1;
					  	}
						&:before{
							content:"";
							height: 40px;
							width: 100%;
							position: absolute;
							display: block;
							left: 0;
							top: 0;
							background-color: currentColor;
							opacity: .1;
							@include media-breakpoint-down(xs) { 
								min-height: 60px;
							}
						}
						
						
					}
				}
				.career-planning-nav-link{
					border-bottom: 3px solid transparent;
					position: relative;
					color: #fff;
					font-size: $font-size-sm;
					@extend .nav-link;
					@extend .d-flex;
					@extend .align-items-center;
					height: 40px;
					@extend .justify-content-center;
					@extend .px-0;
					@extend .px-sm-3;
					@extend .flex-column;
					@extend .flex-sm-row;
				
					@include media-breakpoint-down(xs) { 
						height: 100%;
						padding-left:15px!important;
						padding-right:15px!important;
					}
			
					svg{
						margin-right: 5px;
						z-index: 1;
						width: 20px;
					    height: 20px;
						.career-planning-nav-svg{
	 						fill:#fff;
	 					}
	 					@include media-breakpoint-down(xs) { 
						   margin-right: auto;
						   margin-left: auto;
						}
					}
					
				}

			}

		}
	}
}