//////////////////////////////////////////////////////////////////////////////////////////

// _EXPIRED-JOB.SCSS

// This file includes all rules which affect the generic expired job module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


	#expired-job {
		display: block;
		width: 100%;	
		font-size: 1.2em;
		text-align: center;
		padding-bottom: 20px;
		
		div {
			padding-bottom: 20px;
		}
		
		h3, .h3 {
			font-size: 1.7em;
			line-height: 100%;
			padding-bottom: 40px;
		}
		
		p {
			font-size: 1.3em;
			padding-bottom: 40px;
		}
		
		.secondary.bottom {
			width: 25%;
			padding: 15px 0;
			text-align: center;
			font-size: 1.2em;
		}

		@include breakpoint(small) {
			width: 100%;
			
			.secondary.bottom {
				width: 90%;
				padding: 10px 0;
				text-align: center;
				font-size: 1.2em;
			}
		}
	}