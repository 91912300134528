//////////////////////////////////////////////////////////////////////////////////////////

// _COOKIE-CONSENT.SCSS

// This file includes all rules which affect the cookie consent module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	
	
	#cookie-consent {
		display: block;
		position: sticky;
		z-index: $zindex-sticky;
		top: auto;
		bottom:0;
		width: 100%;
		margin: auto;
		background-color: #eaf0f4;
		padding: 15px;
		border-bottom: 1px solid rgba(0,0,0,0.2);
		box-shadow: 0 5px 15px rgba(0,0,0,0.5);
		box-sizing: border-box;
		font-size: 14px;

		.site {
			background: transparent;
			text-align: center;
		}

		p {
			margin-bottom: 10px;
			text-align: center;
		}

		.fa {
			margin-left: 0;		
		}

		.cookie-consent-content {
			max-width: 740px;
			text-align: center;
			margin: auto;
			transition: all 600ms;
			background-color: rgba(255, 255, 255, 0.5);
			padding: 0 15px;
			box-sizing: border-box;

			p {
				text-align: justify;
			}
			&.open {
				max-height: 900px;
				visibility: visible;
				overflow: visible;
				opacity: 1;
				padding: 15px;
				margin-top: 15px;
			}
			&.close {
				max-height: 0;
				visibility: hidden;
				overflow: hidden;
				opacity: 0;
				float: none;
			    font-size: inherit;
			    font-weight: inherit;
			    line-height: inherit;
			    color: inherit;
			    text-shadow: inherit;
			}
			h5, .h5 {
				margin-bottom: 5px;
			}
			.secondary {
				background: transparent;
				color: $ym-warning-red;
				border: 0;
			}
		}
		@include breakpoint(small) {

			.secondary {
				display: block;
				margin: auto;
				width: 100%;

				+.secondary {
					margin-top: 10px;
				}
			}
			.cookie-consent-content h5, .cookie-consent-content .h5 {
				text-align: center;
			}
		}
	}