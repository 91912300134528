.job-features-section {
    display: flex;
    width: 100%;
    padding: 0rem 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 0.25rem;
    border: 1px solid #c6cad2;
    .job-features-items-section {
      display: flex;
      padding: 0.5rem 0rem;
      align-items: flex-start;
      align-content: flex-start;
      align-self: stretch;
      flex-wrap: wrap;
      row-gap: 1.5rem;
      &.items-with-show-less{
        .job-feature-item-container:nth-child(n+9){
          display: none;
        }
        @media (min-width:768px) and (max-width: 1365px){
          .job-feature-item-container:nth-child(n+7){
            display: none;
          }
        }
      }
      
      .job-feature-item-container {
        width: 50%;
        @media (min-width: 768px) {
          width: 33.33%;
        }
        @media (min-width: 1366px) {
          width: 25%;
        }
      }
      
    }
    .job-features-show-more-less-section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;
      .job-feature-show-more-less-button {
        display: flex;
        padding: 0.375rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        border-top: 1px solid #c6cad2;
        border-right: 1px solid #c6cad2;
        border-left: 1px solid #c6cad2;
        cursor: pointer;
        box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.08);

        &:focus{
          outline: #000 2px dotted;
        }
        .show-more-less-icon {
          display: flex;
          width: 1rem;
          height: 1rem;
          justify-content: center;
          align-items: center;
          border-radius: 1rem;  
        }
        .show-more-icon-color{
          border: 1px solid #a2aaad;
          svg{
            fill: #a2aaad;
          }
        }
        .show-less-icon-color {
          border: 1px solid var(--site-primary-color,#1badba);
          svg{
            fill: var(--site-primary-color,#1badba);
          }
        }
        .show-more-less-text {
          text-align: center;
          font-family: Roboto;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .show-more-text-color {
          color: #4a4a4a;
        }
        .show-less-text-color {
          color: var(--site-primary-color,#1badba);
        }
      }
    }
  }
  