//////////////////////////////////////////////////////////////////////////////////////////

// _JOB-LISTING.SCSS

// This file includes all rules which affect the generic job listing module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	
	#job-listing {
		float: right;
		width: 63%;	
		li {
			display: inline;
		}
		
		.listing-options {
			
			b, strong {
				float: left;
			}
			
			i, em {
				float: right;
				font-style: normal;
			}
			
			ol {
				float: left;
				padding-left: 10px;
			}

			li + li:before {
				color: $miscLtGrey;
				content: "| ";
				padding: 0 7px;
			}
			.tooltip:hover {
				cursor: pointer;
			}
		}
		.backfill {
			float: right;
		}
		.listings {
			height: auto;
			background: $bkgrdLtGrey;
			margin-top: 10px;
			padding: 10px;
			
			h6, .h6 {
				font-size: 1.5em;
				font-weight: normal;
				padding: 20px 0;
				text-align: center;
			}
			.career-fair {
				float: left;
				position: relative;
				top: -5px;
				margin-right: 5px;
			}
			.job-summary-top-left {
				float: left;
				width: 80%;
				margin-top: 5px;
			}
			.listing-logo {
				width: 20%;
				float: right;
				text-align: right;
				margin: auto auto 5px;
				min-height: 50px;

				img {
					max-height: 40px;
					max-width: 100%;
				}
			}
			.job-summary {
				background: #ffffff;
				padding: 10px;
				margin-bottom: 10px;
				
				em {
					font-style: normal;
				}
				
				h3, .h3 {
					color: $primary-cta-color;
					font-size: 1.7em;
					font-weight: 400;
					line-height: 100%;
					word-break: break-word;
				
					&:hover {
						color: $linkHover;
					}			
				}
				img ~ h3, img ~ .h3 {
					margin-top: 5px;					
				}
				.date {
					width: auto;
					float: left;
					text-align: left;
					clear: both;
					color: $textGrey;
					font-style: italic;
				}
				.designations {
					float: left;
					clear: both;
					color: #000000;
					margin-bottom: 5px;
					text-align: center;
					
					li {
					    background: #ccc;
					    padding: 3px 10px;
					    border-radius: 5px;
					    margin-right: 5px;

					}
				}
				.save-open {
					float: right;
					
					li + li:before {
						color: $miscLtGrey;
						content: "| ";
						padding: 0 15px;
						cursor: pointer;
					}
					li {
						a {
							cursor: pointer;
						}
					}
				}
				
				em + div {
					padding-top: 20px;
				}
				.job-summary-top {
					position: static;
					margin: -10px auto auto -10px;
					min-height: 10px;
					
					* {
						float: left;
					}
					
					p { 
						float: left;
						padding: 2px 10px 2px 12px;
						width: 95px;
						height: 25px;
						position: relative;
						margin: auto;
					}
					
					p + p {
						margin-left: -11px;
					}
					
					.preferred {
						background: url("../img/graphics/bkgrd-preferred.png") no-repeat 0 0 transparent;
						color: white;
						z-index:100;
					}
					
					.spotlight {
						background: url("../img/graphics/bkgrd-spotlight.png") no-repeat 0 0 transparent; 
						color: black;
						z-index:90;
					}
	
					i {
						float: right;
						width: auto;
					}
				}
			@include breakpoint(medium) {
				.designations {
					margin-bottom: 0px;
					
					li {
					   display:block;
					   margin-bottom:5px;
					}
				}
			}
			}
			.job-listing-detail {
				padding-top: 20px;
				p {
					height: 100px;
					overflow: hidden;
				}
				
				img {
					float:left;
					margin: 0 7px 0 0;
					border: thin solid $miscLtGrey;
				}
				
				.affiliations-fairs {
					margin-top: 10px;
					padding-top: 8px;
					border-top: thin dotted $miscLtGrey;
				}
			}
		}
		#jt_indeed_attribution {
			text-align: right;
			margin-top: 5px;			
		}
		
		@include breakpoint(medium) {
			width: 59%
		}

		@include breakpoint(six-eighty) {
			.listing-options i {
				clear:both;
				padding-top: 10px;
			}		
		}

		
		@include breakpoint(small) {
			float:none;
			display: block;
			width: 100%;			
		}
	}
	
	
		
	@import "members-only";
	@import "vertical-network-listing";