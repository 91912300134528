// --------------------------------------------------
//
//		LOCKED PANEL OVERLAY
//		
//
// --------------------------------------------------

.locked-panel {
	position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .85);
    color: #FFF;
    @extend .p-3;
    top: 0;
    left: 0;
    z-index: 99;
    .locked-panel-cta{
    	@extend .d-flex;
		@extend .justify-content-center;
		@extend .align-items-center;
		@extend .flex-column;
		height: 100%;
		z-index: 1;
    }
    .locked-panel-heading{
    	@extend .d-flex;
    	@extend .flex-row;
    	@extend .align-items-center;

	    .locked-panel-icon{
	    	
	    	svg{
	    		width: 24px;
	    		height: 24px;
	    	}
	    	.locked-panel-svg{
	    		fill:$system-bright-yellow;
	    	}
	    }
	    .locked-panel-title{
	    	font-size: 1.1rem;
	    	@extend .py-1;
	    	@extend .px-2;
	    	color: #fff;
	    }
	}
    .locked-panel-sub-heading{
    	font-size: $h6-font-size;
    	color: #fff;
    	@extend .text-center;
    }
    .locked-panel-buttons{
    	@extend .d-flex;
        @extend .flex-column;
		@extend .flex-sm-row;
		a.secondary{
			@extend .m-2;
		}
    }
}

.locked-blur{
    -webkit-filter: blur(3px);
    filter: blur(3px);
}





