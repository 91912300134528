// --------------------------------------------------
//
//		CAREER ADVICE LANDING
//
// --------------------------------------------------
//Landing pag

.career-advice-landing-page{

	.career-advice-landing-header{
		background: linear-gradient(to right, rgba(0, 0, 0, .9), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url(../img/career-advice-landing-hero.png) no-repeat center right/cover;
		@extend .career-planning-landing-header;
		position: relative;

		.career-advice-landing-intro{
			@extend .career-planning-landing-intro;
			@extend.career-planning-max-body-width;
		
			.career-advice-landing-intro-header{
				@extend .text-left;

				h1{
					@extend .career-planning-landing-intro-header-h1;
				}

				p{
					@extend .career-planning-landing-intro-header-p;
				}
			}
		}
	}
	.career-advice-topic-nav{
		background: rgba(216, 216, 216 , .2);
	}
	
	.career-advice-landing-body{
		@extend .career-planning-landing-body;
		@extend .pt-0;
		@extend .career-planning-max-body-width;
		@include media-breakpoint-down(xs) { 
			padding-left:10px;
			padding-right:10px;
		}

		.career-advice-featured-content, .career-advice-lastest-content, .career-advice-author-content{
			@extend .position-relative;
			.entry-article-card{
				width: auto!important;
				max-width: auto!important;
				flex: 1!important;
				@include media-breakpoint-down(xs) { 
					margin-left:15px!important;
					margin-right:15px!important;
				}
			}
			.bio-card {
				width: 100%!important;
				max-width: auto!important;
				flex: 0 1 1!important;
				margin-top: 40px;
				@include media-breakpoint-down(xs) { 
					margin-left:15px!important;
					margin-right:15px!important;
				}
			}

		}
		.career-advice-author-content{
			.swiper {
			    padding-left: 10px;
			    padding-right: 10px;
			    				
			}
		}
	
		.career-advice-landing-body-header-with-view-all{
			@extend .d-flex;
			@extend .flex-row;
			@extend .justify-content-between;
			@extend .align-items-center;
			a{
				font-size:$font-size-sm ;
				font-weight: $font-weight-light-bold;
			}
		}
		.career-advice-landing-body-header{
			@extend .career-planning-body-header;
			@extend .text-left;
			@extend .mb-2;
		}
	}
}





