//////////////////////////////////////////////////////////////////////////////////////////

// _EMPLOYERS-DIRECTORY-CTA.SCSS

//////////////////////////////////////////////////////////////////////////////////////////


#employers-directory-cta{
	
	.interior {
	    background: #f5f5f5;
	    padding: 0;
	    position: relative;
	    min-height: 80px;

	    .directory-cta-inner {
		    max-width: 960px;
		    margin: auto;

		    &:before {
			    background: rgba(0, 0, 0, .15);
			    content: "";
			    position: absolute;
			    display: block;
			    width: 60%;
			    height: 80px;
			    left: 0;
			}
			&:after {
			    border-bottom: 20px solid transparent;
			    border-top: 20px solid transparent;
			    border-left: 20px solid rgba(0, 0, 0, .15);
			    content: "";
			    position: absolute;
			    display: block;
			    left: 60%;
			    top: 50%;
			    transform: translateY(-50%);
			}
			.directory-cta-btn-text {
			    display: inline-block;
			    width: 60%;
			    min-height: 80px;
			    position: relative;
			    float: left;

			    h3 {
				    position: absolute;
				    top: 50%;
				    left: 15px;
				    transform: translateY(-50%);
				    color: #fff;
				    font-size: 1.7em;
				    font-weight: 400;
				}
			}
			.directory-cta-btn {
			    position: relative;
			    width: 35%;
			    display: inline;
			    min-height: 80px;
			    float: right;

			    .secondary{
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translate(-50%, -50%);
				    border: 1px solid #fff;
				    color: #fff;
				    text-decoration: none;
				    padding: 7px 25px;
				    cursor: pointer;
				    text-align: center;
				    background: transparent;

				    &:hover{
					    border: 1px solid #fff;
					    color: currentColor;
					    text-decoration: none;
					    background: #fff;
					}
				}
			}
		@media (max-width: 960px){
			&:before {
			    width: 50%;
			}
			&:after {
			    left: 50%;
			}
			.directory-cta-btn {
			    width: 49%;
			}
			.directory-cta-btn-text {
			    width: 50%;
			}
		}
		}
	}
@include breakpoint(medium) {
	.interior {
		min-height: 160px;

		.directory-cta-inner{
		   	width: 100%;

		   	&:after {
			    display:none;
			}
			&:before {
			    width: 100%;
			}
			.directory-cta-btn-text{
				width: 100%;
            	display: block;

			    h3 {
					left: 0;
					right: 0;
					margin: auto;
					text-align: center;
				}
			}
			.directory-cta-btn {
				width: 100%;
				display: block;
			}
		}   
	}
}
}


