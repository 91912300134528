//////////////////////////////////////////////////////////////////////////////////////////

// _JOB-DATA-BASICS.SCSS

// This file includes all rules which affect the job data basics component generally 
// found in the <aside> on the Job View Page. The rules in this file are not to be 
// modified to accommmodate custom partner sites. Site-specific customization is to be  
// handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////



	.job-data-basics {
		font-size: 1 em;
		line-height: 1.5em;
		padding: 30px 0 0;
		
		h5, .h5 {
			font-size: 1.2em;
			float: none;
		}
		
		div {
			color: $textMdGrey;
			font-weight: 700;
			padding-right: 5px;
			display: block;	
		}
		
		li {
			padding: 5px 0;
			clear:both;
			display: block;
		}
		
		span {
			font-style: normal;
			float: left;
			word-break: normal;
			padding-top: 5px
		}
		
		em {
			display: block;
			color: $textMdGrey;
			text-align: left;
			font-style: normal;
		}
		
		em + em {
			padding-top: 5px;
		}
		
		.currency {
			color: $textLtGrey;
			font-size: .90em;
			margin-top: -3px;
		}
	}	