// --------------------------------------------------
//
//		BIO PAGE
//      This is the main styles for the author bio page and the career coach bio page updating these styles with update both pages
//
// --------------------------------------------------
//
.career-planning-bio-page {
  @extend .career-planning-bottom-page-padding;

  .bio-page-header-bar {
  }

  .bio-page-header-bg {
    position: absolute;
    box-shadow: $box-shadow;
    border: 1px solid $gray-lightest;
    background: rgba(216, 216, 216, 0.2);
    height: 150px;
    z-index: -1;
    width: 100%;

    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }

  .bio-page-header {
    @extend .d-flex;
    @extend .flex-column;
    @extend .career-planning-max-body-width;
    @extend .px-3;
    @extend .pb-3;
    @extend .pt-4;
  }

  .bio-page-contributor-name {
    font-size: 2em;
    font-weight: 400;
    padding-left: 25%;
    color: $textMdGrey;
    padding-left: 25%;
    margin-left: 1rem;
    @extend .my-0;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      text-align: center;
      margin-left: 0;
    }
  }

  .bio-page-contributor-title {
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    color: $gray;
    padding-left: 25%;
    margin-left: 1rem;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      text-align: center;
      margin-left: 0;
    }
  }

  .bio-page-body {
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-lg-row;
    @extend .career-planning-max-body-width;

    @include media-breakpoint-down(md) {
      margin-top: 0px;
    }

    .cta-action-item {
      margin-right: -10px;
    }
  }

  .bio-page-aside-content {
    @extend .text-center;
    @extend .mx-2;

    .bio-page-contributor-image {
    }

    @include media-breakpoint-down(xl) {
      flex: 0 0 calc(25% - 1rem);
      max-width: calc(25% - 1rem);
    }

    @include media-breakpoint-down(md) {
      flex: 0 0 calc(100% - 1rem);
      max-width: 100%;
      margin-top: 0px;
    }
    .bio-page-contributor-quote {
      @extend .my-3;
      @extend .mx-auto;
      @extend .px-3;

      max-width: 500px;

      p {
        font-family: "PT Serif", serif;
        font-weight: $font-weight-normal;
        font-size: $font-size-base;
      }
    }
    .bio-social-icons {
      @extend .px-3;
      @extend .mt-3;

      ul {
        @extend .flex-wrap;
        li {
          @extend .mb-2;
        }
      }
    }
    .bio-page-contact-button {
      @extend .mt-3;
    }
  }
  .bio-page-main-content {
    @extend .mx-3;

    @include media-breakpoint-down(xl) {
      flex: 0 0 calc(75% - 2rem);
      max-width: calc(75% - 2rem);
    }

    @include media-breakpoint-down(md) {
      flex: 0 0 calc(100% - 2rem);
      max-width: 100%;
      margin: 1.5rem 1rem;
    }

    .bio-page-main-content-BG {
      @extend .card-2;
    }

    .bio-page-main-content-header-bar {
      @extend .career-planning-main-content-header-bar;
    }
    .bio-page-main-content-headings {
      font-weight: $font-weight-light-bold;
      font-size: $font-size-sm;
      @extend .mb-1;
    }
    .bio-page-contributor-main-content {
      @extend .my-3;
      font-size: $font-size-sm;
      p {
        color: $gray;
      }
      ol {
        list-style: revert;
        margin: revert;
        padding: revert;
        font-size: initial;
      }
    }
    .bio-page-main-content-logos {
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-start;
      @extend .flex-wrap;
      gap: 1rem;
      & > div {
        @extend .mr-4;
        @extend .mb-3;
      }
      img {
        width: 100%;
        height: 100%;
        max-height: 50px;
      }
    }
    .bio-page-contributor-certifications {
      ul {
        li.bio-page-contributor-certifications-list {
          @extend .d-flex;
          @extend .flex-row;
          @extend .py-1;
          @extend .align-items-start;

          .svg-circle-icon {
            fill: $gray-lighter;
            margin-top: 5px;
            max-width: 9px;
            max-height: 9px;
            margin-right: 10px;
            width: 100%;
            flex: 0 0 10px;
          }
        }
      }
    }
  }
  .bio-page-view-all-section {
    @extend .career-planning-max-body-width;
    margin-top: 3rem;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }

  .career-advice-view-all-section-heading {
    @extend .career-planning-view-all-heading;
  }

  .career-advice-view-all-section-heading-bar {
    @extend .career-planning-view-all-heading-bar;
  }
}
