// --------------------------------------------------
//
//		CHIPS
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

.chip {
	  border: $chip-outline-size solid $chip-outline-color;
	  background: #fff;
	  padding: 0 1em;
	  line-height: $chip-height;
	  height: $chip-height;
	  border-radius: 50px;
	  display: inline-block;
	  margin: 0 $chip-margin-x;
	  position: relative;

	  // Chips that can be dismissed / closed
	  &.chip-icon-append {
		  padding: 0 calc(1em + #{$icon-size}) 0 1em;
	  }

	.chip-close-icon {
		display: inline-block;
		box-sizing: border-box;
		width: 18px;
		height: 18px;
		border-width: 3px;
		border-style: solid;
		border-color: currentcolor;
		border-radius: 100%;
		background: currentColor url('../img/filter-close-icon.png');
		right: 8px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-repeat: no-repeat;
  	background-position: center;
  	background-size: 14px;
	}
}

// Give Chips Margin when showing all
.filter-panel.show-all-chips {
	.chip {
		margin-bottom: $chip-margin-y;
	}
}