// -------------------------------------------------------------------
//
//		SORTING COMPONENTS
//		
//
// -------------------------------------------------------------------

select.sorting-select {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EDown Icon%3C/title%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='24' height='24'/%3E%3Cpath d='m2.226 7.226c0.1443-0.14466 0.34023-0.22596 0.54455-0.22596 0.20433 0 0.40025 0.081301 0.54455 0.22596l8.6851 8.6867 8.6851-8.6867c0.19455-0.19455 0.47811-0.27053 0.74387-0.19932 0.26576 0.07121 0.47334 0.27879 0.54455 0.54455s-0.0047701 0.54932-0.19932 0.74387l-9.2297 9.2297c-0.1443 0.14466-0.34023 0.22596-0.54455 0.22596s-0.40025-0.081301-0.54455-0.22596l-9.2297-9.2297c-0.14466-0.1443-0.22596-0.34023-0.22596-0.54455 0-0.20433 0.081301-0.40025 0.22596-0.54455z' fill='%236B6B6B'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 17px;  
    border: 1px solid transparent;
    color:$gray;
    font-size: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: .7rem;
    padding-right: 2.2rem;
    outline: none;
    
    &:hover{
    	box-shadow: $box-shadow;
        border-radius: $border-radius;
        border-color: $gray-lightest;
    }
    &:focus{
        outline: #000 2px dotted;
    }
}