// --------------------------------------------------------
//
//  ICONS
//  Framework 2.0.0+
//
// --------------------------------------------------------


// Icon Animation
// For non-input element icons
// ---------------------------------------

.anim-icon {
  width: $icon-size-lg !important;
  height: $icon-size-lg !important;
  //background: red;
  //padding: 10px !important;
  margin: 0;
//  margin: 20px;
  font-size: $icon-size;
  display: block;
  position: relative;
  vertical-align: middle;
  
  a {
    position: absolute;
    left: 0;
    top: 0;
    width: $icon-size;
    height: $icon-size;
    //border: $icon-border-width-off $icon-border-style-off $icon-color-off;
    border-radius: 100%;
    display: block;
    font: normal normal normal $icon-size/1 FontAwesome bootstrap-icons;
    //color: $icon-color-off;
    //font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;

    &:after {
     left: 1px;
     top: 1px;
     //margin-top: -0.5em;
     display: block;
     position: relative;
     text-align: center;
    }
  }

  &:hover > a {
    //animation: check-in .3s forwards;
    transition: box-shadow 0.7s;
    color: $primary;
    //transition: background-color .1s .2s, box-shadow 1s;
    //border-width: $icon-border-width-on;
    //border-style: $icon-border-style-on;

    &:after {
     // animation: icon .3s forwards;
    }
  }
}

// Icon Animation
// For checkbox element icons
// ---------------------------------------

//.anim-icon {
//  width: $icon-size;
//  height: $icon-size;
//  margin: 20px;
//  font-size: 13px;
//  display: inline-block;
//  position: relative;
//  vertical-align: middle;
//
//  input{
//    display: none;
//  }
//  
//  label{
//    position: absolute;
//    left: 0;
//    top: 0;
//    width: 100%;
//    height: 100%;
//    border: $border-width-off $border-style-off $off-color;
//    border-radius: $border-radius;
//    display: block;
//    font: normal normal normal 13px/1 FontAwesome;
//    color: $off-color;
//    font-size: inherit;
//    text-rendering: auto;
//    -webkit-font-smoothing: antialiased;
//    &:after{
//      left: 0;
//      top: 50%;
//      margin-top: -0.5em;
//      display: block;
//      position: relative;
//      text-align: center;
//    }
//  }
//  input:checked + label{
//    animation: check-in .3s forwards;
//    transition: background-color .1s .2s, box-shadow 1s;
//    border-width: $border-width-on;
//    border-style: $border-style-on;
//    &:after{
//      animation: icon .3s forwards;
//    }
//  }
//}


// Keyframes

@keyframes icon {
  0% {
    margin-top: -0.5em;
    font-size: 1.5em;
  }
  100% {
    font-size: 1em;
    opacity: 1;
    color: white;
  }
}

@keyframes check-in {
  0% {
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  80% {
    left: -5%;
    top: -5%;
    width: 110%;
    height: 110%;
  }
  100% {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes check {
  0% {
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
  }
  10% {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  80% {
    left: -5%;
    top: -5%;
    width: 110%;
    height: 110%;
  }
  90% {
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
  }
  100% {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}