//////////////////////////////////////////////////////////////////////////////////////////

// _FEEDBACK.SCSS

// This file includes all rules which affect the questions/feedback module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	
	
	#feedback {

		padding-top: 25px;

		.interior {
			background: $bkgrdLtGrey;
			text-align: center;
			padding-bottom: 5px;
			
			p{
				margin-bottom: 0;
			}
		}
		@include breakpoint(small) {
			padding: 20px 10px;
			box-sizing: border-box;
		}
	}