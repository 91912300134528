//////////////////////////////////////////////////////////////////////////////////////////

// _COMPANY-PROFILE.SCSS

// This file includes all rules which affect the company profile component generally 
// found in the sidebar on the Job View Page. The rules in this file are not to be modified
// to accommmodate custom partner sites. Site-specific customization is to be handled 
// in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////



	#company-profile {
		
		.company {
			padding: 20px 0;
			@extend .d-flex;
			@extend .align-items-center;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .flex-column;
			@extend .flex-md-row;
		}
		img.badging{
			width: auto;
			height: 100%;
			max-height:70px;
		}
		h2 {
			display:inline-block;
			font-size: 2.2em;
			font-weight: 400;
			padding: 10px 0;
			@extend .flex-fill;
			@extend .text-center;
			@extend .text-md-left;
			@extend .pl-0;
			@extend .pl-md-3;
		}
		h5, .h5 {
			font-size: 1.3em;
			padding: 20px 0 5px;
		}
		
		aside {
			
			ul{
				li{
					div {
						font-weight: 700;
						padding-bottom: 0px;
						
						* {
							font-weight: 400;
						}
						
						span {
							display: block;
						}
					}
				}
			}
			
			li + li {
				padding: 10px 0 0;
			}
		}
		
		.company-data-basics h5, #job-listing h5, .company-data-basics .h5, #job-listing .h5 {
			padding: 10px 0;
		}
		
		.jt_notice {
			padding-bottom: 20px;
			margin: 20px 0;
			border-top: thin dotted $miscLtGrey;
			border-bottom: thin dotted $miscLtGrey;
			margin-top:0;
		}
		
		@include breakpoint(small) {
			
			.company img {
				border-right: none;
				margin: 0 0 5px 0;
			}
			
			aside {
				float: none;
				width: 100%;
			}
		}
	}	