//////////////////////////////////////////////////////////////////////////////////////////

// _CALL-TO-ACTION.SCSS

// This file includes all rules which affect the generic call-to-action anchors. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

// INDEX

	// 1.0 - Primary CTA
	
	// 2.0 - Secondary CTA
	
	// 3.0 - Tertiary CTA

	// 4.0 - Social CTA
	
	
////////////////////////////////////////////////////////

// 1.0 - PRIMARY CTA

////////////////////////////////////////////////////////

	.primary {
		background-color: $primary-cta-color; 
		color:#FFF;
		font-weight: 500;
		display:inline-block;
		text-decoration:none;
		border:none;
		padding:10px 25px;
		cursor: pointer;
		text-align: center;
	}
	
	.primary.subdued {
		opacity: 0.7;
	}

	.primary:hover {
		background-color: $primary-hover-background; 
		text-decoration:none;		
		cursor: pointer;
		color: #fff;
	}
	
	.primary.subdued:hover, .primary.subdued:hover {
		opacity: 0.7;
	}
	
	.primary.inactive {
		background-color: #f8f8f8;
		color: #666;
	}
	
////////////////////////////////////////////////////////

// 2.0 - SECONDARY CTA

////////////////////////////////////////////////////////
	
	.secondary {
		background-color: $secondary-cta-color; 
		color:#FFF;
		display:inline-block;
		text-decoration:none;
		border:none;
		padding:7px 25px;
		cursor: pointer;
		text-align: center;
	}

	.secondary.subdued {
		opacity: 0.7;
	}
	
	.secondary:hover {
		background-color: $secondary-hover-background; 
		text-decoration:none;		
		cursor: pointer;
		color: #FFF;
	}
	
	.secondary.subdued:hover {
		opacity: 0.7;
	}
	
	.secondary.inactive {
		background-color: #f8f8f8;
		color: #666;
	}
	
////////////////////////////////////////////////////////

// 3.0 - TERTIARY CTA

////////////////////////////////////////////////////////
	
	.tertiary {
		background-color: $bkgrdLtBlue ;
		color: $linkDefault;
		display:inline-block;
		text-decoration:none;
		border:none;
		padding:10px 25px;
		cursor: pointer;
		text-align: center;
	}
	
	.tertiary:hover {
		background-color:#555555;
		color: $white;
		text-decoration:none;
		cursor: pointer;
	}
	

			
		.call-to-action {
			float:right;
			
			.secondary {
				display: inline-block;
				margin-left: 5px;
				padding:10px 25px;
			}
		}

////////////////////////////////////////////////////////

// 4.0 - SOCIAL CTA

////////////////////////////////////////////////////////

	.btn-facebook {
		background-color: $facebookBlue;
	}
	.btn-linkedin {
		background-color: $linkedinBlue;
	}
	.btn-twitter {
	 	background-color: $twitterBlack; 
	}
	.btn-gplus{
	 	background-color: $googleRed; 
	}
	.btn-social {
		color: $white;
		padding: 8px 12px;
		display: inline-block;
		border-radius: 4px;
		text-align: center;
	}
	.btn-social:hover {
		opacity: 0.9;
		color: $white;
	}
	.btn-social+.btn-social {
		margin-left: 5px;
	}
	.default-form-box i.fa-brands {
		color: inherit;
		display: inline-block;
		padding-top: 0;
	}
	.default-form-box i.fa {
		color: inherit;
		display: inline-block;
		padding-top: 0;
	}






        
             
          
        
 