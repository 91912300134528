//////////////////////////////////////////////////////////////////////////////////////////

// _UPLOAD-RESUME.SCSS

// This file includes all rules which affect the generic recent-searches module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

	#evaluate-resume{
		text-align: center;
	}
	.upload-resume {
		
		sup, sub {
			font-size: 1em;
		}
		.file-evaluation span{
			padding: 0;
		}
		.file-evaluation {
			margin: 20px 0 0 10px;
			float: left;
		}
		.file-evaluation-text{
			float: left;
		}
		.onoffswitch {
			position: relative;
			width: 60px;
			-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
			margin-left: 10px;
			float: left;
		}
		.onoffswitch-checkbox {
			display: none;
		}
		.onoffswitch-label {
			display: block;
			overflow: hidden;
			cursor: pointer;
			border: 1px solid $white;
			border-radius: 25px;
		}
		.onoffswitch-inner {
			display: block;
			width: 200%;
			margin-left: -100%;
			transition: margin 0.3s ease-in 0s;
		}
		.onoffswitch-inner:before, .onoffswitch-inner:after {
			display: block;
			float: left;
			width: 50%;
			height: 23px;
			padding: 0;
			line-height: 25px;
			font-size: 12px;
			color: $white;
			font-weight: bold;
			box-sizing: border-box;
		}
		.onoffswitch-inner:before {
			content: "Yes";
			text-transform: uppercase;
			padding-left: 8px;
			background-color: #8CE196;
			color: $black;
		}
		.onoffswitch-inner:after {
			content: "No";
			text-transform: uppercase;
			padding-right: 11px;
			background-color: #EEEEEE;
			color: $black;
			text-align: right;
		}
		.onoffswitch-switch {
			display: block;
			width: 15px;
			margin: 4px;
			background: $white;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 33px;
			border: 2px solid $white;
			border-radius: 25px;
			transition: all 0.3s ease-in 0s; 
		}
		.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
			margin-left: 0;
		}
		.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
			right: 0px; 
		} 
						
		@include breakpoint(medium) {
			box-sizing: border-box;
			padding: 10px;
		}
		
		@include breakpoint(small) {
			
			.file-upload-form .file-upload-container {
				float: none;
			}
			
			.file-upload-form .primary {
				float: none;
				margin-top: 20px;
			}
		}
	}