//////////////////////////////////////////////////////////////////////////////////////////

// _SEARCH-FORM.SCSS

// This file includes all rules which affect the generic search form module.
// The rules in this file are not to be modified to accommmodate custom partner sites.
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

#job-search-form {
  form {
    padding: 20px;
    background: none;
    position: absolute;
    bottom: 0;
    left: 0;
    .location_autocomplete {
      float: left;
      border: none;
      margin: 0;
      padding: 0 20px 0 0;
      width: 35%;
    }
    fieldset {
      float: left;
      border: none;
      margin: 0;
      padding: 0 20px 0 0;
      width: 35%;

      input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
      }

      .primary {
        font-size: 1.3em;
        float: right;
        padding: 11px 45px;
      }
      .select-style {
        width: 100%;
      }
      .select-style select {
        padding: 10px 5px;
        width: 100%;
      }

      input[type="text"],
      input[type="search"],
      select {
        height: 41px;
      }
    }

    fieldset.call-to-action {
      padding-right: 0;
      width: 20%;
    }

    @include breakpoint(medium) {
      .location_autocomplete {
        width: 30%;
      }
      fieldset {
        .primary {
          font-size: 1.2em;
          padding: 9px 30px;
        }
      }
    }

    @include breakpoint(small) {
      position: relative;
      background: $white;
      margin-top: 100px;
      padding-bottom: 0;
      .location_autocomplete {
        float: none;
        display: block;
        padding: 0 0 20px;
        width: 100%;
      }
      fieldset {
        float: none;
        display: block;
        padding: 0 0 20px;
        width: 100%;

        button {
          width: 100%;
        }

        .primary {
          font-size: 1.2em;
          text-align: center;
          float: none;
          display: block;
        }
      }
      fieldset.call-to-action {
        width: 100%;
      }
    }
  }
}

//Job Alert Search form home-page

#home-page {
  #job-alert-search-form {
    padding-top: 0;
    .site {
      background: transparent;
    }
    input {
      color: #333;
    }

    &.expanded-img {
      margin-bottom: -122px;
      top: -122px;
      position: relative;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;

      fieldset {
        background: transparent;

        .form-content {
          .form-content-bottom {
            .checkbox-container {
              .checkmark:after {
                border-color: #333;
              }
            }
          }
        }
      }
    }
    fieldset {
      border: none;
      margin: 0 auto;
      padding: 15px;
      background: #f5f5f5;

      .form-content {
        width: 75%;
        float: left;

        .form-content-top {
          .select-style {
            background: #fff;
            width: 48%;
            margin-right: 2%;
            display: inline-block;
            -webkit-box-shadow: inset 0px 0px 0px 1px #ccc;
            -moz-box-shadow: inset 0px 0px 0px 1px #ccc;
            box-shadow: inset 0px 0px 0px 1px #ccc;
            select {
              border: none;
              box-shadow: none;
              background: transparent;
              width: 100%;
            }
          }
          padding-bottom: 10px;

          &.keyword-only {
            input#keywords {
              width: 98%;
              float: none;
            }
          }
          input {
            &#keywords {
              width: 48%;
              padding: 10px;
              margin-right: 2%;
              float: left;
            }
          }
          .location_autocomplete {
            width: 48%;
            margin-right: 2%;
            display: inline-block;
          }
        }
        .form-content-bottom {
          .JAS-email-disabled {
            opacity: 0.5;
          }
          .checkbox-container {
            position: relative;
            width: 48%;
            margin-right: 2%;
            display: inline-block;
            float: left;
            line-height: 40px;

            input {
              position: relative;
              opacity: 0;
              cursor: pointer;
              height: 20px;
              width: 30px;
              z-index: 999;

              &:checked ~ .checkmark:after {
                display: block;
              }
              &:checked ~ .checkmark {
                background-color: currentColor;
              }
            }
            label {
              left: 30px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              line-height: normal;
              font-size: 1.1em;
            }
            .checkmark {
              position: absolute;
              top: 50%;
              left: 0;
              height: 20px;
              width: 20px;
              border: 1px solid currentColor;
              transform: translateY(-50%);

              &:after {
                content: "";
                position: absolute;
                display: none;
                left: 7px;
                top: 2px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
          input#JAS-email {
            width: 48%;
            padding: 10px;
            margin-right: 2%;
          }
        }
      }
      .form-button {
        display: inline-block;
        width: 25%;

        svg {
          padding-left: 10px;
          vertical-align: bottom;
          position: relative;
          top: 1px;

          #job-search-icon {
            fill: currentColor;
          }
        }
        button.primary {
          padding: 8px 10px;
          width: 100%;
          font-size: 1.3em;
        }
      }
      @include breakpoint(medium) {
        .form-content {
          width: 100%;
          float: none;

          .form-content-top {
            &.keyword-only {
              input#keywords {
                width: 100%;
                margin-right: 0;
              }
            }
            input {
              &#keywords {
                margin-right: 2%;
                width: 49%;
              }
            }
            .location_autocomplete,
            .select-style {
              width: 49%;
              margin-right: 0;
            }
          }
          .form-content-bottom {
            input {
              &#JAS-email {
                width: 49%;
                margin-right: 0;
              }
            }
            .checkbox-container {
              width: 49%;
              margin-right: 2%;
            }
          }
        }
        .form-button {
          display: block;
          width: auto;
          margin-top: 10px;
        }
      }
      @include breakpoint(small) {
        .form-content {
          .form-content-top {
            &.keyword-only {
              input#keywords {
                margin-bottom: 0px;
              }
            }
            input {
              &#keywords {
                margin-right: 0;
                margin-bottom: 10px;
                width: 100%;
                float: none;
              }
            }
            .location_autocomplete,
            .select-style {
              width: 100%;
            }
          }
          .form-content-bottom {
            .checkbox-container {
              width: 100%;
              margin-right: 0;
              margin-bottom: 10px;
              float: none;
            }
            input {
              &#JAS-email {
                width: 100%;
              }
            }
          }
        }
      }
    }
    @include breakpoint(medium) {
      &.expanded-img {
        margin-bottom: -172px;
        top: -172px;
      }
    }
    @include breakpoint(small) {
      &.expanded-img {
        margin-bottom: 0px;
        top: 0px;
        color: currentColor;
        background: #f5f5f5;

        fieldset {
          .form-content {
            .form-content-bottom {
              .checkbox-container {
                .checkmark:after {
                  border-color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

//Autocomplete base styles home-page/ job search page

.location_autocomplete {
  position: relative;

  #location-icon {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    color: #7b7b7b;
    width: 0;
    line-height: 41px;
    padding: 0 10px;
  }
  input#location {
    padding-left: 30px;
    width: 100%;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}
.ui-autocomplete {
  width: 15%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  z-index: 999;
  max-height: 300px;
  overflow-y: auto;
  li {
    min-height: 40px;
    border-bottom: 1px dotted #ccc;
    color: #666;
    background-color: white;

    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
    &:last-child {
      border-bottom: none;
    }
    div {
      padding: 10px;
    }
  }
  .ui-state-active {
    height: 100%;
    background-color: #eee;
  }
}

//Autocomplete job search page and job view page

#inner-page-header {
  #job-search-form form {
    fieldset.call-to-action {
      width: 10%;
    }
    .location_autocomplete {
      width: 50%;
      padding-right: 5px;

      input#location {
        width: 65%;
        float: left;
      }

      .radius_select {
        width: 34%;
        float: left;
        // height: 39px;
        background: #fff;
        border: 1px solid #ccc;
        border-left: none;

        select {
          width: 100%;
          padding: 5px;
          background: transparent;
          border-left: none;
          border-radius: 0px;
          border: none;

          // Changed height for new job search
          height: 39px;
          &#radius {
            color: #666;
          }
        }
      }
      .fade_radius {
        opacity: 0.5;
      }
    }
    @media (max-width: 600px) {
      .location_autocomplete {
        padding-right: 0px;
      }
      input#location {
        width: 69%;
      }
      .radius_select {
        width: 30%;
      }
    }
    @include breakpoint(small) {
      .location_autocomplete {
        width: 100%;
        display: inline-block;
        padding-bottom: 10px;
        padding-right: 0px;
      }
      fieldset.call-to-action {
        width: 100%;
      }
    }
  }
}

.inner-jobseeker-page .ui-autocomplete {
  width: 18%;
}

// CAREERS-3362 Fix for Checkbox

#jt_search {
  legend {
    position: absolute;
    left: -999em;
  }

  > .call-to-action + fieldset.search-position-title {
    display: block;
    min-width: 87% !important;
    width: 87% !important;
    text-align: left;
    margin: 0 auto;
  }

  > .search-position-title {
    > div > input[type="checkbox"]:first-of-type {
      width: initial !important;
    }
    > .jt_subcontent {
      margin-top: 5px;
      float: left;
    }
  }
}
svg.icon {
  max-width: inherit;
  height: 1em;
  display: inline;
  fill: currentColor;
}
