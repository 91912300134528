//////////////////////////////////////////////////////////////////////////////////////////

// _INNER-PAGE-HEADER.SCSS

// This file includes all rules which affect the generic inner page header with search form. 
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


	#inner-page-header {
		
		background: $bkgrdLtGrey;
		padding: 10px 0 0;
		
	
		.site-name-tag {
			text-align: left;
			float: left;
			width: 32%;
			padding: 0 20px 10px;
			box-sizing: border-box;
		
			h1 {
				font-size: 2em;
				line-height: 1;
				padding-top: 10px;
				margin: 0;
			}
		
			em {
				font-size: 1.3em;
				font-style: normal;
			}
		}

		#job-search-form {
			width: 65%;
			float: right;
			
			form {
				position: relative;
				padding-top: 20px;
				bottom: 5px;
				box-sizing: border-box;

		
				fieldset {
					float: left;
					padding-right: 10px;
			
					input {
						padding: 10px;
					}
			
					.primary {
						font-size: 1.3em;
						float: right;
						padding: 8px 45px;
					}
				}
		
				fieldset.call-to-action {
					padding-right: 0;
					
					.secondary {
						padding: 13px 15px;
						font-size: 1.2em;
					}
				}
			}
		}
		
		@include breakpoint(medium) {
			.site-name-tag {
				float: none;
				width: 100%;
				
				h1 {
					display: inline;
					padding-right: 10px;
				}
			}
			
			#job-search-form {
				width: 100%;
				float: none;
				
				form fieldset {
					padding-right: 5px;
				}
			}
		}
		
		@include breakpoint(small) {
			background: $white;
			padding: 0;
			
			.site-name-tag {
				float: none;
				width: 100%;
				text-align: center;
				background: $bkgrdLtGrey;
				padding: 20px 10px;
				
				h1 {
					display: block;
					font-size: 2.5em;
					padding-top: 0;					
				}
				
				em {
					font-size: 1.7em;
					font-style: normal;
					display:block;
					padding-top: 10px;
				}
			}
			
			#job-search-form {
				width: 100%;
				float: none;
				
				form { 
					margin-top: 10px;
					width: 100%;
					
					fieldset {
						float:none;
						display: block;
						width: 100%;
						box-sizing: border-box;
						width:100%;					
					
						.primary {
							font-size: 1.2em;
							text-align: center;
							float: none;
							display: block;
						
						}
					}
					
					fieldset.call-to-action {
						padding-right: 0;
					
						.secondary {
							float: none;
							display: block;
							text-align: center;
							font-size: 1.5em;
							margin: 0;
						}
						
						button.icon-search:before { 
							content: 'Search';
							font-family: arial, sans-serif;
						}
						button.fa-search:before {
							content: 'Search';
							font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
						}
					}

				}
			}
		}
		
	}	