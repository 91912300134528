
// ---------------------------------------------------------------
//	FRAMEWORK 2.1 and below
// ---------------------------------------------------------------
.expanded-footer{
	display: block;
    position: fixed;
    z-index: $zindex-fixed;
    top: auto;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: auto;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    visibility: hidden;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property:  visibility;
    transition-property:  visibility;
	
	&.show-footer{
    	visibility: visible;
        
    }
	a.footer-close-btn {
		position: absolute;
	    right: 10px;
	    top: -31px;
	    z-index: 9;
	    background: #fff;
	    padding: 5px 10px;
	    border-radius: 4px 4px 0px 0px;

	    svg {
		    max-width: 15px;
		    #icon-footer-close {
			    fill: #6c757d;
			}
		}
	}
	
    #footer {
        max-width: 100%;
	    padding-top: 0px;
	    

	    .powered-by {
		    padding: 10px;
		}
	}
}
#expanded-footer-background {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.9);
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}






    
 