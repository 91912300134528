.sort-dropdown-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .dropdown-toggle {
    display: flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    border-radius: 2.5rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;

    color: #4a4a4a;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.875rem;

    &::after {
      content: none;
    }
  }
  .dropdown-menu {
    color: #222;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: auto;
    .dropdown-item {
        padding-inline: 0.5rem;
    }
    .sort-inactive, .sort-active {
      color: #222;
      font-family: Roboto, sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .sort-icon {
    font-size: 1rem;
    color: #5b6770;
  }
}
