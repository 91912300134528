//////////////////////////////////////////////////////////////////////////////////////////

// _SWIPER-CAROUSEL.SCSS

//////////////////////////////////////////////////////////////////////////////////////////

.swiper-carousel{
    .swiper {
        width: 95%;
        
        .swiper-slide{
            @extend .d-flex;
            @extend .justify-content-center;
            height: auto;
            width: 300px;
        }
        @include media-breakpoint-down(xs) { 
             width: 90%;
        }
    }

    .swiper-button-prev {
        left: -5px;

        @include media-breakpoint-down(xs) { 
            left: 3px;
        }
    }
    .swiper-button-next {
        right: -5px;

        @include media-breakpoint-down(xs) { 
             right: 3px;
        }
    }

    .swiper-button-next,  .swiper-button-prev {
        width: 46px;
        height: 46px;
        color: #757575;
        margin-bottom: auto;
        margin-top: auto;
        box-shadow: $box-shadow;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        @include media-breakpoint-down(xs) { 
            width: 36px;
            height: 36px;
        }
        &:focus-within{
             outline: #000 2px dotted;

        }
        &:after {
            font-size: 20px;
            line-height: normal;
            @include media-breakpoint-down(xs) { 
                font-size: 16px;
            }
        }
    }
}
