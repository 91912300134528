//////////////////////////////////////////////////////////////////////////////////////////

// _BREADCRUMBS.SCSS

// This file includes all rules which affect the generic breadcrumbs found on interior 
// pages. The rules in this file are not to be modified to accommmodate custom partner 
// sites. Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////



	.breadcrumbs {
		padding: 10px 0;
		
		li {
			display: inline-block;
			
			a:hover {
				cursor: pointer;
			}
		}
		
		li + li:before {
		    color: $miscLtGrey;
    		content: "/";
    		padding: 0 5px;
		}
	}	