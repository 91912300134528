// -------------------------------------------------------------------
//
//		FORM TYPE COMPONENTS
//		Created for Framework 2.1.0+
//
//		Use for form related components
//
// -------------------------------------------------------------------

// 'ig' stands for 'input group' -- class names need to be different than Bootstrap, and shouldn't be too long
.ig_ {

	&input-accordion {
		.input-group-prepend .input-group-text {
			border-bottom-left-radius: 0;
			border-bottom: 0 !important; 
		}
		.input-group-append .input-group-text {
			border-bottom-right-radius: 0;
			border-bottom: 0 !important; 
		}
		input.form-control {
			border-bottom: 0 !important; 
		}
	}

}