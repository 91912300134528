//////////////////////////////////////////////////////////////////////////////////////////

// _MEMBERSHIP-CTA.SCSS


//////////////////////////////////////////////////////////////////////////////////////////

#results-page{
	.members-only .membership-promotion-section{
		float: right;
	    display: block;
	    clear: both;
	    
	    a{
	    padding: 0 5px;
	    }
	}

}

#basic-page{ 

	.payment-membership-promotion-container {
	   	padding-top: 20px;
	}

	.dual-login .payment-membership-promotion-container {

		p{
			padding: 0;
		    padding-bottom: 10px;
		}
		a{
	      	font-weight: 500;
		}
		h3{
		    font-weight: 500;
			padding-bottom: 20px;
			display: block;
		}

	}

	.my-account .listing-detail.settings {

		div.membership_promotion_section{
			float: none;      
    		width: auto;      
    		margin: 0;      
    		padding: 0;      
    		border: none; 
		}
		div.membership_questions_section{
            float: none;      
    		width: auto;      
    		margin: 0;      
    		padding: 0;      
    		border: none;
		}
		a#membership_promotion_candidate_account_page {       
		    padding: 10px 0;       
		}      
		.account-membership-container {
			p {       
			    padding: 10px 0;       
			}       
		}
		div.switch-field {          
		    overflow: hidden; 
		    float: none;         
		    width: auto;         
		    margin: auto;         
		    padding: 5px 0 10px 0;         
		    border: none;         
		    background: transparent; 

		    input {          
			    position: absolute !important;          
			    clip: rect(0, 0, 0, 0);          
			    height: 1px;          
			    width: 1px;          
			    border: 0;          
			    overflow: hidden;

			    &:checked + label{
			       background-color: #999;          
				  -webkit-box-shadow: none;          
				  box-shadow: none;        
				  color:#fff;  
			    }          
			}

			label {          
			  	float: left;
			  	display: inline-block;          
			  	width: 20%;          
			  	background-color: #f5f5f5;         
			  	color: rgba(0, 0, 0, 0.8);          
			  	font-size: 14px;          
			  	font-weight: normal;          
			  	text-align: center;          
			  	text-shadow: none;          
			  	padding: 6px 14px;          
			  	border: 1px solid rgba(0, 0, 0, 0.2);          
			  	-webkit-transition: all 0.1s ease-in-out;          
			  	-moz-transition:    all 0.1s ease-in-out;          
			  	-ms-transition:     all 0.1s ease-in-out;          
			  	-o-transition:      all 0.1s ease-in-out;          
			  	transition:         all 0.1s ease-in-out;

				&:hover {
					cursor: pointer;
				} 

				&:first-of-type {
					border-radius: 4px 0px 0px 4px;  
				}

				&:last-of-type {          
					border-radius: 0 4px 4px 0;          
				}                           
			}     

		}

	}

	.new-account{

		div.switch-field {          
		    overflow: hidden; 
		    float: none;         
		    width: auto;         
		    margin: auto;         
		    padding: 5px 0 10px 0;         
		    border: none;         
		    background: transparent; 

		    input {          
			    position: absolute !important;          
			    clip: rect(0, 0, 0, 0);          
			    height: 1px;          
			    width: 1px;          
			    border: 0;          
			    overflow: hidden;

			    &:checked + label{
			       background-color: #999;          
				  -webkit-box-shadow: none;          
				  box-shadow: none;        
				  color:#fff;  
			    }          
			}

			label {          
			  	float: left;
			  	display: inline-block;          
			  	width: 20%;          
			  	background-color: #f5f5f5;         
			  	color: rgba(0, 0, 0, 0.8);          
			  	font-size: 14px;          
			  	font-weight: normal;          
			  	text-align: center;          
			  	text-shadow: none;          
			  	padding: 6px 14px;          
			  	border: 1px solid rgba(0, 0, 0, 0.2);          
			  	-webkit-transition: all 0.1s ease-in-out;          
			  	-moz-transition:    all 0.1s ease-in-out;          
			  	-ms-transition:     all 0.1s ease-in-out;          
			  	-o-transition:      all 0.1s ease-in-out;          
			  	transition:         all 0.1s ease-in-out;

				&:hover {
					cursor: pointer;
				} 

				&:first-of-type {
					border-radius: 4px 0px 0px 4px;  
				}

				&:last-of-type {          
					border-radius: 0 4px 4px 0;          
				}                           
			}
		}     

	} 
}

// --------------------------------------------------
//
//		NEW CAREER FAIR STYLES
//		Framework 2.0.0+
//
// --------------------------------------------------

// For inside Job Details on Job Search
.job-members-only-cta {
	@extend .tile-row;
	@extend .border-bottom;
	@extend %align-items-center;
	padding-left: $grid-gutter-width-md + $grid-gutter-width-sm;
	padding-right: $grid-gutter-width-md + $grid-gutter-width-sm;
	flex-wrap: nowrap;

	// Holds all details
	.mo-details {
		@extend %col-xl-8;
		@extend %col-md-7;
		@extend %col-12;
		@extend %col-12;
		@extend .rm-p-l;
		flex-grow: 2 !important;
	}

	.mo-callout {
		@extend %row;
		@extend .rm-m-hor;
		@extend .h6;
		@extend .rm-m-ver;
		margin-bottom: $grid-gutter-width-xs;
	}

	.mo-title-row {
		@extend %row;
		@extend .rm-m-hor;
		font-weight: 700;
		@include text-truncate();
		//@extend .justify-content-between;
	}

	.mo-desc {
		@extend %row;
		@extend .rm-m-hor;
	}

	.mo-cta {
		//@extend %col-xl-3;
		//@extend %flex-lg-fill;
		@extend .rm-p-r;
		//text-align: right;
    display: flex !important;
    flex: 1 1 0 !important;
    flex-flow: row-reverse;
	}
}

// MOBILE AND BELOW
// ----------------------------------------

@media (min-width: map-get($grid-breakpoints, "xs")) and (max-width: map-get($grid-breakpoints, "sm")) {

	// For inside Job Details on Job Search
	.job-members-only-cta {
		.mo-cta {
			margin-top: $grid-gutter-width-xs;
			flex: 1 0 auto;

			a.primary {
				flex: 1 0 auto;
			}
		}
	}

}