//////////////////////////////////////////////////////////////////////////////////////////

// _JOB-VIEW-PAGE.SCSS

// This file includes all rules which affect the generic job detail view module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


.job-view-page {
	 padding: 0 10px;
	 box-sizing: border-box;

	// Make all elements within Job Search to be right content sizing
	// Current framework CC Framework (2.0.0) doesn't have this on all elements
	// and we don't want to break the rest of the system, so it's specified here
	*,
	*::before,
	*::after {
	  box-sizing: border-box;
	}
	.job-results-details, .job-hiring-company, .tertiary-content-tile {
	    margin: 8px 0px;
	}
	.job-results-details{
		overflow-x: hidden;
		margin-top:0px;
	}
	.job-details-logo {
		@extend %d-sm-flex;
	}
	// Job details section
	.job-results-details {
		.job-details {
			@extend %col-sm-auto;
			min-width: 0;	
		}
		.job-cta {
		    @extend %col-lg-auto;
		}
		.job-details-logo-container{
			@extend %col-sm-auto;
		}
	}
	@media (max-width: map-get($grid-breakpoints, "xl")){
		.job-career-fair-cta .cf-cta {
		    max-width: 100%;
		}
	}
	@media (min-width: map-get($grid-breakpoints, "md")){
		.job-view-container .job-main-data .job-details {
			flex: 1;
		}
	}
	@media (max-width: map-get($grid-breakpoints, "md")){
		.job-main-data {
			.job-cta {
			    margin-top: 8px;
			}
		}
	}
}
