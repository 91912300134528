//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-COURSES.SCSS

//////////////////////////////////////////////////////////////////////////////////////////


#basic-page	{
	.cards_item {
		padding: 0px;

		&:not(:last-child) {
			padding-right: 10px;
		}
	}
	@include breakpoint(medium) {
		.cards_item:not(:last-child) {
			padding-right: 0;
		}
	}
}	  	

#featured-courses {

	.call-to-action {
	    padding: 20px 0 40px;
	    font-size: 1.1em;
	    clear: both;
	}

	.course_cards {
		display: flex;
		flex-wrap: nowrap;
		gap: 10px;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;

		.course_item {
		width:100%;
	    display: flex;
			.course_card {
				width:100%;
				overflow: hidden;
				transition: all 0.3s ease 0s;
				border: 1px solid #ccc;
				text-align: center;
				background: #fff;
			
				&:before {
					border-top: 5px solid currentColor;
					content: "";
					display: block;
				}
				&:hover {
					margin-top: -5px;
					-moz-box-shadow: 0px 2px 7px rgba(0,0,0,0.25);
					-webkit-box-shadow: 0px 2px 7px rgba(0,0,0,0.25);
					box-shadow: 0px 2px 7px rgba(0,0,0,0.25);
					margin-bottom: 5px;
				}

				.card_icon {
					text-align: left;
					color: #333;
					position: relative;

					svg {
						width: 18px;
						height: auto;
					}

					#card_svg_Fill {
						fill: #CCC;
					}

					.card_info {
						position: absolute;
						top:10px;
						right:10px;
						line-height:24px;
					}

					svg.card_svg {
						display: inline-block;
						position: absolute;
						left: 10px;
						top: 10px;
					}
				}	
			}

			.card_content {
				display: flex;
    			padding: 50px 10px 10px;
    			background: #fff;
    			flex-direction: column;

				.card_title {
					flex: 0 0 67px;
				}

				.card_title h5 {
					margin: 10px auto;
					font-size: 1.2em;
					font-weight: bold;
				}
				
				.card_text {
					color: #535353;
					line-height: 1.5;
					margin-bottom: 1.25rem;
					font-weight: normal;
				}
			}
		}
	}
	@media (max-width: 960px) {  
		.interior {
			padding: 5px 10px;
		}
	}
	@include breakpoint(medium) {
		.course_cards {
			flex-direction: column;
		}
	}
	@include breakpoint(small) {
		.call-to-action  {
			text-align: center;
			float: none;
			display: block;
			margin: 0 auto;
		}
	 	.secondary  {
			display: block;
			width: 70%;
			margin: 0 auto;
		}				
	}	
}
		
	
.job-result-tiles .js-course-tile {
	.job-results-course {
		font-size: 0.9em;
	}
}