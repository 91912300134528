//////////////////////////////////////////////////////////////////////////////////////////

// _css-spinner.SCSS

//this spinner is located on the job search page and candidate search page

//////////////////////////////////////////////////////////////////////////////////////////

   .css-spinner {
	  display: block;
	  position: relative;
	  width: 64px;
	  height: 64px;
	  margin: auto;
	}


	.css-spinner div {
	  transform-origin: 32px 32px;
	  animation: lds-spinner 1.2s linear infinite;
	}
	.css-spinner div:after {
	  content: " ";
	  display: block;
	  position: absolute;
	  top: 3px;
	  left: 29px;
	  width: 5px;
	  height: 14px;
	  border-radius: 20%;
	  background: #000;
	}
	.css-spinner div:nth-child(1) {
	  transform: rotate(0deg);
	  animation-delay: -1.1s;
	}
	.css-spinner div:nth-child(2) {
	  transform: rotate(30deg);
	  animation-delay: -1s;
	}
	.css-spinner div:nth-child(3) {
	  transform: rotate(60deg);
	  animation-delay: -0.9s;
	}
	.css-spinner div:nth-child(4) {
	  transform: rotate(90deg);
	  animation-delay: -0.8s;
	}
	.css-spinner div:nth-child(5) {
	  transform: rotate(120deg);
	  animation-delay: -0.7s;
	}
	.css-spinner div:nth-child(6) {
	  transform: rotate(150deg);
	  animation-delay: -0.6s;
	}
	.css-spinner div:nth-child(7) {
	  transform: rotate(180deg);
	  animation-delay: -0.5s;
	}
	.css-spinner div:nth-child(8) {
	  transform: rotate(210deg);
	  animation-delay: -0.4s;
	}
	.css-spinner div:nth-child(9) {
	  transform: rotate(240deg);
	  animation-delay: -0.3s;
	}
	.css-spinner div:nth-child(10) {
	  transform: rotate(270deg);
	  animation-delay: -0.2s;
	}
	.css-spinner div:nth-child(11) {
	  transform: rotate(300deg);
	  animation-delay: -0.1s;
	}
	.css-spinner div:nth-child(12) {
	  transform: rotate(330deg);
	  animation-delay: 0s;
	}

	@keyframes lds-spinner {
	  0% {
	    opacity: 1;
	  }
	  100% {
	    opacity: 0;
	  }
	}