.job-search-page-right-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  font-family: Roboto, "sans-serif";
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #bec4c4 !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }

  .apply-button {
    height: 2.25rem;
    text-transform: uppercase;
    color: var(--site-secondary-text-color, #fff);
    font-family: Roboto, "sans-serif";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--site-secondary-color, #0072ce);
    border-color: var(--site-secondary-color, #0072ce);
  }

  .applied-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2.25rem;
    text-transform: uppercase;
    color: var(--site-secondary-color, #0072ce);
    font-family: Roboto, "sans-serif";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid rgba(0, 0, 0, 0.15);
    .applied-icon {
      font-size: 1.5rem;
      align-self: center;
      padding-top: 0.1rem;
    }
  }

  .search-filter-container {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .top-mobile-action-buttons {
    display: flex;
    height: 2.5rem;
    padding: 0.5rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .back-job-details-container {
      display: none !important;
    }

    &.show-community-intel {
      .back-results-button-container,
      .next-community-intel-container {
        display: none !important;
      }
      .back-job-details-container {
        display: flex !important;
      }
    }
  }

  #job-and-location-popover-container {
    .popover {
      z-index: 2;
    }
  }

  .job-and-location-container {
    position: relative;
    display: flex;
    height: 100%;
    z-index: 0;

    .with-search-bar {
      height: calc(100vh - 100px - 65px - 45px);
      @media (max-width: map-get($grid-breakpoints, "xl")) {
        height: calc(100vh - 100px - 169px - 40px);
      }
      @media only print {
        height: auto !important;
        overflow: hidden !important;
      }
    }

    .job-middle-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: relative;
      // padding-bottom: 1.25rem;
      overflow-y: auto;
      transition: margin 0.5s ease-in-out;
      width: 100%;
      margin-right: 0px;
      @media only print {
        height: auto !important;
        overflow: hidden !important;
      }

      &.hide-job-middle-container {
        @media (max-width: map-get($grid-breakpoints, "xl")) {
          display: none;
        }
      }

      &.job-middle-container-collapse {
        @media (min-width: 1280px) {
          margin-right: 320px;
        }
        @media (min-width: 1440px) {
          margin-right: 345px;
        }
      }

      &::-webkit-scrollbar {
        width: 5px !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #bec4c4 !important;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }

      @media (max-width: map-get($grid-breakpoints, "xl")) {
        // padding-bottom: 4.5rem;
      }

      .banner-container {
        display: flex;
        // padding: 1rem 0rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        // .leaderboard-adspace {
        //   @media (max-width: 1274px) {
        //     // TODO: remove !important and make changes in the original class once everything is tested
        //     width: 100% !important;
        //     height: 100% !important;
        //     display: block !important;
        //     position: relative !important;
        //     opacity: 1;
        //   }
        // }
      }
      .next-btn-to-community-intel-container {
        text-decoration: underline;
      }
      .job-details-parent-card-container,
      .job-details-parent-floating-card-container {
        display: flex;
        padding: 0.75rem 1rem;
        align-items: flex-start;
        gap: 1.25rem;
        align-self: stretch;
        @media (max-width: 425px) {
          padding-inline: 0.5rem;
        }
      }
      .job-details-parent-padding-desktop {
        @media (min-width: 641px) {
          padding-top: 3rem;
        }
      }
      .job-details-parent-floating-card-container {
        position: sticky;
        top: 0;
        z-index: 5;
        background: #fff;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
        display: none;

        &.show-floating-job-card {
          display: flex;

          @media (max-width: map-get($grid-breakpoints, "xl")) {
            display: none;
          }
        }
      }
      .job-features-parent-container {
        padding: 0rem 1rem;
        @media (max-width: 425px) {
          padding-inline: 0.5rem;
        }
      }
      .job-description-parent-container {
        padding: 0.75rem 1rem;
        @media (max-width: 425px) {
          padding-inline: 0.5rem;
        }
        ol,ul {
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;
        }
        ol{
          list-style-type: decimal;
        }
        ul{
          list-style-type: disc;
        }
      }
      .job-id-parent-container {
        padding: 0.75rem 1rem;
        color: #1e1e1e;
        text-align: right;
        font-family: Roboto, "sans-serif";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.875rem;
        letter-spacing: 0.01125rem;
        @media (max-width: 425px) {
          padding-inline: 0.5rem;
        }
      }

      .company-details-jobs-learning-parent-container {
        display: flex;
        padding: 0rem 1rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        align-self: stretch;
        @media (max-width: 425px) {
          padding-inline: 0.5rem;
        }
        .company-details-container {
          display: flex;
          padding: 0.75rem;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
          align-self: stretch;
          border-radius: 0.25rem;
          border: 1px solid #d9d9d9;

          .company-header-container {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            align-self: stretch;

            @media (min-width: map-get($grid-breakpoints, "xl")) and (max-width: 1279px) {
              flex-direction: column;
              align-items: center;
              .logo-header-container {
                flex-direction: column;
                align-items: center;
                .company-heading {
                  text-align: center;
                }
              }
            }

            &.company-header-on-community-intel-open {
              @media (min-width: map-get($grid-breakpoints, "xl")) and (max-width: 1365px) {
                flex-direction: column;
                align-items: center;
                .logo-header-container {
                  flex-direction: column;
                  align-items: center;
                  .company-heading {
                    text-align: center;
                  }
                }
              }
            }

            .logo-header-container {
              display: flex;
              align-items: flex-start;
              gap: 0.5rem;
              flex: 1 0 0;
              @media (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-self: stretch;
              }
              .company-logo {
                width: fit-content;
                height: 4.44913rem;
                max-width: 9.375rem;
                max-height: 4.44913rem;
                border-radius: $company-logo-border-radius;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
                &:not(.logo-none) {
                  padding: 4px;
                  $custom-border-color: #d6d6d6;
                  border-color: transparent;
                  border: 0;
                  background-image: repeating-linear-gradient(
                      0deg,
                      $custom-border-color,
                      $custom-border-color 5px,
                      transparent 5px,
                      transparent 9px,
                      $custom-border-color 9px
                    ),
                    repeating-linear-gradient(
                      90deg,
                      $custom-border-color,
                      $custom-border-color 5px,
                      transparent 5px,
                      transparent 9px,
                      $custom-border-color 9px
                    ),
                    repeating-linear-gradient(
                      180deg,
                      $custom-border-color,
                      $custom-border-color 5px,
                      transparent 5px,
                      transparent 9px,
                      $custom-border-color 9px
                    ),
                    repeating-linear-gradient(
                      270deg,
                      $custom-border-color,
                      $custom-border-color 5px,
                      transparent 5px,
                      transparent 9px,
                      $custom-border-color 9px
                    );
                  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
                  background-position: 0 0, 0 0, 100% 0, 0 100%;
                  background-repeat: no-repeat;
                }

                &.logo-none {
                  @extend %col;
                  @extend %col-auto;
                  @extend %d-xl-flex;
                  @extend %align-items-center;
                  @extend %justify-content-center;
                  display: flex;
                  line-height: inherit !important;
                  text-align: center;
                  border: 1px solid $company-logo-border-color;
                  border-radius: $company-logo-border-radius;
                  background: $company-logo-empty-bg;
                  color: $company-logo-empty-font-color;
                  @extend .h6;
                  @extend .rm-m-b;
                  line-height: 1 !important;
                  width: 72px;
                  height: 81px;
                  // padding-right: 0 !important;
                  span {
                    display: block;
                    width: 100%;
                    @include text-truncate();
                  }
                }
              }
              .company-heading {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.25rem;
                flex: 1 0 0;
                align-self: stretch;
                @media (max-width: 767px) {
                  text-align: center;
                }
                .company-name {
                  align-self: stretch;
                  color: #222;
                  font-family: Roboto, "sans-serif";
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
                .company-subtext {
                  align-self: stretch;
                  color: #222;
                  font-family: Roboto, "sans-serif";
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
            .linkedIn-button {
              @media (max-width: 767px) {
                display: none;
              }
              display: flex;
              cursor: pointer;
              height: 2.25rem;
              padding: 0.625rem 0.5rem 0.5rem 0.5rem;
              align-items: center;
              gap: 0.5rem;
              border-radius: 0.25rem;
              border: 1px solid rgba(0, 0, 0, 0.15);
              background: #fff;
              .linkedIn-text {
                color: #222;
                font-family: Roboto, "san-serif";
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              .linkedIn-icon {
                font-size: 16px;
                color: $linkedinBlue;
              }
            }
          }
          .company-descripton-container {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.25rem;
            align-self: stretch;
            color: #4a4a4a;
            font-family: Roboto, "sans-serif";
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 136.687%; /* 1.196rem */
          }
          .view-company-profile-link {
            color: var(--site-primary-color, #1badba);
            font-family: Roboto, "sans-serif";
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 136.687%; /* 1.196rem */
          }
          .more-jobs-from-company-container {
            display: flex;
            padding-top: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            align-self: stretch;
            border-top: 1px solid #ebebeb;
            .more-jobs-title {
              align-self: stretch;
              color: #222;
              font-size: 1rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .more-job-item {
              display: flex;
              cursor: pointer;
              padding-bottom: 0.5rem;
              flex-direction: column;
              align-items: flex-start;
              gap: 0.25rem;
              align-self: stretch;
              border-bottom: 1px solid #ebebeb;
              &:last-of-type {
                border-bottom: 0px;
              }
              .item-job-title {
                align-self: stretch;
                color: #222;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              .item-job-location {
                align-self: stretch;
                color: #4a4a4a;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              .item-job-posted {
                display: flex;
                align-items: baseline;
                gap: 0.25rem;
                align-self: stretch;
                .posted-icon {
                  color: #5b6770;
                  font-size: 0.75rem;
                }
                .posted-text {
                  color: #5b6770;
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
            .see-more-jobs-link {
              align-self: stretch;
              color: var(--site-primary-color, #1badba);
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
          .linkedIn-section-mobile {
            @media (min-width: 767px) {
              display: none;
            }
            display: flex;
            padding: 0.5rem 0.25rem 0rem 0.25rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.75rem;
            align-self: stretch;
            border-top: 1px solid #ebebeb;
            background: #fff;
            .linkedIn-text {
              align-self: stretch;
              color: #222;
              text-align: center;
              font-family: Roboto, " san-serif";
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .linkedIn-button {
              display: flex;
              cursor: pointer;
              height: 2.25rem;
              padding: 0.625rem 0.5rem 0.5rem 0.5rem;
              justify-content: center;
              align-items: center;
              gap: 0.625rem;
              align-self: stretch;
              border-radius: 0.25rem;
              border: 1px solid rgba(0, 0, 0, 0.15);
              background-color: #fff;
              .linkedIn-context {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                .linkedIn-icon {
                  font-size: 16px;
                  color: $linkedinBlue;
                }
                .linkedIn-label {
                  color: $linkedinBlue;
                  font-family: Roboto, "san-serif";
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }
            }
          }
        }

        .jobs-you-may-like-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          border-radius: 0.25rem;
          border: 1px solid #d9d9d9;
          .jobs-you-may-like-title {
            display: flex;
            padding: 0.25rem 0.75rem;
            align-items: flex-start;
            gap: 0.625rem;
            align-self: stretch;
            border-bottom: 1px solid #ebebeb;
            flex: 1 0 0;
            color: #222;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.875rem;
            letter-spacing: 0.015rem;
          }
          .jobs-recommended-container {
            display: flex;
            padding: 0.5rem 0.75rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.625rem;
            align-self: stretch;
            .g-scrolling-carousel {
              padding-inline: 0px;
              .items {
                padding-inline: 0px;
                margin-inline: 0px;
              }
            }

            &.jobs-recommended-on-community-intel-open {
              @media (min-width: 1280px) and (max-width: 1365px) {
                .items div.job-tile {
                  flex: 0 0 70%;
                  max-width: 70%;
                }
              }
            }
          }
        }
        .learning-courses-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          border-radius: 0.25rem;
          border: 1px solid #d9d9d9;
          .learning-courses-title {
            display: flex;
            padding: 0.25rem 0.75rem;
            align-items: flex-start;
            gap: 0.625rem;
            align-self: stretch;
            border-bottom: 1px solid #ebebeb;
            flex: 1 0 0;
            color: #222;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.875rem;
            letter-spacing: 0.015rem;
          }
          .learning-courses-recommended-container {
            display: flex;
            padding: 0.5rem 0.75rem;
            align-self: stretch;
          }
          &.learning-courses-on-community-intel-open {
            @media (min-width: 1280px) and(max-width:1365px) {
              .learning-courses-recommended-container {
                .course_cards {
                  flex-direction: column;
                }
              }
            }
          }
        }
      }

      .apply-button-mobile-container {
        @media (min-width: map-get($grid-breakpoints, "xl")) {
          display: none;
        }
        display: flex;
        padding: 0.5rem 0.5rem 0.75rem 0.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;
        align-self: stretch;
        position: sticky;
        bottom: 0;
        width: 100%;
        background: #fff;
        z-index: 2;
      }
    }

    .community-intel-container {
      min-width: 345px;
      display: flex;
      width: 345px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-left: 1px solid rgba(0, 0, 0, 0.15);
      background: #fff;
      transition: right 0.5s ease-in-out;
      position: absolute;
      right: -345px;
      z-index: 10;
      @media only print {
        height: auto !important;
        overflow: hidden !important;
      }
      @media (min-width: map-get($grid-breakpoints, "xl")) and (max-width: 1439px) {
        min-width: 320px;
        width: 320px;
        right: -320px;
      }

      @media (min-width: map-get($grid-breakpoints, "xl")) and (max-width: 1279px) {
        position: absolute;
      }

      &.show-mobile-community-intel {
        @media (max-width: map-get($grid-breakpoints, "xl")) {
          display: flex;
          right: 0;
        }
      }

      &.community-intel-container-open {
        right: 0;
        @media (min-width: map-get($grid-breakpoints, "xl")) and (max-width: 1279px) {
          position: absolute;
          right: 0;
        }
      }

      .slide-panel-button {
        display: flex;
        align-items: flex-start;
        position: absolute;
        z-index: 1;
        left: -2.6rem;
        display: flex;
        height: 2.25rem;
        padding: 0.625rem 0.5rem 0.5rem 0.5rem;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0rem 0rem 0rem 0.25rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-left: 1px solid rgba(0, 0, 0, 0.15);
        background: #fff;
        cursor: pointer;
        .slide-icon {
          font-size: 1.5rem;
          color: #5b6770;

          &.slide-icon-close {
            color: var(--site-primary-color, #1badba);
            transform: rotateZ(180deg);
          }
        }
      }
      @media (max-width: map-get($grid-breakpoints, "xl")) {
        position: relative;
        display: none;
        width: 100%;
        min-width: 0px;
        .slide-panel-button {
          display: none;
        }
      }
      .additional-overflow-container {
        display: flex;
        padding: 0rem 0.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        align-self: stretch;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 1.25rem;
        @media (min-width: 426px) and (max-width: map-get($grid-breakpoints, "xl")) {
          padding-inline: 1rem;
        }
        &::-webkit-scrollbar {
          width: 5px !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1 !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #bec4c4 !important;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555 !important;
        }
      }
      .location-details-form-container {
        display: flex;
        padding-top: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        align-self: stretch;
        @media (max-width: map-get($grid-breakpoints, "xl")) {
          padding-top: 0;
        }
        .input-group {
          .input-group-prepend {
            .input-group-text {
              background: none;
              border-right: none;
              padding-right: 0.25rem;
              padding-left: 0.5rem;
              height: 40px;
              .input-icon-job-location {
                color: #0469cd;
                font-size: 1.1rem;
              }
            }
          }
          select {
            height: 40px;
            border-left: none;
          }
        }
      }
      .community-intel-error-container {
        display: flex;
        padding: 1.25rem;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
        align-self: stretch;
        font-family: Roboto, "sans-serif";
        font-style: normal;
        .error-title {
          color: #0469cd;
          text-align: center;
          font-size: 1.3rem;
          font-weight: 600;
          line-height: 1.875rem;
          align-self: stretch;
        }
        .error-subtext {
          color: #8c8c8c;
          text-align: center;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: normal;
          align-self: stretch;
        }
      }
      .location-details-container {
        display: flex;
        padding-bottom: 0px;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        .details-card {
          display: flex;
          padding: 0.5rem;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
          align-self: stretch;
          border-radius: 0.25rem;
          background: #f5f5f5;
          .salary-section {
            display: flex;
            padding: 0.5rem 0.5rem 0.75rem 0.5rem;
            justify-content: space-between;
            align-items: flex-end;
            align-self: stretch;
            border-bottom: 1px solid #c6cad2;
            .salary-title-subtitle {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 0.25rem;
              font-family: Roboto, "sans-serif";
              font-style: normal;
              line-height: normal;
              .salary-title {
                align-self: stretch;
                color: #222;
                font-size: 1rem;
                font-weight: 500;
              }
              .salary-subtitle {
                align-self: stretch;
                color: #5b6770;
                font-size: 0.75rem;
                font-weight: 400;
              }
            }
            .salary-value {
              display: flex;
              align-items: flex-start;
              gap: 0.25rem;
              align-self: stretch;
              color: #0469cd;
              font-family: Roboto, "sans-serif";
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            .salary-value-compare {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-start;
              gap: 0.25rem;
              align-self: stretch;
              .compare-percentage-salary {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 0.25rem;
                align-self: stretch;
                color: #13ca7d;
                text-align: right;
                font-family: Roboto, "sans-serif";
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              .compare-container {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 0.625rem;

                .compare-value {
                  display: flex;
                  align-items: center;
                  gap: 0.25rem;
                  color: #9ba3b0;
                  font-family: Roboto, "Nunito Sans", Helvetica, Arial,
                    sans-serif;
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }
              .compare-value-indicator {
                width: 7px;
                height: 7px;
                border-radius: 50%;
              }

              .compare-value-from {
                background-color: #fc74a8;
              }

              .compare-value-to {
                background-color: #0469cd;
              }
            }
          }
          .details-header {
            display: flex;
            padding: 0.5rem 0.5rem 0.75rem 0.5rem;
            align-items: flex-start;
            gap: 0.625rem;
            align-self: stretch;
            border-bottom: 1px solid var(--Light-Gray, #c6cad2);
            background: rgba(255, 255, 255, 0);
            color: #222;
            font-family: Roboto, "sans-serif";
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .info-card {
          display: flex;
          padding: 0.5rem;
          align-items: flex-start;
          gap: 0.625rem;
          align-self: stretch;
          color: #222222;
          background: #ebebeb;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 0.25rem;
          .info-icon {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

// TODO: Remove this after iteration 3 deployed
// .apply-button-mobile-container {
//   @media (min-width: map-get($grid-breakpoints, "xl")) {
//     display: none;
//   }
//   display: flex;
//   padding: 0.5rem 0.5rem 0.75rem 0.5rem;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 0.625rem;
//   align-self: stretch;
//   position: fixed;
//   bottom: 0;
//   width: 100%;
//   background: #fff;
//   z-index: 2;
// }
