// -------------------------------------------------------------------
//
//		POPOVER COMPONENTS
//		Created for Framework 2.1.0+
//
//		For styling popovers without modifying base bootstrap.
//		Please call all utilities possible as includes to reduce file sizes.
//
// -------------------------------------------------------------------

.popover {
	box-shadow: $popover-box-shadow;
}

.popover[x-out-of-boundaries]{
	visibility:hidden;
}