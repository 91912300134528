.job-details-board-card-container {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
  .company-logo {
    display: flex;
    width: 4rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    background: rgba(189, 189, 189, 0.05);
  }
  .main-job-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    .title-action-container {
      display: flex;
      align-items: flex-start;
      gap: 0.625rem;
      align-self: stretch;
      .title {
        flex: 1 0 0;
        .title-text {
          color: #222;
          font-family: Roboto, Arial, Helvetica, sans-serif;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.625rem;
        }
      }
      .actions-container {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        .dropdown {
          .dropdown-toggle {
            &::after {
              content: none;
            }
          }
          .dropdown-menu {
            top: 4px !important;

            .dropdown-item {
              display: flex;
              align-items: center;
              gap: 0.75rem;
              padding-inline: 1.25rem;
              .list-icon {
                font-size: 1rem;
                color: #1e1e1e;
              }
              .list-text {
                color: #000;
                font-family: Roboto, "sans-serif";
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
              }
            }
          }
        }

        .bookmark-icon {
          display: flex;
          font-size: 1.5rem;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: $gray;

          &:hover {
            color: var(--site-primary-color, #1badba);
          }

          &:hover {
            color: var(--site-primary-color, #1badba);
          }
        }
        .bookmark-icon-on {
          color: var(--site-primary-color, #1badba);
        }
        .menu-icon {
          font-size: 1rem;
          cursor: pointer;
          padding-right: 0.25rem;
          color: #1e1e1e;
        }
      }
    }
    .company-name-container {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;
      .icon {
        width: 1.25rem;
        height: 1.25rem;
        path {
          fill: var(--site-primary-color, #1badba);
        }
      }
      .company-name {
        color: #4a4a4a;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .location-type-container {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      align-self: stretch;
      flex-wrap: wrap;
      .icon {
        width: 1.25rem;
        height: 1.25rem;
        path {
          fill: var(--site-primary-color, #1badba);
        }
      }
      .location {
        color: #4a4a4a;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .additional-locations {
        display: flex;
        padding: 0.0625rem 0.125rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.125rem;
        background: #f6f6f6;
        .number-of-location-text {
          color: var(--site-primary-color, #1badba);
          font-family: Roboto, Arial, Helvetica, sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .location-type {
        display: flex;
        padding: 0.125rem 0.25rem;
        align-items: center;
        gap: 0.375rem;
        .separator {
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 0.625rem;
          background: var(--site-primary-color, #1badba);
        }
        .location-type-text {
          color: #222;
          font-family: Roboto, Arial, Helvetica, sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
    .tags-apply-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      @include breakpoint(small) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
      }
      .tags-container {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        padding-right: 1rem;
        gap: 0.5rem 0.75rem;
        flex: 1 0 0;
        flex-wrap: wrap;
        //   .tag-label {
        //     display: flex;
        //     padding: 0.25rem 0.5rem;
        //     justify-content: center;
        //     align-items: center;
        //     gap: 0.625rem;
        //     font-size: $label-font-size;
        //     font-weight: $label-font-weight;
        //     color: $label-text-color;
        //     border-radius: $label-border-radius;
        //     font-family: Roboto, Arial, Helvetica, sans-serif;
        //     &-spotlight {
        //       color: $system-bright-yellow;
        //     }
        //     &-preferred {
        //       color: $system-blue;
        //     }
        //     &-member-company {
        //       color: $system-Navy;
        //     }
        //     &-member-company {
        //       color: $system-Navy;
        //     }
        //     &-candidate-spotlight {
        //       color: $system-bright-yellow;
        //     }
        //     &-diversity {
        //       color: $system-purple;
        //     }
        //     &-veterans {
        //       color: $system-green;
        //     }
        //     &-career-fair {
        //       color: $gray-600;
        //     }
        //   }
        //   @each $color, $value in $theme-colors {
        //     .tag-label-#{$color} {
        //       @include label-variant($value);
        //     }
        //   }
        .label {
          margin: 0px;
        }
      }
      .apply-button {
        min-width: 107px;
        @media (max-width: map-get($grid-breakpoints, "xl")) {
          display: none;
        }
      }
    }
    .posted-days-text {
      color: #9ba3b0;
      text-align: right;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.job-details-board-floating-card-container {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
  .main-job-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    .title-action-container {
      display: flex;
      align-items: flex-start;
      gap: 0.625rem;
      align-self: stretch;
      .title {
        flex: 1 0 0;
        .title-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          color: #222;
          font-family: Roboto, Arial, Helvetica, sans-serif;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.625rem;
        }
      }
      .actions-container {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding-right: 2.5rem;
        .dropdown {
          .dropdown-toggle {
            &::after {
              content: none;
            }
          }
          .dropdown-menu {
            top: 4px !important;

            .dropdown-item {
              display: flex;
              align-items: center;
              gap: 0.75rem;
              padding-inline: 1.25rem;
              .list-icon {
                font-size: 1rem;
                color: #1e1e1e;
              }
              .list-text {
                color: #000;
                font-family: Roboto, "sans-serif";
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
              }
            }
          }
        }

        .bookmark-icon {
          display: flex;
          font-size: 1.5rem;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: $gray;

          &:hover {
            color: var(--site-primary-color, #1badba);
          }
        }
        .bookmark-icon-on {
          color: var(--site-primary-color, #1badba);
        }
        .menu-icon {
          font-size: 1rem;
          cursor: pointer;
          padding-right: 0.25rem;
          color: #1e1e1e;
        }
      }
    }
    .job-info-apply-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .separator {
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 0.625rem;
        background: var(--site-primary-color, #1badba);
      }
      .job-info {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex: 1 0 0;
        align-self: stretch;
      }
    }

    .company-name-container {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;
      .company-name {
        color: #4a4a4a;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
    .location-type-container {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      align-self: stretch;
      padding-right: 1rem;
      // flex-wrap: wrap;
      .location {
        color: #4a4a4a;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
      .additional-locations {
        display: flex;
        padding: 0.0625rem 0.125rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.125rem;
        background: #f6f6f6;
        .number-of-location-text {
          color: var(--site-primary-color, #1badba);
          font-family: Roboto, Arial, Helvetica, sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .location-type {
        display: flex;
        padding: 0.125rem 0.25rem;
        align-items: center;
        gap: 0.375rem;
        .location-type-text {
          color: #222;
          font-family: Roboto, Arial, Helvetica, sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          min-width: max-content;
        }
      }
    }
    .apply-button {
      min-width: 107px;
      @media (max-width: map-get($grid-breakpoints, "xl")) {
        display: none;
      }
    }
  }
}
