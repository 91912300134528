//////////////////////////////////////////////////////////////////////////////////////////

// _SIDEBOARD-ADSPACE.SCSS

// This file includes all rules which affect the generic sideboard-adspace module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

// ---------------------------------------------------
//	JOB SEARCH SIDEBOARDS
// ---------------------------------------------------

.job-search-main {
	.sideboard-adspace {
		&.outside {
			left: 1270px;
			&.leftside{
				left: -170px;
			}
		}
	}
	
	.job-search-sideboard {
    margin-bottom: 125px;
    text-align: center;
    display: block;
    padding-top: 10px;
	}

	// Left hand rail in pane view
	.job-results-list {
		.job-search-sideboard {
			margin-bottom: $grid-gutter-width-md;
		}
	}

	// Right hand in pane 
	.region_main-pane {
		.sideboard-adspace {

			// IE11 - Fixes and overrides - These cannot move in this page, they must be in this cascading order!
			&.outside, &.leftside {
				position: static;
				left: auto !important;
				height: auto;
				display: block !important;
			}

			margin-left: 0;
			margin-right: auto;
			margin-top: $grid-gutter-width-sm !important;
			margin-bottom: $grid-gutter-width-md !important;
			padding-bottom: $grid-gutter-width-md !important;
			@extend %d-flex;
			flex-direction: column;
			position: unset;
			left: unset;
			flex-wrap: nowrap;
			max-width: 160px;
			width: 160px;

			ins {
				display: block;
			}
		}
	}
}

// JOB 2 PANE VIEW TABLET AND MOBILE
@media (max-width: 1623px) { 
	.job-results-body .region_main-pane {
		.sideboard-adspace {
			width: 0 !important;
			
			&.outside, &.leftside {
					display: none !important;
					position: absolute !important;
					opacity: 0;
					width: 0 !important;
					height: 0 !important;
			}
		}
	}
}
	
// ---------------------------------------------------
//	REGULAR PAGE SIDEBOARDS
// ---------------------------------------------------

.sideboard-adbulter {
	text-align: center;
  background: #fff;
  padding: 10px 11px 15px;
  margin: 0 auto;	
}

.sideboard-adspace {	
	box-sizing: border-box;
	background: $white;
	text-align: left;
	padding: 10px 11px 15px;
	margin: 0 auto;

	h5, .h5 {
		font-size: 1.65em;
		display: inline-block;
		padding: 0 5px 15px;
		font-weight: 400;
		text-transform: uppercase;
		font-family: 'Oswald', sans-serif;
	}

	hr {
		display: none;
		width: 10%;
		color: $white;
	}
	
	a {
		width: 100%;
		text-align: center;
		padding: 18px 0;
		font-size: 1.2em;
	}

	em {
		color: $black;
		font-size: 1.35em;
		font-style: normal;
		font-weight: 400;
		letter-spacing: .02em;
		display: block;
		position: absolute;
		bottom: 55px;
		width: 100%;
		box-sizing: border-box;
		background: rgba(255,255,255, 0.75);
		border-bottom: 1px solid rgba(230,230,230,0.5);
		border-top: 1px solid rgba(230,230,230,0.5);
		padding: 5px;
	}
	
	.job-alert-signup {
		text-align: center;
	}

	&.outside {
		position: absolute;
		top: 0;
		background: transparent;
    padding: 0;
    overflow: visible;

		div>div {
			background: transparent;
			height: auto;
			border: 0;
		}
		a {
			display: block;
			padding: 0;
		}
	}

	div > div {
		background: url("../img/photos/bkgrd-upload-resume-1.jpg") no-repeat center center transparent;
		height:230px;
		border: thin solid #eeeeee;
		margin-bottom: 15px;
		position: relative;
		box-sizing: border-box;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	div a img {
		width: 100%;
		height: auto;
	}		
	.standard-leaderboard-horizontal a img {
		max-height: 90px;
		max-width: 768px;
	}
	.wide-leaderboard-horizontal a img {
		max-height: 90px;
		max-width: 970px;
	}
	.medium-square a img {
		max-height: 250px;
		max-width: 250px;
	}
	.medium-rectangle-horizontal a img {
		max-height: 250px;
		max-width: 300px;
	}
	.medium-rectangle-vertical a img {
		max-height: 300px;
		max-width: 250px;
	}
	.rectangle-horizontal a img {
		max-height: 150px;
		max-width: 180px;
	}
	.rectangle-vertical a img {
		max-height: 180px;
		max-width: 150px;
	}		
	@media (max-width: 1338px){
		.sideboard-adspace.outside {
		    display: none;
		}
	}
	@include breakpoint(medium) {
		h5, .h5 {
			padding-bottom: 0;
			line-height: 100%;
		}
	}

	
	@include breakpoint(small) {
		display: none;
	}
}

.extreme-right-sideboard {
	@include breakpoint(eight-hundred) {
		display:none;
	}
}