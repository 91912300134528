// --------------------------------------------------------
//
//  ICONS
//  Font Awesome 4
//  Framework 2.0.0+
//
// --------------------------------------------------------

// Variables
// -----------------------------

$icon-favorite: "\f005";
$icon-bookmark: "\f02e";
$icon-bookmark-star: "\f19f";
$icon-share: "\f1e0";

// Base Extensions
// -----------------------------

.icon {
  font-family: FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.ico-sm {
  font-size: $icon-size * 0.5;
}
.ico-lg {
  font-size: $icon-size * 1.5;
}
.ico-lg {
  font-size: $icon-size * 2;
}

// Display Colors for active icons
.icon-off {
  color: $gray;
}
.icon-off a {
  color: $gray;
}

// ----------------------------------
// CALL ICONS
// ----------------------------------

// Makes Bookmark on Job Search animate
// .bookmark {
//   @include icon-on-state($icon-color, $icon-favorite);
// }
.bookmark {
  @include icon-on-state($icon-color, $icon-bookmark-star);
  a {
    font-family: "bootstrap-icons" !important;
  }
}

// There is also an .ico in Employer styles, be careful!
// ------------------------------------------------------------
.inner-jobseeker-page {
  .ico {
    svg,
    a {
      width: $icon-size;
      fill: currentColor;
    }

    a:hover svg {
      fill: currentColor;
    }

    //  a:after {
    //    color: inherit;
    //    content: $icon-share;
    //  }
    //
    //  a:hover:after {
    //    color: currentColor;
    //  }
  }

  .ico-share {
    //  @extend .icon;

    svg,
    a {
      width: $icon-size;
      fill: currentColor;
    }

    a:hover svg {
      fill: currentColor;
    }

    //  a:after {
    //    color: inherit;
    //    content: $icon-share;
    //  }
    //
    //  a:hover:after {
    //    color: currentColor;
    //  }
  }
}
