// --------------------------------------------------
//
//    LABELS
//    Taken from Bootstrap 3.3.4 because labels seem to
//    no longer exist in Bootstrap 4
//
// --------------------------------------------------

.label {
  display: inline;
  padding: .1em .65em;
  margin: 4px;
  font-size: $label-font-size-lg;
  font-weight: $label-font-weight;
  color: $label-text-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: $label-border-radius;
  &-spotlight{
    color: #000;
  }
  // [converter] extracted a& to a.label

  // Empty labels collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

// Add hover effects, but only for links
// a.label {
//   &:hover,
//   &:focus {
//     color: $label-link-hover-color;
//     text-decoration: none;
//     cursor: pointer;
//   }
// }
 
// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .label-#{$color} {
    @include label-variant($value);
  }
}   

// Colors
// Contextual variations (linked labels get darker on :hover)
// 
// .label-default {
//   @include label-variant($label-default-bg);
// }
// 
// .label-primary {
//   @include label-variant($label-primary-bg);
// }
// 
// .label-success {
//   @include label-variant($label-success-bg);
// }
// 
// .label-info {
//   @include label-variant($label-info-bg);
// }
// 
// .label-warning {
//   @include label-variant($label-warning-bg);
// }
// 
// .label-danger {
//   @include label-variant($label-danger-bg);
// }
