//////////////////////////////////////////////////////////////////////////////////////////

// _checkout.SCSS

//////////////////////////////////////////////////////////////////////////////////////////

#basic-page .site {
    position: relative;
}
.checkout-pay-area{
    .nav{
        @extend .mx-3;
        padding-top: 1rem;
        .nav-link{
            font-weight: 500;
            background-color: #f1f1f1;
            border-radius: 0;
            &.active{
                background-color: #535353;
                border-radius: 0.25rem;
            }

        }
    }
    .jt_cte_area_content{
        z-index: $zindex-tooltip;
    }
    form{
        @extend .d-flex;
        @extend .flex-column;
        @extend .flex-md-row;
        
        span.checkout_error_text{
            display: none;
            color: red;
            font-weight: bold;
            margin: auto;
            text-align: center;

        }
        .checkout-content-left {
            @extend .mr-md-3;
            width: 100%;
            @include media-breakpoint-up(md) { 
              flex: 0 0 auto;
              width: calc(60% - 1rem);
            }
        }

         .checkout-content-right {
            width: 100%;
            margin-top:1rem;
            @include media-breakpoint-up(md) { 
              flex: 0 0 auto;
              width: 40%; 
              margin-top:0;
            }
        }
    
        .checkout-content-left-container, .checkout-content-right-container{
            
            border: 1px solid #DDD;
            border-radius: 4px;

            @include media-breakpoint-down(md) { 
              width: 100%;  
            }

            .checkout-content-header{
                @extend .d-flex;
                @extend .justify-content-between;
                font-weight:  500;
                background: #F1F1F1;
                border-bottom: 1px solid #DDD;
                padding: 10px;
                width: 100%;
            }
            .checkout-form-container{
                @extend .p-4;
            }
            .checkout-recipient-summary-container, .checkout-billing-summary-container{
                @extend .d-flex;
                @extend .flex-column;
                @extend .p-4;
            }

            .checkout-form-row-col{
                width: 100%;
                @extend .d-flex;
                @extend .flex-row;
                @extend .align-items-center;

                select:first-child{
                    @extend .mr-3;
                }
            }
            .checkout-form-col{
                @extend .my-3;
                @extend .d-flex;
                @extend .justify-content-center;
                @extend .text-center;
            }
            .checkout-form-row{
                @extend .d-flex;
                @extend .flex-column;
                @extend .flex-md-row;
                @extend .align-items-center;
                @extend .mb-3;

                .checkout-required{
                    display: inline!important;
                }
                &.checkbox-content-left{
                     @extend .align-items-start;
                }
                label{
                    width: 100%; 
                    @extend .text-md-right;
                    @extend .pr-3;
                    @include media-breakpoint-up(md) { 
                      width: 30%;  
                    }

                }
            }
            .checkout-input-group{
                @extend .input-group;
                @extend .p-3;
               
            }
            .checkout-input-group-append{
                @extend .input-group-append;
            }
            .checkout-alert{
                @extend .alert;
                @extend .mx-3;
                @extend .pb-3;
            }

            .checkout-form-check{
                position: relative;
                display: block;
                padding-left: 1.25rem;

            }
            .checkout-form-label{
                margin-bottom: 0;
                width: auto!important;
            }
            .checkout-form-check-input{
                position: absolute;
                margin-top: 0.3rem;
                margin-left: -1.25rem;
            }
            .checkout-summary-header{
                @extend .d-flex;
                @extend .flex-row;
                font-weight: 500;
                border-bottom: 1px solid #DDD;
                padding: 10px;
                .checkout-summary-item{
                    flex: 0 0 auto;
                    width: 60%;
                }

                .checkout-summary-price{
                    flex: 0 0 auto;
                    width: 25%;
                }
                 .checkout-summary-action{
                    flex: 0 0 auto;
                    width: 15%;
                }

            }

            .checkout-summary-row{
                @extend .d-flex;
                @extend .flex-row;
                border-bottom: 1px solid #DDD;
                padding: 10px;
                .checkout-summary-row-item{
                    flex: 0 0 auto;
                    width: 60%;
                    padding-right: 10px;
                }

                .checkout-summary-row-price{
                    flex: 0 0 auto;
                    width: 30%;
                    word-break: break-word;
                }
                .checkout-summary-row-actions{
                    @extend .d-flex;
                    @extend .flex-row;
                    @extend .justify-content-end;
                    flex: 0 0 auto;
                    width: 10%;

                }

            }
            .checkout-summary-total{
                padding-top: 20px;
                padding-bottom: 10px;
            }
            .checkout-bold{
                font-weight: 500;
            }
            .checkout-summary-total-row{
                    @extend .d-flex;
                    @extend .flex-row;
                    @extend .justify-content-between;
                    padding: 5px 10px;

               
                    .checkout-summary-total-left{
                        flex: 0 0 auto;
                        width: 60%;
                    }
                   .checkout-summary-total-right{
                        flex: 0 0 auto;
                        width: 40%;
                        span{
                            display: inline-block!important;
                        }
                    }
            }
            .checkout-form-input{
                @extend .form-control;
            }
        }
        .checkout-required{
            color: red;
            font-size: 8pt;
            font-weight: 700;
        }
    }
.checkout-coupon-row{
        @extend .d-flex;
        @extend .flex-row;
        font-size: 16px;
        border-bottom: 1px solid #DDD;
        padding: 1rem;
        .checkout-coupon-row-item{
            flex: 0 0 auto;
            width: 70%;
            padding-right: 10px;
        }

        .checkout-coupon-row-discount{
            flex: 0 0 auto;
            width: 25%;
            word-break: break-word;
        }
        .checkout-coupon-row-actions{
            @extend .d-flex;
            @extend .flex-row;
            @extend .justify-content-end;
            flex: 0 0 auto;
            width: 5%;

        }

    }
    .checkout-coupon-link{
        font-size: 16px;
        font-weight: 500;
        @extend .p-3;

    }
    hr{
        margin: 0;
    }
    .primary{
        display: block;
        width: 100%;
        @extend .my-3;
        &[disabled]{
        background-color: #F1F1F1!important;
        color: #AEAEAE!important;
        }
    }
    #payment_acc_apply, #payment_acc_remove{
        width:auto!important;
    }
}
 .checkout-secondary-button{
    border-radius: 4px;
    font-size: 1em!important;
    padding: 0.75em 1.25em;
    color: #535353;
    background-color: #F1F1F1;
    border: 1px solid transparent;
    transition: all .1s ease-in-out;
    display: block;
    width: 100%;

    &[disabled]{
    background-color: #F1F1F1!important;
    color: #AEAEAE!important;
    }
}
