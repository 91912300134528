//////////////////////////////////////////////////////////////////////////////////////////

// _BASIC-PAGE.SCSS

// This file includes all rules which affect the Job Target basic page (eg Usage Agreement, 
// Privacy Policy and Help pages). The rules in this file are not to be modified to 
// accommmodate custom partner sites. Site-specific customization is to be handled in a 
// separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


	#basic-page {
	
		.site {
			width:100%;
		}
		
		section {
			padding-top: 20px;
		}
		
		section.no-padding {
			padding-top: 0;
		}
		
		h1 {
			margin-bottom: 20px;
		}
		
		h2 {
			font-weight: 400;
			padding: 5px 0;
			margin-bottom: 10px;
			border-top: thin dotted $miscLtGrey;
			border-bottom: thin dotted $miscLtGrey;
		}
		
		h3, .h3 {
			font-size: 1.2em;
			padding-bottom: 10px;
		}
		
		li {
			padding: 10px;
		}
		
		p {
			font-size: 1.1em;
			padding-bottom: 10px;
			margin: 0;
		}
		
		p + h3, p + .h3 {
			padding-top: 10px;
		}
		
		span {
			display: block;
		}
		
		small {
			display:block;
			font-size: .95em;
			padding-top: 20px;
		}
				
		.breadcrumbs {
			
			li {
				padding: 0;
			}
		}
		
		.delete-alert {
			cursor: pointer;
		}

		table {
			width: 100%;
			border-spacing: 5px;
				
			caption {
				color: $textLtGrey;
				padding: 10px 0;
			
				b, strong {
					font-weight: 400;
				}
			
				i, em {
					float: right;
					font-style: normal;
				}
			
				a {
					padding-right: 20px;
				}
			
				a + a {
					padding-left: 20px;
					padding-right: 0;
					border-left: thin solid $miscLtGrey;
				}

			}
			
			.col-width-a {
				width: 5%;
			}
			
			.col-width-b {
				width: 10%;
			}

			.col-width-c {
				width: 15%;
			}

			.col-width-d {
				width: 20%;
			}

			.col-width-e {
				width: 25%;
			}

			.col-width-f {
				width: 30%;
			}

			.col-width-g {
				width: 35%;
			}

			.col-width-h {
				width: 40%;
			}

			.col-width-i {
				width: 45%;
			}

			.col-width-j {
				width: 50%;
			}

			.col-width-k {
				width: 55%;
			}

			.col-width-l {
				width: 60%;
			}

			.col-width-m {
				width: 65%;
			}

			.col-width-n {
				width: 70%;
			}

			.col-width-o {
				width: 75%;
			}

			.col-width-p {
				width: 80%;
			}

			.col-width-q {
				width: 85%;
			}

			.col-width-r {
				width: 90%;
			}

			.col-width-s {
				width: 95%;
			}

			th {
				font-weight: 400;
				font-size: .95em;
				padding: 10px;
				
				@include breakpoint(medium) {
					padding: 10px 4px;
				}
			}
	
			td {
				padding: 10px;
			
				a {
					padding-right: 10px;
					cursor:pointer;
				}
			
				a + a {
					padding-left: 10px;
					border-left: thin solid $miscLtGrey;
				}
				
				p {
					font-size: 1em;
					word-break: break-word;
				}
			}
		
			tr {
				background: $bkgrdLtGrey;
			}

			tr.paused-alert {
				color: $miscLtGrey;
			}
				
			tr + tr {
				border-bottom: thin dotted #eeeeee;
				background: $white;
			}
		}
		
		.message-page {
			p {
				padding-top: 20px;
			}
		}
		
		@include breakpoint(medium) {
			.breadcrumbs {
				padding-left: 10px;
			}

			table, 
			thead,
			tbody,
			tfoot,
			th,
			td,
			tr {
				display: block;
			}

			table {

				caption {
					display: block;

					i, em {
						float: none;
						display: block;
						font-style: normal;
					}
				}

				td {
					position: relative;
					white-space: normal;
					padding-left: 25%;
					border-top: 2px solid #fefefe;
					min-height: 18px;

					span.options a {
						display: inline-block;
						padding: 0 10px 0 0;
					}

					a+a {
						border-left: 0;
					}
				}

				td.description {
					padding-left: 10px;
				}

				td:before {
					position: absolute;
					top: 8px;
					left: 8px;
					width: 16%;
					padding-right: 8px;
					white-space: nowrap;
					font-weight: bold;
					content: attr(data-title);
				}

				.fa {
					line-height: 1em;
				}

				.listing-detail td {
					padding-left: 8px;
				}

				.listing-detail td:first-of-type {
					display: none;
				}

				// Hide table headers (but not display: none;, for accessibility)

				tr th {
					position: absolute;
					top: -9999px;
					left: -9999px;
				}
			}

		}

		
		@include breakpoint(small) {
			padding: 5px;
			
			.mobile-hide {
				display: none;
			}
			
			h1 {
				font-size: 1.9em;
			}
			
			h1, .breadcrumbs {
				padding-left: 10px;
				box-sizing: border-box;
			}
			
			h2 {
				box-sizing: border-box;
				padding: 5px;
			}
			
			span {
				position: relative;
			}
			
			table {
				caption, th, td {
					vertical-align: top;
				}
				
				caption {
					width: 100%;
					box-sizing: border-box;
					padding: 5px 5px 15px;
					
					b, strong {
						float:none;
						display:block;
					}

					i, em {
						float: none;
						display: block;
						font-style: normal;
					}
				}
				
				td {
					padding: 8px 8px 8px 37%;
				}

				td:before {
					width: 33%;					
				}
				
				td span.options a {
					display: inline-block;
					float:none;
					border-left: none;
					padding: 0 10px 0 0;
				}
				
				td span.options b {
					border-left:none;
					float:none;
					position: relative;
					display:block;
					padding: 10px 0;
				}

			}
		}

		.talent-boost-logos {
			img{
			    width:100%;
			    max-width:960px;
			    padding:10px 0px;
			}
		}
	}


.page-string-description {
	@extend %container;

	> div,
	> p {
		@extend %col;
		@extend %col-sm-12;
		padding-top: $grid-gutter-width-md;
		padding-bottom: $grid-gutter-width-md;
	}
}