// ----------------------------------------------------------------
// ----------------------------------------------------------------
//
//	!!! DEPRECIATED FILE !!!
// 
//	DO NOT USE THESE VARIABLES IN YOUR NEW WORK!!
//	Please see 'variables' in the parent 'framework' folder
//
// ----------------------------------------------------------------
// ----------------------------------------------------------------
 
////////////////////////////////////////////////////////		
	   
// 1.0 NORMALIZE RULES  
	
////////////////////////////////////////////////////////
		
		html {
			font-family: sans-serif;
			-ms-text-size-adjust: 100%; 
			-webkit-text-size-adjust: 100%; 
		}

		body {
			margin: 0;
		}
		
		a {
			background: transparent;
		}

		a:focus {
			outline:#000 2px dotted;
		}

		a:active,
		a:hover {
			outline: 0;
		}	
		
		audio,
		canvas,
		video {
			display: inline-block;
		}

		audio:not([controls]) {
			display: none;
			height: 0;
		}

		[hidden],
		template {
			display: none;
		}
				
		img {
			border: 0;
		}

		svg:not(:root) {
			overflow: hidden;
		}

	
	

////////////////////////////////////////////////////////

// 2.0 - VARIABLES
	
////////////////////////////////////////////////////////
	
		// 2.1 - Colors
		
			$linkDefault: #3463aa;
			$linkHover: #333333;

            $ym-green: #a8ad00;
            $ym-navy: #062d5d;  
            $ym-slate: #5B6770;
            $ym-grey: #a2a9ae;
            $ym-brown: #493627;
            $ym-warning-red: #a94442;
			
			$bkgrdLtBlue: #eaf0f4;
			$bkgrdLtGrey: #f5f5f5;
			$bkgrdErrorAlert: #faf2f2;
			$bkgrdWarningAlert: #fcfae1;
			$bkgrdSuccessAlert: #f0fbe9;

			$textDkGrey: #333333;
			$textMdGrey: #535353;
			$textGrey: #676767;
			$textLtGrey: #888888;
			$textAlert: #d13333;
			
			$primary-cta-color: #009900;
			$primary-cta-gradient: #29812f;
	
			$primary-hover-background: #333333;
			$primary-hover-gradient: #3bae43;
	
			$secondary-cta-color: #3463aa;
			$secondary-cta-gradient: #07357a;
	
			$secondary-hover-background: #555555;
			$secondary-hover-gradient: #3463aa;	

			$tertiary-cta-color: #eaf0f4;
			$tertiary-cta-gradient: #eaf0f4;
	
			$tertiary-hover-background: #555555;
			$tertiary-hover-gradient: #333333;	
			
			$tooltipBkgrd: #ffffe5;
			$tooltipBorder: #aaaaaa;
			$tooltipShadow: #959595;
			$tooltipCaret: #515150;
			
			$miscLtGrey: #CCCCCC;
			$white: #ffffff;
			$black: #000000;

			$facebookBlue: #2B5F9E;
			$linkedinBlue: #0073A3;
			$twitterBlue:  #1b95e0;
			$twitterBlack:  #000000;
			$googleRed:    #EA4335;
			$websitePurple:#3F2B9E;
			$youtubeRed:   #FF0000;
		

		// 2.2 - Dimensions
			$xsmall: 320px;
			$three-seven-five: 375px;
			$four-one-four: 414px;
			$small: 480px;
			$five-four-zero: 540px;
			$five-six-nine: 569px;
			$six-zero-four: 604px;
			$six-eighty: 680px;
			$medium: 768px;
            $mediumtab: 767px;
			$eight-hundred: 800px;
			$large: 960px;
			
			$widePage: 980px;
			$thinPage: 500px;	
			
			$full: 100%;
			

////////////////////////////////////////////////////////

// 3.0 MIXINS

////////////////////////////////////////////////////////

//	@mixin clearfix {
//		*zoom: 1;
//
//		&:before, &:after {
//			content: "";
//			display: table;
//		}
//
//		&:after {
//			clear: both;
//		}
//	}




////////////////////////////////////////////////////////

// 4.0 CSS3 BREAKPOINTS

////////////////////////////////////////////////////////
	
	@mixin breakpoint($point) {
		@if $point == xsmall {
			@media (max-width: $xsmall) { @content; }
		}
		@else if $point == three-seven-five {
			@media (max-width: $three-seven-five) { @content; }
		}
		@else if $point == four-one-four {
			@media (max-width: $four-one-four) { @content; }
		}
		@else if $point == small {
			@media (max-width: $small) { @content; }
		}
		@else if $point == five-four-zero {
			@media (max-width: $five-four-zero) { @content; }
		}
		@else if $point == five-six-nine {
			@media (max-width: $five-six-nine) { @content; }
		}
		@else if $point == six-zero-four {
			@media (max-width: $six-zero-four) { @content; }
		}
		@else if $point == six-eighty {
			@media (max-width: $six-eighty) { @content; }
		}
		@else if $point == medium {
			@media (max-width: $medium) { @content; }
		}
        @else if $point == mediumtab {
			@media (max-width: $mediumtab) { @content; }
		}
		@else if $point == eight-hundred {
			@media (max-width: $eight-hundred) { @content; }
		}
		@else if $point == large {
			@media (max-width: $large)  { @content; }
		}
	}
	



////////////////////////////////////////////////////////

// 5.0 REUSABLE ELEMENTS

////////////////////////////////////////////////////////

	// 5.1 Clearfix

	.clearfix {
		@include clearfix;
	}


	// 5.2 Underline
	
	.underline {
		text-decoration: underline;
	}


	// 5.3 Text Alert
	
	.textAlert {
		color: $textAlert;
	}
	
	
	// 5.4 Text-Alignment
	
	.center {
		text-align: center;
	}
		
	.right {
		text-align: right;
	}
	
	// 5.5 Category Header
	
	.category-header {
		padding-bottom: 10px;
				
		@include breakpoint(medium) {
			h2 {
				padding-left: 10px;
			}
		}
	}
	
	
	// 5.6 Dotted Rule
	
	.dotted-rule {
		margin-top: 10px;
		border-top: thin dotted $miscLtGrey;
	}
	
	// 5.7 Pagination
	
	.pagination {
		text-align:center;
		padding-top: 20px;
		color: $textGrey;
		
		@include breakpoint(small) {
			display: block;
			float: none;
			width: 100%;
			padding-left: 10px;
			box-sizing:border-box;
		}
		
		li + li {
			
			@include breakpoint(small) {
				padding-left: 10px;
			}
		}
		.previous {
			float: left;
		}
		.next {
			float: right;
		}
	}

	// 5.8 Powered by
		
	.powered-by {
		padding: 0 20px;
		box-sizing: border-box;
        
        @include breakpoint(small) {
            text-align: right;
        }
        
	
		.dotted-rule {
			min-width: 70%;
			float: left;
			margin-top: 40px;
			-webkit-flex: 1;  
			-ms-flex: 1;   
			flex: 1 1 0;  
			max-width: 85%;
			display: none;

			
			@include breakpoint(eight-hundred) {
				min-width: 65%;
			}
			@include breakpoint(medium) {
				min-width: 60%;
			}
			@include breakpoint(six-zero-four) {
				min-width: 55%;
			}
			@include breakpoint(five-four-zero) {
				min-width: 50%;
			}
			@include breakpoint(small) {
				display: none;
			}	
		}
		
		a {
			float: right;
			padding-bottom: 5px;
			cursor: pointer;
			margin-left: 10px;
			width: auto;
			display: block;
			
			img {
				vertical-align: bottom;
			}
			@include breakpoint(small) {
				flex: 0 0 auto;
                width: 100%;
                margin-left: 0;
            }
			@include breakpoint(xsmall) {
			    float: none;
			    display: block;
			}
		}

		@include breakpoint(small) {
			.dotted-rule {
				display: none;
			}
		}			
	}
	
	
	// 5.9 Inactive
	
	a.inactive {
		color: $miscLtGrey;
		
		&:hover {
			color: $miscLtGrey;
			cursor: help !important
		}
	}
	
	// 5.10 Hide Component
	
	.hide {
		display:none;
		visibility: hidden;
	}
	
	// 5.11 Sideboard Ad
	
	.sideboard-ad {
		background: none repeat scroll 0 0 $bkgrdLtGrey;
		padding: 10px;
	}
	
	// 5.12 Placeholder
	
		::-webkit-input-placeholder {
		   color: #777777;
		}

		:-moz-placeholder { /* Firefox 18- */
		   color: #777777;  
		}

		::-moz-placeholder {  /* Firefox 19+ */
		   color: #777777;  
		}

		:-ms-input-placeholder {  
		   color: #777777;  
		}
		
	// 5.13 Sort-Up, Sort-Down
	
	.sort-up, .sort-down {
		font-size: .8em;
	} 
	
	// 5.11 New line
	
	.new-line {
		clear: both;
	}
	
	// 5.12 Full width
	
	.full-width {
		width: 100%;
		box-sizing: border-box;
	}
	
	// 5.13 Break-all
	
	.break-all {
		word-break: break-all;
	}

	
	
////////////////////////////////////////////////////////

// 6.0 - IMPORTS
	
////////////////////////////////////////////////////////
	
//	@import "layout";
//	@import "typography";
//	@import "forms";
//	@import "call-to-action";



//	Added for Bootstrap fix in 2.0 Framework
// ------------------------------------------

img, svg {
  vertical-align: middle;
}