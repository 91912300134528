.search-filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  position: relative;
  // @media (min-width:map-get($grid-breakpoints, "xl")) {
  //   min-height: 126px;
  // }

  .apply-button {
    height: 2.25rem;
    text-transform: uppercase;
    color: var(--site-secondary-text-color, #fff);
    font-family: Roboto, "sans-serif";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--site-secondary-color, #0072ce);
    border-color: var(--site-secondary-color, #0072ce);
  }
  .applied-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2.25rem;
    text-transform: uppercase;
    color: var(--site-secondary-color, #0072ce);
    font-family: Roboto, "sans-serif";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid rgba(0, 0, 0, 0.15);
    .applied-icon {
      font-size: 1.5rem;
      align-self: center;
      padding-top: 0.1rem;
    }
  }
  .apply-button-disabled {
    background-color: #0000002b;
    color: #00000042;
    border: none;
  }
  .input-group {
    input {
      border-radius: 4px !important;
    }
    .input-group-prepend {
      .input-group-text {
        background: none;
        border-right: none;
        padding-right: 0.25rem;
        padding-left: 0.5rem;
        .input-icon {
          color: #a2aaad;
          font-size: 1rem;
        }
      }
    }
    input {
      border-left: none;
      padding-inline: 0.5rem;
    }
  }
  .filter-button {
    display: flex;
    height: 2.25rem;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    cursor: pointer;

    &:focus,
    &:hover {
      border-color: var(--site-primary-color, #1badba);
    }

    .filter-icon {
      font-size: 1rem;
      color: #00000073;
    }
    .filter-pill {
      display: flex;
      width: 100%;
      min-width: 1.25rem;
      height: 1.25rem;
      padding: 0.1875rem 0.3rem;
      justify-content: center;
      align-items: center;
      border-radius: 6.25rem;
      background: var(--site-primary-color, #1badba);

      .filter-number {
        color: var(--site-primary-text-color, #fff);
        font-family: Roboto, "sans-serif";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.875rem;
      }
    }
  }

  .search-section {
    display: flex;
    padding: 0.75rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;

    .search-section-input {
      height: 40px;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
    .job-title-keyword-input {
      width: 100%;
    }

    .location-radius-section {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
      width: 100%;
      .location-input {
        width: 100%;
        min-width: 100px;
        flex-shrink: 2;
        flex-wrap: nowrap !important;
        .ui-autocomplete-input {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        @media (max-width: map-get($grid-breakpoints, "xl")) {
          min-width: auto;
        }
      }
      .radius-dropdown {
        width: fit-content;
        border-radius: 4px !important;
      }
    }

    .search-filter-btn {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
      align-self: stretch;
      @media (max-width: map-get($grid-breakpoints, "xl")) {
        width: 100%;
      }
      .apply-button,
      .applied-button,
      .apply-button-disabled {
        @media (max-width: map-get($grid-breakpoints, "xl")) {
          display: none;
        }
        width: 180px;
        height: 40px;
      }

      .applyBtnHeader {
        @media (max-width: map-get($grid-breakpoints, "xl")) {
          display: none;
        }
        display: flex;
      }

      .search-button {
        height: 40px;
        min-width: 140px;
        width: max-content;
        border-color: rgba(0, 0, 0, 0.15);
        color: var(--site-primary-color, #1badba);

        font-family: Roboto, "sans-serif";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        background-color: #fff;
        line-height: normal;
        &:hover {
          border-color: var(--site-primary-color, #1badba);
        }
        @media (max-width: map-get($grid-breakpoints, "xl")) {
          flex: 1;
          min-width: auto;
        }
      }

      .filter-button {
        height: 40px;
        // @media (min-width: map-get($grid-breakpoints, "xl")) {
        //   display: none;
        // }
      }
    }

    @media (max-width: map-get($grid-breakpoints, "xl")) {
      flex-wrap: wrap;
    }
  }
  .filter-section {
    display: flex;
    padding: 0.75rem;
    justify-content: center;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    width: -webkit-fill-available;
    background: #fff;
    z-index: 2;

    .filters-label {
      color: #4a4a4a;
      font-family: Roboto, "sans-serif";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      padding: 0.375rem 0px;
    }

    .filter-chips-container {
      display: flex;
      align-items: flex-start;
      white-space: nowrap;
      overflow: hidden;
      gap: 0.625rem;
      flex: 1 0 0;
      padding: 0.15rem 0px;

      .filter-chip {
        display: flex;
        padding: 0.375rem 0.5rem 0.375rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 0.3125rem;
        border-radius: 6.25rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        cursor: pointer;

        .filter-chip-label-container {
          display: flex;
          align-items: flex-start;
          gap: 0.1875rem;

          .filter-chip-label {
            color: var(--site-primary-color, #1badba);
            font-family: Roboto;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1rem;
          }
        }
        &:focus,
        &:hover {
          border-color: var(--site-primary-color, #1badba);
        }
        .filter-chip-icon {
          width: 1.125rem;
          height: 1.125rem;
          color: #00000073;
          padding-right: 1px;
          flex-shrink: 0;

          &:focus,
          &:hover {
            color: var(--site-primary-color, #1badba);
          }
        }
      }

      .empty-chip-container {
        width: 100%;
        height: 2rem;
        border-radius: 3.125rem;
        background: #f8f8f8;
      }
    }

    .filter-chips-container-show-all {
      flex-wrap: wrap;
      .filter-chip {
        .filter-chip-label-container {
          .filter-chip-label {
            text-wrap: wrap;
          }
        }
      }
    }

    .actions-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;

      .show-clear-actions {
        display: flex;
        align-items: center;
        gap: 0.3125rem;

        .action-label {
          font-family: Roboto, "sans-serif";
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem; /* 200% */
        }
        .separator-text {
          color: #6b6b6b;
          font-family: Roboto, "sans-serif";
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem; /* 133.333% */
        }
        .action-label-disable {
          color: rgba(0, 0, 0, 0.45);
        }
        .action-label-enable {
          color: var(--site-primary-color, #1badba);
          cursor: pointer;
        }
      }
      .apply-button,
      .applied-button,
      .apply-button-disabled {
        width: 180px;
      }
    }
    @media (max-width: map-get($grid-breakpoints, "xl")) {
      display: none;
    }
  }
}
