// --------------------------------------------------
//
//		Occupation Search CTA 
//
// --------------------------------------------------

#occupation-search-CTA{
 	@extend .d-flex;
 	@extend .py-0;
	@extend .align-items-lg-center;
	height: 20vh;
	@extend .my-4;
	@include media-breakpoint-down(lg) { height: 180px; }
	@include media-breakpoint-down(xs) { height: 280px; }
	
	&.dark-skin{
		background: url(../img/occupation-hp-hero.png) no-repeat center center / cover;
	    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .85);
	    color: #fff;

	    .occupation-search-CTA-container{
			.occupation-search-CTA-button{
				.secondary {
				    background-color: transparent;
				    border: 1px solid #fff;
				    color: #FFF;
				}
			}
		}
	}
	&.brand-skin{
		color: #fff;
		.occupation-search-CTA-container{
			.occupation-search-CTA-button{
				.secondary {
				    background-color: transparent;
				    border: 1px solid #fff;
				    color: #FFF;
				}
			}
		}
	}
	&.light-skin{

		.typewrite-container{
			border-right-color: currentColor!important;
		}

	}
		
	.occupation-search-CTA-container{
			background: transparent!important;
			@extend .d-flex;
			@extend .align-items-lg-center;
			@extend .flex-lg-row;
			@extend .justify-content-between;
			@extend .flex-column;
			@extend .px-2;
			@extend .py-4;
			@extend .p-md-4;
			@extend .text-center;
			@extend .text-lg-left;

		.occupation-search-CTA-text{
			font-size: $h2-font-size;
			font-weight: $font-weight-normal;
			@extend .pb-3;
			@extend .pb-lg-0;
			@extend .col-lg-8;


			.typewrite-container{
				display: inline-block;
				position: relative;
				min-height: 30px;
				vertical-align: middle;
				@extend .mb-3;
				@extend .mb-lg-0;

				&:after{
					content: "";
					display: inline-block;
					width: 2px;
					height: 30px;
					background: currentColor;
					margin-left: 5px;
				}
			}
		}
		.occupation-search-CTA-button{
			@extend .col-lg-4;
			@extend .text-center;
			@extend .text-lg-right;
		}
	}
}