.progress-bar-tooltip-wrapper {
	position: relative;
	height: 100%;
	width: 100%;

	.custom-tooltip {
		position: absolute;
		background-color: #464646;
		font-size: 0.625rem;
		color: white;
		padding: 0 0.5rem;
		border-radius: 4px;
		opacity: 0;
		transition: opacity 0.2s;
		top: 150%;

		// left: 50%;
		transform: translateX(-50%);
		width: max-content;
		z-index: 10;
	}

	&:hover {
		.custom-tooltip {
			opacity: 1;
		}
	}
}

.multiple-progress-bar-container {
	display: flex;
	flex-direction: row;
	gap: 0;
	height: 100%;
	justify-content: center;
	align-items: center;

	.progress-indicator {
		display: flex;
		width: 1.125rem;
		height: 1.125rem;
		padding: 0.125rem 0.25rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		border-radius: 6.25rem;
		border: 1px solid rgba(140, 140, 140, 0.33);
		background: #fff;
		color: rgba(30, 30, 30 ,0.70);
		font-size: 0.4375rem;
		font-style: normal;
		font-weight: 600;
		font-family: Roboto, 'Nunito Sans', Helvetica, Arial, sans-serif;
	}

	.progress-bar-container {
		position: relative;
		display: flex;
		flex-direction: row;
		gap: 0;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;

		.progress-bar-container-right {
			width: 100%;
			background-color: #d9d9d9;
			height: 4px;
			border-radius: 4px;

			.progress-value {
				background-color: #0469cd;
				height: 100%;
				max-width: 100%;
				border-radius: 4px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			.border-full {
				border-radius: 4px;
			}
		}

		.progress-bar-container-left {
			width: 100%;
			background-color: #d9d9d9;
			height: 4px;
			border-radius: 4px;

			.progress-bar-tooltip-wrapper {
				display: flex;
				justify-content: right;

				.progress-value {
					background-color: #fc74a8;
					height: 100%;
					max-width: 100%;
					border-radius: 4px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}

		.difference-value {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			padding: 0 0.25rem;
			border-radius: 2px;
			transition: opacity 0.2s;
			top: 150%;
			left: 50%;
			transform: translateX(-50%);
			width: max-content;
			font-family: Roboto, 'Nunito Sans', Helvetica, Arial, sans-serif;
			font-size: 0.625rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}

	.difference-positive {
		background-color: rgba(6, 189, 112, 0.75);
	}

	.difference-negative {
		background-color: rgba(255, 34, 34, 0.70);
	}
}
