// --------------------------------------------------
//
//		SORTS
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

.sort-bar {
	@extend %row;
	@extend .align-items-stretch;
	@extend .justify-content-around;

	min-height: 32px;
	//min-width: 100%;
	background: $white;
}

.sort-bar-inner {
	@extend %col;
}

.sort-options {
	@extend %row;
	height: 100%;
	@extend %align-items-stretch;
	@extend %align-self-stretch;
	@extend %justify-content-between;
}

.sort-option {
	@extend %col;
	@extend %flex-fill;
	@extend %col-auto;
	line-height: 2.5;
	padding-left: $grid-gutter-width-sm * 1.75;
	padding-right: $grid-gutter-width-sm;

	&:hover,
	&:active {
		color: currentColor;
	}

	&:not(:first-child) {
		border-left: 1px solid $gray-400;
	}

	svg {
		height: 11px;
		width: 8px;

		&#icon-sort-desc { 
			position: relative;
			top: 3px;
			left: -3px;
			transform: rotate(180deg);
		}
	}
}
 
.sort-active,
.sort-active.sort-asc svg:first-child g[class^="sort-arrow"],
.sort-active.sort-desc svg:nth-child(2) g[class^="sort-arrow"] {
	color: currentColor;
	fill: currentColor;
	display: inline-block;
}

.sort-active.sort-asc svg:first-child,
.sort-active.sort-desc svg:nth-child(2) {
	position: relative;
	top: -2px;
}

.sort-inactive,
.sort-option:not(.active) svg g[class^="sort-arrow"] {
	color: $gray-600;
	fill: $gray-600;
}

.sort-option:not(.sort-active) svg {
	display: none;
}

.sort-option.sort-asc svg#icon-sort-desc {
	display: none !important;
}

.sort-option.sort-desc svg#icon-sort-asc {
	display: none !important;
}