//////////////////////////////////////////////////////////////////////////////////////////

// _TRANSLATOR-TOOL.SCSS

// This file includes all rules which affect the translator tool module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Kai ByRoade at kai.byroade@communitybrands.com

//////////////////////////////////////////////////////////////////////////////////////////

.translator-tool {
	&-header {
		float: right;

		.goog-te-gadget select {
			max-height: 25px;
		}
		#google_translate_element{
            margin: 10px;
		}
	}

	&-footer {
		float: right;
		margin-top: 15px;
	}
}