//////////////////////////////////////////////////////////////////////////////////////////

// _UPLOAD-RESUME.SCSS

// DEPRECIATED - new styles at bottom

//////////////////////////////////////////////////////////////////////////////////////////

// .page-list {
// 	color: #676767;
// }
// 
// .pagination {
// 	float: right;
// 	display: block;
// }
// 
// .pagination>li:first-of-type>a {
// 	border-radius: 4px 0 0 4px;
// }
// 
// .pagination>li:last-of-type>a {
// 	border-radius: 0 4px 4px 0;
// }
// 
// .pagination>li>a {
// 	padding: 8px 12px;
// 	line-height: 1.42857143;
// 	text-decoration: none;
// 	background-color: #fff;
// 	border: 1px solid #ddd;
// 	display: block;
// }
// 
// .pagination>li+li>a {
// 	border-left: 0;
// }
// 
// .pagination>li>a:hover {
// 	background-color: #f5f5f5;
// }
// 
// .pagination li.active>a {
// 	color: #999;
// 	background-color: #f5f5f5;
// 	cursor: default;
// }
// 
// .pagination>.inactive>a:hover {
// 	background-color: #fff;
// }
// 
// .pagination>li>a .arrow {
// 	display: none;
// }
// 
// .pagination>li {
// 	float: left;
// 	display: block;
// }
// 
// .inactive>a {
// 	color: #999;
// 	cursor: default;
// }
// 
// #job-listing {
// 
// 	.job-num {
// 		padding-top: 28px;
// 	}
// 
// 	.pagination {
// 		padding-top: 20px;
// 	}
// 
// 	.pagination>li {
// 		float: left;
// 		display: block;
// 	}
// }
// 
// #results-page aside .listing-options em {
// 	float: none;
// }
// 
// .job-num {
// 	float: left;
// 	padding-top: 10px;
// 
// 	select {
// 		height: auto;
// 		border: 1px solid #ddd;
// 		padding: 3px 0;
// 		line-height: 1.42857143;
// 		border: 1px solid #ddd;
// 		color: #535353;
// 		border-radius: 4px;
// 	}
// 
// 	span {
// 		display: block;
// 		margin-top: 2px;
// 	}
// }
// 
// aside {
// 
// 	.listing-options {
// 		padding-bottom: 0;
// 
// 		li {
// 			display: inline-block;
// 			text-align: left;
// 		}
// 		li+li:before {
// 			color: #ccc;
// 			padding: 0 7px;
// 			content: "| ";
// 		}
// 	}
// }
// 
// .clearfix>.page-list {
// 	margin-bottom: 15px;
// }

// ------------------------------------------
//
//	NEW FRAMEWORK PAGINATION
//  Framework 2.0.0+
//
// ------------------------------------------

ul.pagination {
	@extend .row;
	@extend .rm-p;
	flex-wrap: nowrap;
	margin-inline: 0;

	li {
		@extend .align-items-center;
		@extend .col;
		@extend .rm-p;

		&:not(.inactive) a {
			cursor: pointer;
		}

		&:not(.active) a {
			color: $gray-700;
		}

		&.active a {
			cursor: default;
			//color: currentColor !important;
		}

		a {
			@extend .d-flex;
			@extend .align-self-start;
		}
	}

	.page-link-nav {
		border: none; 
	  background: 0 0;
	  border-top-left-radius: 0;
	  border-bottom-left-radius: 0;
	}

	li.page-item a {
		padding: 0.65em;
		line-height: 1.2em;
		font-weight: bold;
	}

	li.inactive a,
	li.inactive a svg .pagination-arrows {
	  color: $gray-700;
	  fill: $gray-700;
	}

	li a svg .pagination-arrows {
	  fill: currentColor;
	}
}