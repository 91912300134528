//////////////////////////////////////////////////////////////////////////////////////////

// _MODAL.SCSS

// This file includes all rules which affect the generic pop-up module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


.modal-open {
	overflow: hidden;
}


.modal-dialog.captcha-holder {
    overflow: hidden
}
.close {
    cursor: pointer;
}
.modal-content {
    box-sizing: border-box;
	position: relative;
	padding: 1rem;
		img {
			margin: 0 auto;
			display: block;
		}
	.header {
	
		@extend .modal-header;
		border-bottom: none;
		padding: 0px;
		text-align: left;


		h5, .h5 {
		background: transparent;
		border: none;
		padding: 0;
		clear: both;
		overflow: hidden;
		font-size: 1.25em;
		float: none;
		
		b {
			font-family: arial, helvetica, sans-serif;
			font-weight:normal;
			padding-left:5px;
		}
		a{
			cursor: pointer;
		}
	}
		
		h5.error-modal, .h5.error-modal {
			color: $textAlert;
			background: $bkgrdErrorAlert;
			font-weight: normal;
		}

		h5.warning-modal, .h5.warning-modal {
			color: $textDkGrey;
			font-weight: normal;
			background: $bkgrdWarningAlert;
		}
	
		h5.success-modal, .h5.success-modal {
			color: $textMdGrey;
			font-weight: normal;
			background: $bkgrdSuccessAlert;
		}
	}

	.alert {
		color: $textAlert;
		border: none;
		clear: both;
		display: block;
		}
		
		p {
			padding: 20px 10px 10px;
		}
		
	/* form */
	form {

		button	{
			float: right;
			clear: both;
			width: 100%;
			margin-top: 10px;
		}

		a {
			display: block;
			text-align: center;
			padding-top: 20px;
			clear: both;
			cursor: pointer;
		}

		span a {
			text-align: left;
			padding-top: 5px;
		}
		
		p + p {
			padding-top: 0;
		}
			
		fieldset {
			border: medium none;
			float: left;
			margin: 0 0 15px;
			padding: 0;

			label {
				padding-bottom: 5px;
				display: block;
				text-align: left;
			}
			
			input {
					padding: 5px;
					clear: both;
					display: block;
					width: 93%;
			}

		}

		.email {
			width: 57%;
			float: left;
				input {
					width: 97%;
				}
		}

		.password {
			width: 40%;
			padding: 0;
			float: right;
		}
		}

		.new-user {
			overflow: hidden;
			padding-top: 10px;
			border-top: thin solid $miscLtGrey;

			a {
				display: block;
			} 
			
			a + a {
				padding-top: 5px;
			}
		}
	}

	form#qCreate {
		text-align: left;
		label {
			font-weight: bold;
		}
		fieldset {
			width: 49%;
		}
		fieldset+fieldset {
			margin-left: 2%;
		}
	}
.job-search-main{	
	#jt_apply_email {
		input#fileupload {
		    position: absolute;
		    top: 0;
		    right: 0;
		    margin: 0;
		    opacity: 0; 
		    -ms-filter: 'alpha(opacity=0)';
		    direction: ltr;
		    cursor: pointer;
		    padding: 11px;
		}
		#addFiles_btn span {
		    margin: auto !important;
		    flex: 0 1 auto !important;
		    padding: 6px !important;
		}
		.glyphicon-plus {
		    position: relative;
		    display: inline-block;
		    font-family: 'Glyphicons Halflings';
		    font-style: normal;
		    font-weight: bold;
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		    font-size: 20px;

		    &:before {
			    content: "\2b";
			}
		}
	}
}


#email .modal-content form, #email-resume .modal-content form, #share .modal-content {

	h6, .h6 {
		font-size: 1.2em;
		padding: 10px 0 5px;
		border-bottom: 1px dotted #ccc;
		margin-bottom: 15px;
		clear:both;
		font-weight: 400;
	}

	button {
			float: none;
			clear: both;
			width: 100%;
	}
	
	fieldset {
			border: medium none;
			float: none;
			margin: 0 0 15px;
			padding: 0;

			&.half {
				width: 49%;
			
				&.left {float: left;}
			&.right {float: right;}
		}
		
		&.full {
			clear:both;
		}

		&.full input{
			width:100%;
			box-sizing: border-box;
		}

		textarea {
			padding: 5px;
			clear: both;
			display: block;
			width: 100%;
			box-sizing: border-box;
		}
		.cancel {
			float: none;
			padding: 20px 0 0;
			text-align: center;
			cursor: pointer;
		}
	}
}


#delete .modal-content {

	button {
		float: right;
	}
	
	.cancel {
		float: none;
		padding: 9px 30px;
		cursor: pointer;
		text-align: center;
	}
	
	p {
		
		display: block;
		padding: 20px 10px;
	}
}

#share .modal-content {

	h2 {
		padding: 20px 0;
	}
	
	button, .primary, .secondary, .tertiary {
		padding-left: 0px;
		padding-right: 0px;
	}
	
	.social-media ul {
		text-align: center;
		
		li {
		display: inline;
		font-size: 2.5em;
		}
	}
	
	div + div {
		margin: 20px 0 0;
		padding: 10px;
		background: #f5f5f5 none repeat scroll 0 0;
	}
}

#apply-options .modal-content {

	h2 {
		padding: 20px 0;
		text-align: center;
	}
	
	p {
		text-align: center;
		font-size: 1.1em;
	}
	
	div {
		text-align: center;
		display: -ms-flex;	
		display: -webkit-flex;	
		display: flex;
		&.header{
			display: flex!important;
		}
		.secondary {
			font-size: 1.1em;
			-webkit-flex: 1;	
			-ms-flex: 1;	
			flex: 1 1 50%;
			padding: 5px;
			margin: 5px;
		}
	}
	@include breakpoint(small) {
		
		div {
			display: block;
		}
		
		.secondary {
			flex: 0 0 auto;
			display:block;
			box-sizing: border-box;
			width: 100%;
		}
		
		a + a {
			margin-top: 10px;
		}
	}
}
#jt_apply_email{
	.template-download.fade.in {
		    opacity: 1;
	}
	.template-upload.fade.in {
		    opacity: 1;
	}
	input#fileupload {
	    font-size: 20px!important;
	}
}
#save-search, #cancel-resume-build, #delete-resume, #unpublish-resume, #delete-cover, #publish-resume, #link-resume, #delete-alert , #upgrade-resume {

	
	.modal-content {

		button {
			float: none;
			width: 100%;
		}
	
		.cancel, form a {
			float: none;
			padding: 20px 0 0;
			cursor: pointer;
			text-align: center;
			display: block;
		}
	
		fieldset {		
			padding: 20px 15px 15px;
			float:none;
			width: 87%;
		}
	
		input {
			width: 100%;
		}
	
		label {
			font-weight: 700;
		}
	}
}

#link-resume #resume_link a {
	word-break: break-word;
}

#delete-job-application .modal-content, #delete-account .modal-content, #revoke-job-application .modal-content, #accept .modal-content, #decline .modal-content{

	button {
		float: right;
	}
	
	.cancel {
		float: none;
		cursor: pointer;
		text-align: center;
	}
	
	p {
		
		display: block;
		padding: 20px 10px;
	}
	
	p + p {
		padding-top: 0;
	}
}

#accept .modal-content, #decline .modal-content {
	
	form {
		padding-top: 20px;

	}
	
	textarea {
		display:block;
		width: 100%;
		box-sizing: border-box;
		margin: 5px 0 20px;
	}
}

#EEOC-info {

	div {
		padding-top: 20px;
	}
	
	div + div {
		padding-top: 10px;
	}
	
	a {
		display:block;
		padding: 10px;
	}
	
	ul {
		list-style-type: disc;
		list-style-position: outside;
		padding: 0 20px 5px;
	}
	
	h3, .h3 {
		font-size: 1.3em;
		font-weight: 400;
	}

}

#reset-password {

	
	fieldset {
		width: 100%;
	}

	i {
		display: block;
		padding-top: 20px;
	}
	
	.modal-content form {
	
		label {
			padding:10px 0;
		}
	
		fieldset input {
			width:95%;
			margin-top: 3px;
		}
		
		button {
			width: 98%;
			float: none;
			display:block;
		}
		
		span {
			line-height: 1.2;
		}
	}
	
}


#sign-in .modal-content {
	width: 400px;

	form {
		.primary {
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			-o-border-radius: 5px;
			border-radius: 5px;
			font-size: 17px;
			background: #3fa947;
			margin-bottom: 14px;
		}
		fieldset {
			margin-bottom: 14px;
			float: none;
			
			label, .label {
				padding: 0 0 5px;
				display: block;
				color: #333;
				font-size: 15px;
			}

			input {
				border: 1px solid #ccc;
				padding: 5px 10px;
				width: 95.5%;
			}

			&.invalid input {
				border-color: #990000;
				color: #990000;

					label {
						color: #990000;
						font-weight: bold;
					}
			}
			a {
				text-align: left;
				text-decoration: underline;
			}

			&.check label {
				font-size: 14px;
				overflow: hidden;
				padding: 0 0 10px;

				a {
					display: initial;
				}
				input[type=checkbox] {
					float: left;
					width: 20px;
					margin: 0 5px 0 0;
					display: block;
				}
			}
			&.agreement {
				background: #f5f5f5;
				padding: 10px 10px 0;
				overflow: hidden;
			}
			.required-label {
				padding: 0 0 14px;
				display: block;
			}
			sup {
				top: -1px;
			}

			&.switch .button-selector {
				border: 1px solid #ccc;
				-webkit-border-radius: 7px;
				-moz-border-radius: 7px;
				-ms-border-radius: 7px;
				-o-border-radius: 7px;
				border-radius: 7px;
				overflow: hidden;
				padding: 3px;

				input[type=radio] {
					display: none;
				}

				input[type=radio] + label {
					-webkit-border-radius: 5px;
					-moz-border-radius: 5px;
					-ms-border-radius: 5px;
					-o-border-radius: 5px;
					border-radius: 5px;
					cursor: pointer;
					display: block;
					float: left;
					width: 46%;
					padding: 10px 2%;
					text-align: center;
				}
				input[type=radio]:checked + label {
					background: #3462a5;
					color: #fff;
				}
			}
		}//End of fieldset
	} //end of form

	.form-switch {
		a {
			background: #3462a5;
			font-size: 18px;
			color: #fff;
			padding: 30px 0;
			margin: 0 -15px -15px;
			width: 430px;
			display: block;
			text-align: center;
			-webkit-border-radius: 0 0 5px 5px;
			-moz-border-radius: 0 0 5px 5px;
			-ms-border-radius: 0 0 5px 5px;
			-o-border-radius: 0 0 5px 5px;
			border-radius: 0 0 5px 5px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

@media (min-width: 768px) {
	.modal-dialog {
		width: 600px;
		margin: 30px auto;
	}
	
	.modal-content {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	}
	.modal-sm {
		width: 300px;
	}
}
@media (min-width: 992px) {
	.modal-lg {
		width: 900px;
	}
}

// MEDIUM (TABLET) BREAKPOINT

		@include breakpoint(medium) {
			width: $medium;
						
			.modal-dialog {
					width: 600px;
					margin: 30px auto;
		
				.modal-content {
					-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
					box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

					form {
						button	{
							float: none;
							width: 100%;
							clear: both;
							margin-bottom: 15px;
						}
							.email {
								width: 100%;
								float: left;
								clear:both;
								input {
									width: 97%;
								}
							}

							.password {
								width: 100%;
								padding: 0;
								float: right;
									input {
										width: 97%;
									}
							}
						}
				}
	 		 }

			#email .modal-content form {

			
			fieldset {
					&.half {
						width: 100%;
					
						&.left {float: left;}
					&.right {float: right;}

						input{
						width: 97%;
					}
				}
			}
		}		
		.modal-sm {
			width: 300px;
		}
	}

		

// SMALL (SMARTPHONE) BREAKPOINT
		
		@include breakpoint(small) {
			width: $small;
			

			.modal-lg {
				width: 900px;
			}
			.modal-content {
				form#qCreate {
					fieldset {
						width: 100%;
						float: none;
					}
					fieldset+fieldset {
						margin-left: auto;
					}
				}
			}
		}
