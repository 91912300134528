//////////////////////////////////////////////////////////////////////////////////////////

// _FILTERS-NAV

//  This file is used on both job seeker and employer platforms, so editing this file will effect both
// This also includes the radio butons that belone in the filters nav
//////////////////////////////////////////////////////////////////////////////////////////

//radio buttons

.custom-control input[type="radio"] {
  display: none;
}

.custom-control label:before {
  content: " ";
  display: block;
  position: absolute;
  top: 1px;
  margin: 0 10px 0 0px;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid $darker;
  background-color: transparent;
}
.custom-control-label {
  position: relative;
  display: block;
  padding-left: 30px;
}
.custom-control input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 4px;
  content: " ";
  display: block;
  background: $darker;
}
.custom-control {
  padding: 5px 10px;
  margin: 0;
  &label {
    font-size: 14px;
    font-weight: normal;
  }
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $darker;
  background-color: #fff;
}
.custom-control-label:after {
  left: 0;
}
.custom-control-label:before {
  left: 0;
}
//filter
.filters-nav {
  color: $darker;
  .container {
    box-sizing: border-box;
  }
}
.fliters-close-btn {
  float: right;
  svg {
    max-width: 15px;

    #icon-filter-close {
      fill: $darker;
    }
  }
}
.filters-nav {
  a {
    outline: 0;
    text-decoration: none;
  }
  height: 100%;
  width: 400px;
  position: fixed;
  z-index: $zindex-popover;
  top: 0;
  right: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 8px 0 4px rgba(0, 0, 0, 0.12);
  visibility: hidden;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  transition-property: right, visibility;
  padding: 10px 0;

  &.show-filter {
    right: calc(100% - 400px);
    visibility: visible;
  }
  p {
    color: $darker;
  }
  .row {
    &:not(:first-child) {
      border-bottom: 1px solid $gray-lighter;
    }
  }
  .body-title-md {
    margin-bottom: 0;
  }

  .filters-header-container {
    display: flex;
    width: 25rem;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    .filter-header-number-of-filter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex: 1 0 0;
      .filters-header {
        color: $darker;
      }
      .number-of-filter {
        display: flex;
        padding: 0rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 1.5rem;
        border: 1px solid var(--site-primary-color, #1badba);
        color: var(--site-primary-color, #00adbb);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
      }
    }
    .clear-all-button {
      color: #222;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      text-decoration-line: underline;
    }
  }

  .disabled-filter {
    .filters-checkbox-heading {
      color: $disabled;
      &:hover {
        color: $disabled;
      }
    }
    .filter-checkbox {
      .checkmark {
        border: 1px solid $disabled;
      }
      input {
        cursor: default;
      }
    }
  }
  .filters-checkbox-heading {
    margin-right: 40px;
    width: auto;
    box-sizing: border-box;
    display: block;
    color: $darker;
    &:hover {
      color: $darker;
    }
  }
  .filters-checkbox-wrap {
    padding: 10px 0;
  }
  .filter-checkbox {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    input {
      position: relative;
      opacity: 0;
      cursor: pointer;
      height: 17px;
      width: 17px;
      z-index: 999;

      &:checked ~ .checkmark:after {
        display: block;
      }
      &:checked ~ .checkmark {
        background-color: var(--site-primary-color, #1badba);
      }
    }
    .checkmark {
      position: absolute;
      top: 50%;
      left: 0;
      height: 17px;
      width: 17px;
      border-radius: 2px;
      border: 1px solid var(--site-primary-color, #1badba);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .sub-filter-checkbox {
    position: relative;
    padding: 5px 10px;
    display: table;

    input {
      position: relative;
      opacity: 0;
      cursor: pointer;
      height: 17px;
      width: 17px;
      z-index: 999;

      &:checked ~ .checkmark:after {
        display: block;
      }
      &:checked ~ .checkmark {
        background-color: var(--site-primary-color, #1badba);
      }
    }
    .checkmark {
      position: absolute;
      top: 7px;
      left: 10px;
      height: 17px;
      width: 17px;
      border-radius: 2px;
      border: 1px solid var(--site-primary-color, #1badba);

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .body-title-sm {
      margin-bottom: 0;
      padding-left: 10px;
      position: relative;
      display: table-cell;
    }
  }

  .filter-accordion-heading[aria-expanded="true"]:before {
    content: " ";
    width: 400px;
    height: 100%;
    background-color: $gray-lightest;
    position: absolute;
    left: -15px;
    top: 0;
    display: block;
    z-index: -1;
  }

  .filter-accordion-heading {
    display: block;
    position: relative;
    color: $darker;
    border: 0;
    outline: 0;
    padding: 10px 0;
    padding-right: 20px;
    &:hover {
      color: $darker;
    }
    .label-count-container {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;
	  padding-right: 0.625rem;
	  .label-container{
		flex: 1 0 0;
	  }
      .count-container {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 6.25rem;
        background: var(--site-primary-color, #1badba);
        color: $white;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
  }

  .filter-accordion-heading:after {
    content: "";
    display: block;
    background: url(../img/icon-down-arrow.svg) no-repeat;
    background-size: contain;
    height: 9px;
    width: 13px;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }
  .filter-accordion-heading[aria-expanded="true"]:after {
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
  .filter-accordion {
    max-height: 200px;
    overflow-x: scroll;
    border: 0;
    outline: 0;
    padding: 10px;
    overflow-x: hidden;
    margin-right: -15px;
    padding-right: 25px;
  }
  .filter-sign-in {
    padding: 15px 0;
  }
  .filter-save {
    padding: 15px 0;
    input {
      border-radius: 4px;
      margin-bottom: 10px;
    }
    .row {
      border-bottom: none;
    }

    .base-btn {
      width: 100%;
    }
    .body-title-md {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 575px) {
    width: 100%;

    &.show-filter {
      right: 0;
    }
    .filter-accordion-heading[aria-expanded="true"]:before {
      width: 560px;
    }
  }
}
#filters-background {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: $zindex-popover;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 0;
  transition: opacity 1s;
}
