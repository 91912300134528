//////////////////////////////////////////////////////////////////////////////////////////

// _ORDER-PURCHASE.SCSS

// This file includes all rules which affect the generic order purchase module. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


	.order-purchase {
		
		.alert {
			font-size: 1em;
			padding: 10px;
			margin: 0 0 10px;
		}
			
		.wizard {
			
			.content {	
				min-height: 10em;
				
				.body label[for="total"]  {
					padding-right: 10px;
				}
				
				label[for="member-rate"], label[for="non-member-rate"] {
					float: right;
				}				
				
				h3, .h3 {
					font-weight: 400;
				}
				
				h4, .h4 {
					font-size: 1.1em;
					padding-bottom: 10px;
				}
								
				.subscriptions, .registration {
					
					label[for="subscription-plan"] {
						font-size: 1.2em;
						font-weight: 500;
						padding-left: 0;
						
						input {
							margin-right: 5px;
						}
					}					
				}
				
				.coupons {					 
					
					.coupons-applied {
						margin: 0;
				
						label {
							float: left;
							padding-left: 0;
							padding-right: 20px;
						}
					
						label + label {
							border-left: none;
						}
				
						input {
							display: block;
						}
					}
				
					.add-coupon {	
						padding: 20px 0 10px;
						text-align: right;
					}					
				}
				
				.payment {

					ul {
					
						li {
							list-style-type: none;
							float:left;
						}
						
						li.total {
							font-weight: 700;
						}

					}
					
					input[name="cc_name"] {
						width: 230px;
					}
			
					input[name="cc_number"] {
						width: 150px;
					}

					input[name="cc_cvv2"] {
						width: 50px;
					}
					
					input[type="image"] {
						border: none;
						vertical-align: middle;
						float: right;
						display:block;
					}
				
					.credit-card-payment {
						label {
							float: left;
							padding-left: 0;
							padding-right: 15px;
						}
				
						input {
							display: block;
						}
					}								
				}	
		
			}
		}
		
		@include breakpoint(small) {
		
			.wizard .steps {
			
				a {
					font-size: 200%;
					max-height: 10px;
					padding:0 10px 30px;
				}
				
				.disabled a, .disabled a:hover, .disabled a:active {
					color: $white;
					
					.number {
						color: #aaaaaa;
					}
				}				
			}
			
			.add-coupon .primary {
				display: block;
				text-align: center;
				margin-top: 10px;
			}
		}	
	}