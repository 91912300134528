//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-SERVICES.SCSS

// This file includes all rules which affect the generic featured services module.
// The rules in this file are not to be modified to accommmodate custom partner sites.
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

#featured-services {
  .interior {
    min-height: 150px;

    ul {
      li {
        background: $bkgrdLtGrey;
        min-height: 130px;
        padding: 10px;

        h5,
        .h5 {
          font-size: 1.2em;
        }

        p {
          font-size: 0.95em;
          text-align: center;
          margin-bottom: 10px;
        }

        a {
          display: block;
          width: 100%;
          text-align: center;
          padding-top: 0px;
        }
      }
    }
  }

  /*@include breakpoint(medium) {
			.bx-controls-direction {
				display: none;
			}
		}*/
  .featured-services-swiper {
    padding-inline: 28px;

	.swiper-button-disabled {
		pointer-events: auto;
	}
	
    .swiper-button-prev {
      left: 12px;
    }

    .swiper-button-next {
      right: 12px;
    }
  }
  .swiper-slide {
	background: $bkgrdLtGrey;
	min-height: 130px;
	padding: 10px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	.swiper-featured-services-title {
	  font-size: 1.2em;
	  text-align: center;
	  margin-bottom: 10px;
	  font-size: 16px;
	}

	.swiper-featured-services-context {
	  font-size: 0.95em;
	  text-align: center;
	  margin-bottom: 10px;
	}

	.swiper-featured-services-link {
	  display: block;
	  width: 100%;
	  text-align: center;
	  padding-top: 0px;
	}

	a {
	  display: block; 
	}
	
	img {
		object-fit: contain;
		width: auto;
		height: auto;
	  }
  }
}
