.ocu-mail-template {
    font-family: Roboto, Arial, sans-serif; /*Update to match system font*/
    padding: 0;
    margin: 0;
    padding: 1.25rem 0;
  
    td {
      text-align: center;
    }
  
    @media screen and (max-width: 767px) {
      padding: 0.5rem;
    }
    .ocu-card {
  
      @media screen and (max-width: 767px) {
        width: 100%;
      }
  
      
  
        img.ocu-icon {
  
          @media screen and (max-width: 767px) {
            max-width: 124px;
          }
          @media screen and (max-height: 740px) {
            max-width: 110px;
          }
          @media screen and (max-height: 720px) {
            max-width: 100px;
          }
  
        
  
          .h2 {
           
  
            @media screen and (max-width: 767px) {
              font-size: 18px;
            }
          }
  
          .h3 {
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
  
          .p {
  
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
  
        .buttons {
  
          
        }
      }
    }
    @media screen and (max-width: 424px) {
      .resubscribe-btn,
      .switch-to-weekly-btn {
        display: block !important;
        width: 100% !important;
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }