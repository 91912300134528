.custom-error-toast {
  @extend .position-fixed;
  @extend .p-3;
  z-index: 5;
  right: 0;
  .toast {
    @extend .text-white;
    background-color: #ff2e1c;
    .toast-header {
      @extend .text-white;
      background-color: #ff2e1c;

      i {
        @extend .mr-2;
        font-size: 16px;
      }
      .close {
        @extend .ml-2;
        @extend .mb-1;
        color: #ffffff80;
      }
    }
  }
}
