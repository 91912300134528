//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-JOB-PROMO.SCSS

// This file includes all rules which affect the promos in the featured jobs module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

/////////////////////////////////////////////////////////////////////////////////////////


		.featured-job-promo {
			box-sizing: border-box;
			background: $bkgrdLtGrey;
			min-height: 400px;
			width: 42%;
			padding:10px;
			float:right;
			box-sizing: border-box;
						
			@include breakpoint(medium) {
				width: 41%;
				
				.sideboard-adspace h5, .sideboard-adspace .h5 {
					font-size: 1.5em;
					padding-bottom: 10px;
				}
				
				.sideboard-adspace hr {
					display: none;
				}				
			}
			
			@include breakpoint(small) {
				display: none;
				min-height: 0;
			}
		}