//////////////////////////////////////////////////////////////////////////////////////////

// _JOB-APPLICATION.SCSS

// This file includes all rules which affect the generic job application module. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

	.job-application {

		h3, .h3 {
			font-size: 1.4em;
			font-weight: 400;
			padding-top: 20px;
		}
		
		h4, .h4 {
			font-weight: 400;
			padding-bottom: 10px;
		}
		
		textarea {
			display: block;
			width: 100%;
			height: 150px;
			margin: 5px 0 10px;
			padding: 5px;
		}
		
		input[type="date"] {
			width: 90px;
		}
		
		span.hide {
			display:none;
		}

					
		.wizard {
		
			.steps {
			
				 ul > li {
				width: 20%;
				padding: 0 !important;
				
					@include breakpoint(xsmall) {
						width: 25%;
					}
					
					@include breakpoint(six-eighty) {
						width: 25%;
					}
				}
				
				a {
					@include breakpoint(medium) {
						font-size: 100%;
					}
				}
			}
			
			.content {
				padding-bottom: 10px;
				min-height: 10em;
				
				legend {
					width: 100%;
					a {
					font-size:.7em;
					float:right;
					font-weight: 400;
					cursor:pointer;
					}
				}
				
				small {
					bottom: 10px;
				}
				
				div label {
					padding-left: 0;
				}
				
				div + div {
				
				}
				
				div > div {
					margin: 10px 0 0;
					padding: 20px 20px 10px 0;
					border-top: thin dotted $miscLtGrey;
				}
				
				.body {
					
					label[for="cover-letter-text"], label[for="resume-text"] {
						width: 100%;
					}
					input[type="text"]{
						width: 100%;
    					max-width: 400px;
					}

					input[type="text"], input[type="date"], label[for="self-id"], label[for="veteran"], label[for="disabled"], label[for="gender"] {
						display: block;
					}
					
					label[for="cover-letter-text"]  input[type="text"] {
						display: inline;
						margin-left: 10px;
						width: 220px;
					}

					input[name="resume-name"], input[name="cover-letter-name"], input[name="relocation-preference"]{
						margin-left: 5px;
						display: inline;
						width: 250px;
					}
					
					input[name="employer-name"], input[name="position-title"] {
						width: 300px;
					}
 					
					label[for="work-authorization"], label[for="relocation"], label[for="self-id"], label[for="veteran"] {
						line-height: 2;
					}
					
					input[type="radio"], input[type="checkbox"] {
						margin-right: 4px;
						width: 15px;
						height: 15px;
					}
									
					.alert {
						padding: 10px;
						margin-top: 0;
					}				
				}
				
				.add-documents {
					p {
						font-size: 1em;
					}
					p + p {
						padding-bottom: 20px;
					}
					
					label[for="select-cover"], label[for="select-resume"], label[for^="resume_"]{
						width: 250px;
					}
					
					i {
						display: block;
						font-size: .9em;
						padding: 20px 0;
					}
				}
				
				.filter-questions {
					label {
						display:block;
					}
					
					label[for="range-question"] {
						padding: 10px 0 30px;
						line-height: 1;
							
						b {
							float: none;
							display: block;
							font-weight: 400;
							padding-bottom: 10px;
						}
						
						label {
							float:left;
							text-align: center;
							padding-right: 15px;
							
							input {
								display:block;
								margin-bottom: 3px;
							}
						}
					}
					
					input[type="text"], textarea {
						width: 100%;
					}
					
					select {
						display:block;
						width:300px;
					}
				}	
			}
			
			.steps span {
    			display: inline;
    		}
    	}

		@include breakpoint(small) {
		
			.wizard .steps {
			
				a {
					font-size: 200%;
					max-height: 10px;
					padding:0 10px 30px;
				}
				
				.disabled a, .disabled a:hover, .disabled a:active {
					color: $white;
					
					.number {
						color: #aaaaaa;
					}
				}				
			}
			
			.wizard .content {
				
				small {
					bottom: 0;
				}
				
				fieldset {
					box-sizing: border-box;
					width: 100%;
					margin: 0;
				}
				
				legend {
					font-size: 1.3em;
				}
				
				.body label {
					padding: 7px 0 0 0;
				}
				
				.body label[for="cover-letter-text"] input[type="text"] {
					margin: 10px 0 0;
					width: 100%;
				}
				
				.add-documents {
					label[for="select-cover"], label[for="select-resume"], label[for^="resume_"] {
						width: 100%;
					}
				}
			}
		}	
	}
	
