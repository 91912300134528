//////////////////////////////////////////////////////////////////////////////////////////

// _LEADERBOARD-ADSPACE.SCSS

// This file includes all rules which affect the generic leaderboard adspace module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


.leaderboard-adspace {
		clear: both;
		display: block;
		margin: 0 auto 20px;
		text-align: center;

/*				
	@include breakpoint(small) {
		display: none;
	}
	
	@media screen and (min-width:481px) and (max-width:767px){
		display: none;
	}
*/

}
/*	
.leaderboard-adspace.top.site a img {
	height: 100%;
	max-height: 90px;
	width: 100%;
	max-width: 768px;
}

.leaderboard-adspace.bottom {		
	@media screen and (max-width:767px) {
		display: none;
	}	
}
*/

.leaderboard-adspace.site {
	div a img {
		width: 100%;
		height: auto;
	}		
	.standard-leaderboard-horizontal a img {
		max-height: 90px;
		max-width: 768px;
	}
	.wide-leaderboard-horizontal a img {
		max-height: 90px;
		max-width: 970px;
	}
	.medium-square a img {
		max-height: 250px;
		max-width: 250px;
	}
	.medium-rectangle-horizontal a img {
		max-height: 250px;
		max-width: 300px;
	}
	.medium-rectangle-vertical a img {
		max-height: 300px;
		max-width: 250px;
	}
	.rectangle-horizontal a img {
		max-height: 150px;
		max-width: 180px;
	}
	.rectangle-vertical a img {
		max-height: 180px;
		max-width: 150px;
	}		
	div {
		padding-top: 20px;
	}
}

// ---------------------------------------------------
//	JOB SEARCH LEADERBOARDS
// ---------------------------------------------------

// TOP LEADERBOARD
// ---------------------------------------------------

// Starts with .job-search-page so that it only affects two pane view
// NOTE: There is so much nesting so that it overrides any code PRs put in
// or the code above without using !important

.job-search-page {
	.region_main-pane {
		.job-results-container {
			.leaderboard-adspace {
				@extend .rm-p-t;
				@extend .rm-p-r;
				@extend .rm-p-b;
				@extend .rm-p-l;
				@extend .rm-m-b;
				-ms-flex-pack: center;
				margin-left: auto;
			    margin-right: auto;
			    @extend %align-self-center;
			    @extend %d-flex;
			    @extend %align-items-center;
			    @extend %justify-content-center;

			    background: transparent;

			    &.bottom {
			    	.standard-leaderboard-horizontal {
	  					@extend .rm-m-t;
	  					margin-bottom: $grid-gutter-width-sm;
	  		    	}
  		  		}

				.standard-leaderboard-horizontal {
					@extend .rm-p-t;
					@extend .rm-p-r;
					@extend .rm-p-b;
					@extend .rm-p-l;
					margin-top: $grid-gutter-width-sm;
				    margin-left: auto;
				    margin-right: auto;
					@extend .rm-m-b;
				    text-align: center;
				    background: transparent;
				}
			}
			
		} // END : JOB-RESULTS-CONTAINER
	} // END : REGION_MAIN-PANE
} // END : JOB-SEARCH-PAGE

// JOB 2 PANE VIEW TABLET AND MOBILE
@media (max-width: 640px) { 
	.job-search-page .job-results-body .region_main-pane {
		.leaderboard-adspace {
			width: 0 !important;
			display: none !important;
			position: absolute !important;
			opacity: 0;
			width: 0 !important;
			height: 0 !important;
		}
	}
}