//////////////////////////////////////////////////////////////////////////////////////////

// _VERTICAL-NETWORK-LISTING.SCSS

// This file includes all rules which affect the generic vertical network listing found within 
// job-listings. The rules in this file are not to be modified to accommmodate custom partner 
// sites. Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


		
.vertical-network-listing {
	background: $bkgrdLtBlue;
	padding: 15px;
	margin-bottom: 10px;
	
	em {
		font-style: normal;
	}
	
	h3, h4, .h3, .h4 {
		color: $linkDefault !important;
		font-weight: 400;
		line-height: 100%;
	
		&:hover {
			color: $linkHover !important;
		}			
	}
	
	h3, .h3 {
		font-size: 1.7em;
	}
	
	h4, .h4 {
		text-align: left;
		font-size: 1.3em;
		display: block;
		padding-bottom: 20px;
	}
}
			
