// --------------------------------------------------
//
//		BIO CARD
//
// --------------------------------------------------
.g-carousel-bio-card{
	.items{
		padding-top: 30px;
		@extend .pb-3;
	}
}
.bio-card{
	@extend .card-2;
	@extend .p-0;
	@extend .mx-3;
	@extend .d-flex;
	@extend .flex-column;
	@extend .justify-content-between;
	margin-bottom: 4rem;
	cursor: pointer;
	white-space: normal;
	min-height: 300px;

	@include media-breakpoint-down(xl) { 
		flex: 0 0 auto;
		width: calc(33.33% - 2rem);
	}
	@include media-breakpoint-down(md) { 
		flex: 0 0 auto;
		width: calc(50% - 2rem);
	}
	@include media-breakpoint-down(sm) { 
		flex: 0 0 auto;
		width: 95%;
		max-width: 450px;
	}
	> div:focus-visible{
		outline: #000 2px dotted;
	}
	.bio-card-author{	
		@extend .text-center;
		margin-top: -20px;
		@extend .pb-3;
		img{
			box-shadow: 2px 12px 17px 1px rgba(0, 0, 0, 0.15);
		}
		
	}

  	.bio-card-header{
 		@extend .text-center;
 		@extend .px-3;
 		min-height: 171px;
  	}
  	.bio-card-name{
		font-weight: $font-weight-normal;
		font-size: $font-size-base;
		@extend .text-truncate;
		}

		.bio-card-title{
			font-weight: $font-weight-normal;
			font-size: $font-size-sm;
			color: $gray;
			@extend .text-truncate;
		}
  	.bio-card-body{
  		@extend .text-left;
  		@extend .px-3;
  		@extend .mt-3;
  		min-height: 75px;

  		.bio-card-bio-info{
	  		height: 100px;
			font-weight: $font-weight-normal;
			font-size: $font-size-base;
			color: $gray;
		}
  		
  	}
  	.bio-card-social-footer{
  		@extend .p-3;	
  		margin-bottom: -35px;
	}

}

@each $color, $value in $advice-colors {
  	.topic-link-hover-#{$color} {
	    color:$textMdGrey;
	    &:hover{
	    	color: $value;
	  	}
	}
}

