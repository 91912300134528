//////////////////////////////////////////////////////////////////////////////////////////

// _GENERIC-ERROR.SCSS

// This file includes all rules which affect the generic alert messages. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

#generic-error {
	&.four-hundred {
		#hero {
			background-image: url("../img/graphics/bkgrd-400.png");
		}
	}
	&.four-oh-one {
		#hero {
			background-image: url("../img/graphics/bkgrd-401.png");
		}
	}

	&.four-oh-three {
		#hero {
			background-image: url("../img/graphics/bkgrd-403.png");
		}
	}
	&.four-oh-four {
		#hero {
			background-image: url("../img/graphics/bkgrd-404.png");
		}
	}
	&.four-twenty-nine {
		#hero {
			background-image: url("../img/graphics/bkgrd-429.png");
		}
	}
	&.five-hundred {
		#hero {
			background-image: url("../img/graphics/bkgrd-500.png");
		}
	}
	&.five-oh-one {
		#hero {
			background-image: url("../img/graphics/bkgrd-501.png");
		}
	}
	&.five-oh-two {
		#hero {
			background-image: url("../img/graphics/bkgrd-502.png");
		}
	}
	&.five-oh-three {
		#hero {
			background-image: url("../img/graphics/bkgrd-503.png");
		}
	}
	&.five-twenty {
		#hero {
			background-image: url("../img/graphics/bkgrd-520.png");
		}
	}
	
	#hero {
		background-repeat: no-repeat;
		background-position: right 30px;
		background-color: transparent;
		height: auto;
		div:first-of-type {
			height: 300px;
		}
		padding: 0 20px;
	}
	h4, .h4 {
		font-size: 4em;
		padding-top: 20px;
	}
	
	em {
		display: block;
		color: $textDkGrey;
		font-size: 1.5em;
		font-style: normal;
	}
	
	p {
		font-size: 1.2em;
		padding: 25px 0 15px;
		border-top: thin dotted $miscLtGrey;
	}
	
	@include breakpoint(medium) {
		#hero {
			background-position: center 30px;
			padding: 0 10px;
		}
		h4, em, p, .h4 {
			text-align: center;
		}
		
		h4, .h4 {
			padding-top: 60px;
		}
		
		em {
			padding-bottom: 100px;
		}
		
		p {
			font-size: 1.15em;
		}
	}
	
	@include breakpoint(small) {
		&.four-oh-four, &.five-hundred, &.four-oh-three {
			#hero {
				background-image: none;
			}
		}
		#hero {		
			background-color: $white;
			background-image: none;
			div:first-of-type {
				height: auto;
			}	
		}
		
		h4, em, p, .h4 {
			text-align: center;
			padding-left: 0;
		}
		
		h4, .h4 {
			padding-top: 0;
		}
		
		em {
			font-size: 1.7em;
			padding-bottom: 40px;
		}
	}
}