//////////////////////////////////////////////////////////////////////////////////////////

// _TYPOGRAPHY.SCSS

// This file includes all rules which affect the generic type elements. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

// INDEX

	// 1.0 NORMALIZE RULES - address styling not present or inconsistent in/between specific browsers
	
	// 2.0 GENERIC TYPOGRAPHY RULES
		
		// 2.1 - Headers
		// 2.2 - Anchors
		// 2.3 - Body Copy
		// 2.4 - Font Icons
		

////////////////////////////////////////////////////////
	
	//titles

////////////////////////////////////////////////////////
		

		.body-title-xs {
		    font-size: .9em;
		    font-weight: normal;
		}
		.body-title-sm {
		    font-size: 1.1em;
		    font-weight: normal;
		}
		.body-title-md {
		    font-size: 1.2em;
		    font-weight: normal;
		}
		.body-title-lg {
		    font-size: 1.3em;
		    font-weight: normal;
		}
		.body-title-xlg {
		    font-size: 1.5em;
		    font-weight: normal;
		}

////////////////////////////////////////////////////////
	
	// 1.0 NORMALIZE RULES

////////////////////////////////////////////////////////		
        h1 {
			font-size: 2em;
			margin: 0.67em 0;
		}

		abbr[title] {
			border-bottom: 1px dotted;
		}

		b,
		strong {
			font-weight: bold;
		}

		dfn {
			font-style: italic;
		}

		hr {
			-moz-box-sizing: content-box;
			box-sizing: content-box;
			height: 0;
		}

		mark {
			background: #ff0;
			color: #000;
		}

		code,
		kbd,
		pre,
		samp {
			font-family: monospace, serif;
			font-size: 1em;
		}

		pre {
			white-space: pre-wrap;
		}

		q {
			quotes: "\201C" "\201D" "\2018" "\2019";
		}

		small {
			font-size: 80%;
		}

		sub,
		sup {
			font-size: 75%;
			line-height: 0;
			position: relative;
			vertical-align: baseline;
		}

		sup {
			top: -0.5em;
		}

		sub {
			bottom: -0.25em;
		}
		

////////////////////////////////////////////////////////
		
	// 2.0 GENERIC TYPOGRAPHY RULES

////////////////////////////////////////////////////////

	// 2.1 Headers
	
	h1 {
		color: $textDkGrey;
		font-size: 2em;
		font-weight: 700;
		margin: 10px 0;
	}

	h2 {
		color: $textDkGrey;
		font-size: 1.7em;
		font-weight: 700;
	}


	h3, .h3 {
		color: $textDkGrey;
		font-weight: 700;
		display: block;
	}

	h4, .h4 {
		color: $textDkGrey;
		font-size: 1.25em;
		font-weight: 700;
		display: block;
	}

	h5, .h5 {
		color: $textDkGrey;
		font-weight: 700;
		display: block;
	}

	h6, .h6 {
		color: $textDkGrey;
		font-weight: 700;
		display: block;
	}


	// 2.2 Anchors
	
	a {
		color: $linkDefault;
		text-decoration: none;
	}
	
	a:hover {
		color: $linkHover;
	}
	
	a, a:active, a:focus {
		outline: none;
	}
	
	
	// 2.3 Body Copy
	
	body {
		color: $textMdGrey;
		font-size: .8em;
	}
	
	p {
		margin-bottom: 5px;
	}
	
	strong {
		font-weight: 700;
	}
	
	em, i {
		font-style: italic;
	}
	
	b {
		font-weight: 700;
	}
	
	sub, sup {
		font-size: smaller;
	}
	

	// 2.4 Icon Fonts	
/* moved to fonts.css */

	.fa {
		margin-right: .2em;
		margin-left: .2em;
		text-align: center;
		line-height: 1em;
		text-decoration: inherit;
	}