//////////////////////////////////////////////////////////////////////////////////////////

// _FORMS.SCSS

// This file includes all rules which affect the generic forms. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

// INDEX


	// 1.0 Normalize Rules - address styling not present or inconsistent in/between specific browsers
	
	// 2.0 Generic Form Rules
		
	
// RULES
	
	// 1.0 Normalize Rules
	
	
		fieldset {
			border: none;
			margin: 0 2px;
			padding: 0.35em 0.625em 0.75em;
		}

		legend {
			border: 0;
			padding: 0;
		}
		
		button,
		input,
		select,
		textarea {
			font-family: inherit; 
			font-size: 100%; 
			margin: 0;
		}

		button,
		input {
			line-height: normal;
		}

		button,
		select {
			text-transform: none;
		}

		button,
		html input[type="button"],
		input[type="reset"],
		input[type="submit"] {
			-webkit-appearance: button;
			cursor: pointer;
		}

		button[disabled],
		html input[disabled] {
			cursor: default;
		}

		input[type="checkbox"],
		input[type="radio"] {
			/*box-sizing: border-box; causing conflict in IE - not rendering*/
			padding: 0;
		}

		input[type="search"] {
			-webkit-appearance: none;
			-moz-box-sizing: content-box;
			-webkit-box-sizing: content-box;
			box-sizing: content-box;
			border-radius: 0;
		}

		input[type="search"]::-webkit-search-cancel-button,
		input[type="search"]::-webkit-search-decoration {
			-webkit-appearance: none;
		}

		button::-moz-focus-inner,
		input::-moz-focus-inner {
			border: 0;
			padding: 0;
		}
		
		input[type="text"], input[type="search"], input[type="email"], input[type="password"], input[type="tel"], input[type="url"], select {
			height: 41px;
			border: thin solid #ccc;
		}
		
		select[multiple] {
			height: 100%;
		}
		
		input[type="text"], input[type="search"], input[type="email"], input[type="password"], input[type="tel"], input[type="url"] {
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
		}
		
		select {
			border: 1px solid #ccc;
			overflow: hidden;
			overflow-y: scroll;			
		}
						
		.select-style {
			background: $white;
		}

		.select-style select {
			border: 1px solid #ccc;
			box-shadow: none;
			background: transparent;
			height: 41px;
		}
		
		select:focus {
			border: thin solid #cccccc;
		}
		
		select:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}

		textarea {
			overflow: auto;
			vertical-align: top;
		}
		
		// Default form box

		#social_form {
			margin-bottom: 15px;
		}

		.login-newAccount {

			.default-form-box {

				h4 {
					color: $black;
					display: block;
					font-size: 1.7em;
					font-weight: 400;
					padding-bottom: 10px;
				}
			}
		}
		
		.default-form-box {
			background: $bkgrdLtGrey;
			display:block;
			padding: 40px;
		
			button {
				float:right;
			}
					
			form {
				background: $white;
				padding: 20px;
			}
		
			fieldset {
				float:left;
				margin: 0;
				padding: 0 10px 20px 0;
			}

			.login fieldset, .new-account fieldset {
				float: none;
				padding-right: 0;
			}

			.login, .new-account {
				float: left;
				width: 49%;
			}
			.new-account {
				margin-left: 2%;
			}

			.validate-question select {
				width: 100%;
			}
			
			fieldset.full {
				width: 100%;
			}
		
			label {
				display:block;
				font-weight: 700;

				& > em {
					color: $textLtGrey;
					display: block;
					padding-top: 10px;
					text-align: left;
				} 
			}

			i {
				color: $textLtGrey;
				display: block;
				padding-top: 10px;
				text-align: left;
				
				sup {
					font-size: 1em;
				}
			}
		
			input, select {
				width: 260px;
				padding: 8px;
			}
		
			input[type="checkbox"] {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
			}
			
			input[type="radio"] {
				width: 15px;
			}
			
			input[type="search"] i {
				-webkit-appearance: textfield;
			}
			
			select option {
				padding: 1px 8px;
			}
			
			select[multiple] {
				height: 140px;
				width: 260px;
			}
		
			textarea {
				width: 100%;
				height: 100px;
				padding: 10px;
				box-sizing: border-box;
			}

			span {
				padding-top: 5px;
			}
			
			span.alert {
				color: $textAlert;
				padding: 0 0 10px;
				margin-top: 0;
				font-size: 1.1em;
				
				u {
					font-family: arial, helvetica, sans-serif;
					text-decoration: none;
					padding-left: 5px;
				}
			}
			.opt-out {
			    width: auto;
			    padding: 10px;
			    background: #fcfcfc;
			    margin-top: 5px;
			}
			@include breakpoint(small) {
				background: transparent;
				padding: 5px;

				form {
					padding: 20px 5px;
				}
				
				button {
					float: none;
					width: 100%;
				}
				.login, .new-account {
					float: none;
					width: 100%;
				}
				.new-account {
					margin-left: auto;
					margin-top: 20px;
				}
				fieldset {
					padding-bottom: 15px;
				}
			}
		}
		.opt-out {
			    width: auto;
			    padding: 10px;
			    background: #fcfcfc;
			    margin-top: 5px;
		}
		// File Upload form box
		
		.file-upload-form {
			padding: 20px;
			background: $bkgrdLtGrey;
			margin-bottom: 10px;
			
			.file-upload-container {
				box-sizing: border-box;
				width:400px;
				border: 1px solid #efefef;
				padding:10px;
				background: #ffffff;
				float:left;
			}
			.file-upload-override-button {
				position: relative;
				overflow: hidden;
				cursor: pointer;
				background-color:$tertiary-cta-color;
				padding: 8px 12px;
				float: left;
			}
			.file-upload-override-button:hover {
				background-color:$tertiary-hover-background;
				color: $white;
			}
			.file-upload-override-button:active {
				position:relative; top:1px;
			}
			.file-upload-button {
				position: absolute;
				height: 50px;
				top: -10px;
				left: -10px;
				cursor: pointer;
				opacity: 0;
				filter:alpha(opacity=0);
			}
			.file-upload-filename {
				float: left;
				margin-left: 10px;
				height: auto;
				padding: 8px;
			}
			
			.primary {
				padding:15px 25px;
				float:left;
				margin: 6px 20px;
			}
			
			span {
				float: left;
				padding: 25px 10px 10px 20px;
			}
			
			@include breakpoint(small) {
			
				.primary, span {
					float:none;
					display: block;
					clear:both;
					margin-left: 0;
					box-sizing: border-box;
					width: 100%;
				}
				
				.file-upload-container {
					width: 100%;
					margin-bottom: 10px;
				}
			}
		}

		.center-login-form{
			@extend .form-signin;
			border-radius: 4px;
    		border: 1px solid #DDD;
    		@extend .mt-5;
    		h1{
    			@extend .h2;
    			@extend .center;
    		}
    		p{
    			@extend .center;
    		}
    		button{
	    		width: 100%;
	    	}
	    	.form-label-group {
	    		input{
	    			height: 2.5rem!important;
	    			color: #535353;
	    			padding-left: .75rem!important;
	    			&:not(:placeholder-shown){
	    				
	    				padding-top: 1.25rem;
    					padding-bottom: .25rem;
   
	    			}
	    		}
	    		label{
	    			height: 2.5rem!important;
	    		}
	    	}
		}

	
/*
@include breakpoint(small) {
	@media screen and (-webkit-min-device-pixel-ratio:0) {
			input {
				-webkit-appearance: none!important;
				border-radius: 0 !important;
			}
	}
}
*/