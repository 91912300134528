//////////////////////////////////////////////////////////////////////////////////////////

// _RECENT-SEARCHES.SCSS

// This file includes all rules which affect the generic recent-searches module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

	
	.recent-searches {
		padding: 40px 0;
		
		p + p {
			font-size: 1.1em;
			padding-left: 10px;
		}
		
		u {
			color: $textLtGrey;
			text-decoration: none;
		}
		
		@include breakpoint(small) {
			display: none;
		}
	}