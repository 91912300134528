//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-JOBS.SCSS

// This file includes all rules which affect the generic featured jobs module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	
	#featured-jobs {
		.interior { 
			padding: 0
		}
	}