//////////////////////////////////////////////////////////////////////////////////////////

// _CAREER-FAIR-BANNER.SCSS

// This file includes all rules which affect the career fair registration banner. 
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

.career-fair-banner {
	box-sizing: border-box;
	padding: 20px;
	
	&.site {
		background: $bkgrdLtGrey;
	}
	
	.secondary {
		float:right;
	}
	
	time {
		font-size: 1.3em;
	}
	
	@include breakpoint(small) {
		.secondary {
			display: block;
			float: none;
			margin-top: 20px;
		}
	}
	
	& + .career-fair-banner {
		margin-top: 20px;
	}
}


// BASIC-PAGE STYLES

		
#basic-page .career-fair-banner {
	
	strong {
		color: $textDkGrey;
		display: block;
		font-size: 2em;
		font-weight: 400;
		padding: 20px 0 5px;		
		border: none;
		line-height: 100%;
		margin: 0;
	}
	
	em {
		color: $textDkGrey;
		border-bottom: 2px solid $white;
		display: block;
		font-size: 1.6em;
		font-style: normal;
		font-weight: 700;
		padding-bottom: 5px;
	}
}


// --------------------------------------------------
//
//		NEW CAREER FAIR STYLES
//		Framework 2.0.0+
//
// --------------------------------------------------

// For inside Job Details
.job-career-fair-cta {
	@extend .tile-row;
	@extend .border-bottom;
	@extend %align-items-center;
	padding-left: $grid-gutter-width-md + $grid-gutter-width-sm;
	padding-right: $grid-gutter-width-md + $grid-gutter-width-sm;
	flex-wrap: nowrap;

	// Holds all details
	.cf-details {
		@extend %col-xl-8;
		@extend %col-md-7;
		
	}

	.cf-logo {
		@extend %col;
		@extend %col-auto;
		@extend %d-flex;
		@extend %align-items-center;
		@extend %justify-content-center;
		line-height: inherit !important;
		text-align: center;
		background: $company-logo-bg;
		border: 1px solid $gray-400;
		border-radius: $company-logo-border-radius;
		width: 60px;
		height: 60px;

		img {
			max-width: 56px;
			height: auto;
		}

		&.logo-none {
			background: $company-logo-empty-bg;
			color: $company-logo-empty-font-color;
			@extend .h6;
			@extend .rm-m-b;
		}
	}

	.cf-callout {
		@extend %row;
		@extend .rm-m-hor;
		@extend .h6;
		@extend .rm-m-ver;
		margin-bottom: $grid-gutter-width-xs;
	}

	.cf-title-row {
		@extend %row;
		@extend .rm-m-hor;
		font-weight: 700;
		@include text-truncate();
		//@extend .justify-content-between;
	}

	.cf-date {
		@extend %row;
		@extend .rm-m-hor;
	}

	.cf-cta {
		@extend %col-xl-3;
		@extend %flex-lg-fill;
		@extend .rm-p-r;
		text-align: right;
	}
}