//////////////////////////////////////////////////////////////////////////////////////////

// _FOUR-OH-FOUR.SCSS

// This file includes all rules which affect the generic alert messages. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


#four-oh-four {
	#hero {
		height: 425px;
		background: url("../img/graphics/bkgrd-404.png") right 60px no-repeat #fff;
		text-align: left;
		padding: 0 20px;
	}
	h4, .h4 {
		font-size: 4em;
		padding-top: 85px;
	}
	
	em {
		display: block;
		color: $textDkGrey;
		font-size: 1.5em;
		font-style: normal;
		padding: 10px 0;
	}
	
	em + em {
		color: $textMdGrey;
		font-size: 1.2em;
	}
	
	#job-search-form {
		form {
			input#keywords {
				width:100%;
			}
		
			.primary {
				padding: 11px 30px;
				font-size: 1.1em;
			}
		}
	}
	
	@include breakpoint(medium) {
		#hero {		
			background-position: center 60px;
			padding: 0 10px;
		}
		
		h4, em, p, .h4 {
			text-align: center;
		}
		
		p {
			font-size: 1.15em;
		}
		
		#job-search-form {

			form {
				width: 100%;
				padding: 20px 0;
				input {
					width: 100%;
				}
				input#keywords {
					width: 100%;
					margin-left: 10px;
				}
		
				.primary {
					padding: 11px 30px;
					width: 100%;
				}
			}
		}
	}
	
	@include breakpoint(small) {
		#hero {		
			background: $white;
			height: auto;		
		}
		
		h4, .h4 {
			padding-top: 0;
		}
		
		em {
			font-size: 1.7em;
			padding-bottom: 40px;
		}
		
		em + em {
			padding: 0 10px;
		}
		
		#job-search-form {

			margin-top: 20px;
		
			form {
				margin-top: 20px;
				text-align: center;
				padding: 20px;
				padding-bottom: 0;
				position: relative;
				top: 0;
				box-sizing: border-box;
				
				fieldset {
					box-sizing: border-box;
				}
					
				div {
					float:none;
					display: block;									
				}
				
				input {
					width: 100%;
				}
			
				input#keywords {
					width: 100%;
					margin-left: 0;
				}
			
				.primary {
					font-size: 1.2em;
					text-align: center;
					float: none;
					display: block;
					padding: 9px 8px;
					width: 100%;
					margin: 0 auto;
				}
			}
		}
	}	
}