//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-EMPLOYERS.SCSS

// This file includes all rules which affect the job alert functionality on the results page.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

//////////////////////////////////////////////////////////////////////////////////////////
		
	
	#results-page {
		.job-alert-inline {
			float: right;
			margin-top: 10px;

			.secondary {
				box-sizing: border-box;
			}
			@include breakpoint(small) {
				float: none;
				margin: auto auto 15px;
				text-align: center;

				.secondary {
					width: 60%;
				}
			}
		}
	}

	#create-alert {
		.modal-content {
			fieldset {
				width: 100%;
				box-sizing: border-box;
				margin: auto auto 10px;
				padding: 15px;
			}

			button, .cancel {
				width: calc(100% - 30px);
				margin: auto;
				float: none;
			}
		}
	}

	.alert-criteria {
		margin-top: 10px;
		font-size: 1.2em;
		display: inline-block;
		padding-left: 10px;
	}
	.search-filters{
		.alert-criteria {
			display: block;
			padding: 0 10px;
		}
		.answer-list{
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

	}
	.modal-content {
		form {
			text-align: center;

			fieldset input {
				width: 100%;
			}
		}
	} 
	#alert-login {
		.modal-content {
			p {
				padding: 10px 0;
			}
			h5, .h5 {
				font-size: 1.25em;
			}
		}
		.social-login {
			background-color: $bkgrdLtGrey;
			padding: 20px;
			p {
				padding-top: 0;
			}
		}
		#qCreate div {
			background-color: $bkgrdLtGrey;
			padding: 20px 20px 5px;
			margin-bottom: 5px;
		}
	}