//////////////////////////////////////////////////////////////////////////////////////////

// _MORE-COMPANY-JOBS.SCSS

// This file includes all rules which affect the more company jobs component generally 
// found in the sidebar on the Job View Page. The rules in this file are not to be modified
// to accommmodate custom partner sites. Site-specific customization is to be handled 
// in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////



	.more-company-jobs {
		padding-top: 20px;
		
		a {
			display: block;
			width: 100%;
			font-size: 1.2em;
			text-align:center;
			padding: 30px 0;
			background: $bkgrdLtBlue;
		}
	}	