// --------------------------------------------------
//
//		LINKS / INTERACTIONS
//		Not just reserved for links
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

*[data-href-type="inactive"] {
	cursor: default;
}