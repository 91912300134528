//////////////////////////////////////////////////////////////////////////////////////////

// _SOCIAL-LOGIN.SCSS

// This file includes all rules which affect the generic vertical network listing found within 
// job-listings. The rules in this file are not to be modified to accommmodate custom partner 
// sites. Site-specific customization is to be handled in a separate css file.

//////////////////////////////////////////////////////////////////////////////////////////

.social-login {
	margin-bottom: 15px;
}
		
@include breakpoint(small) {
	.social-buttons {
		.btn-social {
			display: block;
		}
		.btn-social + .btn-social {
			margin-left: auto;
			margin-top: 1em;
		}
	}
}
			
