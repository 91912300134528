// --------------------------------------------------
//
//		BUTTONS
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

.btn-filter {
	@extend .btn;
	@extend .btn-outline-secondary;

	svg {
		fill: currentColor;
	}

	&.btn-filters-applied {
		border-color: $success;
		color: $success;
	}
}

.btn-search {
	vertical-align: middle;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
}

// Buttons with SVGs
.btn-svg svg {
	width: 16px;
	height: 16px;
	vertical-align: middle;
	fill: currentColor;
}

// Button disabled
.secondary:disabled{
	opacity: $btn-disabled-opacity;
	pointer-events: none;
}

// -------------------------------------
// Button for Job Apply
// -------------------------------------

.btn-job-applied {
	background: transparent !important;
	border: $green 1px solid !important;
	border-radius: $border-radius;
	color: $green !important;
}