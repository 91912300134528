//////////////////////////////////////////////////////////////////////////////////////////

// _COMPANY-LOGO-LARGE.SCSS

// This file includes all rules which affect the large company logo component generally 
// found in the sidebar section of Job View Page. The rules  in this file are not to be 
// modified to accommmodate custom partner sites. Site-specific customization is to be
// handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////



	.company-logo-large {
		width: 330px;
		height: 110px;
		border: thin solid $miscLtGrey;
		padding: 0;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}	