//////////////////////////////////////////////////////////////////////////////////////////

// _UPLOAD-RESUME.SCSS

// This file includes all rules which affect the generic recent-searches module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

//////////////////////////////////////////////////////////////////////////////////////////
	#job-detail-view {
		.company, .location {
			padding-bottom: 0;
		}
		.similar-jobs .similar-jobs-tile {
			padding-bottom: 15px;
		}
	}
	.similar-jobs {
		clear: both;
		padding-bottom: 0;
		h5, .h5 {
			margin-bottom: 20px;
		}

		a, strong {
			display: block;
			min-height: 36px;
		}
		.similar-jobs-row {
			padding-bottom: 0;
		}
		.similar-jobs-tile {
			width: 23%;
			margin: 0 1%;
			border: 1px solid #eee;
			padding: 15px;
			box-sizing: border-box;
			float: left;
			min-height: 165px;
			vertical-align: top;
			line-height: 1.3;
		}
		.company {
			padding-bottom: 0;
			margin-top: 10px;
			font-weight: 700;
		}
		.location {
			padding-bottom: 0;
		}
		.members-only {
			a {
				color: $tooltipBorder;
			}
			a:hover {
				text-decoration: none;
			}
			.company {
				text-transform: uppercase;
				color: $tooltipBorder;
				font-size: 1.2em;
			}
		}
		@include breakpoint(medium) {
			h5, .h5 {
				margin-bottom: 10px;
			}
			.similar-jobs-tile {
				width: 48%;
				margin: 8px 1%;
			}

		}
		@include breakpoint(small) {
			.similar-jobs-tile {
				width: 100%;
				margin: 10px auto;
				min-height: 0;

				li {
					width: 50%;
				}
			}
		}
	}
	#expired-job ~ .similar-jobs {
		font-size: 1.1em;

		.h5 {
			font-size: 1.05em;
			letter-spacing: .015em;
			border-bottom: 1px dotted $textLtGrey;
			padding-bottom: 5px;
			margin-bottom: 10px;
			color: $textMdGrey;
		}
	}