// --------------------------------------------------
//
//		INPUTS
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

// Border Box for Inputs
input {
	  box-sizing: border-box;
}


// --------------------------------------------------
//
//	SWITCHES
//	Customize switches from Bootstrap v4.3.1
//
// --------------------------------------------------

.custom-switch {
  padding-left: $switch-indicator-width + $custom-control-gutter;

  input[type="checkbox"]:focus {

		+ .custom-control-label::before {
			outline: none;
      border-color: $switch-indicator-bg;
		}
	}

  .custom-control-label {
    cursor: pointer;

    &::before {
      left: -($switch-indicator-width + $custom-control-gutter);
      width: $switch-indicator-width;
      height: $switch-indicator-height;
      pointer-events: all;
      // stylelint-disable-next-line property-blacklist
      border-radius: $switch-border-radius;
      background-color: $switch-indicator-bg;
      border-color: $switch-indicator-bg;
      border-style: outset;
      opacity: 0.5;
      top: -0.25rem;
    }

    &::after {
      top: -0.05rem;
      //top: calc(#{(($switch-indicator-height - $switch-toggle-size))});
      left: calc(#{-($switch-indicator-width + $custom-control-gutter)} + #{$custom-control-indicator-border-width * 2.5});
      width: $switch-toggle-size;
      height: $switch-toggle-size;
      background-color: $switch-toggle-color-off;
      // stylelint-disable-next-line property-blacklist
      border-radius: $switch-toggle-size;
      @include transition(transform .15s ease-in-out, $custom-forms-transition);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
  	&::before {
  		opacity: 1;
  		border-color: $switch-indicator-bg-on !important;
      background-color: $switch-indicator-bg-on;
  	}
    &::after {
      background-color: $switch-toggle-color-on;
      transform: translateX($switch-indicator-width - $switch-toggle-size - $custom-control-gutter);
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }

  // Switch on Light
  &.custom-switch-dark {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        border-color: $switch-alt-toggle-bg-on !important;
        background-color: $switch-alt-toggle-bg-on;          
      }
      &::after {
        background-color: $switch-alt-toggle-color-on;
      } 
    }
  }
}


