.become-a-member-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    padding: 0;
    .illustration {
      //   width: 100%;
    }

    .heading {
      align-self: stretch;
      color: var(--site-primary-color,#1badba);
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.625rem;
    }

    .subtext {
      color:  #222;
      text-align: center;
      align-self: stretch;
      font-family: Roboto, sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    align-self: stretch;

    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;
      align-self: stretch;

      .join-now {
        display: flex;
        height: 2.25rem;
        padding: 0.625rem 0.5rem 0.5rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        border-radius: 0.25rem;
        background: var(--site-primary-color,#1badba);
        border: none;

        color: #fff;

        /* Font/Buttons/button-label-12px-400 */
        font-family: Roboto, sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .sign-in {
        display: flex;
        height: 0.8125rem;
        padding: 0rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        border-radius: 0.25rem;
        color: var(--site-primary-color,#1badba);

        /* Font/Buttons/button-label-12px-400 */
        font-family: Roboto, sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: none;
      }
    }
    .ad-container {
      position: relative;
      display: flex;
      padding: 1rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      align-self: stretch;
      border-radius: 0.25rem;
      border: 2px dashed #ebebeb;
      background: #fff;
      .ad-label-header {
        display: flex;
        padding: 0.125rem 0.25rem;
        align-items: flex-start;
        gap: 0.625rem;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0px);
        top: -0.5rem;

        color: #5b6770;
        text-align: center;
        font-family: Roboto, sans-serif;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-variant: all-small-caps;

        border-radius: 0.125rem;
        background: #ebebeb;
      }
      .career-fair-container {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        align-self: stretch;
        .logo {
          width: 3.75rem;
          height: 3.75rem;
        }
        .logo-placeholder {
          display: flex;
          width: 3.75rem;
          height: 3.75rem;
          padding: 0.75694rem 0.71875rem 0.74306rem 0.78125rem;
          justify-content: center;
          align-items: center;
          border-radius: 0.25rem;
          border: 2px dashed #ebebeb;
          .icon {
            font-size: 2.25rem;
            flex-shrink: 0;
            color:var(--site-primary-color,#1badba)
          }
        }
        .contents {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.25rem;
          flex: 1 0 0;
          color: #222;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          font-family: Roboto, sans-serif;
          .title {
            font-size: 0.875rem;
            align-self: stretch;
          }
          .subtext {
            font-size: 0.75rem;
            align-self: stretch;
          }
          .date {
            font-weight: 400;
            color: #4a4a4a;
            font-size: 0.75rem;
            align-self: stretch;
          }
        }
      }
      .register-btn {
        display: flex;
        height: 2.25rem;
        padding: 0.625rem 0.5rem 0.5rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        border-radius: 0.25rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #fff;

        color: var(--site-primary-color,#1badba);

        /* Font/Buttons/button-label-12px-400 */
        font-family: Roboto, sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .slider-btn {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 6.25rem;
        border: 1px solid #ebebeb;
        background: #fff;
        position: absolute;
        .icon {
          display: flex;
          width: 1rem;
          height: 1rem;
          padding: 0.28125rem 0.25rem;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
        }
      }
      .right-slider-btn {
        right: -0.75rem;
        bottom: 50%;
        transform: translate(0px, 50%);
      }
      .left-slider-btn {
        left: -0.75rem;
        bottom: 50%;
        transform: translate(0px, 50%);
      }
    }
  }
}
