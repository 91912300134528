.job-feature-item-container {
  display: flex;
  height: 100%;
  padding: 0.5rem;
  align-items: center;
  gap: 0.625rem;
  .job-feature-icon-color {
    font-size: 20px;
    color: var(--site-primary-color,#1badba) ;
  }
  .job-feature-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    flex: 1 0 0;
    align-self: stretch;
    position: relative;
    .job-feature-item-title {
      align-self: stretch;
      color: #4a4a4a;
      font-family: Roboto;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .job-feature-item-value {
      align-self: stretch;
      color: #222222;
      font-family: Roboto;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-break: break-word;
      display: flex;
      gap: 4px;
      align-items: flex-end;
    }

    [data-toggle=popover]{
      cursor: pointer;
    }

    .job-feature-item-ellipsis {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      line-height: 1.3;
      @media(max-width: 425px){
        word-break: break-word;
        -webkit-line-clamp: 2;
      }
    }
    .job-feature-item-ellipsis-hover{
      &:focus, &:hover {
        color:black;
      }
      &:focus:after, &:hover:after{
        content:attr(data-text);
        overflow: visible;
        text-overflow: inherit;
        background: #fff;
        position: absolute;
        left:auto;
        top:42px;
        width: auto;
        max-width: 20rem;
        border: 1px solid #eaebec;
        padding: 0 .5rem;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
        white-space: normal;
        word-wrap: break-word;
        display:block;
        color:black;
        margin-top:-1.25rem;
      }
    }
  }
}
