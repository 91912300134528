// -------------------------------------------------------------------
//
//    RADIO BUTTON COMPONENTS
//   
//
// -------------------------------------------------------------------
.radio-buttons-container{
    @extend .d-flex;
    @extend .flex-column;

  .radio-button-outlined {
    @extend .d-flex;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #DDD;
    margin: 10px;

    input.radio-button-outlined-input{
      margin: .35rem!important;
    }
    &:focus-within{
      border-color:#0085FF;
    }
    &:hover{
      border-color:#0085FF;
    }
    &:active{
      border-color:#0085FF;
    }
    .radio-button-label{
      @extend .d-flex;
      @extend .flex-column;
      font-weight: 400;

      .radio-button-label-heaeder{
        font-weight: 700;
        font-size: 115%;
      }
    }
  }
}
