//////////////////////////////////////////////////////////////////////////////////////////

// _NO-RESULTS.SCSS

// This file includes all rules which affect the generic no-results module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

	
	#no-results {
		float: none;
		width: 100%;	
		font-size: 1.2em;
		text-align: center;
		
		h3, .h3 {
			font-size: 1.7em;
			line-height: 100%;
			padding-bottom: 40px;
		}
		
		p {
			font-size: 1.3em;
			padding: 20px;
			margin-top: 10px;
			background: $bkgrdWarningAlert;
		}

		@include breakpoint(small) {
			float:none;
			display: block;
			width: 100%;
		}			
	}