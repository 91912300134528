// -------------------------------------------------------------------
//
//		CARD-2 TYPE COMPONENTS
//		Created for Framework 2.1.0+
//
//		
//
// -------------------------------------------------------------------

.card-2{
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  border: 1px solid $gray-lightest;
  background:#fff;
  padding: 1rem;
  .card-2-title{
  	font-size: $h5-font-size;
  }
  .card-2-subtitle{
	font-size: $h6-font-size;
  }
}