//////////////////////////////////////////////////////////////////////////////////////////

// _LISTING-DETAIL.SCSS

// This file includes all rules which affect the generic listing detail expanded element.  
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


		.listing-detail {
			
			textarea {
				display: block;
				min-height: 100px;
				width: 100%;
				margin-top: 5px;
				margin-bottom: 10px;
				box-sizing: border-box;
			    -webkit-box-sizing: border-box;
			    -moz-box-sizing: border-box;
			}
			
			fieldset {
				float: left;
			    width: 100%;
			    padding: 0;
			    margin: 0;
			}
			
			div {
				padding: 10px;
				margin-bottom: 20px;
				background: $white;
				width: auto;
				border: thin solid $miscLtGrey;
				
				h3, .h3 {
					font-size: 1.3em;
					border-bottom: thin dotted $miscLtGrey;
					margin-bottom: 10px;
				}
				
				p {
					padding: 10px 0 5;
				}
				
				span {
					display: inline;
					padding-left: 20px;
				}
				
				li label {
					display: inline-block;
					width: 120px;
					font-weight: 500;
				}
			}
		}
