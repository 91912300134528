// ---------------------------------------------------------------
//
//		FOOTERS
//		Default footers, not specific for custom partner footers
//
// ---------------------------------------------------------------

// ---------------------------------------------------------------
//	FRAMEWORK 1.9 and below
// ---------------------------------------------------------------

#footer {
	background-color: #fff;
	padding-top: 40px;
	font-size: 14px;

	@include breakpoint(xsmall) {
		padding-top: 10px;
	}
}

.footer-content-inner.site {
	background-color: transparent;
}
.footer-content {
	padding: 20px 10px;
	background:$bkgrdLtGrey;
	box-sizing: border-box;

	h3{
		margin-bottom: 0;
		line-height: 1.5;
	}
	ul{
		margin-bottom: 0;
	}
	
	.footer-nav {
		float: left;
		width: 25%;
		
		li {
			line-height: 2;
		}
	}

	.copyright-contact {
		float: left;
		width: 50%;
		padding: 0 5px;
		
		b {
			display: block;
			font-weight: 400;
		}
	}
	
	.social-media {
		float: right;
		
		li {
			display: inline-block;
			font-size: 1.7em;
			padding: 0 4px;
		}
	}
	p {
		margin-bottom: auto;
	}

	@include breakpoint(medium) {
		.social-media li {
			display: block;
		}
	}
	@include breakpoint(small) {

		text-align: center;

		.footer-nav {
			float: none;
			margin: auto;
			width: 100%;

			li {
				line-height: 1.5;
				display: inline-block;
			}
			li + li {
				margin-left: 6px;
			}
		}
		.copyright-contact {
			float: none;
			margin: 15px auto auto;
			width: 100%;
			padding: 0;
		}
		.social-media {
			float: none;
			margin: 15px auto auto;

			li {
				display: inline-block;
			}
		}
	}
}

.icon-custom-margin{
	margin-left: 0.2em;
	margin-right: 0.2em;
}

