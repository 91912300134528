.custom-loader-container {
  height: 70vh;
  display: grid;
  place-items: center;

  .loader-default {
    svg {
      width: 3.25em;
      transform-origin: center;
      animation: 2s linear infinite rotate4;
      circle {
        fill: none;
        stroke: var(--site-primary-color, #1badba);
        stroke-width: 4;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        animation: 1.5s ease-in-out infinite dash4;
      }
    }
  }
  @keyframes rotate4 {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash4 {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -125px;
    }
  }
}
