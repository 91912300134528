// --------------------------------------------------
//
//		CAREER ADVICE ARTICLE PAGE
//
// --------------------------------------------------

.career-advice-article-page {
  .career-advice-article-header-image {
    max-height: 275px;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .career-advice-article-body {
    @extend .container-fluid;
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-md-row;
    @extend .career-planning-bottom-page-padding;
    @extend .career-planning-max-body-width;
    @extend .pt-3;
    @extend .align-items-start;
    ul,ol{
      list-style: revert;
      margin: revert;
      padding: revert;
      font-size: initial;
    }
    
    img {
      width: 100%
    }
    .career-advice-article-page-main-content-header-bar {
      @extend .career-planning-main-content-header-bar;
    }
    .cta-action-item {
      margin-right: -10px;
    }
    .career-advice-article-page-main-content {
      @extend .card-2;
      @extend .mx-0;
      @extend .mx-md-3;
      @extend .mb-3;
      @extend .mb-md-0;
      @extend .p-lg-4;
      ul,ol{
        list-style: revert;
        margin: revert;
        padding: revert;
        font-size: initial;
      }

      margin-top: -50px;

      @include media-breakpoint-down(xl) {
        flex: 0 0 calc(68% - 2rem);
        max-width: calc(68% - 2rem);
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: -30px;
      }

      .career-advice-article-title {
        h1 {
          font-size: 1.7rem;
          font-weight: $font-weight-base;
          @extend .my-1;
          @include media-breakpoint-down(sm) {
            font-size: $font-size-lg;
          }
        }
      }
      .career-advice-article-subtitle {
        font-size: 1.2rem;
        font-weight: $font-weight-base;
        @extend .mb-3;
        @include media-breakpoint-down(sm) {
          font-size: $font-size-base;
        }
      }
      .tag {
        @extend .ml-0;
        @extend .mb-2;
      }
      .career-advice-article-author-container {
        @extend .mb-3;
        @extend .pb-3;
        border-bottom: 1px solid $gray-lighter;

        .career-advice-article-author-header {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 1rem;
          .career-advice-article-author-avatar-container {
            @extend .d-flex;

            .career-advice-article-author-image {
              margin-right: -10px;
              a {
                @extend .d-flex;
                @extend .flex-row;
                @extend .align-items-center;
                color: $textMdGrey;
                &:hover {
                  color: #000;
                }
              }
              .article-card-author-image {
                &:hover {
                  cursor: pointer;
                  transition-property: all;
                  transition-duration: 300ms;
                  transition-timing-function: ease-in-out;
                  transform: scale(1.2);
                }
              }
              .career-advice-article-author-name-title {
                @extend .d-flex;
                @extend .flex-column;
                @extend .ml-3;
                @extend .flex-wrap;

                .career-advice-article-author-name {
                  font-weight: $font-weight-normal;
                  font-size: $font-size-base;
                }
                .career-advice-article-author-title {
                  font-weight: $font-weight-normal;
                  font-size: $font-size-sm;
                  color: $gray;
                }
              }
            }
          }
        }
        .career-advice-article-author-names-container {
          width: 90%;
        }
        .career-advice-article-author-body {
          p {
            display: inline;
            font-weight: $font-weight-normal;
            font-size: $font-size-sm;
          }
          a {
            @extend .pl-2;
            font-weight: $font-weight-normal;
            font-size: $font-size-sm;
          }
        }
      }

      .career-advice-article-page-body {
        p {
          font-size: $font-size-base;
          font-weight: $font-weight-normal;
          @extend .mb-3;
        }
        h2 {
          font-size: 1.4rem;
          font-weight: $font-weight-light-bold;
        }
        h3 {
          font-size: 1.3rem;
          font-weight: $font-weight-light-bold;
        }
        h4 {
          font-size: 1.2rem;
          font-weight: $font-weight-light-bold;
        }
        h5 {
          font-size: 1.1rem;
          font-weight: $font-weight-light-bold;
        }
      }
    }
    .career-advice-article-aside-content {
      @include media-breakpoint-down(xl) {
        flex: 0 0 calc(32% - 1rem);
        max-width: calc(32% - 1rem);
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 calc(100% - 1rem);
        max-width: 100%;
        margin-top: 0px;
      }
      .entry-article-card-cta {
        @extend .card-2;
        @extend .mb-3;
      }

      .entry-article-card-cta-body {
        @extend .mb-3;
      }

      .career-advice-topic-nav {
        @extend .mb-3;

        .career-advice-topic-nav-container {
          @extend .p-3;
        }
      }
      .career-advice-article-related-content {
        .career-advice-article-related-content-header {
          @extend .mt-5;
          @extend .mb-3;
        }

        .career-advice-article-related-content-header-title {
          @extend .career-planning-body-sub-header;
          @extend .text-center;
        }

        .career-advice-article-related-content-header-subtitle {
          @extend .text-center;
          font-weight: $font-weight-normal;
          font-size: $font-size-base;
          color: $gray;
        }

        .career-advice-article-related-content-container {
          @extend .d-flex;
          @extend .flex-row;
          @extend .flex-wrap;
          @extend .align-items-center;
          @extend .justify-content-center;
          @extend .mx-0;
          @extend .mx-lg-2;
          @extend .mx-md-0;

          .entry-article-card {
            @include media-breakpoint-down(xl) {
              flex: 0 0 100%;
              max-width: 400px;
            }
            @include media-breakpoint-down(sm) {
              flex: 0 0 calc(50% - 1rem);
              max-width: calc(50% - 1rem);
            }
            @include media-breakpoint-down(xs) {
              flex: 0 0 100%;
              max-width: 400px;
            }
          }
        }
      }
    }
  }
  .advice-disclaimer {
    @extend .pt-2;
  }
}
