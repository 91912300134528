.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: .75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}
.form-label-group > .textarea-label {
    background: #fff;
    height: 20px;
    border: thin solid #ccc;
    border-bottom: 0;
    border-radius: .25rem .25rem 0 0;
}
.form-label-group input::-webkit-input-placeholder, .form-label-group textarea::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder, .form-label-group textarea:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder, .form-label-group textarea::-ms-input-placeholder{
  color: transparent;
}

.form-label-group input::-moz-placeholder ,.form-label-group textarea::-moz-placeholder{
  color: transparent;
}

.form-label-group input::placeholder, .form-label-group textarea::placeholder{
  color: transparent;
}

.form-label-group input:not(:placeholder-shown), .form-label-group textarea:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label, .form-label-group textarea:not(:placeholder-shown) ~ label{
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}
    

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none!important;
  }
  .form-label-group input:not(:placeholder-shown), .form-label-group textarea:not(:placeholder-shown) {
     padding: 12px;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
  .form-label-group textarea::-ms-textarea-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none!important;
  }
  .form-label-group input:not(:placeholder-shown), .form-label-group textarea:not(:placeholder-shown) {
     padding: 12px;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
  .form-label-group textarea:-ms-textarea-placeholder {
    color: #777;
  }
}
