//////////////////////////////////////////////////////////////////////////////////////////

// FREQUENT-VIEWS.SCSS

// This file includes all rules which affect the generic news-feed module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

	
	.news-feed {
		padding: 10px;
		margin-bottom: 25px;
		background: $bkgrdLtGrey;
		
		a {
			display: block;
			background: $white;
			padding:5px 10px;
		}
		
		a + a {
			border-top: thin dotted $miscLtGrey;
		}
		
		u {
			color: $textLtGrey;
			text-decoration: none;
		}
		
		@include breakpoint(small) {
			display: none;
		}
	}