//////////////////////////////////////////////////////////////////////////////////////////

// _STEP-WIZARD.SCSS

// This file includes all rules which affect the step-wizard form module. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

	
	form#wizard {
		margin-top: 20px;
	}
	
	.wizard {
				
		.steps {
			
			 ul > li {
			width: 135px;
			padding: 0;

				
				@include breakpoint(small) {
					width: 20%;
				}
				
				@include breakpoint(xsmall) {
					width: 25%;
				}
			}
			
			a {
				font-size: 120%;
			}
			
			.number {
				font-size: 100%;
				
				@include breakpoint(small) {
					line-height: 4;
					top: -30px;
				}
			}
			
			.current.error {
				background: transparent;
			}

		}
		
		.content {
			font-size: 1.2em;
			position: relative;

			.primary {
				padding:5px 25px;
			}
							
			legend {
				padding-top: 20px;
				font-size: 1.4em;
				font-weight: 500;
			}
			
			input {
				padding: 5px;
			}
							
			select {
				padding: 4px;
			}
			
			div {
				background: #ffffff;
				padding:10px 20px;
				margin-bottom: 20px;
				
				label {
					display: block;
					padding-left: 20px;
				}
			}
			
			small {
				position: absolute;
				left: 40px;
				bottom: 40px;
			}
			
			em {
				font-style: normal;
			}
			
			p {
				clear: both;
			}
				 
			 span {
				position: relative;
			 }	
						
			.body input {
				display: inline;
			}
			
			
			.body label {
				padding-right: 20px;
			}											
		}
	}
	
	
	#basic-page .wizard .steps ul > li {
	
		@include breakpoint(small) {
			padding: 5px;
		}
	}
