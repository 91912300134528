//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-EMPLOYERS.SCSS

// This file includes all rules which affect the generic featured employers module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
		
	
	#featured-employers {
		.interior { 
			background: $bkgrdLtGrey;
			padding: 35px;
			
			p {
				display: none;
			}

			@include breakpoint(small) {
				padding: 25px;
				
				p {
				display:block;
				padding-bottom: 10px;
				}
			}
		}
		
		#featured-employer-logos {
			.mobile {display: none;}
	
			ul {
				list-style-type: none;
				text-align: center;
			
				li {
					display:inline-block;				
					margin: 10px;
					width: 150px;
					height: 70px;
					background: #ffffff;
					
					a {
						width:150px;
						height: 70px;
						display: table-cell;
						vertical-align: middle;
						text-align: center;
						
						img {
							max-width: 135px;
							max-height: 60px;
						}
					}
															
					a:hover {
						border: thin solid $miscLtGrey;
					}
				}
			}
			
			@include breakpoint(medium) {
				
				ul {
					li {
						margin-right: 20px;
					}
					
					li.responsive-drop {
						display: none;
					}
				}
			}

			@include breakpoint(small) {
				.mobile-hide {display: none}
				.mobile {display: block;}
				
				ul li {
					display: block;
					float: none;
					width: 75%;
					height: auto;
					padding: 10px;
					margin: 5px auto 0;
					
					a {
					font-size: 1.1em;
					width:100%;
					height: auto;
					display: block;
					text-align: center;
					}
					
					a:hover {
						border: none;
					}
					
					&.responsive-drop {
						display: block;
						float: none;
					}
				}
			}
		}
		
		.call-to-action {
			padding: 20px 0 40px;
			font-size: 1.1em;
			
			@include breakpoint(small) {
				float: none;
				display: block;
				text-align: center;
				font-size: 1.1em;
				padding-bottom: 0;
				
				.secondary {
					font-size: 1.1em;
					text-align: center;
					float: none;
					display: block;
					margin: 5px auto 0;
					width: 70%
				}
			}
		}

		#featured-employers-carousel {
			padding: 35px 0px;
		 
			.featured-employer-swiper {
				padding-inline: 14px;
		 
				.swiper-slide {
					text-align: center;
		 
					a {
						width: 130px;
						height: 130px;
						display: block;
		 
						img {
							object-fit: contain;
							width: 100%;
							height: 100%;
						}
					}
				}
		 
				.swiper-button-prev {
					left: 12px;
				}
		 
				.swiper-button-next {
					right: 12px;
				}
			}
		}	 
	}