//////////////////////////////////////////////////////////////////////////////////////////

// _BROWSE-BY-CATEGORY.SCSS

// This file includes all rules which affect the browse by category component generally 
// found on the Home Page. The rules in this file are not to be modified to accommodate
// custom partner sites. Site-specific customization is to be handled in a separate
// css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////






//updated browse-by

#browse-by{
    padding-bottom: 25px;
	.interior {
	    padding: 0px 10px;
	    width: auto;

		h3 {
		    font-size: 1.3em;
		    font-weight: 400;
		    text-overflow: ellipsis;       
		    width: 100%;       
		    white-space: nowrap;       
		    overflow: hidden; 
		}
		#browse-tab1 {
		    display: block;
		}
		ul.browse-tabs {
			margin: 0;
			padding: 0;
			float: left;
			list-style: none;
			width: 100%;
			border-bottom: 1px solid #ddd;

			li {
				float: left;
				margin: 0;
				cursor: pointer;
				padding: 12px 10px;
				overflow: hidden;
				position: relative;
				border-bottom: 5px solid transparnet;
				font-size: 14px;
				width: 25%;
				text-align: center;
				box-sizing: border-box;

				&.active a:after {
				    height: 3px;
				    background: currentColor;
				    content: "";
				    display: block;
				    position: absolute;
				    bottom: 0;
				    width: 100%;
				    left: 0;
				}
				a
				&:after {
					height: 3px;
					background: currentColor;
					content: "";
					display: block;
					position: absolute;
					bottom: 0;
					width: 0%;
					left:0;
				}
				&:hover:after {
					height: 3px;
					background:currentColor;
					content: "";
					display: block;
					position: absolute;
					bottom: 0;
					width: 100%;
					left:0;
				}
			}
			
		}
		.browse-tab-container {
		    margin-top: 4px;
			clear: both;
			width: 100%;
			overflow: auto;
			margin-bottom: 25px;

			.tab-drawer-heading { 
				display: none; 

				.plus-minus-toggle {
				    cursor: pointer;
				    position: relative;
				    float: right;

				    &:after {
					  	transform-origin: center;
					  	background: currentColor;
					  	content: '';
					  	height: 2px;
					    right: 0;
					    position: absolute;
					    top: 11px;
					    width: 15px;
					    transition: transform 500ms ease;
					}
				    &:before{
					    background: currentColor;
					    content: '';
					    height: 2px;
					    right: 0;
					    position: absolute;
					    top: 11px;
					    width: 15px;
					    transition: transform 500ms ease;
					}
					&.collapsed{
						&:after {
						  transform: rotate(90deg);
						}
						&:before {
						  transform: rotate(180deg);
						}
					}
				}
			}

			.browse-tab-content {
				padding: 20px 0px;
				display: none;

				.browse-tab-list{
					&:after {
					    content: "";
					    display: table;
					    clear: both;
					}
				}
				.browse-tab-show-all {
				    padding: 0px 10px;
				    font-size: 1.2em;
				    text-align: right;
				    cursor: pointer;
				    clear:left;
				}
				.extended-list {
				    transition: opacity .7s ease-out;
				    opacity: 0; 
				    height: 0;
				    overflow: hidden;

				    &.extended-list-no {
					    opacity: 1;
    					height: 100%;
    					overflow: visible; 
					}
				}

				a{
					padding: 3px 10px;
					font-size: 1.2em;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					width: 33.333%;
					float: left;
					box-sizing: border-box;
				}
			}
		}
	@include breakpoint(medium) {

    h3 {
	    width: auto;
	}
		.browse-tabs {
			display: none;
		}
		div#browse-tab1 {
		    display: none;
		}
		.browse-tab-container {
		    border: 1px solid #cccccc;
		    border-top: 0;
		    clear: both;
		    float: none;
		    overflow: auto;
		    margin: auto;
		    margin-top: 4px;

		    .tab-drawer-heading {
			    background-color: #fff;
			    border-top: 1px solid #cccccc;
			    margin: 0;
			    padding: 10px 15px;
			    display: block;
			    cursor: pointer;
			    -webkit-touch-callout: none;
			    -webkit-user-select: none;
			    -khtml-user-select: none;
			    -moz-user-select: none;
			    -ms-user-select: none;
			    user-select: none;
			   
			}
			.browse-tab-content {
			    background: #f5f5f5;
			    border-top: 1px solid #cccccc;
			    padding: 10px 5px;

			    a {
			    	width: 50%;

				}
			}
		}
	}
	@include breakpoint(small) {
		.browse-tab-container {
			.browse-tab-content {
				a {
				width: 100%;
				}
			}
		}
	}
	}	
}
