/*Mixin for demo use*/

.ocu {
  font-family: Roboto, Arial, sans-serif; /*Update to match system font*/
  @include flexgroup(center, center, nowrap);
  padding: 0;
  margin: 0;
  padding-block: 1.25rem;

  @media screen and (max-width: 767px) {
    padding: 0.5rem;
  }

  .btn {
    padding: 0.625rem 0.5rem 0.5rem 0.5rem;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .btn-primary {
    background: var(--site-primary-color, #00adbb);
    color: var(--site-primary-text-color, $white);
    border-color: var(--site-primary-color, #00adbb);
  }
  .btn-outline-primary {
    color: var(--site-primary-color, #00adbb);
    border-color: #ebebeb;
    &:hover{
        background: none;
    }
  }

  .single-action-button{
    width: 14.375rem;
  }

  .ocu-card {
    @include flexgroup(center, flex-start, nowrap);
    flex-direction: column;
    gap: 1.5rem;
    width: 500px;
    // height: 100%;
    overflow-y: auto;
    height: 770px;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fff; /*Update to color var*/
    box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .content {
      @include flexgroup(center, center, nowrap);
      flex-direction: column;
      gap: 1.5rem;
      width:100%;
      padding-block: 1.5rem;
      padding-inline: 1.325rem;
      @media screen and (max-width: 767px) {
        padding-inline: 1.5rem;
      }
      @media screen and (max-width: 425px) {
        padding-inline: 0rem;
      }

      .success-message-container {
        @include flexgroup(center, center, nowrap);
        flex-direction: column;
        gap: 2.25rem;
        align-self: stretch;
        width: 100%;

        .success-message {
          @include flexgroup(center, center, nowrap);
          gap: 0.5rem;
          color: #099706;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          .success-icon {
            font-size: 1rem;
          }
        }
        .dashed-line {
          border-bottom: 1px dashed #a2aaad;
          width: 100%;
          max-width: 21.5rem;
        }
      }

      img.ocu-icon {
        border: none;
        outline: 0;
        overflow: unset;
        display: block;
        max-width: 154px;
        max-height: 184.94px;
        padding: 0;
        text-align: center;

        @media screen and (max-width: 767px) {
          max-width: 124px;
        }
        @media screen and (max-height: 740px) {
            max-width: 110px;
          }
        @media screen and (max-height: 720px) {
            max-width: 100px;
          }
      }

      .messaging {
        color: #222; /*Update to color var*/
        width:100%;

        h2 {
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 24px;

          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }

        h3 {
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 24px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 4px;
          text-align: center;
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }

      .form {
        @include flexgroup(center, flex-start, wrap);
        gap: 24px;
        width: 100%;
        flex-direction: column;

        .form-group {
          width: 100%;
          margin: 0;

          .form-control {
            height: 3rem;
            margin-top: 0.25rem;
            width: 100%;
          }
        }

        .submit-btn {
          width: 100%;
        }
      }

      .buttons {
        .resubscribe-btn {
          width: 8.5625rem;
        }
        .switch-to-weekly-btn {
          width: 12.375rem;
        }

        @include flexgroup(flex-start, space-between, wrap);
        margin-top: 16px;

        @media screen and (max-width: 424px) {
          flex-direction: column;
          gap: 1rem;
          .resubscribe-btn {
            width: 100%;
          }
          .switch-to-weekly-btn {
            width: 100%;
          }
        }
      }
    }
  }
}
