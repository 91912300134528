// -------------------------------------------------------------------
//
//		PAGE ACTION ITEMS TYPE COMPONENTS
//		Created for Framework 2.1.0+
//
//		
//
// -------------------------------------------------------------------
//hero image share 
.hero-intro-actions{
    position: absolute;
    display: inline-block;
    right: 20px;
    bottom: 20px;
    @include media-breakpoint-up(xl) { right: calc((100% - 1230px) / 2); }
  .cta-action-item{
    .dropdown{
      &.show{
        a.cta-action-item-link{
          border: 1px solid rgba(0, 0, 0, 0.5) !important;
          background: #fff;
          svg{
            fill: rgba(0, 0, 0, 0.5);
            .share-button-svg{
              fill: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
    .cta-action-item-link{
      padding: .7rem;
      border: 1px solid #ccc !important;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50% !important;
      @extend .d-flex;
      &:focus{
        outline-color: #fff;
      }       
      svg{
        fill: #CCC;
        width: 20px;
        height: 20px;

        .share-button-svg{
          fill: #CCC;
        }
      }
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.5) !important;
        background: #fff;
        svg{
          fill: rgba(0, 0, 0, 0.5);
          .share-button-svg{
            fill: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
    .dropdown-menu{
      top: 55px!important;
    }
  }
}

//body share 
.cta-action-item{
  .dropdown{
    &.show{
      a.cta-action-item-link{
        box-shadow: $box-shadow;
        border-radius: $border-radius;
      }
    }
  }
  
  a.cta-action-item-link{
    padding: .7rem;
    @extend .mb-1;
    border: 1px solid transparent;
    
    svg{
      width: 20px;
      fill: $gray;
      .share-button-svg{
        fill: $gray;
      }
    }
    &:hover {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        border-color: $gray-lightest;
    }
    &.dropdown-toggle::after {
        display: none;
    }
  }
  .dropdown-menu{
    width: 100%;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    border: none;
    background:#fff;
    @extend .p-3;
    color: $textMdGrey;
    min-width: 300px;
    font-size: .8rem;
    overflow: hidden;
    transform: none!important;
    will-change: unset;
    right: 0;
    left: auto!important;
    top: 40px!important;
    
    .dropdown-menu-container{

    }
    button{
      text-decoration: none !important;
      border: none !important;
      padding: 8px 25px !important;
      cursor: pointer !important;
      text-align: center !important;
      margin: 0 !important;
      line-height: normal !important;
      border-radius: $border-radius!important;
    }
    .form-email-share{
      @extend .mt-1;
  
      .form-input-group{
        @extend .form-label-group;

        input{
          @extend .form-control;
          font-weight: $font-weight-normal;
          height: 100%;
        }

        textarea{
          @extend .form-control;
          font-weight: $font-weight-normal;
          font-size: $font-size-base;
        }
      }
    }
    .share-socal-heading{
      font-weight: $font-weight-normal;
      font-size: $font-size-base;
    }
    .dropdown-socal-share{
      @extend .d-flex;
      @extend .flex-row;
      @extend .mt-1;
      @extend .mb-3;

      .social-link-button{
        @extend .px-2;
        @extend .py-1;
        @extend .mr-2;
        font-weight: $font-weight-normal;
        font-size: $font-size-base;
        border-radius: $border-radius;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        i{
          width: 16px;
        }
      }
    }
    .dropdown-link-share{
      @extend .mt-1;
      @extend .mb-2;
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-center;

      .dropdown-link-share-button{
        border: 1px solid $gray-lightest!important;
        padding: .3rem .5rem!important;
        background: transparent;
        color: $gray;
        border-radius: $border-radius!important;
        @extend .my-1;
        &:hover{
          background: $gray-lightest; 
          color: $gray;
        }
        svg{
          width: 18px;
          @extend .mr-1;
        }
        .link-svg{
          fill: $gray;
        }
      }
    }
    .dropdown-link-share-confirmed, .dropdown-link-email-confirmed{
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-center;
      
      svg{
        width:16px;
        @extend .mr-1;
      }
      .checkmark-icon{
        fill: $green;
      }
    }
    .dropdown-link-share-confirmed{
      @extend .ml-3;
    }
    .dropdown-link-email-confirmed{
      @extend .mt-2;
      @extend .mb-3;
    }
  }  
}