// --------------------------------------------------
//
//		ARTICLE CARD
//
// --------------------------------------------------

.entry-article-card {
  @extend .card-2;
  @extend .p-0;
  @extend .mx-2;
  @extend .mb-4;
  @extend .mt-1;
  @extend .h-auto;
  white-space: normal;
  @extend .d-flex;
  @extend .flex-column;
  min-height: 262px;

  @include media-breakpoint-down(xl) {
    flex: 0 0 auto;
    width: calc(25% - 1rem);
  }
  @include media-breakpoint-down(md) {
    flex: 0 0 auto;
    width: calc(33.33% - 1rem);
  }
  @include media-breakpoint-down(sm) {
    flex: 0 0 auto;
    width: calc(50% - 1rem);
  }
  @include media-breakpoint-down(xs) {
    flex: 0 0 auto;
    width: 100%;
    max-width: 400px;
  }

  .entry-article-card-image {
    position: relative;
    overflow: hidden;
    transition: transform 0.5s ease;
    max-height: 120px;
    border-radius: 0.25rem 0.25rem 0 0;
    &:hover img {
      transform: scale(1.2);
    }

    a {
      &:focus:before {
        border-color: #fff;
      }
      &:before {
        content: "";
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        cursor: pointer;
        border: transparent 2px dotted;
      }
    }

    img {
      @extend .img-fluid;
      border-radius: 0.25rem 0.25rem 0 0;
      transition: transform 0.3s ease;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .entry-article-card-topic {
    margin-top: -15px;
    z-index: 1;
    .tag {
      box-shadow: 0 0 0 3px #fff;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .entry-article-card-header {
    @extend .px-3;
    @extend .pt-2;
    @extend .pb-3;
    flex: 1 1 auto !important;
    .entry-article-card-heading {
      font-weight: $font-weight-normal;
      font-size: $font-size-base;
    }
    @include media-breakpoint-down(xs) {
      min-height: auto;
    }
  }
  .entry-article-card-footer {
    @extend .pb-3;
    @extend .px-3;

    .author-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      .entry-article-card-author {
        margin-right: -8px;
        .article-card-author-image {
          &:hover {
            cursor: pointer;
            transition-property: all;
            transition-duration: 300ms;
            transition-timing-function: ease-in-out;
            transform: scale(1.2);
          }
        }
        a {
          @extend .d-flex;
          @extend .flex-row;
          @extend .align-items-center;
          color: $gray;
          &:hover {
            color: #000;
          }
        }

        .dropdown-menu {
          max-height: 224px;
          overflow-y: scroll;
          width: max-content;
          .dropdown-item {
            column-gap: 10px;
          }
        }
      }
    }
  }
}
.entry-article-card-cta {
  @extend .career-planning-article-card-cta;
}
.entry-article-card-cta-heading {
  @extend .career-planning-article-card-cta-heading;

  .article-card-cta-icon {
    @extend .career-planning-article-card-cta-icon;
  }
  .entry-article-card-cta-heading-title {
    @extend .career-planning-card-cta-heading-title;
  }
}
.entry-article-card-cta-body {
  @extend .career-planning-article-card-cta-body;
}
.entry-article-card-cta-link {
  @extend .career-planning-article-card-cta-link;
}

@each $color, $value in $advice-colors {
  .topic-link-hover-#{$color} {
    color: $textMdGrey;
    &:hover {
      color: $value;
    }
  }
}

//outside the class since we are displaying dropdown attached to body

.article-card-dropdown.dropdown-menu {
  max-height: 224px;
  overflow-y: scroll;
  width: max-content;
  .dropdown-item {
    column-gap: 10px;
  }
}
