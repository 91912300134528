//////////////////////////////////////////////////////////////////////////////////////////

// _HEADER.SCSS

// This file includes all rules which affect the generic header module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	#header {
		z-index: 1020;
	}
	
	.header-content {
		position: relative;
		line-height: 1.5;
		ul {
			margin-bottom: 0;
		}
	 
		#logo {
			float: left;
			padding-right: 30px;
			padding-top: 4px;
			max-width: 230px;
			display: block;
		
			
			img {
				max-width: 100%;
				max-height: 50px;
			}
		}
		
		#nav-trigger {
			display: none;
		}
		
		.call-to-action {
			margin-bottom: 0;
			margin-top: 10px;
		}
		
		#menu {
			float: left;
		    text-align: center;
		    display: block;
			height: fit-content;

			> a {
				display: none;
			}

			ul {
				box-sizing: border-box;
			}

			li {
		    	position: relative;
		    	
		    	&:hover {
			        background-color: #eaf0f4;
			    }
			    
		    	a {
					font-size: 13px;
			        display: block;
			        padding: 20px 24px;
			        margin: 0 10px;
				}  

		    	ul {
				   	background-color: #eaf0f4;
			        display: none;
			        position: absolute;
			        top: 100%;
			        overflow: visible;
			        left: 0;
			        right: 0;
			        z-index: 1;

					
					li a {
						font-size: 13px;
						padding: 10px 0;
						margin: 0 15px;
					}  
				}
				
			}


			> ul {
			    padding: 0; 

			    > li {
				    height: 100%;
        			float: left;

				    > a {
					    height: 100%;
					}
				}
			}
		}
		 
		#menu li:hover > ul {
		    display: block;
		}
 
		.primary-nav {
			font-size: 1.2em;
		}
		
		.collapse {
			display: none;
		}


// MEDIUM (TABLET) BREAKPOINT

		@include breakpoint(medium) {

						
			#logo {
				padding-left: 10px;
				max-width: 200px;
				padding-right: 20px;
			}

			#nav-trigger {
				display: none;
			}
			
			.call-to-action {
				font-size: .95em;
				margin-right: 10px;
				padding-top: 7px;
				
				a {
					padding: 5px;
				}
			}
			#menu li a {
				padding: 10px;
			}		
			#menu ul li  {
				width: 135px;
			}	
		}
	}	

// SMALL (SMARTPHONE) BREAKPOINT
		
		@include breakpoint(small) {


			#logo {
				padding-left: 10px;
			}
			
			.header-content #menu li ul .left {
    			float: none;
			}

			.header-content {
				padding-bottom: 10px;
				
				#menu {
					position: relative;
					float: none;
					top: 0;
					left: auto;
					width: 100%;
					margin: 0;
					padding: 0;

					> ul > li {
						float: none;
						width: 80%;
						clear: both;
						display: block;
						text-align: left;
					}

					.mobile-hide {display: none;}

					#nav-trigger {
						display: block;
						font-size: 2.5em;
						padding: 0 0 30px 0;
						position: relative;
						cursor: pointer;
						/*width: 40%;*/
						/*height: 25px;*/
						/*text-align: right;*/
						/*top: 0px;*/
						/*left: 55%;*/
						float: right;
						text-decoration: none;
						
						/*i {
							position: absolute;
							top: 6px;
							right: 0;
						}*/
					}

				   
				    				
				    ul {
				    	float: none;
						display: none;
						height: 100%;
						width: 100%;

						li {
							width: 100%;
							
							ul {
								margin-left: 0;
								width: 100%;
								float: none;
								display: block;

								li {
									border-bottom: 1px dotted #ccc;
								}
								li.collapse a {
									padding: 0;
									border-top: 0;									
								}
								li:last-child {
									border-bottom: none;
								}
							}
						}
				    }

					li {
			    		position: static;
			    		clear: both;

						ul {
							float: none;
							display: block;
							height: 100%;
							width: 100%;

							background-color: $bkgrdLtBlue;
							margin-left: 0;
							padding: 10px;
							position: static;
							top: 100%;
							overflow: visible;
							-webkit-box-shadow: none;
							-moz-box-shadow: none;
							box-shadow: none;

							.left {
								float: none;
							}
						
							.collapse {
								display: block;
								border: none;
								padding: 10px 0;
								background-color: #fff;
							}
						}
					}
				}

				.call-to-action {
					margin: 0 auto;
					display:block;
					clear:both;
					float:none;
					width: 30%;
					text-align: center;
				}
			}					
		}


//updates//
#menu  ul.sub-navigation{
	min-width: 200px;
}
.header-content #menu li.has-sub-navigation ul.sub-navigation{
	padding-top: 0;
}
.header-content #menu li{
	text-align: left;
}
.header-content #menu li.has-sub-navigation.show ul{
    display: block;
}
.header-content #menu li.has-sub-navigation.show ul ul{
	display: none;  
}   
.header-content #menu li.has-sub-navigation li.flyout-navigation-menu:hover ul{       
    display: block;       
}   
.header-content #menu li.has-sub-navigation.flyout-navigation-menu ul {
    display: none;
}     
.header-content #menu li.has-sub-navigation.flyout-navigation-menu.show ul {
    display: block;
}    
.header-content #menu ul ul {                          
   width: 100%;                  
}                      
.header-content #menu ul ul ul {            
	top: 0; 
	left: auto; 
	right: -99.5%;             
}
.header-content #menu ul ul .flyout-navigation-menu.dropdown-edge ul {
    right: auto;
    left: -99.5%;
}
.header-content #menu ul.nav-wrapper-ul {
    display: block;
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation ul{
    display: none;
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation.show ul {
    display: block;
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation.flyout-navigation-menu ul {
    display: none;
}     
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation.flyout-navigation-menu.show ul {
    display: block;
} 
.header-content #menu ul.nav-wrapper-ul li {
    border-top: 1px solid #ddd;
}
.header-content #menu ul.nav-wrapper-ul li li{
    border-top: none;
}
ul.clearfix.nav-wrapper-ul {
    border-bottom: 1px solid #ddd;
    
}
#nav-trigger {
	z-index: $zindex-dropdown;
}
.nav-wrapper-ul > li{    
	background-color:#ffffff; 
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation ul li a{
	padding-top: 10px;
	padding-bottom: 10px;
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation.flyout-navigation-menu {
    border-top: none;
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation .nav-list-item{
	position: relative;
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation .nav-list-item:after {
    line-height: 0;
    content: "\f0d7";
    font-family: FontAwesome;
    display: inline-block;
    transition: all 0.5s ease;
    padding-left: 10px;
    transform: none;
   
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation.show .nav-list-item:after {
      content: "\f0d7";
   
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation.flyout-navigation-menu .nav-list-item:after {
    line-height: 0;
    content: "\f0d7";
    font-family: FontAwesome;
    display: inline-block;
    transition: all 0.5s ease;
    padding-left: 10px;
    transform: none;
   
}
.header-content #menu ul.nav-wrapper-ul li.has-sub-navigation.flyout-navigation-menu.show .nav-list-item:after {
      content: "\f0d7";
   
}