// --------------------------------------------------
//
//		TILES
//		Framework 2.0.0+
//
// --------------------------------------------------

.g-scrolling-carousel {
	width: 100%;
	margin: 0 auto;
	display: block;
  padding-right: 10px;
  padding-left: 10px;
}

.items {
  flex-wrap: nowrap;
	@extend .row;
	padding: 4px;
}

.jc-right,
.jc-left {
  width: 36px;
  height: 36px;
  color:#757575;
  margin-bottom: auto;
  margin-top:auto;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.04), 0 4px 8px 0 rgba(0,0,0,0.20);
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index:2;
  opacity: 0.94;
}
.jc-right {
  right:-10px;
}
.jc-left {
  left:-10px;
}
.jc-right:hover,
.jc-left:hover{
  opacity: 0.98;
}
.jc-right svg,
.jc-left svg{
  position: absolute;
  left: 0;right: 0;bottom: 0;top: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  fill: #757575;
}
.jc-right:hover svg,
.jc-left:hover svg{
	fill: #000;
}
.g-scrolling-carousel .job-items::-webkit-scrollbar { 
  display: none; 
}
//@media (pointer: coarse) {
//  .jc-right,
//  .jc-left {
//    display: none !important;
//  }
//}
.g-scrolling-carousel {
  position: relative;
}
.g-scrolling-carousel .items {
  overflow-x: scroll;
  white-space: normal;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  padding-left: 20px;
  padding-right: 20px;
}
.g-scrolling-carousel .items::-webkit-scrollbar { 
  display: none; 
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}
