//////////////////////////////////////////////////////////////////////////////////////////

// _LAYOUT.SCSS

// This file includes all rules which affect the generic layout. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

// INDEX

	// 1.0 - BODY & PAGE CONTAINERS
	

// ------------------------------------------------
// THIS WAS BROUGHT IN FROM COMPASS SOMEWHERE IN OLD FRAMEWORK
// ADDING IT HERE
// ---------------------------------------------------


html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline
}
*, ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
 
html {
  line-height: 1
}

ol,ul {
  list-style: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

caption,th,td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle
}

q,blockquote {
  quotes: none
}

q:before,q:after,blockquote:before,blockquote:after {
  content: "";
  content: none
}

a img {
  border: none
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
  display: block
}
	
////////////////////////////////////////////////////////

// 1.0 - BODY & PAGE CONTAINERS

////////////////////////////////////////////////////////	

body {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1.5;
}


.super {
	width: 100%;
	position: relative;
	/*@include backface-visibility(hidden);*/
}


.site {
	width: 100%;
	max-width: $large;
	margin: 0 auto;
//	@include clearfix;
	overflow: visible;

	.thin-page & {
		width: $thinPage;
	}
	
	@include breakpoint(medium) {
		max-width: 100%;
	}
	
	@include breakpoint(small) {
		max-width: 100%;
	}
	@include breakpoint(xsmall) {
		max-width: 100%;
	}
}


.interior {
		padding: 5px 0;
}

.wrapper-max-1920{
	max-width: 1920px;
	margin: 0 auto;
}

.wrapper-max-1440{
	max-width: 1440px;
	margin: 0 auto;
}




aside {
	width: 35%;
	max-width: 400px;
	float: left;
	
	div {
		padding-bottom: 20px;
	}
	
	@include breakpoint(medium) {
		margin-right: 10px;
	}
}


#job-view-page, #basic-page, #results-page, #company-profile, #company-directory, #company-profile {
		padding: 0 10px;
}
