
.custom-dropdown-tag{
  display: flex;
.dropdown-tag-container {
  cursor: pointer;
  .tag-background {
    background: #f6f6f6;
    padding: 0.0625rem 0.125rem;
  }
  .tag-container {
    width: fit-content;
    display: flex;
    height: 1.125rem; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border-radius: 0.125rem;
    .tag-label {
      color: var(--site-primary-color,#1badba);
      font-family: Roboto, "san-serif";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .dropdown-menu[x-out-of-boundaries] {
    // transform: translate3d(0px, 18px, 0px) !important;
    visibility:hidden;
  }
  
  .dropdown-menu {
    z-index: 1;
    // width: max-content;
    // position: absolute;
    // top: 1.4rem;
    // display: flex;
    // padding: 0.5rem;
    // flex-direction: column;
    // gap: 0.625rem;
    // border-radius: 0.25rem;
    // background: #fff;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    max-height: 115px;
    max-width: 300px;
    width:max-content;
    min-width: auto;
    overflow-y: auto;

    &.show{
      display: flex;
      flex-direction: column;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    scrollbar-width: thin;

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #bec4c4;
    }
    
    scrollbar-color: #bec4c4 #f1f1f1;

    // /* Handle on hover */
    // &::-webkit-scrollbar-thumb:hover {
    //   background: #555;
    // }

    // /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .dropdown-label {
      color: #222;
      font-family: Roboto, "san-serif";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0.25rem 1rem;
      white-space: normal;
      cursor: default;
    }
  }
}
.dropdown-toggle::after{
  display: none;
}
}
