//////////////////////////////////////////////////////////////////////////////////////////

// _COMPANY-PROFILE-LIST.SCSS

// This file includes all rules which affect the generic blank module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.



/////////////////////////////////////////////////////////////////////////////////////////
	
	
	#jt_page-company_profile_list {
		padding: 10px 0;
		.jt_page_inner { 
			max-width: 960px;
            margin: auto;
		}
		#jt_companies { 
	        td {
			padding: 10px;
			}
			.jt_header{
				td{
				background: #f3f3f3;
			    font-size: 15px;
			    font-weight: 600;
				}
			}
		}
		
	}

	#company-directory {
		
		#jt_companies { 
			margin-bottom: 25px; 

			tbody  {       
				border: #ddd 1px solid;        
			}

			td {                                                
			    padding: 10px!important;                                                
			    border-bottom: #ddd 1px solid;                                                
			}
			.jt_nav1 {         
			    font-size: 15px;         
			} 
			thead {
				td {            
			    	border-bottom: none; 
			    }           
			}                          
	        .jt_header{
	        	border: #ddd 1px solid;   
				
				td{
					font-size: 1.5em; 
				}
			}
			.jt_page{ 
				a {                                               
				    color: #f83600;                                                
				    text-decoration: none;                                               
				}
			}
			.jt_nav1{ 
				font-size: 15px; 

				a {                                               
				    padding: 0 2px;                                               
				}
			}
			tbody {
				tr:nth-child(odd) {         
				    background: #F1F1F1;        
				}            
            }                              
		}
		
	}