// --------------------------------------------------
//
//		JOB SEARCH PAGE
//
// --------------------------------------------------

// Removes margin and padding from the inner page
.job-search-page {
  overflow-y: hidden;

  #results-page {
    margin: 0;
    padding: 0;
  }
  #header.nav-scroll {
    z-index: $zindex-popover;
    position: absolute;
    width: 100%;
    .header-content {
      padding-bottom: 0;
    }
  }
}

.job-search-main {
  box-sizing: border-box;

  // Make all elements within Job Search to be right content sizing
  // Current framework CC Framework (2.0.0) doesn't have this on all elements
  // and we don't want to break the rest of the system, so it's specified here
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  @include make-container($grid-gutter-width-md);
  background-color: $white;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-right: 0px;
  margin-left: 0px;

  // This is the search area / header
  // -----------------------------------------------
  .page-head {
    // Passing argument '0' means no gutters/padding

    background-color: $white;
  }

  // Remove fieldset styling from old framework
  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  // Inputs and Search

  .job-search-header {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-start;
    @extend .align-items-lg-center;
    max-width: 1560px;
    margin: auto;
    @extend .px-3;
    @extend .py-3;
    @extend .py-md-2;
    @extend .flex-column;
    @extend .flex-lg-row;

    h1 {
      font-size: 1.25em;
      font-weight: 600;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      @extend .mr-0;
      @extend .mr-md-2;
      @extend .mb-2;
      @extend .mt-0;
      @extend .mt-lg-2;
    }
  }
  #jt_search {
    @extend .d-flex;
    @extend .justify-content-start;
    @extend .justify-content-md-center;
    @extend .align-items-center;
    @extend .flex-wrap;
    @extend .flex-md-nowrap;
  }
  #js-search-form {
    width: 100%;
    @extend .align-items-center;
  }
  .keyword-search {
    @extend .form-control;
  }
  .search-position-title {
    flex: 1 1 100%;
    @extend .flex-md-fill;
    @extend .mr-0;
    @extend .mr-md-2;
    @extend .pb-2;
    @extend .pb-md-0;
  }
  .location-col {
    flex: 1 1 100%;
    @extend .flex-md-fill;
    @extend .mr-0;
    @extend .mr-md-2;
    @extend .pb-2;
    @extend .pb-md-0;
    .select-style {
      flex: 1;
      select {
        width: 100%;
      }
    }
  }
  .filter-button-group {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    @extend .d-flex;
    @extend .mr-2;
    @extend .flex-row;
    @include media-breakpoint-down(sm) {
      flex: 1 1 calc(20% - 0.5rem);
    }
    @include media-breakpoint-down(xs) {
      flex: 1 1 calc(30% - 0.5rem);
    }
    .btn-filter {
      width: 100%;
    }
  }
  #data-step-launch-tutorial {
    flex: 0 0 auto;
    @extend .d-flex;
    @extend .justify-content-center;
    @include media-breakpoint-down(sm) {
      flex: 1 1 5%;
    }
    @include media-breakpoint-down(xs) {
      flex: 1 1 10%;
    }
  }
  .submit-search-group {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    @extend .mr-2;
    @include media-breakpoint-down(sm) {
      flex: 1 1 calc(75% - 0.5rem);
    }
    @include media-breakpoint-down(xs) {
      flex: 1 1 calc(60% - 0.5rem);
    }
    .btn-svg {
      @extend .d-flex;
      width: 100%;
      @extend .justify-content-center;
      &::before {
        content: "Search";
        position: relative;
        display: block;
        @extend .pr-2;
      }
    }
  }

  .location_autocomplete,
  .js-location-input {
    @extend .input-group;
    @extend .flex-nowrap;
    input,
    select {
      @extend .form-control;
      width: auto;
    }
    select {
      border-radius: 0 0.25rem 0.25rem 0;
    }
    .radius_select {
      @extend .input-group-append;
    }
  }
} // end .job-search-main

// ------------------------------
//	JS MAIN CONTENT
// ------------------------------

// Fluid container w/ BG
.job-results-cf {
  @include make-container($grid-gutter-width-md);
  padding-inline: 0;
  background-color: $alt-body-bg;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

// Sized container for JS v1.0
.job-results-c {
  //@include make-container($grid-gutter-width-md);
  //@include make-container-max-widths();
  //min-height: $job-view-min-height;
  //@extend %align-items-stretch;
}

// Job Row
.job-results-body {
  @extend %row;
  height: calc(100vh - 100px);
  // z-index: 50;
  flex-wrap: nowrap;
  align-content: flex-start;
  overscroll-behavior: contain;
  margin-left: -10px;
  max-width: 1920px;
  margin-inline: auto;
  position: relative;
  // position: sticky;
  // top: 0;
}

// Container for Job Details
.job-results-container {
  @extend %flex-column;
  @extend %col-8;
  @extend %align-self-stretch;
  @extend .rm-p-l;
  padding-bottom: $grid-gutter-width-xs;
  overscroll-behavior: contain;
  -ms-scroll-chaining: none;
  scroll-snap-stop: always;
}

// Region for the main pane view (ui splicing as seen in material design)
.region_main-pane {
  width: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;

  // This is to affect the two pane view only
  .job-results-container {
    padding-right: $grid-gutter-width-md;
    //min-width: 590px;
    max-width: 1020px;
    flex-grow: 1;
  }
  @media only print {
    height: auto !important;
    overflow: hidden !important;
}
}

// List of Search Results
.job-results-list {
  @extend %col;
  @extend %col-3;
  @extend %align-self-stretch;
  border-top: 1px solid #ebebeb;
  border-right: 1px solid $gray-500;
  border-left: 1px solid $gray-500;
  height: calc(
    100vh - 100px - 50px
  ); // This is how google does it - calc will need to be with javascript to calculate the header and top bar height
  overflow-y: scroll;
  z-index: 20;
  overscroll-behavior: contain;
  -ms-scroll-chaining: none;
  position: sticky;
  top: 0;
  scroll-snap-stop: always;
  max-width: 400px;
  width: 400px;
  min-width: 400px;

  @media (max-width: 1439px) {
    width: 375px;
    min-width: 375px;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #bec4c4 !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }

  .search-filter-container-smaller-devices {
    display: none;
    @media (max-width: map-get($grid-breakpoints, "xl")) {
      display: flex;
      position: sticky;
      top: 0;
    }
  }
}

// Row for list of tiles
.job-result-tiles {
  @extend %row;
  @extend %flex-column;
  @extend %flex-nowrap;
  @extend .rm-p;
  @extend %flex-fill;
  z-index: 5;
  //	max-height: 78%;
  //height: 100%;

  .candidate-products-promotion-tile {
    border: 1px solid #e61461 !important;
    .candidate-products-promotion-data {
      align-items: flex-start !important;
      .candidate-products-promotion-logo {
        padding: 8px 0px;
      }
      .candidate-products-promotion-button-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.625rem;
        align-self: stretch;
      }

      .candidate-products-promotion-button {
        border-radius: 0.25rem;
        background: #fb2576;
        color: #ffffff;
        border: none;
        display: flex;
        padding: 0.25rem;
      }

      .candidate-products-promotion-ad-container {
        display: flex;
        padding: 0.25rem 0.1875rem 0.3125rem 0.1875rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -8px;
        top: -8px;
        border-radius: 0rem 0.1875rem;
        background: #f0f0f0;
      }
    }
  }
}

// Company Location
.job-location {
  @extend %flex-fill;
  @extend %col-xl-8;
  @extend %col-lg-8;
  @extend %col-md-12;
  @extend %col-8;
  @extend .rm-p-l;
  display: flex;
  gap: 0.5rem;
  row-gap: 0rem;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.8rem;
  .custom-dropdown-tag .dropdown-tag-container .dropdown-menu .dropdown-label {
    font-size: 0.8rem;
  }
  .custom-dropdown-tag .dropdown-tag-container .tag-container .tag-label {
    font-size: 0.8rem;
  }
  .custom-dropdown-tag .dropdown-tag-container .dropdown-menu {
    @media (min-width: map-get($grid-breakpoints, "xl")) {
      max-width: 280px;
    }
  }
}

.workplace-location {
  font-size: 0.8rem;
  font-weight: 600;
  color: $gray;
  text-transform: capitalize;
}

// Holds logo and details
.job-main-data {
  @extend %row;
  width: 100%;
  height: 100%;
  @extend .rm-m-hor;
  padding: $grid-gutter-width-sm 0;
  flex-wrap: nowrap;
  @extend %align-items-center;
}

.job-title {
  @extend %col;
  @extend .rm-p-r;
  @extend .rm-m-b;
  line-height: inherit !important;
}

// Row contianing title and bookmark
.job-detail-row {
  @extend %row;
  @extend .rm-p-hor;
  @extend %justify-content-between;
  flex-wrap: nowrap;
  align-items: flex-start;

  //remove excess margin
  + .job-company-row {
    margin-top: -0.25em;
  }
}

// Row contianing labels
.job-label-row {
  @extend %row;
  @extend .rm-p-hor;
  padding-left: $grid-gutter-width-xs;
}

//Row containing company name
.job-company-row {
  @include text-truncate();
  font-weight: 700;
  font-size: 0.8rem;
}

.job-subtext-row {
  @extend %d-flex;
  @extend %justify-content-between;
}

.job-details-logo {
  @extend %col;
  @extend %col-auto;
  @extend %d-none;
  @extend %d-xl-flex;
  @extend %align-items-center;
  @extend %justify-content-center;
  line-height: inherit !important;
  text-align: center;
  background: $company-logo-bg;
  border: 1px solid $company-logo-border-color;
  border-radius: $company-logo-border-radius;

  &.logo-none {
    background: $company-logo-empty-bg;
    color: $company-logo-empty-font-color;
    @extend .h6;
    @extend .rm-m-b;
    line-height: 1 !important;

    span {
      display: block;
      width: 100%;
      @include text-truncate();
    }
  }
}

.job-tile {
  //@extend %col-xl-12;
  display: flex !important;
  flex-basis: auto !important;

  .job-details {
    @extend %col-xl-10;
    @extend %col-md-12;
    @extend %col-sm-12;
    //@extend %flex-grow-1;

    // These things were added to fix the sizing issue in smaller screens (not mobile)
    flex: 0 1 auto !important;
    max-width: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 90%;
  }

  .job-main-data {
    display: flex !important;
    flex: 0 0 auto;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start !important;
  }

  &.message-no-keywords {
    background: transparent !important;
    font-size: 1em !important;
    padding-top: $grid-gutter-width-md;
    padding-bottom: $grid-gutter-width-md;
    line-height: 24px;
    font-weight: 700;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
  }

  .job-title {
    a {
      color: inherit;
    }
  }

  // Job Logo on Tiles
  .job-details-logo {
    display: flex !important;
    @include relative-text-box(72, 81, 25);

    img {
      max-width: 60px;
      max-height: 80px;
      height: auto;
    }
  }

  // These were added to fix the smaller screen size issue
  .job-subtext-row {
    flex-wrap: wrap !important;
    display: flex !important;
    flex: 1 0 auto;
  }

  .job-location {
    max-width: unset;
  }
  .job-posted-date {
    font-size: 0.75rem;
  }
}

.job-details {
  @extend %col;
  @extend .rm-p-r;
}
.company-hiring-jobs .job-details {
  flex: none;
}
// Status highlight spotlight
//.status-highlight-spotlight-left .job-title {
//	color: darken($spotlight, 5%);
//}

.job-alert-bar {
  @extend %row;
  background-color: $system-blue;
  color: $white;
  @extend .h6;
  @extend .rm-m-ver;
  @extend %d-flex;
  @extend %align-items-center;
  @extend %justify-content-center;
  min-height: 50px;
  max-width: 400px;
  width: 100%;
  z-index: 50;
  padding: 0 $grid-gutter-width-md;
  margin: 0;
  @media (max-width: 1439px) {
    max-width: 375px;
  }

  //&::after {
  //	content: " ";
  //	display: block;
  //	max-width: 420px;
  //	width: 100%;
  //	background: transparent;
  //	height: 50px;
  //}

  > div {
    @extend %col;
    //@extend %col-auto;
    @extend %align-self-center;
    position: relative;

    // Removed but keeping style in case we decide to re-implement
    //input[type="checkbox"]:focus + label {
    //	border-bottom: 1px dashed currentColor;
    //}

    &:after {
      display: block;
      width: 18px;
      height: 18px;
      content: " ";
      fill: $white;
      position: absolute;
      right: $grid-gutter-width-md;
      top: 0;
    }

    &.job-alert-off:after {
      width: 20px;
      height: 20px;
      top: 0;
      background-image: url("../img/icons-notification-off.svg");
    }

    &.job-alert-on:after {
      background-image: url("../img/icons-notification-on.svg");
    }
  }
}

// Pagination and results
.job-results-bar {
  @extend %row;
  @extend %align-items-center;
  @extend %justify-content-around;
  min-height: 45px;
  z-index: 20;
  background: transparent;

  // PANE VIEW - Results Number Bar
  &.job-result-count {
    @extend %justify-content-start;
    background: $white;

    padding-left: $grid-gutter-width-md;
    padding-right: $grid-gutter-width-md;

    b.results-text {
      @extend .text-truncate;
    }
  }

  // Results Pagination
  &.job-results-bottom {
    background: transparent;
    border-top: 1px solid $gray-500;
  }

  // Results Sort
  &.job-results-top {
    border-bottom: 1px solid $gray-500;
  }

  nav,
  .job-results-bar-inner {
    @extend %col;
    @extend %col-auto;
  }

  nav {
    flex: 0 0 auto;
    flex-grow: 1;

    ul {
      margin-right: 0;
    }
  }

  .job-results-bar-inner {
    line-height: 1.2em;
    flex: 0 1 auto;
    flex-grow: 1;
  }

  //	SMALL FOOTER
  // ----------------------------------------------

  &.global-footer-compact {
    min-height: 45px; // Needed because IE11 is dumb :)
    height: unset; // Needed to overwrite initial 45px for IE11
    height: auto; // Now tells it to fill whatever the content is
    @extend %d-flex;
    justify-content: center !important;
    overflow-x: hidden;
    overflow-y: visible;
    padding: 0 $grid-gutter-width-sm;
    padding-top: $grid-gutter-width-md;

    ul {
      @extend %row;
      @extend %align-items-center;
      @extend %justify-content-around;
      flex: 1 0 100% !important;
      flex-wrap: wrap;

      li {
        @extend %d-flex;
      }

      > li {
        line-height: 3em;
        > a {
          //padding: 0 $grid-gutter-width-sm;
        }
      }
    }

    .footer_branding-compact {
      margin-top: $grid-gutter-width-md;
      margin-bottom: $grid-gutter-width-md;
      @extend %d-flex;
      @extend %justify-content-center;
      @extend %align-items-center;
      flex: 1 0 auto !important;
      flex-wrap: nowrap;
    }

    .footer_powered-by-compact {
      margin-top: $grid-gutter-width-md;
      margin-bottom: $grid-gutter-width-md;
      @extend %d-flex;
      @extend %justify-content-around;
      @extend %align-items-center;
      flex: 1 0 auto !important;
    }
  } // End compact global footer
}

.intern-bar {
  @extend %row;
  @extend %align-items-center;
  @extend %justify-content-around;
  height: 50px;
  min-width: 100%;
  background: $white;
  border-top: 1px solid $gray-400;
  position: absolute;
  bottom: 0;
  z-index: 20;
}

.intern-bar-inner {
  @extend %col;
  @extend %col-auto;
  line-height: 2.9em;
}

//	Job Details
// ------------------------------

// Job details section
.job-results-details {
  @extend .tile-flat;
  @extend .tile-container;
  overflow-x: hidden;

  .job-details {
    @extend %col-lg-9;
    @extend %col-md-12;
    @extend %flex-md-row;
    @extend %col-sm-12;
    @extend %col-12;
  }

  .job-details-logo {
    @extend %d-md-flex;
    @include relative-text-box(120, 129, 13);

    img {
      max-width: 118px;
      height: auto;
    }
  }
}

.logo-network {
  fill: $network;
}

.job-details-inner {
  @extend .tile-inner;

  .company-location-row {
  }

  .job-details {
    @extend %col-9;
  }

  .job-main-data {
    @extend .tile-row;
    @extend .border-bottom;
  }

  .job-features-section {
    margin: 14px;
  }

  // Holds Job Desc and Info
  .job-main-desc {
    @extend .tile-row;

    // Nesting for specifity on the Job View 2 Pane
    .job-desc {
      // We should consider revising * with something less global / intensive, but
      // the best way of doing this is restricting the user putting HTML into the job desc.
      // ------
      // This is to remove any set 'width' the user has put into HTML in the job desc that blows out the window
      > *,
      > * img {
        width: unset; // tells the browser to ignore the set width on the element
        width: auto; // then sets to the parent's width or what the parent tells this child to do
        max-width: inherit; // inherits any max-width in case of mobile, etc
      }
    }
  }

  // Holds Job Desc
  .job-desc {
    @extend %col;
    //@extend %flex-fill;
    //@extend %col-12;
    //@extend %col-sm-8;
    @extend %order-2;
    @extend %order-sm-1;

    ul,
    ol {
      margin-left: $grid-gutter-width-lg;

      ul,
      ol {
        // Added extra 2px because otherwise the parent line looks off from the next indented bullet
        margin-left: $grid-gutter-width-lg + 2px;
      }

      li {
        //list-style-position: inside;
        margin-top: $grid-gutter-width-xs;
      }
    }

    ul {
      li {
        list-style-type: disc;
      }
    }

    ol {
      li {
        list-style-type: decimal;
      }
    }
  }

  .job-stats {
    @extend %col;
    @extend %col-12;
    @extend %col-sm-4;
    @extend %order-1;
    @extend %order-sm-2;

    //		.job-data-contain.social-connect {
    //			@extend .shadow-sm;
    //			padding: $grid-gutter-width-sm;
    //		}
  }

  h1 {
    @extend .rm-m-t;
    font-weight: bold;
    line-height: 1.15em !important;
    font-size: 1.85em;
  }

  .company-location {
    display: flex;
    gap: 0.5rem;
    padding-left: 0;
  }

  // Add Margin
  .job-detail-row,
  .job-label-row,
  .job-company-row {
    margin-bottom: $grid-gutter-width-xs;
  }

  .job-cta {
    @extend %align-self-stretch;
    @extend %col-lg-3;
    @extend %col-md-12;

    .job-cta-items {
      @extend %row;

      // iPad Pro and Desktops
      @extend %flex-lg-column;
      @extend .h-100;
      @extend %justify-content-between;
      @extend %align-items-lg-end;

      // iPad (not pro) and below
      @extend %flex-row;
      @extend %align-items-center;
    }

    .job-cta-actions {
      @extend %order-lg-1;
      @extend %order-2;
      @extend %align-self-stretch;
      @extend %d-flex;
      @extend %flex-row;
      @extend %justify-content-end;

      div {
        @extend %col;
        flex-grow: 0;
        // margin: 0 $grid-gutter-width-xs;

        &:first-child {
          margin-top: -2px;
          font-size: 18px;
        }

        a:not(:hover) {
          svg {
            fill: $icon-color-default;

            &:hover {
              fill: currentColor;
            }
          }
        }
      }

      .ico-share {
        width: $icon-size;
        height: $icon-size;

        a:after {
          position: relative;
          top: -2px;
        }
      }
    }

    .job-cta-buttons {
      @extend %order-lg-2;
      @extend %order-md-1;
    }

    .job-cta-actions,
    .job-cta-buttons {
      @extend %col;
      @extend %col-auto;
    }
  }
}

.job-cta-actions {
  text-align: right;
}

.job-cta-buttons {
  text-align: right;
}

// --------------------------------------
//	SPINNER
// --------------------------------------

#job-search-spinner {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  max-width: 100px;
  margin: auto;
  z-index: $zindex-popover;
}

// --------------------------------------
//	COMPANY INFO
// --------------------------------------

.job-hiring-company {
  @extend .tile-flat;
  @extend .tile-container;

  .job-details-logo {
    max-height: 81px;
    margin-top: 0;

    &:not(.logo-none) {
      $custom-border-color: #d6d6d6;
      border-color: transparent;
      border: 0;
      background-image: repeating-linear-gradient(
          0deg,
          $custom-border-color,
          $custom-border-color 5px,
          transparent 5px,
          transparent 9px,
          $custom-border-color 9px
        ),
        repeating-linear-gradient(
          90deg,
          $custom-border-color,
          $custom-border-color 5px,
          transparent 5px,
          transparent 9px,
          $custom-border-color 9px
        ),
        repeating-linear-gradient(
          180deg,
          $custom-border-color,
          $custom-border-color 5px,
          transparent 5px,
          transparent 9px,
          $custom-border-color 9px
        ),
        repeating-linear-gradient(
          270deg,
          $custom-border-color,
          $custom-border-color 5px,
          transparent 5px,
          transparent 9px,
          $custom-border-color 9px
        );
      background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;
    }

    &.logo-none {
      width: 72px;
      height: 81px;
      padding-right: 0 !important;
    }
  }
}

.job-hiring-inner {
  @extend .tile-inner;
  gap: 0.5rem;
}

.job-hiring-main {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  padding: 16px 16px 0px 16px;
  justify-content: space-between;
  .company-main-data {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;

    .job-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;
      flex: 1 0 0;
      align-self: stretch;
      padding: 0px;
      .job-company-row {
        white-space: wrap;
      }
    }
    .job-details-logo {
      padding: 2px;
      margin-top: 0;
      width: 120px;
      @extend %d-flex;

      img {
        max-width: 120px;
        width: 100%;
        max-height: 80px;
        height: auto;
      }
    }
    img.badging {
      width: auto;
      height: 100%;
      max-height: 70px;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      .job-details {
        align-items: center;
        .company-subtext-row {
          text-align: center;
        }
      }
    }
  }

  .linkedIn-button {
    @media (max-width: 767px) {
      display: none;
    }
    display: flex;
    cursor: pointer;
    height: 2.25rem;
    padding: 0.625rem 0.5rem 0.5rem 0.5rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    .linkedIn-text {
      color: #222;
      font-family: Roboto, "san-serif";
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .linkedIn-icon {
      font-size: 16px;
      color: $linkedinBlue;
    }
  }
}

.job-hiring-about {
  @extend .tile-row;
  flex-direction: column;
  gap: 0.5rem;
}

.job-hiring-desc {
  @extend %col;

  //@extend %col-12;
  //@extend %col-sm-7;
  //@extend %col-md-8;
  //@extend %flex-fill;
}

.company-hiring-jobs {
  @extend %col;
  @extend %d-flex;
  @extend %col-12;
  @extend %flex-column;
  margin-right: 0;
  border-top: 1px solid #ebebeb;
  padding-top: 16px;

  div {
    @extend .rm-p-b;
  }

  strong {
    margin-bottom: $grid-gutter-width-xs;
  }

  .job-details {
    @extend .rm-p-l;
    @extend .border-bottom;

    cursor: pointer;

    // Animation Hover
    .job-detail-row,
    .job-subtext-row {
      transform: translateX(0);
      transition: transform 0.3s ease-in-out;
    }

    // Animation on Hover
    &:hover,
    &:focus {
      .job-detail-row,
      .job-subtext-row {
        transform: translateX($grid-gutter-width-sm);
        transition: transform 0.3s ease-in-out;
      }
    }

    .job-title {
      font-weight: bold;
      @include text-truncate();
    }
    .job-company-row {
      font-weight: normal;
    }

    .job-subtext-row {
    }

    .job-posted-date {
      &:before {
        font-family: FontAwesome;
        content: "\f017";
        margin-right: $grid-gutter-width-xs;
      }
    }

    + .job-details {
      margin-top: $grid-gutter-width-sm;
    }
  }
  .job-details:last-of-type {
    margin-bottom: 12px;
    border-bottom: none !important;
  }
}

.linkedIn-section-mobile {
  @media (min-width: 767px) {
    display: none;
  }
  display: flex;
  padding: 0.5rem 0.25rem 0rem 0.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  border-top: 1px solid #ebebeb;
  background: #fff;
  .linkedIn-text {
    align-self: stretch;
    color: #222;
    text-align: center;
    font-family: Roboto, " san-serif";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .linkedIn-button {
    display: flex;
    cursor: pointer;
    height: 2.25rem;
    padding: 0.625rem 0.5rem 0.5rem 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    .linkedIn-context {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .linkedIn-icon {
        font-size: 16px;
        color: $linkedinBlue;
      }
      .linkedIn-label {
        color: $linkedinBlue;
        font-family: Roboto, "san-serif";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

// --------------------------------------
//	DATA_STEP_TUTORIAL
// --------------------------------------

.step-tutorial {
  cursor: pointer;
}
// --------------------------------------
//	APPLIED FILTERS
// --------------------------------------

.applied-filters-row {
  @extend %d-none;
  @extend %d-sm-flex;
}

// --------------------------------------
//	TERTIARY CONTENT TILES
// --------------------------------------

.tertiary-content-tile {
  @extend .tile-flat;
  @extend .tile-container;

  h6 {
    font-weight: bold;
  }
}

.tertiary-content-inner {
  @extend .tile-inner;

  .job-details {
    .job-detail-row {
      flex-wrap: nowrap;
    }
  }
}

.tertiary-content-main {
  @extend .tile-row;
  @extend .border-bottom;
  flex-wrap: nowrap;
  @extend %align-items-center;
}

.tertiary-content-data {
  @extend %row;
  width: 100%;
  @extend .rm-m-hor;
  padding: 0 $grid-gutter-width-sm;
  flex-wrap: nowrap;
  @extend %align-items-center;
}

.tertiary-content-about {
  @extend .tile-row;
  @extend %d-flex;
  @extend %align-items-center;

  .course_card {
    border-radius: $border-radius;
    @extend .shadow-sm;
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover,
    &:focus {
      @extend .shadow;
      cursor: pointer;
      transform: translate(
        $grid-gutter-width-xs * -1,
        $grid-gutter-width-xs * -1
      );
    }
  }
}

.tertiary-content-desc {
  @extend %col;
}

.top-mobile-action-buttons {
  @extend %d-none;
  display: flex;
  padding: 0rem 1rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media (max-width: 425px) {
    padding-inline: 0.5rem;
  }
}

// Back button on Mobile
.btn-mobile-back {
  @extend %d-none;
  cursor: pointer;
  .back-btn-container {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    i {
      font-size: 1rem;
      color: #a2aaad;
    }
    span {
      color: #222;
      font-family: Roboto, "sans-serif";
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  svg {
    .back-arrow {
      fill: $icon-color-default;
    }
  }
}

// Scrolling Carousel
// -------------------------------
.items {
  div.job-tile {
    @extend %col-11;
    @extend %col-xl-5;
    @extend %col-lg-6;
    @extend %col-md-6;
    @extend %col-sm-11;
  }

  .job-tile {
    .job-main-data {
      @extend %align-self-md-stretch;
      @extend %align-items-md-start;
      @extend %align-self-lg-stretch;
      @extend %align-items-lg-start;
    }

    &.job-mo {
      .job-details-logo.logo-none {
        white-space: normal;
      }
    }

    .job-company-row {
      @include text-truncate();
      @extend %col-11;
      @extend .rm-p-l;
    }

    .job-details {
      @extend %col-xl-9;
      @extend %col-lg-12;
      @extend %col-md-12;

      // These things were added to fix the sizing issue in smaller screens (not mobile)
      flex: 0 1 auto;
      max-width: calc(100% - 72px);
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 90%;
    }
  }
}

// --------------------------------------------
//
//	BREAKPOINTS
//
// --------------------------------------------

// DESKTOP
// --------------------------------------------

@media (min-width: map-get($grid-breakpoints, "xl")) {
  .job-details-inner {
  }
  .job-result-tiles {
    .job-tile {
      .job-subtext-row {
        flex-wrap: nowrap;

        .job-location {
          //max-width: inherit
        }
      }
    }
  }
}

// -----------------------------------------
//	TABLETS
// ------------------------------------------

@media (min-width: map-get($grid-breakpoints, "xs")) and (max-width: map-get($grid-breakpoints, "md")) {
  // JOB DETAIL PANE - TOGGLE FUNCTIONALITY
  // -----------------------------------------

  .job-results-focus {
    .job-results-list,
    .job-alert-bar {
      padding: 0;
      margin: 0;
      flex: 0 0 0;
      width: 0;
      min-width: 0;
      z-index: -1;
    }
  }

  // REGION TOP BAR - SEARCH INPUTS
  // -----------------------------------------

  // Hide extra space
  .job-search-main {
    overflow: hidden;
  } // end .job-search-main
}

// ------------------------------------------
//	PHONES / 'TILE COLLAPSED' SCREENS
// ------------------------------------------

@media (max-width: map-get($grid-breakpoints, "sm")) and (orientation: portrait) {
  .job-results-list,
  .job-alert-bar {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
  .job-alert-bar {
    margin: 0;
    padding: 0;
    font-size: 100%;
    .job-alert-label {
      padding-left: $grid-gutter-width-sm;
    }
  }
  .top-mobile-action-buttons {
    display: flex !important;
  }
  .btn-mobile-back {
    display: block !important;
  }
  .nav-scroll {
    .header-content {
      padding-bottom: 0;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, "md")) and (orientation: landscape) {
  .job-results-list,
  .job-alert-bar {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
  .job-alert-bar {
    margin: 0;
  }
  .top-mobile-action-buttons {
    display: flex !important;
  }
  .btn-mobile-back {
    display: block !important;
  }
}

//	LARGER RESOLUTION PHONES
// ---------------------------------------
@supports not (hover: hover) {
  @media (max-width: map-get($grid-breakpoints, "xl")) and (orientation: portrait),
    (max-width: map-get($grid-breakpoints, "xl")) and (orientation: landscape) {
    .job-results-list,
    .job-alert-bar {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
    }
    .job-alert-bar {
      margin: 0;
    }
    .top-mobile-action-buttons {
      display: flex !important;
    }
    .btn-mobile-back {
      display: block !important;
    }

    // JOB DETAIL PANE - TOGGLE FUNCTIONALITY
    // -----------------------------------------

    .job-results-focus {
      .job-results-list,
      .job-alert-bar {
        padding: 0;
        margin: 0;
        flex: 0 0 0;
        width: 0;
        min-width: 0;
        z-index: -1;
      }
    }
  }
}
