// --------------------------------------------------------
//
//  ANIMATIONS
//  Framework 2.0.0+
//
// --------------------------------------------------------



// Icon Animation
// -----------------------------------

// Mixins

// CHECKBOX ICONS
// ---------------------------------
// Pass the color of the icon, then the icon 'content' from
// Font Awesome. (EX: "\f02e" for bookmark)
//@mixin on-state-mixin($color, $icon){
@mixin icon-input-on-state($color, $icon){
  label {
    box-shadow: 0px 0px 0px 0px rgba(red($color), green($color), blue($color), .5);
  }
  label:after {
    content: $icon;
  }
  input:checked + label {
    background-color: $color;
    border-color: $color;
    box-shadow: 0px 0px 0px .5em rgba(red($color), green($color), blue($color), 0);
    &:after {
      color: $color;
    }
  }
}

// INLINE ICONS
// ---------------------------------
// Pass the color of the icon, then the icon 'content' from
// Font Awesome. (EX: "\f02e" for bookmark)
//@mixin on-state-mixin($color, $icon){

//@mixin icon-on-state($color, $icon){
//  a {
//    box-shadow: 0px 0px 0px 0px rgba(red($color), green($color), blue($color), .5);
//  }
//  a:after {
//    content: $icon;
//  }
//  &:hover > a {
//    //background-color: $color;
//    //border-color: $color;
//    box-shadow: 0px 0px 0px .5em rgba(red($color), green($color), blue($color), 0);
//    &:after {
//     // color: $color;
//    }
//  }
//}

@mixin icon-on-state($color, $icon){

  & a:focus, & a:active {
    border: none !important;
    outline: 0 !important;
  }

  &.icon-off a {
    box-shadow: 0px 0px 0px 0px rgba(red($color), green($color), blue($color), .5);
  }
  
  a:after {
    content: $icon;
  }

  &.icon-on a {
    //background-color: $color;
    //border-color: $color;
    box-shadow: 0px 0px 0px .5em rgba(red($color), green($color), blue($color), 0);
    &:after {
     // color: $color;
    }
  }
}

// Keyframes

@keyframes icon {
  0% {
 // margin-top: -0.5em;
 // font-size: 1.5em;
  }
  100% {
 // font-size: 1em;
 //  opacity: 1;
 //   color: white;
  }
}

@keyframes check-in {
  0% {
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  80% {
    left: -5%;
    top: -5%;
    width: 110%;
    height: 110%;
  }
  100% {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes check {
  0% {
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
  }
  10% {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  80% {
    left: -5%;
    top: -5%;
    width: 110%;
    height: 110%;
  }
  90% {
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
  }
  100% {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}