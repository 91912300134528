
@mixin relative-text-box($text-box-width, $text-box-height, $text-percentage ){

$text-box-area: $text-box-width * $text-box-height;    
$text-decimal: $text-percentage / 100;

   width: #{$text-box-width}px;
   height: #{$text-box-height}px;
   font-size: calc(#{$text-box-area}px / 100 * #{$text-decimal});
   flex: 0 1 #{$text-box-width}px !important;
}


 

