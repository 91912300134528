//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-JOB-LISTING.SCSS

// This file includes all rules which affect the generic featured job listing module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

	
	#featured-job-listing {
		background: $bkgrdLtGrey;
		min-height: 400px;
		width: 57%;
		float:left;
		margin-right: 5px;
		box-sizing:border-box;
		
		ul {
			padding:10px;
			
			li {
				background: white;
				padding: 5px 10px;
				line-height: 1.4;
				margin-bottom: 10px;
				
				& > * {
					display:block;
				}
				
				em {
					font-style: normal;
				}
				
				a h3, a .h3 {
					color: $linkDefault;
					font-size: 1.3em;
					font-weight: 400;
					text-overflow: ellipsis;
					width: 100%; 
					white-space: nowrap;
					overflow:hidden; 

					&:hover {
						color: $linkHover;
					}
				}
			}		
		}
		
		.secondary {
			font-size: 1.1em;
			float: right;
			margin: 5px 20px 0 0;
		}
		
		@include breakpoint(medium) {
			
			ul {
				li {
					a {
						width: 100%;
					}
				}
			}
		}

		@include breakpoint(small) {
			width: 100%;
			
			.secondary {
				font-size: 1.2em;
				text-align: center;
				float: none;
				display: block;
				width: 70%;
				margin: 0 auto;
			}
		}
	}
	
