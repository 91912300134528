//////////////////////////////////////////////////////////////////////////////////////////

// _RECAPTCHA.SCSS

// This file includes all rules which affect the generic recaptcha component. The rules 
// in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////


.recaptcha-wrapper {	
	@include breakpoint(medium) {
		padding-top: 20px;
		
		#recaptcha_challenge_image {
			margin: 0 !important;
			width: 100% !important;
		}
		
		#recaptcha_response_field {
			margin: 0 !important;
			width: 100% !important;
		}
		
		.recaptchatable #recaptcha_image {
			margin: 0 !important;
			width: 100% !important;
		}
		
		#recaptcha_table img#recaptcha_logo {
			height: 20px;
			width: 40px;
		}
		
		.recaptchatable .recaptcha_r1_c1, 
		.recaptchatable .recaptcha_r3_c1, 
		.recaptchatable .recaptcha_r3_c2, 
		.recaptchatable .recaptcha_r7_c1, 
		.recaptchatable .recaptcha_r8_c1, 
		.recaptchatable .recaptcha_r3_c3, 
		.recaptchatable .recaptcha_r2_c1, 
		.recaptchatable .recaptcha_r4_c1, 
		.recaptchatable .recaptcha_r4_c2, 
		.recaptchatable .recaptcha_r4_c4, 
		.recaptchatable .recaptcha_image_cell {
			margin: 0 !important;
			width: 100% !important;
			background: none !important;
		}
	}	
}

// CAREERS-1979 Add Captcha to Email Apply Modal
.captcha-holder {
  overflow: hidden
}