// --------------------------------------------------
//
//		POSITION UTILITIES
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

.pos-f { position: fixed; }
.pos-s { position: sticky; }
.pos-r { position: relative; }
.pos-a { position: absolute; }

// Offsetting
.pos-t0 { top: 0; }
.pos-b0 { bottom: 0; }
.pos-l0 { left: 0; }
.pos-r0 { right: 0; }

// Z-indexes
// This is looping the array in variables and creating a utility class for each variable
@each $zindex in map-keys($zindex-list) {
  .z-#{$zindex} {
		z-index: #{$zindex};
  }
}