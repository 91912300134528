//////////////////////////////////////////////////////////////////////////////////////////

// 1.0 Imports

//////////////////////////////////////////////////////////////////////////////////////////

	@import "base";

////////////////////////////////////////////////////////

// 3.0 Variables

////////////////////////////////////////////////////////

    $fthin: 300;
    $fnormal: 400;
    $fmedium: 600;
    $fbold: 700;
    $fxbold: 900;

////////////////////////////////////////////////////////

// 3.0 Placeholders

////////////////////////////////////////////////////////

    %title {
        font-weight:$fmedium;
        margin: 0 0 20px;
	}

    %ymlist {
        padding:10px 0px;
        font-size:1.1em;
        font-weight:$fmedium;
	}

    %ymlistborder {
        border-top:1px solid $bkgrdLtGrey;
    }

	%ymlistbefore {
		font-family: FontAwesome;
        font-weight: $fnormal;
        font-style: normal;
        display: inline-block;
        text-decoration: inherit;
        content: "\f105";
        color:#aaaf37;
        font-size:14px;
        margin-right:10px;
	}

// 2.9 - Resources - Home

#resources-page {
    font-family: 'Open Sans', sans-serif;
    
    h2 {
        font-size:1.4em;
        font-weight:$fmedium;
        padding-bottom:5px;
        border-bottom:3px solid $bkgrdLtGrey;
        margin-bottom:20px;
    }
    
    .advice-search-input {
        border-top: 1px solid #e3e3e3;
        padding: 20px 0 0;
        width: 100%;
                
        .input-group {
            position: relative;
        }
        .search-form {
            width: 100%;
            border:1px solid #dddddd;
            height:30px;
            line-height: 30px;
            padding:1px 8px;
        }
        .search-btn {
            background: transparent;
            border: none;
            height: 30px;
            position: absolute;
            right: 0;
            top:0;
        }
    }
    .sr-only {
        display: none;
    }
    
    .pagination {
        border-radius: 4px;
        display: inline-block;
        margin: 20px 0;
        padding-left: 0;
        
        li {
            display: inline;
            
            a, span {
                background-color: #fff;
                border: 1px solid #ddd;
                color: #428bca;
                float: left;
                line-height: 1.42857;
                margin-left: -1px;
                padding: 6px 12px;
                position: relative;
                text-decoration: none;
                
                &:hover, &:focus {
                    background-color: #eee;
                    border-color: #ddd;
                    color: #2a6496;
                }
            }
            &.active {
                a, span, a:hover, a:focus, span:hover, span:focus {
                    background-color: #428bca;
                    border-color: #428bca;
                    color: $white;
                    cursor: default;
                    z-index: 2;
                }
            }
            &.disabled {
                a, span, a:hover, a:focus, span:hover, span:focus {
                    background-color: $white;
                    border-color: #ddd;
                    color: #999;
                    cursor: not-allowed;
                }
            }
            &:first-child a, &:first-child span {
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
                margin-left: 0;
            }
            &:last-child a, &:last-child span {
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
            }
        }
    }
    
    .featured-article {
        min-height: 299px;
        border-bottom:5px solid $white;

        .featured-welcome {
            width:33%;
            padding:25px;
            box-sizing: border-box;
            min-height: 299px;
            float:left;
            background:$bkgrdLtGrey;
            text-align: center;
            
            h1 {
                font-size:1.8em;
                line-height: 1.4;
                margin:0px auto 20px;
            }
            p {
                font-size: 1.2em;
                text-align: center;
                padding-bottom: 20px;
            }
        }
        
        .featured-image {
            border-left:5px solid $white;
            float:right;
            width:67%;
            min-height: 299px;
            box-sizing: border-box;
            background:url(articleimgs/img1.jpg) no-repeat center transparent;
            position: relative;
            
            .featured-image-text {
                background: rgba(0, 0, 0, 0.6);
                color: $white;
                padding: 20px 0 0;
                width: 350px;
                position: absolute;
                left:0;
                bottom:10%;
                overflow: hidden;
                
                em {
                    padding: 0px 25px 0;
                }
                p {
                    font-size:1.4em;
                    padding: 5px 25px 20px;
                    margin-bottom:0;
                }
                a {
                    background: $ym-green;
                    color: #fff;
                    display: block;
                    font-weight: $fmedium;
                    max-height: 0px;
                    line-height: 100px;
                    text-align: center;
                    text-transform: uppercase;
                    -webkit-transition: all 200ms linear;
                    transition: all 200ms linear;
                }
                &:hover a {
                    max-height: 39px;
                    line-height: 39px;
                }
            }
        }
    }
    
    .article-images {
        padding-bottom:25px;
        
        .article-image {
            float: left;
            width: 24.625%;
            margin-right:.5%;
            height: 150px;
            position: relative;
            
            &:nth-of-type(1) {
                background:url(articleimgs/img2.jpg) no-repeat right top transparent;
                background-size:330px auto;
            }
            &:nth-of-type(2) {
                background:url(articleimgs/img3.jpg) no-repeat right top transparent;
                background-size:330px auto;
            }
            &:nth-of-type(3) {
                background:url(articleimgs/img4.jpg) no-repeat left top transparent;
                background-size:330px auto;
            }
            &:nth-of-type(4) {
                background:url(articleimgs/img5.jpg) no-repeat center top transparent;
                background-size:330px auto;
            }
            
            .article-image-text {
                position: absolute;
                bottom:0;
                width:100%;
                text-align:center;
                color:$white;
                background: rgba(0, 0, 0, 0.6);
                overflow: hidden;
                
                p {
                    padding:15px;
                    margin-bottom:0;
                    min-height: 40px;
                    -webkit-transition: all 200ms linear;
                    transition: all 200ms linear;
                }
                a {
                    display: block;
                    padding:0 15px;
                    background:$ym-green;
                    line-height: 1;
                    color:$white;
                    max-height: 0px;
                    line-height: 100px;
                    text-transform: uppercase;
                    font-weight:$fmedium;
                    -webkit-transition: all 200ms linear;
                    transition: all 200ms linear;
                }
                &:hover a { 
                    max-height: 39px;
                    line-height: 39px;
                }
            }
            
            &:last-child {
                margin-right:0;
            }
        }
    }
    aside.pull-right {
        float:right;
        width:32%;
    }
    #article-listing {
        float: left;
        width: 68%;
        
        .article-section {
            display:block;
            float:left;
            width: 46%;
            margin-bottom:40px;
            
            p {
                font-size:1.1em;
                line-height: 1.5;
            }
            li {
                @extend %ymlist;
                @extend %ymlistborder;
                
                &:before {
                    @extend %ymlistbefore;
                }
                
                &:first-child {
                    border-top:none;
                }
            }
            span {
                &.navigate-icon {
                    display: inline-block;
                    height: 17px;
                    width: 17px;
                    background:url(img/navigate.png) no-repeat center center transparent;
                    vertical-align: middle;
                }
            }
            
            &:nth-of-type(odd) {
                margin-right:4%;
                clear: both;
            }
        }
    }
    .sideboard-ad {
        margin-bottom:0px;
        
        img {
            display: block;
            width: 100%;
            max-width: 295px;
        }
    }
    .social-share-right {
        margin-bottom: 10px;
    }
    
    // 2.9.1 - Resources - Article Sub-Categories

    .siderail-advice-search {
        border-top: 0 none;
        float: right;
        margin-bottom: 25px;
        width: 32%;
        margin-bottom: 0;
        
        .advice-search-input {
            border-top: none;
            padding:0;
            
            .search-form {
                border: 1px solid $bkgrdLtGrey;
            }
        }
    }
    .page-title {
        margin-bottom:25px;
        
        h1 {
            @extend %title;
        }
        p {
            font-size: 1.3em;
            font-weight: $fthin;
        }
    }
    #article-links-featured {
        float: left;
        width: 68%;
        
        .article {
            padding-right:25px;
            
            .featured-image {
                display: block;
                float: left;
                min-height: 290px;
                width: 64%;

                &.resume {
                    background: transparent url("articleimgs/img1.jpg") no-repeat center center;
                }
                &.interview {
                    background: transparent url("articleimgs/img3.jpg") no-repeat right top;
                }
                &.advancement {
                    background: transparent url("articleimgs/img4.jpg") no-repeat center center;
                }
                &.navigate {
                    background: transparent url("articleimgs/img5.jpg") no-repeat center center;
                }
            }
            .featured-text {
                float: right;
                width: 36%;
                padding:25px;
                box-sizing: border-box;
                min-height: 290px;
                background:$bkgrdLtGrey;
                
                h3 {
                    font-size: 1.5em;
                    font-weight: $fmedium;
                    line-height: 1.4;
                    margin-bottom:15px;
                }
                p {
                    font-size: 1.1em;
                    line-height: 1.3;
                    margin-bottom:15px;
                }
                a {
                    font-weight: $fmedium;
                    text-transform: uppercase;
                }
            }
        }   
    }
    .articles {
        margin-bottom:25px;
        
        h2 {
            border-bottom:none;
            margin-bottom:0;
            padding-bottom:20px;
            
            a {
                float: right;
                font-size: 0.8em;
                line-height: 2;
                text-transform: uppercase;
            }
        }
        
        .article-boxes {
            text-align: justify;
            
            .article-box {
                border:1px solid $bkgrdLtGrey;
                display: inline-block;
                margin: 0 .5%;
                max-width: 305px;
                text-align: left;
                vertical-align: top;
                width: 31%;
                box-sizing: border-box;
                
                .img {
                    display: block;
                    height: 137px;
                    width: 100%;
                    overflow: hidden;
                    
                    img {
                        width: 100%;
                        display: block;
                    }
                }
                .text {
                    padding: 20px;
                    font-size:1.1em;
                    font-weight:$fmedium;
                    min-height: 95px;
                    box-sizing: border-box; 
                    border-bottom:3px solid $bkgrdLtGrey;
                    -webkit-transition: all 200ms linear;
                    transition: all 200ms linear;
                }
                &:hover {
                    background:$bkgrdLtGrey;
                    
                    .text {
                        border-bottom:3px solid #AAAF37;
                    }
                }
            }
            .stretch {
                width: 100%;
                display: inline-block;
            }
        }
    }
    
    // 2.9.1 - Resources - Article Sub-Category Libraries
    
    .library {
        box-sizing: border-box;
        float: left;
        padding-right: 25px;
        width: 68%;
        
        .categories {
            padding-bottom:15px;
            margin-bottom:25px;
            border-bottom:3px solid $bkgrdLtGrey;
            
            h2 {
                font-size:1.7em;
                margin-bottom:20px;
                border-bottom:none;
            }
            li {
                float: left;
                margin-right: 5%;
                width:45%;
                @extend %ymlist;
                
                &:before {
                    @extend %ymlistbefore;
                }
                &:nth-of-type(3) {
                    @extend %ymlistborder;
                }
                &:nth-of-type(4) {
                    @extend %ymlistborder;
                }
            }
        }
        nav {
            text-align: right;
        }
        .library-article {
            margin-bottom:10px;
            position: relative;
            min-height: 100px;
            overflow: hidden;
            
            &:nth-of-type(odd) {
                background:$bkgrdLtGrey;
            }
            &.featured {
                .img {
                    float: left;
                    max-width: 145px;
                    overflow: hidden;
                    position: absolute;
                    width: 145px;
                    
                    img {
                        float: right;
                        max-height: 135px;
                        margin-right: -55px;
                    }
                }
                .text {
                    float: left;
                    margin-left: 145px;
                    padding: 20px;
                }
            }
            .text {
                float: none;
                margin-left: 0px;
                padding: 15px 20px;
                
                h3 {
                    font-size: 1.2em;
                    font-weight: $fmedium;
                    margin-bottom: 5px;
                }
            }
        }
    }
    
    // 2.9.1 - Resources - Articles
    
    .article-title {
        margin-bottom:25px;
        
        h1 {
            @extend %title;
        }
        p {
            font-size: 1.3em;
            font-weight: $fthin;
        }
        em {
            font-size: 1.1em;
            font-style: normal;
            font-weight: $fmedium;
        }
    }
    #article-image {
        .img {
            float:left;
            width:68%;
            padding-right:25px;
            box-sizing: border-box;
            
            img {
                width: 100%;
                border: 1px solid $bkgrdLtGrey;
                display: block;
            }
        }
    }
    .article-content {
        h2 {
            border-bottom:none;
            margin-top:15px;
            margin-bottom:0;
        }
        p {
            margin-bottom:20px;
            font-size:1.2em;
            font-weight:$fthin;
        }
        ul {
            padding-left:50px;
            
            li {
                list-style: disc;
            }
            p {
                margin-bottom:10px;
            }
        }
        ol {
            padding-left:50px;
            list-style: decimal;
            margin:30px 0;
            
            li {
                color: rgba($ym-navy, .25);
                font-size: 1.8em;
                font-style: italic;
                font-weight: $fbold;
                margin-bottom: 15px;
                
                p {
                    color: #535353;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: $fthin;
                    display: inline-block;
                    vertical-align: top;
                    padding-left:8px;
                    margin-bottom:0;
                }
            }
        }
        .callout {
            background: $bkgrdLtGrey;
            border-top: 3px solid #E8E9EB;
            border-bottom: 3px solid #E8E9EB;
            padding: 20px;
            margin-top:40px;
            
            h3 {
                font-size:1.2em;
                text-transform: uppercase;
                color:#B9BFC2;
            }
            p {
                font-size:1em;
                margin: 10px 0 0;
                font-weight:$fnormal;
            }
        }
    }
}

@media all and (max-width:$widePage) { 
    #resources-page {
        .featured-article .featured-welcome {
            h1 {
                font-size: 1.6em;
                margin: 0 auto 15px
            }
            p {
                font-size: 1.1em;
            }
        }
        #article-links-featured {
            margin-bottom: 25px;
            
            .article .featured-text {
                padding: 20px;
                
                h3 {
                    font-size: 1.2em;
                } 
                p {
                    font-size: 1em;
                }
            }
        }
    }
}
@media all and (max-width:$medium) {
    #resources-page {
        .interior {
            box-sizing: border-box;
            padding: 5px 15px;
        }
        aside.pull-right {
            margin-right:0;
        } 
        .pagination {
            width:auto;
            
            li + li {
                padding-left: 0px;
            }
        }
    }
}
@media all and (max-width:$mediumtab) {
    #resources-page {
        .sideboard-ad {
            display: inline-block;
            margin: 0 2%;
            max-width: 315px;
            vertical-align: top;
        }
        .featured-article {
            .featured-welcome {
                float: none;
                min-height: auto;
                width:100%;
            }
            .featured-image {
                border-left: none;
                float: none;
                margin: 5px auto 0;
                max-width: 450px;
                width: 100%;
            }
        }
        .article-images {
            margin: 0 auto;
            text-align: center;
            width: 80%;
            
            .article-image {
                display: inline-block;
                float: none;
                width:100%;
                max-width: 223px;
                margin-right: 0;
            }  
        }
        #article-listing {
            float: none;
            width: 100%;
            padding-right:0;
        }
        #article-library {
            margin-top:0;
            
            .library {
                float: none;
                width: 100%;
                padding-right:0;
            }
        }
        .article-section {
            width: 48%;
        }
        aside.pull-right {
            float: none;
            margin: 0 auto;
            width: 100%;
            max-width: 100%;
            text-align: center;
            
            .sideboard-ad:nth-of-type(1) {
                margin-bottom:15px;
            }
        }
        #article-links-featured {
            float: none;
            width: 100%;
            
            .article {
                padding-right: 0;
                
                .featured-image {
                    width: 54%;
                } 
                .featured-text {
                    padding: 25px;
                    width: 46%;
                    
                    h3 {
                        font-size: 1.4em;
                    } 
                    p {
                        font-size: 1.1em;
                    }
                }
            }
        } 
        .articles .article-boxes .article-box {
            display: block;
            margin: 0 0 15px;
            max-width: 100%;
            width: 100%;
            height:auto;
            overflow: hidden;
            
            .img {
                float: left;
                height: 100px;
                overflow: hidden;
                width: 30%;
                
                img {
                    float: left;
                    width: 305px;
                    margin-left: -35px;
                }
            }
            .text {
                float: right;
                min-height: 100px;
                padding: 15px;
                width: 70%;
            }
        }
        #article-library nav {
            text-align: center;
        }
        .categories.clearfix li {
            width: 45%;
        }
        #article-image aside.pull-right {
            float: right;
            width: 32%;
        }
    }
}
@media all and (max-width:$six-eighty) {
    #resources-page {
        .article-images {
            width: 100%;
        }
        #article-image {
            .img {
                float: none;
                margin-bottom: 25px;
                padding-right: 0;
                width: 100%;
            }
            aside.pull-right {
                display:none;
            }
        }
    }
}
@media all and (max-width:$small) {
    #resources-page {
        .featured-article {
            .featured-image {
                min-height: 200px;
                background-position: right top;
                
                .featured-image-text {
                    width:100%;
                    bottom: 0;
                    padding-top:0;
                    
                    em {
                        display: none;
                    }
                    p {
                        font-size: 100%;
                        padding: 15px;
                    }
                }
            }
        }
        .article-images {
            .article-image {
                max-width: 100%;
                height: 175px;
                background-size: 450px auto !important;
                background-position: center top;
                
                .article-image-text p {
                    min-height: 0px;
                }
            } 
        }
        #article-listing .article-section {
            width: 100%;
            
            &:nth-of-type(2n+1) {
                margin-right: 0;
            }
        }
        .article-box .img > img {
            margin-left: -55px;
        }
        #article-links-featured .article {
            .featured-image {
                min-height: 150px;
                width: 100%;
                float:none;
                background-size:480px auto !important;
            }
            .featured-text {
                float: none;
                min-height: auto;
                width: 100%;
            }   
        } 
        .articles { 
            h2 a {
                display: block;
                padding: 0 0 15px;
                text-align: left;
                width: 100%;
            } 
            .article-boxes .article-box {
                .img > img {
                    width: 215px;
                    margin-left: -55px;
                }
                .text {
                    padding: 10px;
                    line-height: 1.3;
                }
            }
        }
        .categories.clearfix li {
            float: none;
            margin-right: 0;
            width: 100%;
            
            &:nth-of-type(3) {
                border-top: none;
            }
        }
        .library {
            .library-article {
                min-height: auto;
                
                &.featured {
                    .img img {
                        display:none;
                    } 
                    .text {
                        float: none;
                        margin-left: 0;
                    }
                }
            }
        }
        .article-content ol {
            padding-left: 25px;
        }
        .article-content ul {
            padding-left:25px;
        }
    }
}
@media all and (max-width:$xsmall) {
    #resources-page {
        .featured-article .featured-image {
            min-height: 150px;
        }
        .article-images .article-image {
            height: 150px;
        }
        .articles .article-boxes .article-box {
            .img {
                display: none;
            }
            .text {
                min-height: auto;
                width: 100%;
            }
        }
        .library .library-article .text h3 {
            font-size: 1.1em;
            line-height: 1.3;
        }
        #article-image .img {
            margin-bottom: 15px;
        }
    }
}