// --------------------------------------------------
//
//		JOB TILES
//		Framework 2.0.0+
//
// --------------------------------------------------

// Individual tile
.job-tile {
  @extend %col;
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  // z-index: 1;
  @extend .shadow-sm;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover,
  &:focus {
    @extend .shadow;
    cursor: pointer;
    // transform: translate($grid-gutter-width-xs * -1, $grid-gutter-width-xs * -1);
  }

  .job-details {
    //	padding: 0 $grid-gutter-width-xs 0 $grid-gutter-width-sm;
  }

  &-active {
    background-color: $white;
  }

  .job-title {
    max-width: 88%;
    @extend .h6;
    @extend .rm-m-b;
    font-size: 14px;
    line-height: 0.962rem !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
    color: #222222;
  }

  .bookmark {
    @extend %col;
    @extend %col-auto;
    @extend .align-self-start;
    @extend .rm-p-l;
    font-size: 18px;

    &.bookmarked {
      color: currentColor;
    }
    &.icon-off a {
      color: #6b6b6b;
    }
    a {
      color: var(--site-primary-color, #1badba);
      cursor: pointer;
      &:hover {
        color: var(--site-primary-color, #1badba);
      }
    }
  }

  .job-posted-date {
    &:before {
      font-family: FontAwesome;
      content: "\f017";
      margin-right: $grid-gutter-width-xs;
    }
  }

  .external-posting {
    font-style: italic;
  }

  .external-posting {
    padding-top: 3px;
  }

  + .job-tile {
    margin-left: $grid-gutter-width-sm;
  }
}

// JOB RESULTS LIST
// -------------------------

.job-results-list {
  .job-tile {
    border-right: none;
    border-top: none;
    border-bottom: 1px solid $gray-500 !important;
    border-radius: 0;
    box-shadow: none !important;

    .job-details {
      //max-width: 81%;
    }

    &:hover,
    &:focus {
      background: $white;
      transition: background 0.3s ease-in-out;
      box-shadow: none !important;
      // transform: translate(0,0);
    }

    // For Job List on Job Search
    &:not(.job-tile-active) {
      cursor: pointer;
    }

    &.job-tile-active {
      cursor: default !important;
    }

    + .job-tile {
      margin-left: 0;
    }
  }
}

// ------------------------------------------
//	JOB SEARCH COURSE TILES IN SEARCH
// ------------------------------------------

.job-results-list .job-tile.js-course-tile .job-details {
  flex-grow: 1;
}
