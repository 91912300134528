//////////////////////////////////////////////////////////////////////////////////////////

// 2.0 - Page Specific Styles
	
//////////////////////////////////////////////////////////////////////////////////////////

// 2.1 - JT-Home


// 2.1.1 - #home-page

#main-content>.interior {
	position: relative;
}
	
#home-page {
	
	p {
		font-size: 1.1em;
	}
	
	section {
		padding-top: 25px;
		
		.dotted-rule {
			float: right;
			margin-top: 17px;
			display: none;		
		}
	}
		
	.featured-job-promo .sideboard-adspace {
		text-align: center;
	}	
	
	@include breakpoint(small) {
		
		#featured-job-listing {
			float:none;
			display:block;
		}
		
		.featured-job-promo {
			float: none;
			display: block;
			width: 100%;
			max-width: 100%;
			height: auto;
			margin: 20px 0;

			.sideboard-adspace {
				display: block;
				width: 100%;
				max-width: 320px;
			}
		}
	}
}


// 2.1.2 - #featured-jobs

#featured-jobs {

	.dotted-rule {
		min-width: 82%;
		
		@include breakpoint(medium) {
			min-width: 77%;
		}
		@include breakpoint(small) {
			min-width: 65%;
		}
	}
	
	.interior {
		background: none;
	}
}

// 2.1.3 - #blank-module

#blank-module {

	.dotted-rule {
		min-width: 82%;
		
		@include breakpoint(medium) {
			min-width: 78%;
		}

		@include breakpoint(small) {
			min-width: 65%;
		}
	}
	
	.interior {
		padding: 10px 15px;
	}
}

// 2.1.4 - #featured-services

#featured-services {

	.dotted-rule {
		min-width: 77%;
		
		@include breakpoint(medium) {
			min-width: 72%;
		}

		@include breakpoint(small) {
			min-width: 55%;
		}
	}
}

// 2.1.5 - #featured-employers

#featured-employers {

	.dotted-rule {
		min-width: 75%;
		
		@include breakpoint(medium) {
			min-width: 69%;
		}

		@include breakpoint(small) {
			min-width: 50%;
		}
	}
}



// 2.2 - JT-Results

// 2.2.1

#results-page {

	.interior {
		padding-top: 0;
		.results-content{
			.search-info{
				padding-bottom: 10px;
			}
		}
		@include breakpoint(small) {
			.breadcrumbs, .filters, #job-listing {
				padding-top: 20px;
				border-top: thin dotted $miscLtGrey;
			}
			
			.breadcrumbs {
				padding-left: 0;
				padding: 15px 0;
				box-sizing: border-box;
				float: none;
			}
		}
	}
	
	aside {
		
		i, em {
			float: right;
			font-style: normal;
		}
		
		.sideboard-adspace {
			h5, .h5 {
				padding-bottom: 10px;
			}
						
			em {
				font-size: 1.2em;
				float: none;
			}
			div {
				padding-bottom: 0;
			}
			div > div {
				height: 200px;
			}			
		}

		
		@include breakpoint(small) {
			float: none;
			display: block;
			width: 100%;
			max-width: 100%;
			
			.sideboard-adspace {
				display: block;
				width: 100%;
				max-width: 350px;
			}
			
			.filters {
			
				p {
					b {
						display: none;
					}
					
					i {
						float:left;
						padding-left: 10px;
					}
				}
			}
		}
	}	
								
	.filtered-job-alert {
		font-size: 1.2em;
		text-align: center;
		border-bottom: thin dotted #cccccc;
		margin-bottom: 30px;
	}
	.breadcrumbs {
		float: left;
		padding: 15px 0 30px;
	}
	.results-content {
		clear: both;
	}
}



// 2.3 - JT-View

// 2.3.1

#job-view-page {

	.interior {
		padding-top: 0;
		
		@include breakpoint(small) {
			box-sizing: border-box;
			
			.breadcrumbs, .filters, #job-listing, #job-detail-view, #expired-job {
				padding-top: 20px;
				border-top: thin dotted $miscLtGrey;
				
				.job-data-basics {
					label {
						float:none;
						display:block;
					}
					
					a {
						width: 100%;
						padding-top: 0;
					}
				}
			}
			
			.breadcrumbs {
				padding-bottom: 20px;
				padding-left: 10px;
			}

			#job-detail-view {
				font-size: 1.3em;
				
				h3, .h3 {
					line-height:1.1;
					font-size: 1.3em;
				}
			}
			
			aside {
				display: block;
				float:none;
				width: 100%;
				padding: 5px;
				
				.company-logo-large {
					border: none;
					display: block;
					height: auto;
					width: 100%;
				}
				
				.job-data-basics {
					font-size: 1.3em;
				}
			}
		
		}		
			
	}
	
	&.expired {
		.company-contact {
			display: none;
		}
	}
}


// 2.4 - JT-alert-activated

.activation-message {
	p {
		color:$textDkGrey;
		font-size: 1.2em;
	}
	p + p {
		padding: 20px 0 5px;
	}
}

// 2.5 - JT Privacy Policy

	#basic-page .privacy-policy {
		width: 100%;
		
		section.no-padding {
			padding-top: 0;
		}
		
		a {
			word-break: break-word;
		}
		
		section h4:first-of-type, section .h4:first-of-type {
			padding-top: 10px;
		}
		
		address > * {
			font-size: 1.2em;
		}
		
		@include breakpoint(small) {
			h1 {
				font-size: 1.8em;
			}
		}
	}
	
	.safe-harbor {
		float: right;
		padding:10px;
		margin-left: 10px;
		background: $bkgrdLtGrey;
		
		span {
			padding-bottom: 10px;
		}
		
		img {
			border:thin solid $miscLtGrey;
		}
	}
	
	// 2.6 - JT Help Page

	#basic-page .help {
		width: 100%;
		
		a[href*="seeker_help_questns"] {
			display:none;
		}
		
		div[id*="q"] strong {
			font-size: 1.2em;
		}
		
		h5, .h5 {
			background: none repeat scroll 0 0 #f5f5f5;
			display: inline-block;
			font-size: 1.2em;
			padding: 10px 20px 20px;
			margin-bottom: 10px;
		
			span {
				font-weight: 400;
				padding: 20px;
				margin-top: 5px;;
				background: $white;
			}
		}
		
		h5 + h5, .h5 + .h5 {
			margin-left: 20px;
		}
		
		section + section {
			padding-top:50px;
			h2 + p {
				padding: 20px 10px;
			}
		}
		
		.default-form-box {
			padding-top: 40px;
			
			@include breakpoint(small) {
				fieldset, select, input {
					width: 100%;
					box-sizing: border-box;
				}
			}
		}
		
		.listing-detail {
			
			* {
				box-sizing: border-box;
			}
			
			div {
				border: none;
				width: 95%
			}
			
			label {
				width: 30px;
				font-weight: 700;
			}
			
			span {
				padding: 0;
			}
			
			span + span {
				padding-bottom: 10px;
			}
			
			fieldset {
				width: 100%;
				padding-left: 0;
		
				textarea {
					padding: 10px;
					min-height: 70px;
				}
			}
			
			button {
				float: right;
				margin: 20px 0 10px;
			}
		}
		
		table {

			tr + tr {
				background: #fcfcfc;
				border-bottom: none;
				border-top: 4px solid #ffffff;
			}
			
			tr + tr.listing-detail {
				border-top: none;
			}
		}
	
		.recaptcha-wrapper {
			padding-bottom: 20px;
		}
		
		@include breakpoint(medium) {
			padding: 5px;
			box-sizing:border-box;
			
			h1 {
				padding: 0;
			}			
		}
		
		@include breakpoint(small) {
			table .col-width-g {
				width: 40%;
			}
			table .col-width-c {
				width: 35%;
			}
		}
	}
	


	
	
	// 2.7 - JT Login Page
	
	#basic-page {
	
		.login-newAccount {
			width: 100%;
		
			section {
				padding-top: 0;
			}
		
			h1 em {
				color: $textMdGrey;
				font-size: .7em;
				font-weight: 400;
				font-style: normal;
				padding-left: 10px;
				
				@include breakpoint(small) {
					font-size:.6em;
					display: block;
				}
			}
		
			h3, .h3 {
				text-transform: uppercase;
				padding-bottom: 15px;
			}
		
			h2 {
				border-top: none;
				border-bottom: none;
				
				@include breakpoint(small) {
					font-size: 1.2em;
				}
			}

			i {
				font-style: italic;
			}

			.btn-social .fa-brands {
				margin-right: 0.4em;
				font-style: normal;
			}

			.default-form-box {
				padding: 15px 40px 20px;
				
				fieldset {
					width: 100%;
					box-sizing: border-box;
					padding-right: 0;
					float: none;
				}

				.new-account {
					.name, .password {
						width: 49%;
						float: left;
					}
					.name + .name, .password + .password {
						margin-left: 2%;
					}
				}

				@include breakpoint(medium) {
					padding-left: 20px;
					padding-right: 20px;
					.new-account {
						.name, .password {
							width: 100%;
							float: none;
						}
						.name + .name, .password + .password {
							margin-left: auto;
						}
					}
				}

				@include breakpoint(small) {
					padding: 10px;

					.new-account {
						.name, .password {
							width: 49%;
							float: left;
						}
						.name + .name, .password + .password {
							margin-left: 2%;
						}
					}
					form {
						padding-top: 0;
					}
				}

				.form-fields {
					
						@include breakpoint(small) {
						
							fieldset {
								width: 100%;
							}
							
							input[type="text"], input[type="email"], input[type="password"] {
								box-sizing: border-box;
								width: 100%;
							}
						}
				}
								
				fieldset.save-login {
					padding-top: 0.6em;
					float: left;
					padding-bottom: 0;
				}
			
				fieldset.member-indicator {
					padding: 0 0 20px;
				}
			
				input[type="text"], input[type="email"], input[type="password"] {
					width: 100%;					
				}			
			}	

			.primary {
				margin-top: 20px;
				
				@include breakpoint(medium) {
					margin-top: 40px;
				}
				
				@include breakpoint(small) {
					width:100%;
					margin-top: 20px;
				}
			}
		}
		
		.login-newAccount.avectra {
			
			h1 + span {
				font-size: 1.2em;
			}
			
			.default-form-box {
				padding-top: 40px;
				
				fieldset.call-to-action {
					float: right;
					padding-right: 0;
					
					button {
						float: none;
					}
					
					span {
						padding-top: 10px;
					}
				}
			}
		}
	}
	
	// 2.7.1 - Member Validation Page 
	
		#basic-page .member-validation {
	
		.default-form-box {
			padding-bottom: 20px;
		
			form {
				padding-bottom: 10px;
			}
			
			span {
				padding-bottom: 20px;
				
				em {
					color: $textMdGrey;
					font-style: normal;
					font-weight: 400;
					padding-left: 10px;
				}
			}
			
			fieldset {
				padding-right: 3%;
				width: 30%;
			}
			
			input, select {
				display: block;
				margin-top: 5px;
				width: 100%;
			}
			
			.primary {
				margin: 23px 0 0 30px;
    			padding: 13px 35px;
    			width: 100%;
			}
			
			a.cancel {
				display: block;
				clear: both;
				width: 100%;
				text-align: center;
				padding-top: 20px;
			}
			
			@include breakpoint(medium) {
				fieldset {
					width: 45%;
				}
				fieldset:last-of-type {
					width: 100%;
					margin-right: 0;
				}
			}
			
			@include breakpoint(small) {
				fieldset {
					width: 100%;
					padding-right: 0;
				}
				
				.primary {
					margin-left: 0;
				}
			}
		}
	
	}

	
	// 2.7.5 - Dual Login Page
	
	#basic-page {
		
		.dual-login {
			width: 100%;
			
			section {
				box-sizing: border-box;
				width: 100%;
				padding: 20px;
				background: $bkgrdLtGrey;
				margin-bottom: 30px;
			}
			
			h1 {
				padding-bottom: 10px;
			}
		
			h2 {
				font-size: 1.5em;
				border: none;
				padding-top: 0;
				border-bottom: thin solid $miscLtGrey;
			}
			
			p {
				padding: 20px 0 30px;	
			}
			
			.call-to-action {
				font-size: 1.2em;
				font-weight: 400;
				min-width: 150px;
				text-align:center;
				
				@include breakpoint(small) {
					width: 100%;
					float:none;
					box-sizing: border-box;
				}

			}
			
			@include breakpoint(medium) {
				section {
					margin: auto;
				}
				
				section + section {
					margin-top: 20px;
				}
			}		
		}
	}
	
	// 2.7.8 - Reset Password Page
	
	#basic-page .reset-password {
		width: 100%;
		em {
			font-size: 1.1em;
			font-style: normal;
			padding-bottom: 20px;
			display: block;
		}
			
		.default-form-box {
			padding-top: 10px;
			
			input {
				width: 300px;
			}
			
			label {
				padding-bottom: 10px;
			}
			
			section {
				float:left;
				padding-right: 30px;
			}
			
			section + section {
				padding-right: 0;
				padding-bottom: 50px;
			}

			button {
				clear: both;
				font-size: 1.2em;
				letter-spacing: .02em;
			}
			
			.recaptcha_attribution {
				text-align: right;
			}
			@include breakpoint(small) {
				section {
					float:none;
					padding-right: 0;
				}
				
				fieldset {
					width: 100%;
				
					input {
						box-sizing: border-box;
						width: 100%;
					}
				}
				
				button {
					width: 100%;
				}
			}

		}
		
	}
	
	// 2.7.9 - Signed-Out Reset Password Page
	
	#basic-page .signed-out-reset-password {
	
		.default-form-box {
			padding-bottom: 20px;
		
			form {
				padding-bottom: 10px;
			}
			
			span {
				padding-bottom: 20px;
				
				em {
					color: $textMdGrey;
					font-style: normal;
					font-weight: 400;
					padding-left: 10px;
				}
			}
			
			fieldset {
				padding-right: 30px;
			}
			
			input {
				display: block;
				margin-top: 5px;
			}
			
			.primary {
				margin: 22px 0 0 30px;
    			padding: 12px 35px;
			}
		}
	
	}
	


	// 2.8 - Job Alert Page

	#basic-page .job-alerts {

	.default-form-box {

		
		h3, .h3 {
			font-size: 1.5em;
			font-weight: 400;
		}

		section {
			border-bottom: thin dotted $textLtGrey;
			padding: 20px 0 20px;
		}
		
		section + section {
			padding-top: 30px;
			margin-bottom: 20px;
		}
		
		section.no-padding {
			padding-top: 0;
		}
		
		select, select[multiple], input[type="text"] {
			width: 100%;
		}

		.alert-filters input {
			width: 100%;
		}

		fieldset {
			padding-right: 0;
			width: 100%;
			box-sizing: border-box;
			max-width: 420px;
			float: none;
		}
		.fieldset_checkbox label {
			display: inline-block;
			vertical-align: bottom;
		}
		.fieldset_checkbox input {
			width: 20px;
		}
		.fieldset_radio {
			input {
				width: 15px;
			}
			label {
				display: inline-block;
				padding: 0 20px 0 5px;
			}
			label:first-of-type {
				display: block;
				padding: 0;
				margin-bottom: 10px;
			}
		}

		/*
		
		fieldset.no-padding {
			padding-right: 0;
		}
		
		*/
		em {
			font-style: normal;
			padding: 0 20px 0 5px;				
		}

		.frequency {
			span {
				padding-top: 10px;
			}
		}
		
		button {
			margin-top: 10px;
		}
		
		select + span {
			font-size: .85em;
		}
	}
		
	section {
		padding-top: 40px;
	}
	
	@include breakpoint(medium) {
		.default-form-box  {	
			fieldset.frequency {
				padding: 0;
			}
		}
	}
	
	@include breakpoint(small) {
		padding: 10px;
		box-sizing: border-box;
		
		.default-form-box  {
			fieldset {
				width: 100%;
			}
			select {
				width: 100%;
			}
			
			input[type="text"] {
				width: 100%;
				box-sizing: border-box;
			}
		}	
	}
}

	// 2.9 - Resources Page

#basic-page .resources {

	section {
		padding-bottom: 10px;
		width: 75%;
		float: left;
	}
	
	aside {
		float: right;
		box-sizing: border-box;
		font-size: .85em;
		width: 20%;
		max-width: 100%;
		background: $bkgrdLtGrey;
		margin-top: 20px;
		margin-right: 0;
		padding: 2%;
		text-align: center;
		
		b {
			font-size: 1.4em;
			padding-bottom: 20px;
			display: block;
			border-bottom: thin solid $miscLtGrey;
		}
		
		span {
			padding-top: 20px;
		}
		
		em > span {
			padding-top: 0;
		}
		
		img {
			border: 5px solid $white;
			width: 65%;
		}
		
		ul li {
			padding-bottom: 20px;
		}
	}
	
	ul li {
		padding: 0 0 5px;
		font-size: 1.2em;
	}
	
	ul.bulleted {
	 	list-style-type: disc;
	 	list-style-position: inside;
	 	list-style-image: none;
	 	padding-bottom: 10px;
	 	
	 	li {
	 		float:left;
	 		width: 300px;
	 	}
	}
	strong {
		font-size: 1.3em;
		display: block;
		padding: 10px 0;
	}
	
	.call-to-action {
		font-size: 1.2em;
		width: 200px;
		text-align: center;
		margin-top: 10px;
	}
	
	@include breakpoint(medium) {
		box-sizing: border-box;
		padding: 10px;
	}
	
	@include breakpoint(small) {
		padding: 5px;
		
		aside {
			float: none;
			width: 100%;
			
			img {
				width: 20%;
			}
		}
		
		section {
			float: none;
			width: 100%;
		}
		
		.call-to-action {
			box-sizing: border-box;
			width: 100%;
			float: none;
		}
	}
}

	// 2.10 - Ask an Expert Page

	#basic-page .expert {

		section {
			padding-bottom: 10px;
		}
	
		p + p {
			padding-bottom: 20px;
		}
	
		em {
			font-style: normal;
		}
	
	.our-experts {
		background: $bkgrdLtGrey;
		padding: 30px 30px 20px;
		margin-bottom: 20px;
	
		b {
			color: black;
			display: block;
			font-size: 1.7em;
			font-weight: 400;
			padding-bottom: 10px;
		}
	
		h6, .h6 {
			font-size: 1.25em;
			padding-bottom: 5px;
		}
		
		ul {
			text-align: center;
		}

		li {
			display: inline-block;
			text-align: center;
			padding: 10px;
			margin-bottom: 15px;
			background: $white;
			width: 30%;
			margin-right: 15px;
			box-sizing: border-box;
		
			div {
				display: inline-block;
				padding-left: 5px;
				width: 100%;
			}
		}
	
		img {
			border: thin solid $textMdGrey;
			vertical-align: top;
		}
	}

	.default-form-box {	
		padding-bottom: 30px;
	
		form {
		
			i {
				float: left;
			}
		
			em {
				padding: 0 20px 0 5px;
			}
		}
	
		form + i {
			text-align: center;
			padding-top: 30px;
		}
	
		textarea {
			padding: 10px;
			width: 97%;
		}
	}
	
	@include breakpoint(medium) {
		box-sizing:border-box;
		padding: 0 10px;
		
		.default-form-box {

			textarea {
				width: 94%;
			}
		}
		
		.our-experts {
		  margin: 20px auto;
			li {
				width: 100%;
				float:none;
				display: block;
				margin-right: 0;
				text-align: left;

				div {
					padding-left: 10px;
					width:auto;
				}
			}
			
			h6, .h6 {
				font-size: 1.3em;
			}
			em {
				font-size: 1em;
				font-weight: normal;
			}			
		}
	}
	
	@include breakpoint(small) {
	
	.default-form-box {
		textarea {
			width: 90%;
		}
		span label {
			display: block;
			font-weight: normal;
		}
		button {
			float:none;
			width: 100%;
		}
	}
		.our-experts {
		  margin: 20px auto;
		  
		  	img {
		  		display: block;
		  		margin: 0 auto;
		  	}
		  	
			li {
				width: 100%;
				float:none;
				display: block;
				text-align: center;
			
				div {
					padding-left: 10px;
				}
			}
			
			h6, .h6 {
				font-size: 1.3em;
			}
			em {
				font-size: 1em;
				font-weight: normal;
			}			
		}
	
	}
}


	// 2.11 - Career Coaching Page
	
	#basic-page .career-coaching {

		h2 {
			margin-bottom: 20px;
		}
	
		aside {
			float: right;
			box-sizing: border-box;
			font-size: .85em;
			background: $bkgrdLtGrey;
			margin-top: 20px;
			width: 300px;
			padding: 2%;
			text-align: center;
		}
	
		section {
			padding-bottom: 10px;
			width: 100%;
			float: left;
		
			li {
				padding-bottom: 40px;
			
				div ul {
					list-style: disc inside none;
					padding-bottom: 10px;
				
					li {
						font-size: 1.2em;
						padding: 5px 10px;
					}
				}
			}
					
			div {
				box-sizing: border-box;
				float: left;
				background: $bkgrdLtGrey;
				padding: 3%;
				text-align: center;
				width: 20%;
				margin-bottom: 10px;

				img {
					border: thin solid $miscLtGrey;
					width: 80%;
				}
			
				b {
					display: block;
				}
			}
		
			div + div {
				width: 78%;
				background: $white;
				padding: 0 0 0 20px;
				margin-bottom: 0;
				text-align: left;
			}
		
			.call-to-action {
				margin: 20px 0;
			}
		}

		@include breakpoint(medium) {
			box-sizing:border-box;
			padding: 0 10px;
		}
		
		@include breakpoint(small) {
			section {
				div {
					float: none;
					display:block;
					width: 100%;
					
					img {
						float: left;
						margin-bottom: 15px;
						margin-right: 20px;
						width: 30%;
					}
				}
				
				div + div {
					width: 100%;
				}
			}
		}
	}
	
	// 2.12 - Content Library Page

	#basic-page .content-library {

		aside {
			float: right;
			width: 33%;
			
			.sideboard-adspace div > div {
				height: 200px;
			}
			
			.sideboard-adspace img {
				max-width: 100%;
			}
			
			div.job-alert-signup {
				padding-bottom: 0;
			}
			
			@include breakpoint(small) {
				float: none;
				display: block;
				width: 100%;
				max-width: 100%;
				margin-bottom: 20px;

				.sideboard-adspace, .frequent-views, .news-feed {
					display: block;
					width: 100%;
				}
				
				.sideboard-adspace {
					max-width: 320px;
					text-align: center;
				}
				
				.frequent-views, .news-feed {
					box-sizing: border-box;
				}
			}
		}
		
		h1 {
			padding-bottom: 10px;
		}

		#content-library-listings {
			padding-top: 0;
			
			li {
				padding: 0
			}
			
			li.dod-links, li.federal-jobs-links, li.federal-leads-links, li.specialized-content-links, li.state-links {
				padding: 10px;
			}
			
			li.state-links {
				padding-bottom: 0;
			}
		}
	}

	// 2.13 - My Account Page

	#basic-page .my-account {
		
		h1 {
			
			span {
				font-weight: 400;
				font-size: .55em;
				display: inline-block;
			}
			
			a {
				padding-left: 20px;
				padding-right: 20px;
				border-right: thin solid $miscLtGrey;
			}
			
			a + a {
				padding-right: 0;
				border-right: none;
			}
		}

		.btn-social {
			padding: 0;
			line-height: 32px;
			width: 32px;
			display: inline-block;
		}
		.btn-social:hover {
			opacity: 1;
		}

		#manage-social {
			table td a {
				padding-right: 25px;
			}
			.primary {
				padding-top: 7px;
				padding-bottom: 7px;
			}
			@include breakpoint(medium) {
				td {
					display: table-cell;
					padding: 8px;
					min-width: 110px;
				}
				table td:before {
					display: none;
				}
			}
		}
		
		.listing-detail {

			button {
				float: left;
				margin-right:10px;
			}
			
			div span {
				display: inline-block;
			}
			
			.jobseeker-preformatted-resume div span {
				display: inline;
			}
			
			p {
				padding: 20px 0;
			}
			
			.jobseeker-preformatted-resume p {
				padding: 0;
			}
			
			em {
				display: block;
			}
		}
		
		.listing-detail.settings {
		
			span {
				position: relative;
			}
			
			div {
				float:left;
				width: 26%;
				margin: 20px 0;
			}
			
			div + div {
				margin-left: 5%;
			}
			
			div + div.new-line {
				margin-left: 0;
			}
			
			fieldset {
				float: none;
				width: 90%;
			}
			
			fieldset + fieldset {
				padding-top: 10px;
			}	
					
			input, select {
				width: 100%;
				padding: 5px;
			}
			
			input[type="checkbox"], input[type="radio"] {
				width: 15px;
				height: 15px;
			}
			
			label {
				font-weight: 700;
				display:block;
				padding-bottom: 5px;
			}
			
			label[for*="js"] {
				font-weight: 400;
			}
			
			a {
				display: block;
				padding: 10px ;
			}
			
			button {
				clear: both;
				float: right;
				position: relative;
				bottom: 20px;
				right: 20px;
				margin: 20px 0 0;
				
			}
		}
			
		.listing-detail.contacts {
		
			.call-to-action {
				padding: 20px 0 40px;
				text-align: center;
				float: none;
				
				a {
					padding-left: 40px;
				}
			
				button {
					float:none;
					margin: 0;
				}
				
				
				strong {
					color: $textDkGrey;
					font-size: 1em;
					font-weight: 500;
					background: $miscLtGrey;
					display:block;
					padding: 20px;
					margin-top: 10px;				
				}
			}					
		}
		
		section {
			padding-bottom: 40px;
			
			p {
				font-size: 1em;
			}
		}
		
		
		table {
			tr + tr {
				background: #fcfcfc;
				border-bottom: none;
				border-top: 4px solid #ffffff;
			}
			
			tr + tr.listing-detail {
				border-top: none;
			}

			
			caption.career-fair {
				font-size: 1.3em;
				color: $textDkGrey;
			}
			
			& + table {
				margin-top: 20px;
			}
			
			tfoot tr td {
				padding: 20px;
				font-size: 1.1em;
			}			
		}
		
		@include breakpoint(medium) {
			.listing-detail.settings {
			
				div {
					box-sizing: border-box;
					width: 40%
				}
			}
		}
		
		
		@include breakpoint(small) { 
		
			.col-width-a {
				width: 15%;
			}
	
			#career-fair-invites .col-width-f, #job-applications .col-width-g, #saved-jobs .col-width-g {
				width: 45%;
			}
			
			#contact-requests .col-width-k, #messages .col-width-g {
				width: 60%;
			}
			
			#saved-searches .col-width-o {
				width: 80%;
			}
			
			.listing-detail {
				button {
					margin: 0 0 15px 0;
				    float: none;
				    width: 100%;
				}
				
				li label {
					display: block;
				}
				
				fieldset {
					float: none;
					display: block;
					margin: 0;
					padding: 0;
					width: 100%;
				}
				
				textarea {
					width: 100%;
				}
			}
					
			.listing-detail.settings {
			
				div {
					box-sizing: border-box;
					float:none;
					margin: 0 0 20px;
					width: 100%
				}
				
				div + div {
					margin-left: 0;
				}
				
				button {
					position: relative;
					right: 0;
					bottom: 0;
					margin: 0 0 20px;
				}				
			}
			
			.listing-detail.contacts {
				.call-to-action {
                    a {
					   display:block;
					   padding: 20px 0 0 0;
                    }
                    button[name="contact_yes"] {
					   margin: 0 0 20px;
				    }
				}
			}
		}
	}
	
	// 2.13.1 - Career Fair Schedule Preview
	
	#basic-page .career-fair-reservations {
	
		h2 {
			padding-top: 0;
			border: none;
		}
		
		h3, .h3 {
			background: $bkgrdLtGrey;
			font-size: 1.5em;
			font-weight: 400;
			padding: 5px 10px;
		}
		
		table {
			margin: 0 0 20px;
			tr {
				background: transparent;
				border-bottom: thin dotted #eeeeee;
			}
		}
		
		.conflict {
			color: $textAlert;
		}
	}
	
	// 2.14 ORDER PURCHASE

	#basic-page .order-purchase {
		
		.payment ul {
			padding: 5px 0;
			margin-bottom: 20px;
			border-top: thin dotted $miscLtGrey;
			border-bottom: thin dotted $miscLtGrey;
			
			b {
				float: left;
				font-weight: 500;
			}
			
			li {
				padding: 0 0 0 20px;
			}
		}
		
		.wizard {	
					
			.steps {
				
				span {
					display: inline;
				}
			}
		}
		
		p {
			font-size: 1em;
			
			span {
				display: inline;
			}
		}
				
		table {
			font-size: .9em;
			
			caption {
				color: $textMdGrey;
				padding-top: 0;
			}
			
			th {
				font-size: 1em;
				padding: 5px 10px;
			}
			
			td {
				margin-top: 3px;
				padding: 5px 10px;
			}
			
			tr.sub-total, tr.total {
				background: transparent;
			}
			
			tr.total {
				td {
					font-size: 1.2em;
					padding-top: 10px;
					font-weight: 700;
				}
				td:nth-of-type(2) {
					white-space: nowrap;
				}
			}
		}
		
		label[for="paypal_submit"] {
			padding-left: 0;
			padding-right: 0;
			font-size: .9em;
			width: 100%;
		}
	}
	
	// 2.15 JOB SEEKER SUBSCRIPTION SPLASH PAGE
	
	#basic-page .subscription {
		
		p {
			font-size: 1.2em;
			
			span {
				display: inline;
			}
		}
		
		.default-form-box {
			margin-top: 20px;
			padding: 10px 40px;
		
			form {
				background: transparent;
				padding: 10px 0 20px;
				
				.alert {
					padding: 10px;
				}
			}
			
			button {
				font-size: 1em;
				padding: 10px;
				margin-left: 10px;
				margin-top: -10px;
			}
			
			legend {
				font-size: 1.7em;
				font-weight: 300;
				padding-bottom: 10px;
			}
			
			fieldset {
				width: 100%;
				box-sizing: border-box;
				padding: 10px;
			}
			
			label {
				display: block;
    			margin-bottom: 0.5em;
    			margin-right: 20px;
    			padding: 10px 0 0 0;
			 	font-size: 1.2em;
			 	font-weight: 400;			}
			
			 label[for="subscription-plan"] {
			 	font-size: 1.4em;
			 	display:block;
			 	font-weight: 500;
			 	padding-left: 0;
			 	
			 	.description {
					display: block;
					font-size: 0.85em;
					font-weight: normal;
					padding-bottom: 10px;
				}
			 }
			 
			 label[for="duration"] {
			 	margin-bottom: 20px;
			 	padding-top: 0;
			 }
			 			 
			 label[for="member-rate"], label[for="non-member-rate"] {
			 	background: none repeat scroll 0 0 $bkgrdLtGrey;
    			float: right;
    			padding: 10px 0 0 10px;
    			width: 270px;
    		}
			 
			 label[for="member-rate"]:hover, label[for="non-member-rate"]:hover {
			 	background-color: $miscLtGrey;
			 }
			 
			em {
				font-style: normal;
			}
			
			div {
				background: $white;
				padding: 10px 20px;
				margin-bottom: 20px;
			}
			
			span {
				padding-top: 0;
			}
		
			@include breakpoint(small) { 
				
				button {
					margin-left: 0;
					margin-top: 20px;
				}
			
				label[for*="member"] {
					float:none;
					padding: 10px;
					width: 100%;
					margin-top: 30px;
					box-sizing:border-box;
				}
			}
			
		}
		
		@include breakpoint(medium) {
			box-sizing: border-box;
			padding: 5px;
			
			.default-form-box  {
				padding: 10px;
				
				div {
					margin-bottom: 0;
				}
			
				label {
					margin-right: 0;
				}
			}
		}
	}
	
	// 2.16 CAREER FAIR REGISTRATION SPLASH PAGE
	
	#basic-page .career-fair-registration {
	
		p span {
			display: inline;
		}
	
		@include breakpoint(medium) { 
			box-sizing: border-box;
			padding: 5px;
		}

		.default-form-box {
			margin-top: 20px;
			padding: 0;
		
			form {
				background: transparent;
				padding: 10px;
				
				.alert {
					padding: 10px;
				}
			}
			
			button {
				font-size: 1em;
				padding: 10px;
				margin-left: 10px;
				margin-top: -10px;
			}
			
			legend {
				font-size: 1.7em;
				font-weight: 300;
				padding-bottom: 20px;
			}
			
			fieldset {
				width: 100%;
				box-sizing: border-box;
				padding: 10px;
			}
			
			label {
				display: block;
    			margin-bottom: 0.5em;
    			margin-right: 20px;
    			padding: 10px 0 0 0;
			 	font-size: 1.2em;
			 	font-weight: 400;			
			 }
			
			 label[for="subscription-plan"] {
			 	display: block;
			 	font-size: 1.4em;
			 	font-weight: 500;
			 	padding-left: 0;
			 	
			 	.description {
					display: block;
					font-size: 0.85em;
					font-weight: normal;
					padding-bottom: 10px;
				}
			 }
			 
			 label[for="duration"] {
			 	margin-bottom: 20px;
			 	padding-top: 0;
			 }
			 
			 label[for="member-rate"], label[for="non-member-rate"] {
			 	background: none repeat scroll 0 0 $bkgrdLtGrey;
    			float: right;
    			padding: 10px 0 0 10px;
    			width: 270px;
			 }
			 
			 label[for="member-rate"]:hover, label[for="non-member-rate"]:hover {
			 	background-color: $miscLtGrey;
			 }
			 
			em {
				font-style: normal;
			}
			
			div {
				background: $white;
				padding: 10px 20px;
				margin-bottom: 20px;
			}
			
			span {
				padding-top: 0;
			}
			
			div.reveal-info {
				background: transparent;
				padding: 20px 0;
				margin-bottom: 0;
				
				p {
					color: $textAlert;
					width:100%;
					float:none;
				}
			
				label[for="reveal-contact"] {
					float: right;
					font-size: 1.1em;
				}
			
				input[type="checkbox"] {
					width: 15px;
					height: 15px;
				}
			}
			
			@include breakpoint(medium) { 
				padding: 0;
				
				form {
					padding: 0;
				}
				
				label[for="duration"] {
					display: block;
				}
				
				div.reveal-info p {
					float: none;
					width: 100%;
				}
				
				div.reveal-info label[for="reveal-contact"] {
					float: none;
				}
			}
			
			@include breakpoint(small) { 
				
				button {
					margin-left: 0;
					margin-top: 20px;
				}
				
				label[for*="member"] {
					float:none;
					padding: 10px;
					width: 100%;
					margin-top: 30px;
					box-sizing:border-box;
				}
				
				div {
					margin-bottom: 0;
					padding: 10px 0;
				}
			}
		}
	}
	
	
	// 2.17 JOB APPLICATION
	
	
	
	// 2.18 Manage Resumes
	
	#basic-page .manage-resumes {
		
		section {
			box-sizing: border-box;
			padding: 10px;
			background: #f8f8f8;
			margin-bottom: 30px;
		}

		
		textarea {
			display: block;
			box-sizing: border-box;
			width: 100%;
			padding: 8px;
			height: 150px;
		}
		
		input {
			padding: 8px;
			width: 250px;
		}
		
		h2 {
			border: none;
		}
		
		h3, .h3, strong {
			font-size: 1.4em;
			font-weight: 400;
		}

		.success-alert strong {
			font-weight: 700;
			font-size: 1em;
		}
		
		form h3, form .h3, form strong {
			font-weight: 500;
		}
		
		table {
			border: thin solid #eeeeee;
			margin-bottom: 20px;
			
			caption {
				background: #e3e3e3;
				width: 100%;
				
				b, strong {
					padding:0 10px;
					font-size: 1.4em;
					color: #333333;
				}
			}
			
			th {
				color: #666666;
				padding: 5px 10px;
			}
			
			tr {
				background:#f9f9f9;
			}
			
			tr + tr {
				background: $white;
			}
			
			a strong {
				color: $primary-cta-color;
			}
			
			span b {
				border-left: thin solid $miscLtGrey;
    			padding-left: 10px;
    			
    			@include breakpoint(small) {
    				position: absolute;
    				left: 0;
    				bottom: -10px;
    				border-left: none;
    				padding-left: 0;
    			}
			}		
		}
		
		table + div {
			box-sizing: border-box;
			padding: 30px 10px 10px;
		}
		
		.create-resume {
			width: 100%;
			text-align: center;
			
			div {
				    box-sizing: border-box;
				    width: auto;
				    padding: 15px 7px;
				    display: inline-block;
				
				a {
					padding: 15px;
					width: 100%;
					text-align:center;
					font-size: 1.2em;
					box-sizing:border-box;
				}
			}
			
			div.linkedIn-profile {
				clear:both;
				margin: 0 0 0 30px;
				width: auto;
				float: none;
				display:inline-block;
				padding: 15px 0;

				span {
					position: initial;
				}
			}
			
			p {
				clear: both;
				float: none;
				padding-top: 20px;
				margin: 30px 30px 0;
				display: inline-block;
				border-top: thin solid #ddd;
			}			
		}
		
		.cover-filename {
			float: left;
		}		

		.submit-cover-letter {
		
			button {
				padding: 11px 25px;
			}
		}
		
		
		
		@include breakpoint(medium) {
			
			.create-resume div {
				margin: 0;				
				float: none;				
				width: 100%;
				padding: 10px 0;	
			}
			
			fieldset {
				padding: 0.35em 0 0.75em;
				width: 100%;
			}
			
			input {
				box-sizing: border-box;
				width: 100%;
			}
			
			button {
				width: 100%;
			}
			table, thead, tbody, th, td, tr {
				display:block;
			}
			
			th {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
			
			tr {
				border: 1px solid #ccc;
				position: relative;
			}
			
			td {
				border: none;
				border-bottom: 1px dotted #eee;
				position: relative;
				white-space: normal;
				text-align:left;
			} 
			
			table {

				td span.options a {
					display: inline-block;
					padding-right: 4px;
					padding-bottom: 4px;
				}
				
				td span.options a + a {
					padding-left: 4px;
				}

			}
		}
		
		@include breakpoint(small) {
			table {
				
				td span.options a[data-target="#upgrade-resume"] {
					display: block;
					padding: 10px 0;
				}
			}
		
		}	
	}
	

	// 2.19 View Resume
	
	#view-resume {
	
		h1 {
			margin-bottom: 0;
		}
		
		h2 {
			font-weight: 400;
			padding-bottom: 5px;
			background: none;
			padding-left: 10px;
			margin-bottom: 10px;
		}
		
		h3, .h3 {
			font-size: 1.6em;
			font-weight: 400;
			padding: 10px 0 3px;
			margin-bottom: 10px;
			border-bottom: thin solid $miscLtGrey;
		}
		
		h4, h5, h6, .h4, .h5, .h6 {
			display: inline-block;
			width: 50%;
			text-align: left;
			line-height: 1.7;
		}

		
		em {
			display:block;
			font-style: normal;
			width: 100%;
		}
		
		i, em {
			width: 100%;
			
			a {
				float: right;
				font-size: 1.2em;
				cursor: pointer;
			}
			
			a + a {
				padding-right: 30px;
			}
		}
		
		section {
			padding-top: 30px;

			b {
				display: block;
				padding-top: 10px;
			}
		
			ul {
				padding: 10px;
			}
		
			ol {
				list-style-type: number;
				padding-left: 190px;
			}
		
			li {
				line-height: 1.7em;
				clear: both;
			}
		}
		
		section.relocation.preference {
			float: left;
			margin-top: 10px;
			padding-right: 3%;
			padding-top: 0;
			width:30%;
			
			h2 {
				background: transparent;
				font-size: 1em;
				border-top: thin dotted $miscLtGrey;
				border-bottom: thin dotted $miscLtGrey;
				margin-bottom: 0;
			}
			
			b {
				width:100%;
				text-align:left;
			}
		}
		
		section.additional.information, div.jobseeker-preformatted-resume, section.jobseeker-preformatted-resume, section.build-online-resume, section.relocation {
			clear: both;
		}
		
		p {
			line-height: 1.4em;
		}
		
		span.alert {
			display: block;
		}

		
		.build-online-resume {
			
			div {
				box-sizing: border-box;
				padding: 10px;
				
				i, em {
					float: right;
					width: 50%;
					text-align: right;
				}
				
				i + p, em + p {
					padding-top: 10px;
				}
				
				span p:last-of-type {
					padding-bottom: 10px;
					margin-bottom: 10px;
				}
			}
		}
		
		@include breakpoint(medium) {
			box-sizing: border-box;
			padding: 5px;
			
			i a, em a {
				margin-right: 10px;
			}
		}	
	}


	// 2.20 Update Cover Letter
	
	#basic-page .cover-letter {
	
		.default-form-box {
		
			fieldset.cover-letter-content {
				width: 100%;
				float: none;
				
				textarea {
					width: 100%;
					height: 150px;
				}
			}
			
			.submit-cover-letter {
		
				button {
					padding: 11px 25px;
				}
			}	
			
			.submit-cover-letter, .cover-filename {
				padding-bottom: 0;
			}
			
			.cancel-update {
				float: left;
				padding-left: 30px;
				padding-top: 10px;
			}
			
			@include breakpoint(small) {
				
				fieldset, button, .cancel-update {
					display:block;
					float:none;
					box-sizing: border-box;
					
				}
				
				button, .cancel-update {
					width: 100%;
					margin-top: 20px;
					text-align:center;
					padding-left: 0;
				}
				
				input, select {
					box-sizing: border-box;
					width: 100%;
				}
			}	
		}
	}


	// 2.21 Create Resume
	
	#basic-page .create-resume {
		
		h2 {
			background: $bkgrdLtGrey;
			font-weight: 400;
			padding-bottom: 5px;
			padding-left: 10px;
			margin: 15px 0 0 0;
			border: none;
		}


		h2, label {
			position: relative;
		}
		
		abbr {
			font-size: .4em;
			font-weight: 700;
			font-family: serif;
			font-style: italic;
			color: $white;
			display:inline-block;
			position: absolute;
			background: $linkDefault;
			border-radius: 9px;
			padding: 0 5px;
			margin-left: 2px;
		}
	
		section {
			padding: 20px 10px 0 10px;
		}
		
		div {
			padding: 10px;
		}
		
		div.mce-tinymce div {
			padding: 0;
		}
		
		.default-form-box {
			padding: 0;
			background: transparent;
					
			a.right {
			    background: none repeat scroll 0 0 #eaf0f4;
				float: right;
				font-weight: 400;
				padding: 10px;
				margin-right: -10px;
				
				em {
					font-style: normal;
					font-weight: 700;
					padding: 0 10px 0 0;
				}
			}
			
			.jt_cp_rb_search_multiple {
				font-weight: 400;
				color: $textLtGrey;
			}
			
			.details_certifications {
				font-weight: 400;
			}
			
			form {
				padding: 0;
			}
			
			input, select {
				display:block;
				margin-top: 5px;
			}
			
			input[type="radio"] {
				display: inline-block;
				margin-right: 5px;
			}

			input[type="checkbox"] {
				display: inline-block;
				margin: 5px 3px 5px 5px;
			}
			
			input[name="contact_name_suffix"] {
				width: 45px;
			}
			
			input[name*="zip"] {
				width: 100px;
			}
			
			input[name*="branch"] {
				width: 210px;
			}
			
			input[name*="expertise"], input[name*="recognition"] {
				width: 180px;
			}
			
			select[title="Education Completed"] {
				width: 300px;
			}
			
			input[name*="gpa"] {
				display: inline-block;
				width: 95px;
			}
			
			.summary input {
				width: 100%;
				box-sizing: border-box;
			}
						
			select {
				width: 260px;
			}
			
			select#location_country {
				width: 240px;
			}

			label {
				display: block;
				
				abbr {
					font-size: .7em;
					margin-left: 3px;
				}
				
				span.alert {
					padding-top: 3px;
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}
			
			label[for*="current"] {
				padding: 25px 25px 10px;
			}
			
			label[for*="ungrouped_work_authorization"], label[for*="preferences_job_type"], label[for*="relocation_relocation"], label[for*="preferences_services_desired"] {
				font-weight: 400;
				padding-top: 5px;
			}
			
			label[for="preferences_job_type"] {
				padding: 5px 0 0;
			}
			
			textarea {
				width: 100%;
				margin-top: 5px;
			}
					
			u {
				text-decoration: none;
				font-weight: 400;
				padding: 0 5px;
			}

			.work, .education, .military {
				div {
					border: thin solid #eeeeee;
				}
			}
			
			.education-info {
				input, select {
					width: 300px;
				}
			}
		
			
			.job-info, .education-info, .branch-info {
				clear: both;
				box-sizing: border-box;
				padding:10px;
				background: #fcfcfc;
				
				fieldset:last-of-type {
					padding-bottom: 0;
				}
				
				label[for*="_start"], label[for*="_end"] {
					padding-left: 10px;
				}
				
				label[for*="gpa"] {
					span > input {
						margin-right: 10px;
					}
					
					u + input {
						margin-left: 10px;
					}
				}
				
				span {
					display:block;
					padding-top: 0;
				}
				
				select.jt_cp_select_date {
					display: inline-block;				
				}

				select.jt_cp_select_date.month {
					width: 130px;
					margin-right: 5px;
				}

				select.jt_cp_select_date.year {
					width: 100px;
				}
				
				.current {
					padding: 30px 30px 0 5px;
				}
			}
			
			.add-position, .add-degree, .add-branch {
				background: #f4f8fa;
				margin-top: 10px;
				
				em {
					font-size: 1.4em;
					font-weight: 700;
					padding-right: 10px;
				}
			}
			
			.add-employer, .add-school {
				margin: 10px 0 20px;
				padding: 5px 10px 7px;
				background: $bkgrdLtBlue;

				a {
					font-size: 1.2em;
				
					em {
						font-size: 1.4em;
						font-weight: 700;
						padding-right: 10px;
					}
				}
			}
			
			.branch-info {
				
				label[for="start-date"] {
					padding-left: 0;
				}
				
				label[for="end-date"] {
					padding-right: 10px;
				}
			}
			
			.details, .preferences {
					
				fieldset {
					display: block;
					float: none;
				}
			
				span {
					display: block;
					padding-top: 0;
				}
				
				label[for*="salary"] {
					
					input, select {
						display: inline-block;
						margin-right: 5px;
						width: 190px;
					}
					
					select {
						width: 110px;
					}
					
					select + select {
						margin-right: 20px;
					}
				}
				
				select {
					width: 300px;
				}
			}
			
			.relocation {
			
				div {
					background: #fcfcfc;
					margin-bottom: 10px;
				}
				
				input[name="city"], input[name="other"] {
					width: 245px;
				}
				
				select {
					width: 250px;
				}
			}
			
			.publish {
				background: #fcfcfc;
			}
			
			.call-to-action {
				
				display: block;
				background: $bkgrdLtGrey;
				
				a {
					padding: 20px;
					margin-right: 80px;
					float:right;
				}
				
				button {
					padding: 20px 50px;
				}
			}
			
			.mce-menubar, .mce-toolbar {
				button span {
					display:inline-block;
				}
			}
			
			.mce-btn .mce-caret {
				display: inline-block;
				margin-top: 10px;
			}

		
			@include breakpoint(small) {
				
				fieldset, input, select, .call-to-action a, .call-to-action button {
					box-sizing: border-box;	
					width: 100%;		
				}
				
				select[title="Education Completed"] {
					width: 100%;
				}
				
				input[type="checkbox"], input[type="radio"]  {
					margin-right: 5px;
					width:20px;
				}
				
				input[name="contact_name_suffix"] {
					width: 60px;
				}
				
				label[for*="current"] {
					padding: 25px 10px 10px;
				}
				
				.job-info, .education-info, .branch-info {
					select.jt_cp_select_date.month {
						width: 50%;
					}
				}
				
				.preferences, .details, .relocation {
					select {
						width: 100%;
					}
					input[name="other"], input[name="city"] {
						width: 100%;
					}
					label[for*="salary"] input {
						width: 50%;
						
						@include breakpoint(xsmall) {
							width: 30%;
						}
					}
				}
				
				input[name*="branch"] {
					width: 100%;
				}
				
				input[name*="zip"] {
					width: 50%;
				}
				
				.call-to-action button, .call-to-action a {
					float: none;
					display:block;
					text-align: center;
				}
			}		
		}
	}


	// 2.20 SSO - FIRST TIME USER
	
	#basic-page .SSO-first-login {
	
		.default-form-box {
			margin-top: 20px;
		}
		
		.call-to-action {
			float: none;
			background: $bkgrdLtGrey;
			box-sizing: border-box;
			padding: 10px;
			margin-top: 20px;
			width: 100%;
		}
		
		button {
			margin-top: 2px;
		}
		
		fieldset {
			padding: 10px;
		}
	} 
	
	// 2.21 Edit Resumes
	
	#basic-page .edit-resume {
	
		.default-form-box {
			padding: 0;
			
			form {
				padding: 20px 0;
			}
			
			fieldset {
				width: 100%;
			}
			
			textarea {
				margin-top: 5px;
			}
			
			.mce-menubar, .mce-toolbar {
				button span {
					display:inline-block;
				}
			}
			
			.mce-btn .mce-caret {
				display: inline-block;
				margin-top: 10px;
			}
			
			@include breakpoint(small) {
				.call-to-action button, .call-to-action a {
					float: none;
					display:block;
					text-align: center;
				}
				
				.call-to-action a {
					padding-top: 12px;
				}			
			}
		}
		
		.mce-tinymce.mce-container.mce-panel {
			margin-top: 10px;
		}
	}
	
	// JOB APPLICATION
	
	 #basic-page .job-application .wizard .steps span {display: inline-block;}
	 
	// SITE MAP
	
	#basic-page .site-map {
		h3, h4, .h3, .h4 {
			font-size: 1.1em;
			font-weight: 400;
			padding-bottom: 10px;
		}
		
		h4, .h4 {
			display: inline-block;
			padding-left: 10px;
		}
		
		li {
			padding-left: 0;
		}
		
		ol.level-2 li {
			list-style: inside decimal;
		}
		
		ul.level-3 li {
			list-style: inside disc;
			padding-left: 20px;
			
			a {
				padding-left: 10px;
			}
		}
	}
	
	// YM SSO
		
	#basic-page .YM-login-newAccount {
		
		h1 + span {
			font-size: 1.2em;
		}
		
		.default-form-box {
			padding: 40px 40px 20px;
						
			button {
				float: none;
				margin-top: 20px;
			}
			
			input, select {
				width: 100%;
			}
			
			input[type="checkbox"] {
				height: 20px;
				width: 20px;
			}
			
			span {
				padding: 0 0 10px;
			}
			
			span + button {
				margin-top: 0;
			}
			
			fieldset {
				box-sizing: border-box;
				display: inline-block;
				float: none;
				margin: 0;
				padding: 0 10px 20px 0;
				width: 32%;
			}
			
			fieldset.call-to-action {
				float: right;
				text-align:right;
				width: 220px;
				padding-right: 0;
			}

			fieldset.save-login {
				display: block;
				clear:both;
				width: 100%;
				padding-bottom: 0;
			}
			
			@include breakpoint(medium) {
				
				fieldset {
					width: 49%;
					padding-right: 2px;
				}
				
				fieldset.call-to-action {
					display: inline-block;
					float: right;
					width: 47%;
					text-align: right;
					
					a {
						display: block;
					}
				}
				
				fieldset.save-login {
					display: inline-block;
					float: left;
					clear: none;
					width: 47%;
				}
				
				button {
					width: 100%;
				}
			}
			
			@include breakpoint(five-four-zero) {
				fieldset, fieldset.call-to-action, fieldset.save-login  {
					width:100%;
					display: block;
				}
				
				fieldset.call-to-action, fieldset.save-login {
					float: none;
				}
				
				fieldset.call-to-action a {
					display: inline-block;
				}
			}
			
			@include breakpoint(small) {
				padding: 20px;
				background: none repeat scroll 0 0 #f5f5f5;
				
				form {
					padding: 20px;
				}
				
			}
		}
	}
	
	
// Global
.site {
	background-color: #fff;
}

.inner-jobseeker-page .header-content .call-to-action {
	display: none;
}


.jobseeker-preformatted-resume {
	ol,ul {
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	  }
	  ol{
		list-style-type: decimal;
	  }
	  ul{
		list-style-type: disc;
	  }
}