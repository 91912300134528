//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-STATS.SCSS

// This file includes all rules which affect the generic featured jobs module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

//////////////////////////////////////////////////////////////////////////////////////////
	
	
#featured-stats {
    clear: both;
	.site {
	    background-color: transparent;
	}
	.interior {
	    background: #f5f5f5;
	    padding: 10px;
    	box-sizing: border-box;
	}
	.stats-container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		justify-content: space-evenly;
		text-align: center;
		
		.stats-block {
			position: relative;
    		width: auto;
    		-webkit-flex: 1;
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex:1;
			max-width: 100%;
			box-sizing: border-box;

			span.count {
			    font-size: 45px;
			    font-weight: bold;
			    display: inline-block;
			}
			h3 {
			    display: inline-block;
			    font-size: 20px;
			    font-weight: 400;
			    position: relative;
			    padding-left: 5px;
			    margin-top: 20px;
			}
		}

	@media (max-width: 768px){
		.stats-block{
			span.count {
			    display: inline-block;
			}
			h3 {
			    display: block;

			    &:before {
					content: "";
					height: 2px;
					background: currentColor;
					width: 50px;
					position: absolute;
					left: 0;
					right: 0;
					display: block;
					margin: auto;
					text-align: center;
					top: -15px;
				}
			}
		}
	}
	@media (max-width: 575px){
		.stats-block{
		    -webkit-flex: 0 0 100%;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
		    margin: 20px auto;
		}
	}
	
	}
	&.four-stats{

		.stats-container {

			.stats-block {
	    		width: 100%;
	    		
	    		-webkit-flex: 0 0 25%;
				-ms-flex: 0 0 25%;
				flex: 0 0 25%;
				max-width: 25%;

				span.count {
				    display: block;
				}
				h3 {
				    display: block;
				    padding-left: 0px;

				    &:before {
					    content: "";
					    height: 2px;
					    background: currentColor;
					    width: 50px;
					    position: absolute;
					    left: 0;
					    right: 0;
					    display: block;
					    margin: auto;
					    text-align: center;
					    top: -15px;
					}
				}
			}
		@media (max-width: 768px){
			.stats-block{
			    -webkit-flex: 0 0 50%;
				-ms-flex: 0 0 50%;
				flex: 0 0 50%;
			    max-width: 50%;
			    margin: 10px auto;
			}
		}
		@media (max-width: 575px){
			.stats-block{
				-ms-flex: 0 0 100%;
				-webkit-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
				margin: 20px auto;
			}
		}
		}
	}
	&.three-stats{
		.stats-container{
			.stats-block {
	    		width: 100%;
				-ms-flex: 0 0 33.33%;
			    flex: 0 0 33.33%;
			    max-width: 33.33%;

				span.count {
				    display: block;
				}
				h3 {
				    display: block;
				    padding-left: 0px;

				    &:before {
					    content: "";
					    height: 2px;
					    background: currentColor;
					    width: 50px;
					    position: absolute;
					    left: 0;
					    right: 0;
					    display: block;
					    margin: auto;
					    text-align: center;
					    top: -15px;
					}
				}
			}
		@media (max-width: 575px){
			.stats-block{
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
				
			}
		}
		}
	}
	&.super{
	    background: #f5f5f5;
	    padding: 40px 0;
	    .interior {
		    background: transparent;
		}
	}
}