//////////////////////////////////////////////////////////////////////////////////////////

// _FEATURED-EMPLOYERS.SCSS

// This file includes all rules which affect the job alert landing page functionality.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

//////////////////////////////////////////////////////////////////////////////////////////
		
	
	#basic-page {

		&.alert-resume {

			h1 {
				text-align: center;
				margin: auto auto 10px;
			}
			.job-alert-signup p {
				font-size: 1em;
			}
		}
		&.alert-activated {

			h1 {
				text-align: center;
				margin: auto auto 10px;
			}
			.job-alert-signup p {
				font-size: 1em;
				padding-bottom: 0;
			}
			section.horizontal-job-alert-box {
				padding-top: 0;
			}
			.login-newAccount {

				.default-form-box {
					padding-top: 0;

					.form-fields {
						padding-top: 10px;
						padding-bottom: 10px;

						&:first-of-type {
							padding-top: 0;
						}
					}
				}
				section {
					padding-top: 0;
				}

			}
			.default-form-box {

				fieldset {
					padding-right: 0;
					width: 100%;
					box-sizing: border-box;
					max-width: 420px;
					float: none;

					input[type="text"], input[type="email"], input[type="password"], select, select[multiple] {
						width: 100%;
					}
				}
				.fieldset_checkbox label {
					display: inline-block;
					vertical-align: bottom;
				}
				.fieldset_checkbox input {
					width: 20px;
				}
				.fieldset_radio {
					input {
						width: 15px;
					}
					label {
						display: inline-block;
						padding: 0 20px 0 5px;
					}
					label:first-of-type {
						display: block;
						padding: 0;
						margin-bottom: 10px;
					}
				}
			}
		}

	}
	.horizontal-job-alert-box .job-alert-signup {
		background-color: #f5f5f5;
		max-width: 500px;
		margin: auto;
		padding: 30px 20px;
		font-size: 1.1em;
		text-align: center;
	}
	.alert-activated {
		.horizontal-job-alert-box .job-alert-signup {
			max-width: none;
		}

		.default-form-box button {
			float: none;
		}
	}
	.login-newAccount {
		.validate-buttons {
			.secondary {
				padding: 10px 0;
				width: 49%;
				box-sizing: border-box;
				float: left;
			}

			.secondary + .secondary {
				margin-left: 2%;
			}
		}
	}
	.validate-buttons {
		width: 100%;
		max-width: 420px;
	}
	.validate-fields {
		margin-top: 40px;
	}
	.js-validate .primary {
		width: 100%;
		max-width: 420px;
	}
	.job-alert-buttons {
		margin: auto;
		margin-top: 20px;

		button {
			margin: auto 10px;
		}
		button:first-of-type {
			margin-left: auto;
		}
		button:last-of-type	{
			margin-right: auto;
		}
	}
	@include breakpoint(small) {
		.alert-resume .horizontal-job-alert-box, .alert-activated .horizontal-job-alert-box {
			display: block;
		}
		.job-alert-buttons button {
			display: block;
			margin: auto;
			width: 100%;
		}
		.job-alert-buttons button:first-of-type {
			margin-bottom: 10px;
		}
		.job-alert-buttons button:last-of-type {
			margin-top: 10px;
		}
		#basic-page.alert-activated .login-newAccount .default-form-box {
			padding-top: 10px;
		}
		.login-newAccount .validate-buttons {
			.secondary {
				width: 100%;
				float: none;
			}
			.secondary + .secondary {
				margin-left: auto;
				margin-top: 1em;
			}
		}
	}