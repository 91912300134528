//////////////////////////////////////////////////////////////////////////////////////////

// _CAREER-FAIR-ALERT.SCSS

//////////////////////////////////////////////////////////////////////////////////////////

	.career-fair-alert {

		table {
		    border: thin solid #eeeeee;
		    margin-bottom: 20px;

			th {
			    color: #666666;
			    padding: 5px 10px;
			    & #career_fair_reg {
				    text-align: right;
				}
			}

		    tr {
			    background: #f9f9f9;
			    + tr {
				    background: #fff;
				    border-bottom: thin dotted #eeeeee;
				}
			}

		    caption {
			    background: #e3e3e3;
			    width: 100%;
			    b {
				    padding: 0 10px;
				    font-size: 1.4em;
				    color: #333333;
				}
			}
		}
	}


@include breakpoint(medium){

	.career-fair-alert {
		td {
		    border: none;
		    border-bottom: 1px dotted #eee;
		    position: relative;
		    white-space: normal;
		    text-align: left;
		}
		tr  {
		    border-top: 1px solid #ccc;
		    position: relative;
		}
	}
}



