.coach-email-form-container {
  .email-confirmed-notification {
    text-align: center;
    .dropdown-link-email-confirmed {
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-center;
      justify-content: center;
      padding: 12px 0px;

      svg {
        width: 16px;
        @extend .mr-1;
      }
      .checkmark-icon {
        fill: $green;
      }
    }
  }
  .form-email-share {
    @extend .mt-1;
    text-align: left;
    padding-top: 12px;

    .form-input-group {
      @extend .form-label-group;

      input {
        @extend .form-control;
        font-weight: $font-weight-normal;
        height: 100%;
      }

      textarea {
        @extend .form-control;
        font-weight: $font-weight-normal;
        font-size: $font-size-base;
        resize: vertical;
        max-height: 270px;
      }

      legend {
        position: absolute; 
        left: -999em;
      }
    }
    .topics-container {
      margin-bottom: 1rem;
      .topic-title {
        margin-bottom: 1rem;
      }

      table {
        width: 100%;

        td {
          width: 50%;
          padding-bottom: 8px;
        }
      }
    }
    .message-container {
      margin-top: 12px;
    }
  }
  .share-socal-heading {
    font-weight: $font-weight-normal;
    font-size: $font-size-base;
  }
}
