//////////////////////////////////////////////////////////////////////////////////////////

// _JOB-LISTING-COURSES.SCSS

//////////////////////////////////////////////////////////////////////////////////////////
	
#results-page{

	#job-listing {
		.course_card {
		        border: 1px solid #ccc;
    		    margin-bottom: 10px;
   				background: #fff;
   				
   			&::before {
				    border-top: 5px solid currentColor;
				    content: "";
				    display: block;
			}
   			.card_content{
   				color: #535353;
			 	padding:10px;
			 	background: #fff;
				.card_icon {
				    text-align: left;
				    color: #CCC;
				    position: relative;			    
     				padding-bottom: 30px;

				    svg.card_svg {
					    display: inline-block;
					    position: absolute;
					    left: 0px;
					    top: 0px;
					    #card_svg_Fill {
						    fill: #CCC;
						}
					}
					p {
						display: inline;
						line-height:24px;

						&.credit_type {
					    	right: 10px;
						    text-overflow: ellipsis;
						    width: 25%;
						    white-space: nowrap;
						    overflow: hidden;
						    position: absolute;
						    text-align: right;
						}
						&.card_heading {
					    	left: 30px;
						    position: absolute;
						    text-overflow: ellipsis;
						    width: 55%;
						    white-space: nowrap;
						    overflow: hidden;
						}
					}
				@include breakpoint(medium) {
					p{
						&.credit_type {
							display: none;
						}
						&.card_heading {
						    width: 80%;
						}
					}
				}	 
				}
				.card_title h5 {
					margin: 5px auto;
					font-size: 1.2em;
				}
				.card_text {
					line-height: 1.5;
				}
			}
		}		
	}
}
	
.job-tile.js-course-tile {
	.job-details {
		@extend %col-auto;
		max-width: 100% !important;
	}
}