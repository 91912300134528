// --------------------------------------------------
//
//		SPACING UTILITIES
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

.rm-gutters-horz {
	margin-left: ($grid-gutter-width-md * 0.5) * -1;
	margin-right: ($grid-gutter-width-md * 0.5) * -1;
}

.rm-m { margin: 0; }
.rm-m-l { margin-left: 0; }
.rm-m-r { margin-right: 0; }
.rm-m-t { margin-top: 0; }
.rm-m-b { margin-bottom: 0; }
.rm-m-hor { margin-left: 0; margin-right: 0; }
.rm-m-ver { margin-top: 0; margin-bottom: 0; }

.rm-p { padding-top: 0; padding-right: 0; padding-bottom: 0; padding-left: 0; }
.rm-p-l { padding-left: 0; }
.rm-p-r { padding-right: 0; }
.rm-p-t { padding-top: 0; }
.rm-p-b { padding-bottom: 0; }
.rm-p-hor { padding-left: 0; padding-right: 0; }
.rm-p-ver { padding-top: 0; padding-bottom: 0; }