// --------------------------------------------------
//
//    STATUS HIGHLIGHTS
//
// --------------------------------------------------

.status-highlight {
  margin: 0;
  padding: 0;

  &-horz {
    border-top: 4px solid transparent;
  }

  &-vert {
    border-left: 4px solid transparent;
    //height: 100%;
  }
}

// Horz
@each $color, $value in $theme-colors {
  .status-highlight-#{$color}-left {
    @include status-variant($value, left);
  }
}

// Vert
@each $color, $value in $theme-colors {
  .status-highlight-#{$color}-top {
    @include status-variant($value, top);
  }
}

// Status Highlight for Job Search
.status-highlight-active {
  border-left-color: $primary;
}