//////////////////////////////////////////////////////////////////////////////////////////

// _HORIZONTAL-JOB-ALERT-BOX.SCSS

// This file includes all rules which affect the generic horizontal-job-alert-box module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	
	.horizontal-job-alert-box {	
			box-sizing: border-box;
		text-align: left;
		margin: 0 auto;
			h5, .h5 {
				font-size: 1.65em;
			}
			
			button {

			}
			
		form {
		
			div {
				background:$bkgrdLtGrey;
			}
			
			
			fieldset {
				border: 0;
				float: left;
			}
			
			
			input {
				width: 220px;
				height: 25px;
				padding: 5px;
				border: thin solid #cccccc;
				opacity: .85;
				filter: alpha(opacity=85);
			}
			
			input[name="job-alert-keyword"] {
				width: 235px;
			}
			
			&.invalid input {
				border-color: #990000;
				color: #990000;
				label {
					color: #990000;
					font-weight: bold;
				}
			}

			label {
				display:block;
				padding-bottom: 3px;
			}
			
			.call-to-action {
				float:right;
				padding: 26px 10px 12px;
			}
			
			.required-label {
				color: #666666;
				font-size:.9em;
				padding: 5px 0 20px;
				display: block;
			}
		}
		
		.activate-alert {
			text-align: center;
			height: 360px;
			h5, .h5 {
				border-bottom: thin dotted #dddddd;
			}
			p {
				padding-top: 100px;
			}
			p+p {
				padding-top: 0;
				padding-bottom: 140px;				
			}
			button {
				bottom: 10px;
			}
		}

		@include breakpoint(medium) {
			h5, .h5 {
				font-size: 1.2em;
			}
			form {
			input {
				width: 200px;
			}
			}
			.activate-alert p+p {
				padding-bottom: 105px;
			}
		}

		
		@include breakpoint(small) {
			display: none;
		}
	}