//////////////////////////////////////////////////////////////////////////////////////////

// _BLANK-MODULE.SCSS

// This file includes all rules which affect the generic blank module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	
	
	#blank-module {
		.interior { 
			background: $bkgrdLtGrey;
		}
		p {
			margin-bottom: auto;
		}
		p + p {
			margin-top: 1em;
		}
	}