// -------------------------------------------------------------------
//
//		AVATAR COMPONENTS
//		
//
// -------------------------------------------------------------------
img.avatar{
	border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid $gray-lightest;
    
    &.photo-small {
	    max-width: 35px;
	}
	&.photo-base {
    	max-width: 60px;
	}
	&.photo-medium {
    	max-width: 130px;
	}
	&.photo-large {
    	max-width: 200px;
	}
}

// for avatar with numbers in it like +5,+10
span.avatar{
	display: grid;
	place-items: center;
	border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid $gray-lightest;
	background-color: $gray;
	color: white;
    
    &.photo-small {
	    width: 35px;
		height: 35px;
	}
	&.photo-base {
    	width: 60px;
		height: 60px;
	}
	&.photo-medium {
    	width: 130px;
		height: 130px;
	}
	&.photo-large {
    	width: 200px;
		height: 200px;
	}
}


