//////////////////////////////////////////////////////////////////////////////////////////

// _JOB-DETAIL-VIEW.SCSS

// This file includes all rules which affect the generic job detail view module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////

	
	#job-detail-view {
		float: right;
		width: 100%;	
		font-size: 1.1em;
		padding-top: 10px;
		
		div {
			padding-bottom: 10px;
		}

		.top-info {  
			display: -ms-flex;  
			display: -webkit-flex;  
			display: flex;
	
			.company-logo {
				flex: 0 0 auto;  
				-webkit-flex: 0 0 auto;  
				-ms-flex: 0 0 auto; 
				box-sizing: border-box;
				padding-right: 10px;
				
				img {
					border: 15px solid #f5f5f5;
					padding: 10px;
					max-width: 175px;
				}
			}
			.title-CTA-tools {
				padding-bottom: 0;
				-webkit-flex: 1;  
				-ms-flex: 1;  
				flex: 1 1 0;  
				width: auto;
				
				em {
					font-size: 1.2em;
					font-style: normal;
					line-height: 1;
				}
			}
		}
		.bottom-right-sidebar {
            float: right;
            width: 30%;
        }

		
		h2 {
			font-size: 1.6em;
			font-weight: 500;
			line-height: 1.2;
			padding-bottom: 20px;
		}
		
		h3, .h3 {
			font-size: 1.2em;
			font-weight: 500;
			padding-bottom: 10px;
		}
		
		.h5 {
			font-size: 1.05em;
			letter-spacing: .015em;
			border-bottom: 1px dotted $textLtGrey;
			padding-bottom: 5px;
			margin-bottom: 10px;
			color: $textMdGrey;
		}

		.primary {
			float: left;
			width: 20%;
			text-align: center;
			padding-left: 20px;
			padding-right: 20px;
		}
		
		.primary.bottom {
			width: 100%;
			padding: 15px 0;
			
		}
		
		li {
			display: inline-block;
			
			a {
			text-align: center;
			padding-top: 10px;
			width: 80px;
			display: inline;
			cursor: pointer;
			}
		}
		
		pre {
			font-family: inherit;
			word-break: normal;
		}
		
		.tools {
			float: right;
			padding: 2px 10px 12px;
			
			&.bottom {
				display: none;
			}
		}

		.description-text {
			float: left;
			width: 65%;
			margin-right: 5%;
			.primary {
				float: none;
				margin: 20px auto auto;
				width: 24%;
				display: block;
			}
		}
		.job-data-contain {
			float: left;
			width: 30%;
			padding: 15px;
			box-sizing: border-box;
			background-color: #f5f5f5;
			margin-bottom: 20px;

			.btn-linkedin {
			    color: #fff;
			    background-color: #007bb6;
			    border-color: rgba(0,0,0,0.2);
				}
			.btn-social>:first-child {
			    position: absolute;
			    left: 0;
			    top: 0;
			    bottom: 0;
			    width: 35px;
			    line-height: 34px;
			    font-size: 1.6em;
			    text-align: center;
			    border-right: 1px solid rgba(0,0,0,0.2);
			    padding-right: 5px;
				}
			.btn-block + .btn-block {
			    margin-top: 5px;
				}
			.btn:focus {
			    color: #fff;
			    text-decoration: none;
				}
			.btn {
			    display: block;
			    padding: 6px 12px;
			    margin-bottom: 0;
			    font-size: 14px;
			    font-weight: normal;
			    line-height: 1.42857143;
			    text-align: center;
			    white-space: nowrap;
			    vertical-align: middle;
			    -ms-touch-action: manipulation;
			    touch-action: manipulation;
			    cursor: pointer;
			    -webkit-user-select: none;
			    -moz-user-select: none;
			    -ms-user-select: none;
			    user-select: none;
			    background-image: none;
			    border: 1px solid transparent;
			    border-radius: 4px;
				}
			.btn-social {
			    position: relative;
			    padding-left: 50px;
			    text-align: left;
			    white-space: nowrap;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    max-width: 150px;
			    margin-top: 5px;
				}
			.btn-linkedin:hover {
			    color: #fff;
			    background-color: #005983;
			    border-color: rgba(0,0,0,0.2);
				}
		}
		.social-connect {
			label {
			    margin-bottom: 0;  
			}
		}
		.job-data-basics {
			line-height: 1.2;
			padding: 0 0 10px;
			
			li {
				display: block;
				margin: 0 0 15px 0;
				
				div {
					float: none;
					width: auto;
					text-align: left;
					padding-bottom:0px;
				}
				
				span {
					float: left;
				}
				
				.currency {
					margin-top: 5px;
				}
			}
		}
		
		.company-info {
			background: $bkgrdLtGrey;
			padding: 20px;
			clear: both;
			margin-bottom: 30px;
			
			p {
				padding-bottom: 20px;
			}
			
			.more-jobs {
				float: right;
			}
		}
		
		.generic-details-text {
			padding-top: 25px;
			padding-bottom: 0;
			
			ul {
				list-style: disc outside none;
				line-height: 0;
				padding-left: 40px;
			}
			p {
				margin-bottom: 15px;
			}
			pre span {
				word-break: normal;
			}
			ol {
				list-style: decimal outside none;
				line-height: 0;
				padding-left: 40px;
			}
			
			li {
				display:list-item;
				line-height: 1.5;
			}
			
			& + .generic-details-text {
				padding-bottom: 25px;
			}
			img {
				max-width: 100%;
				height: auto;
			}
		}
		
		.salaryWrapper {
		    padding: 10px;
		    background: #f5f5f5!important;
		    margin-bottom: 20px;

		    div[style]:first-child {
			    width: auto!important;
			    border: none!important;
			    border-width: 0!important;
			    font-family: inherit!important;
			    color: inherit!important;
			    background-color: transparent!important;
			    padding-bottom: 5px!important;
			    min-height: auto!important;
			}

			div[style]:nth-child(3){
			    display:none;
			}

			div {
			    padding-bottom: 0px;
			}

		}
		#PayscaleSalaryCalculator1[style] {
		    padding: 10px;
		    width: auto!important;
		    position: relative;
		    padding-bottom: 10px!important;
		}
		.PayscaleSalaryCalculator div[style]:first-child div a{
		    position: absolute;
		    bottom: 10px;
		    left: 10px;
		}
		div.PayscaleSalaryCalculator  div  div[style]:nth-child(1){
		     text-transform: capitalize!important;
		    font-size: 15px!important;
		    font-weight: bold!important;
		    line-height: initial!important;
		    margin-left: 0!important;

		}
		form#PayScale_startModule[style] {
		    border-bottom: 1px solid #ddd;
		    margin-bottom: 45px!important;
		    padding: 0!important;
		    padding-bottom: 15px!important;

		}
		
		#PayscaleSalaryCalculatorResults1{
		    padding-bottom: 10px!important;
		}

		#PayScale_startModule {

			input {
     			margin: 5px 0;
			}

 			select {
    			background-color: #fff;
			}

 			span {
			    font-size: 14px;
			    font-weight: normal;
			}

		}


		@include breakpoint(medium) {
			
			li a {
				width: 70px;
			}
			.job-data-contain {
				float: none;
				width: 100%;
				margin-bottom: 20px;
			}
			.description-text {
				float: none;
				clear: both;
				width: 100%;
				margin-top: 20px;
			}
			.bottom-right-sidebar {
			    float: none;
			    clear: both;
			    width: 100%;
			    max-width: 350px;
			    margin: auto;
			}
			.job-data-basics {
				li {
					float: left;
					clear: none;
					width: 29%;
					margin: auto 2%;
				}
				li:nth-of-type(3n+1) {
					clear: both;
				}
			}

		}
		
		@include breakpoint(six-eighty) {
			
			.top-info {
				.company-logo  {
					display: none;
				}
			
				.title-CTA-tools {
					max-width: 100%;
				}
			}
		}
		
		@include breakpoint(small) {
				
			.primary {
				float: none;
				width:100%;
			box-sizing: border-box;
				margin-bottom: 20px;
			}
			
			.primary.bottom {
				margin-bottom: 20px;
			}
			
			.tools {
				float: none;
				text-align: center;
				
				&.bottom {
					display: block;
				}
				
				li {
					width: 47%;
					margin-top: 10px;
					margin-right: 5px;
					
					a {
						width: 100%;
						box-sizing: border-box;
						text-align: center;
						background: $white;
						padding: 10px;
						color: $linkDefault;
						font-size:.9em;
					}

					.inactive {
						background: #ccc;
					}
					
					a:hover {
						color: $linkHover;
					}
				}
				
				li + li {
					margin-left: 0;
				}
			}
			
			.job-data-basics {
				display: block;
				
				li {
					width: 100%;
				}
			}

			.description-text .primary {
				width: 100%;
			}
		}
	}


	
	// CAREERS-2867 Job details modal dialog fix

.job-title-container {
  padding: 20px 0px;
  margin: 0px auto;

  > span {
    font-size: 1.7em;
    font-weight: 700;
    color: #333
	}
}