// -------------------------------------------------------------------
//
//		COMBOBOX TYPE COMPONENTS
//		Created for Framework 2.1.0+
//
//		
//
// -------------------------------------------------------------------

// --------------------------------------------------
//
//  Comboboxs
//
//A combobox that demonstrates the autocomplete behavior known as list with inline autocomplete and uses the ARIA 1.0 implementation pattern.//
//https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html //
// --------------------------------------------------

.combobox-wrapper{
  position: relative;
  .form-input-group{
    @extend .form-label-group;
    margin-bottom: 0;
    label{
      @extend .d-flex;
      height: 2.5rem;
    }
    input{
      @extend .form-control;
      height: 2.5rem;
      color: $textMdGrey;
      &:focus{
        color: $text-muted;
      }
      &:not(:placeholder-shown) ~ label{
        align-items: flex-start;
      }
    }
    .combobox-dropdown{
      position: absolute;
      right: 0;
      top: 0;
      padding: 0 0 2px;
      border-radius: 0 .25rem .25rem 0;
      border: 1px solid $border-input;
      height: 100%;
      @extend .d-flex;
      @extend .align-items-center;
      svg{
        margin: 0 5px;
        max-width: 16px;
        .option-arrow-svg{
          fill :$border-input;
        }
      }
      &:hover {
        background-color:$gray-lightest;
      }
    }
  }
  .hidden {
    display: none;
    visibility: hidden;
  }
  .combobox-listbox{
    z-index: $zindex-dropdown;
    position: absolute;
    border: 1px solid $border-input;
    background: #ffffff;
    border-radius: $border-radius;
    height: auto;
    width: calc(100% - 15px);
    max-height: 420px;
    overflow-y: scroll;
    top: 2.5rem;
   li{
      border-bottom: 1px solid $gray-lightest;
      padding: 8px 10px;
      &:last-child{
        border-bottom: none;
      }
      .result {
        cursor: default;
        margin: 0;
      }
      &.focused {
        background: $gray-lightest;
      }
    }
  }
}


/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .form-label-group input#occupation-input:not(:placeholder-shown) {
     padding-right: 35px;
  }

}
