.page-description-section-container {
  display: flex;
  height: 45px;
  min-height: 45px;
  padding: 0rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-top: 1px solid #ebebeb;
  @media (max-width: map-get($grid-breakpoints, "xl")) {
    height: 100%;
    padding: 0.75rem;
  }
  &.page-description-mobile-only {
    display: flex;
    @media (min-width: map-get($grid-breakpoints, "xl")) {
      display: none;
    }
  }
  &.page-description-desktop-only {
    display: none;
    @media (min-width: map-get($grid-breakpoints, "xl")) {
      display: flex;
    }
  }
  .description-content {
    flex: 1 0 0;
    font-family: Roboto, "sans-serif";
    color: #000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.875rem;
  }
  .dismiss-icon {
    font-size: 1rem;
    color: #000;
    cursor: pointer;
  }
}
