// --------------------------------------------------
//
//		NEW LIST STYLES
//		Framework 2.0.0+
//
// --------------------------------------------------

.list-type {
	&-data {
		list-style: none;
		@extend .rm-m;
		margin-bottom: $grid-gutter-width-lg;

		li {
			padding: $grid-gutter-width-sm 0;
			border-bottom: 1px solid $gray-400;
		}
	}
}