//////////////////////////////////////////////////////////////////////////////////////////

// _COMPANY-CONTACT.SCSS

// This file includes all rules which affect the company contact component generally 
// found on the Job View Page. The rules in this file are not to be modified to 
// accommmodate custom partner sites. Site-specific customization is to be handled 
// in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////



	.company-contact {
		padding: 20px 0;
	}	
	
