//////////////////////////////////////////////////////////////////////////////////////////

// _GENERIC-RESPONSE-MESSAGE.SCSS

// This file includes all rules which affect the generic response message module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	
	
	#generic-response-message {
		padding: 10px;
	}