//////////////////////////////////////////////////////////////////////////////////////////

// _CONTENT_LIBRARY.SCSS

// This file includes all rules which affect the generic content library module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	
	#content-library-listings {
		float: left;
		width: 66%;	

		li {
			display: inline;
		}
		
		.listing-options {
			
			b {
				float: left;
			}
			
			i {
				float: right;
			}
			
			ol {
				float: left;
				padding-left: 10px;
			}

			li + li:before {
				color: $miscLtGrey;
				content: "| ";
				padding: 0 7px;
			}
			.tooltip:hover {
				cursor: pointer;
			}
		}
		
		
		.content-category {
			background: $bkgrdLtGrey;
			padding: 10px;
			margin-bottom: 10px;
			
			em {
				font-style: normal;
			}
			
			h3, .h3 {
				color: $textMdGrey;
				font-size: 1.4em;
				font-weight: 400;
				line-height: 100%;	
				width: 90%;
				display:inline;
			}
			
			i {
				display: block;
				width: 100%;
				text-align: right;
			}
			.save-open {
				float: right;
				width: 10%;
				text-align: center;
				
				li + li:before {
					color: $miscLtGrey;
					content: "| ";
					padding: 0 15px;
					cursor: pointer;
				}
				li {
					a {
						cursor: pointer;
						width: 100%;
					}
				}
			}
			
			em + div {
				padding-top: 20px;
			}

		.category-listing-detail {
			margin-top: 10px;
			background: #f9f9f9;

			h4, .h4 {
				color: #535353;
				font-size: 1.2em;
				font-weight: 400;
				padding: 10px 0 5px;
				margin-bottom: 5px;
				border-bottom: thin dotted $miscLtGrey;
			}
					
			p {
				height: 100px;
				overflow: hidden;
			}
			
			img {
				float:left;
				margin: 0 7px 0 0;
				border: thin solid $miscLtGrey;
			}
			
			.content-links {
				border-top: thin dotted $miscLtGrey;
				li {
					display: block;
				}
			
				a {
					font-size: 1.2em;
					display: block;
					padding: 5px;
				}
				
				.state-links {	
				
					h4, .h4 {
						margin-bottom: 15px;
					}

					li {
						background: none repeat scroll 0 0 #ffffff;
						float: left;
						height: 60px;
						margin: 6px 0 6px 12px;
						width: 60px;
						display:table;
						
						a {
							vertical-align: middle;
							display: table-cell;
							margin: 0 auto;
							text-align: center;
							vertical-align: middle;
						}					
					}
				}
			}
		}
	}
		
		@include breakpoint(medium) {
			width: 59%
		}
		
		@include breakpoint(small) {
			float:none;
			display: block;
			width: 100%;
			
			.content-category h3, .content-category .h3 {
				font-size: 1.3em;
			}
		}
	}
	
	
		
	@import "members-only";
	@import "vertical-network-listing";