//////////////////////////////////////////////////////////////////////////////////////////

// _CAREER-PLANNING-HP-SECTION.SCSS

//////////////////////////////////////////////////////////////////////////////////////////
#career-planning-HP-section {
  @extend .my-3;
  @extend .p-3;
  &.career-planning-HP-0-CTAS {
    .career-planning-HP-advice {
      .career-planning-HP-large-article {
        .entry-article-card {
          //   max-height: 280px;
          margin-block: 0 !important;
        }
      }
    }
  }
  &.career-planning-HP-3-CTAS {
    .career-planning-HP-advice {
      .entry-article-card {
        min-height: auto;
        margin-block: 0 !important;
      }
      .career-planning-HP-large-article {
        .entry-article-card {
          //   max-height: 250px;

          .entry-article-card-subtitle {
            @extend .sr-only;
          }
          .entry-article-card-image {
            min-width: auto;
            width: 100%;
          }
        }
      }
    }
    .career-planning-HP-CTAS {
      .career-planning-HP-CTA {
        @include media-breakpoint-up(sm) {
          min-width: auto;
          flex: 1;
          width: 100%;
          flex: nowrap;
        }
      }
    }
  }
  .interior {
    @extend .py-2;
    max-width: 1060px;
    margin: auto;

    .category-header {
      @extend .text-center;
    }
  }
  .call-to-action {
    @extend .text-center;
    float: none;
  }
  .career-planning-HP-content {
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-lg-row;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .career-planning-HP-advice {
    @extend .d-flex;
    @extend .flex-column;
    gap: 1.5rem;
    width: 100%;
    .entry-article-card {
      @include media-breakpoint-down(sm) {
        margin-left: auto !important;
        margin-right: auto !important;
      }
      @include media-breakpoint-up(md) {
        flex: auto;
        margin: 0 !important;
      }
    }
    .career-planning-HP-large-article {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex: 1 1 100%;
        margin: 0 !important;
      }
      .article-card-author-image {
        img.avatar.photo-small {
          @include media-breakpoint-up(md) {
            max-width: 65px;
          }
        }
        span.avatar.photo-small {
          @include media-breakpoint-up(md) {
            width: 65px;
            height: 65px;
          }
        }
      }
      .entry-article-card-subtitle {
        @include media-breakpoint-down(sm) {
          visibility: hidden;
          height: 0;
        }

        a {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
      .entry-article-card {
        min-height: auto;
        @extend .flex-column;
        @extend .flex-md-row;
        // max-height: 340px;

        @include media-breakpoint-up(lg) {
          flex: auto;
          width: auto;
          align-items: stretch;
        }
        @include media-breakpoint-up(md) {
          width: auto;
        }
        .entry-article-card-heading {
          @extend .pb-3;
        }
      }
      .entry-article-card-image {
        @include media-breakpoint-up(md) {
          max-height: unset;
          border-radius: 0.25rem 0 0 0.25rem;
          min-width: 300px;
          max-width: 300px;
        }
      }
      .entry-article-card-content {
        @extend .p-md-2;
        width: 100%;
        z-index: 2;
        @include media-breakpoint-up(md) {
          display: flex;
          flex-direction: column;
          padding-top: 30px !important;
        }
      }
      .entry-article-card-topic {
        @extend .mt-md-0;
      }
    }
    .career-planning-HP-row-articles {
      @extend .d-flex;
      @extend .flex-column;
      @extend .flex-md-row;
      height: 100%;
      gap: 1.5rem;
    }
  }
  .career-planning-HP-CTAS {
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-lg-column;
    @extend .flex-md-row;
    gap: 1.5rem;
    // @extend .mt-1;
    // @extend .mx-2;
    // @extend .mx-lg-2;
    // @extend .mx-md-0;
    // @extend .mx-sm-2;
    @media (min-width: 992px) {
      width: 60%;
    }
    @media (min-width: 576px) and (max-width: 768px) {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
    }
  }

  .career-planning-HP-CTA {
    @extend .card-2;
    @extend .d-flex;
    @extend .flex-column;
    width: 100%;
    // @extend .mb-4;
    @include media-breakpoint-down(xs) {
      max-width: 400px;
      margin: auto;
    }

    @include media-breakpoint-up(sm) {
      //   margin-right: 0.5rem;
      //   margin-left: 0.5rem;
      text-align: left;
    }
    @include media-breakpoint-up(md) {
      text-align: center;
      width: auto;
    }
    @include media-breakpoint-up(lg) {
      min-width: 250px;
      flex: 1 1 100%;
      justify-content: space-evenly;
      text-align: left;
    }
    .career-planning-HP-svg {
      fill: currentcolor;
    }
    .career-planning-HP-CTA-heading-container {
      @extend .d-flex;
      @extend .flex-row;
      @extend .flex-lg-row;
      @extend .flex-md-column;

      @extend .align-items-center;
      @extend .pb-3;

      svg {
        @extend .mr-2;
        @include media-breakpoint-up(sm) {
          max-width: 30px;
        }
        @include media-breakpoint-up(lg) {
          max-width: 40px;
        }
      }
    }
    .career-planning-HP-CTA-heading {
      font-weight: $font-weight-normal;
      font-size: $font-size-xl;
      color: $textMdGrey;

      &:hover {
        color: currentcolor;
      }
    }
    .career-planning-HP-CTA-body {
      font-weight: $font-weight-normal;
      font-size: $font-size-sm;
      color: $textMdGrey;

      &:hover {
        color: currentcolor;
      }
    }
  }
}

//Career Coaches//
#career-coaches-HP-section {
  .interior {
    @extend .py-2;
    max-width: 1060px;
    margin: auto;

    .category-header {
      @extend .text-center;
      padding-bottom: 50px;
    }
    .career-coaches-HP-content {
      @extend .d-flex;
      @extend .flex-column;
      @extend .flex-lg-row;
      @extend .align-items-center;
      .bio-card {
        min-height: 321px;
      }
    }
  }
  .call-to-action {
    float: none;
    @extend .text-center;
  }
}
