//////////////////////////////////////////////////////////////////////////////////////////

// _CAREER-ADVICE-HP-SECTION.SCSS


//////////////////////////////////////////////////////////////////////////////////////////
#featured-career-advice{
	margin-bottom: 20px;
	.category-header{
		@extend .text-center;
	}
	.entry-article-card-hp-container{
		@extend .p-2;
		@extend .pb-3;
		@extend .d-flex;
		@extend .flex-row;
		@extend .flex-wrap;
		@extend .justify-content-center;
	}
	.featured-career-advice-view-all{
		@extend .text-center;
	}

}
