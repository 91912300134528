//////////////////////////////////////////////////////////////////////////////////////////

// DEPRECIATED -- DO NOT MODIFY THIS FILE ANYMORE

// _FILTERS.SCSS

// This file includes all rules which affect the generic filters module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customization is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	.filters {
		padding-bottom: 20px;
		
		form {
			padding-bottom:15px;
			border-bottom: thin dotted $miscLtGrey;
		}
		
		fieldset {
			padding: 5px 0 15px;
			border: none;
		}
		
		h5, .h5 {			
			box-sizing: border-box;
			background: $bkgrdLtGrey;
			font-size: 1.3em;
			font-weight: 400;
			text-transform: uppercase;
			padding: 10px 10px;			
			display: block;
			width: 100%;
		}
			
		h5+div, .h5+div {
		    display: block;
   			padding-top: 10px;
		}
				
		.collapse {
			display: none;
			
			@include breakpoint(small) {
				display: inline;
				float: right;
				padding-right: 10px;
				
				a {
					background: transparent;
				}
			}
		}
		
		em {
			font-style: normal;
		}
		
		i, em {
			color: $textGrey;
			font-style: italic;
			display: block;
		}
		
		input[type="checkbox"] {
			float: left;
			margin-top: 0.5em;
		}
		input[type="text"] {
			width:100%;
			padding:5px;
		}
		input[type="radio"] {
			margin-top: 10px;
			display: inline-block;
		}
		
		label {
			color: $textMdGrey;
			padding: 0 10px;

			
			@include breakpoint(medium) {
				padding: 0 2%;
			}
			
			@include breakpoint(small) {
				padding: 0 10px;
			}
		}
		
		u {
			color: $textLtGrey;
			text-decoration: none;
		}
		
		ul {
			padding: 5px 10px 0;
			
			li {
				color: $linkDefault;
				font-size: 1.1em;
				line-height: 2;
			}
		}
		
		ul + a {
			display: block;
			background: none;
			padding: 0 10px 10px;
		}
		
		ul.text-input {
			padding: 10px 0;
		}

		p {
			.fa-plus, .fa-minus {
				display: none;
				margin-top: 3px;
				float: right;
			}
		}
		
		select {
			border: thin solid #eeeeee;
			color: #666666;
			display: block;
			font-size: 1.1em;
			padding: 5px 0;
			margin-top: 10px;
			width: 100%;
			
			option {
				padding: 2px 10px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.call-to-action {
			display: block;
			padding-top: 10px;
			float:none;
			
			i {
				padding-top:8px;
			}
		}
	}
	@include breakpoint(small) {
		.filters {
			p {
				.fa-plus {
					display: inline-block;
				}
				.refine {
					font-size: 1.3em;
					text-transform: uppercase;
					background-color: #f5f5f5;
					padding: 10px;
					display: block;
					margin-bottom: 10px;
					cursor: pointer;
						strong {
						font-weight: normal;
					}
				}
			}
		}
		#results-page aside .filters p em {
			float: none;
			margin: 15px auto 5px 10px;
		}
	}
