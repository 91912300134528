//////////////////////////////////////////////////////////////////////////////////////////

// _HERO.SCSS

// This file includes all rules which affect the generic hero module.
// The rules in this file are not to be modified to accommmodate custom partner sites. 
// Site-specific customiztion is to be handled in a separate css file.

// Any questions, please contact Reina McCuskey at r.mccuskey@jobtarget.com

//////////////////////////////////////////////////////////////////////////////////////////
	
	#hero {
		height:325px;
		background: url("../img/photos/hero-1.jpg") no-repeat 0 0 transparent;
		position: relative;
		box-sizing: border-box;
		
		.site-name-tag {
			text-align: center;
			position: absolute;
			top: 80px;
			left: 520px;
			
			h1 {
				font-size: 2.5em;
				margin-bottom: 0;
				margin-top: 0;
				line-height: 1;
			}
			
			em {
				font-size: 1.9em;
				font-style: normal;
			}
			em + h1 {
				margin-top: 10px;
			} 		
		}

		#job-search-form form {
			box-sizing: border-box;
			width: 100%;

	
			fieldset .primary {
				padding: 10px;
				width: 100%;
			}
		}
		
		@include breakpoint(medium) {
			.site-name-tag {
				top: 60px;
				left: 480px;
				
				h1 {
					font-size: 2.4em;
				}
				
				em {
					font-size: 1.7em;
				}
			}
	
			#job-search-form form fieldset {
				width: 30%;
			}
	
			#job-search-form form fieldset.call-to-action {
				width: 30%;
			}
		}
		
		@include breakpoint(small) {
			height: auto;
			background: $bkgrdLtGrey;
			padding-bottom: 20px;
			
			.site-name-tag {
				background: $bkgrdLtGrey;
				top: 0;
				left: 0;
				position: relative;
				padding-bottom: 30px;
			}
			
			h1 {
				margin:0;
			}
			
			#job-search-form form {
				margin-top: 0 !important;
				position: relative;
				background: $white;
				margin-top: 100px;
				padding-bottom: 0;

				fieldset {
					float:none;
					display: block;
					padding:0 0 20px;
					width: 100%;
									
					button {
						width: 100%;
					}
				
					.primary {
						font-size: 1.2em;
						text-align: center;
						float: none;
						display: block;
					
					}
				}
				fieldset.call-to-action {
					width: 100%;
				}
			}
		}
	}