.career-fair-card-parent-container {
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    background: #fff;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 425px) {
      padding-inline: 0.5rem;
    }
    .career-fair-content-container {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      flex: 1 0 0;

      .career-fair-logo {
        @extend %col;
        @extend %col-auto;
        @extend %d-flex;
        @extend %align-items-center;
        @extend %justify-content-center;
        line-height: inherit !important;
        text-align: center;
        background: $company-logo-bg;
        border: 1px solid $gray-400;
        border-radius: 0.25rem;
        width: 60px;
        height: 60px;
        display: flex;
        border: 1px solid $gray-400;
        width: 60px;
        height: 60px;
        img {
          max-width: 56px;
          height: auto;
        }
        &.logo-none {
          background: $company-logo-empty-bg;
          color: $company-logo-empty-font-color;
          @extend .h6;
          @extend .rm-m-b;
        }
      }
      .logo-placeholder {
        display: flex;
        width: 3.75rem;
        height: 3.75rem;
        padding: 0.75694rem 0.71875rem 0.74306rem 0.78125rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
        border: 2px dashed #ebebeb;
        .icon {
          font-size: 2.25rem;
          flex-shrink: 0;
          color: var(--site-primary-color,#1badba)
        }
      }
      
      .career-fair-contents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
        flex: 1 0 0;
        font-family: Inter, "sans-serif";
        line-height: normal;
        font-style: normal;
        .career-fair-title {
          align-self: stretch;
          color: #222;
          font-size: 0.875rem;
          font-weight: 600;
        }
        .career-fair-subtext {
          align-self: stretch;
          color: #222;
          font-size: 0.75rem;
          font-weight: 600;
        }
        .career-fair-date {
          align-self: stretch;
          color: #4a4a4a;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
    .career-fair-register-button {
      border-radius: 0.25rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background: #fff;
      width: 7.4375rem;
      height: 2.25rem;
      padding: 0.625rem 0.5rem 0.5rem 0.5rem;
      color: var(--site-primary-color,#1badba);
      font-family: Roboto, "sans-serif";
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &:hover{
        border-color: var(--site-primary-color,#1badba);
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
