// --------------------------------------------------
//
//		SPACING UTILITIES
//		Created for Framework 2.0.0+
//
// --------------------------------------------------

// Rule with no Margin/Padding

.rm-spacing {
	padding: 0;
	margin: 0;
}

// Fake HR
%hr-top-full {
	&::before {
		content: " ";
		height: 0;
		display: block;
		border-top: $border-width solid $border-color !important;
		width: 100vw;
	}
}