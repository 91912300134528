// --------------------------------------------------
//
//		CAREER-PLANNING DESIGN SYSTEM
//		Be very careful when updating styles for these styles affect pages below
//		* Career Insights
//      * Career Advice
//  	* Career Coaching
//
// --------------------------------------------------

.career-planning-max-body-width{
	max-width:1280px;
	@extend .mx-auto;
}
.career-planning-bottom-page-padding{
	padding-bottom: 50px;
}
//Landing Page//Header//

.career-planning-landing-header{
	height: 225px;
	@extend .d-flex;
	@extend .flex-column;
	@extend .justify-content-center;
	position: relative;
	@include media-breakpoint-down(sm) { 
		height: 100%;
		min-height: 225px;
	}

}
.career-planning-landing-intro{
	@extend .container-fluid;
	@extend .d-flex;
	@extend .flex-row;
	@extend .align-items-start;
	@extend .justify-content-between;
	@extend .py-4;
	@extend .py-sm-0;
	padding-right: 100px;
}

.career-planning-landing-intro-header-h1{
	@extend .m-0;
	color: #fff;
	font-size: 2.5em;
    font-weight: 300;

}

.career-planning-landing-intro-header-p{
	font-size: 1rem;
	color: #fff;
	
}

//Landing Page//Body//
.career-planning-landing-body{
	@extend .container-fluid;
	@extend .pt-4;
	@extend .career-planning-bottom-page-padding;

}

.career-planning-body-header{
	max-width: 1000px;
	font-size:$font-size-xl;
	font-weight: $font-weight-base;
	color: $textMdGrey;
	@include media-breakpoint-down(xs) { 
		font-size:$font-size-lg;
	}
}
.career-planning-body-sub-header{
	font-size:$font-size-lg;
}

//Topic Nav//
.career-advice-topic-nav{
	@extend .card-2;
	@extend .p-0;

	.career-advice-topic-nav-container{
		@extend .career-planning-max-body-width;
		@extend .p-2;
		@extend .d-flex;
		@extend .flex-row;
		@extend .flex-wrap;

		.career-advice-topic-nav-heading-container{
			@extend .d-flex;
			@extend .flex-row;
			@extend .align-items-center;
			margin-right: 5px;
			svg{
				width: 17px;
				height: 17px;
				.browse-tags-icon{
					fill: $gray;
				}
			}

			.career-advice-topic-nav-heading{
				@extend .pl-2;
				font-size: $font-size-sm;
				font-weight: $font-weight-normal;
				color: $gray;
			}
		}
	}
}
//breadcrumbs with share above main content sections//
.career-planning-main-content-header-bar{
	@extend .d-flex;
	@extend .flex-row;
	@extend .align-items-start;
	@extend .justify-content-between;
	@extend .mb-3;

	.page-breadcrumbs{

		.breadcrumb{
			@extend .p-0;
		}
	}
}
//View All Page and Sections//
.career-planning-view-all-body{
	@extend .py-2;
	@extend .px-3;
	@extend .px-md-2;
	@extend .d-flex;
	@extend .flex-row;
	@extend .flex-wrap;

}
.career-planning-view-all-heading-bar{
	@extend .container-fluid;
	@extend .d-flex;
	@extend .flex-row;
	@extend .flex-wrap;
	@extend .align-items-center;
	@extend .justify-content-between;
}
.career-planning-view-all-heading{
	@extend .d-flex;
	@extend .flex-row;
	@extend .align-items-center;

	h1, .career-advice-view-all-section-heading-title {
		@extend .pr-1;
		@extend .pr-sm-2;
		@extend .m-0;
		@extend .py-1;

	}
	h1{
    	@extend .career-planning-body-header;
	}
	.career-advice-view-all-section-heading-title{
		@extend .career-planning-body-sub-header;
	}
	
}
//Article Card CTA
.career-planning-article-card-cta{
	@extend .p-3;
	@extend .justify-content-between;
}
.career-planning-article-card-cta-heading{
	@extend .d-flex;
	@extend .flex-row;
	@extend .align-items-center;
	@extend .mb-4;

	svg{
		@extend .mr-2;
		width: 34px;
    	height: 34px;
	}

}
.career-planning-article-card-cta-icon{
	fill: $gray;	
}
.career-planning-card-cta-heading-title{
	font-weight: $font-weight-normal;
	font-size: $font-size-lg;
}
.career-planning-article-card-cta-body{
	font-weight: $font-weight-normal;
	font-size: $font-size-base;
	color: $gray;
	@extend .pb-1;
}
.career-planning-article-card-cta-link{
	font-size: $font-size-base;
	font-weight: $font-weight-light-bold;
}
//load more button 
.load-more-button{
	@extend .m-auto;
	@extend .d-block;
}

//breadcrumbs
.page-breadcrumbs{
	font-size: .9rem;
	.breadcrumb {
		@extend .mb-0;
	}
	.breadcrumb-item.active {
	    color: $gray;
	}
}

//social icons row
.bio-social-icons{
	ul{
		@extend .d-flex;
		@extend .flex-row;
		@extend .align-items-center;
		@extend .justify-content-center;

		li{
		    width: 40px;
		    height: 40px;
		    border-radius: 100%;
		    margin: 0 3px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    border: 1px solid #fff;
		    font-size: 16px;

		    &.bio-social-link-facebook{
		    	
		    	background-color: $facebookBlue;
		    	&:hover{
		    		background-color: #fff;
		    		border-color: $facebookBlue;
		    		& a{
		    			color: $facebookBlue;
		    		}
		    	}
		    }
		    &.bio-social-link-twitter{
		    	
		    	background-color: $twitterBlack;
		    	&:hover{
		    		background-color: #fff;
		    		border-color: $twitterBlack;
		    		& a{
		    			color: $twitterBlack;
		    		}
		    	}
		    }
		    &.bio-social-link-linkedin{
		    	
		    	background-color: $linkedinBlue;
		    	&:hover{
		    		background-color: #fff;
		    		border-color: $linkedinBlue;
		    		& a{
		    			color: $linkedinBlue;
		    		}
		    	}
		    }
		    &.bio-social-link-youtube{
				background-color:$youtubeRed;
				&:hover{
		    		background-color: #fff;
		    		border-color: $youtubeRed;
		    		& a{
		    			.youtube-link-icon{
			    			fill: $youtubeRed;
			    		}
		    		}
		    	}
		    }
		    &.bio-social-link-instagram{
		    	font-size: 20px;
		    	position: relative;
		    	background: #d6249f;
				background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
				z-index: 1;
		    	&:hover{
		    		background: #fff;
		    		&:before{
		    			position: absolute;
		    			content: '';
		    			z-index: 1;
					    border-radius: 100%;
		    			background: #fff;
		    			width: 40px;
		    			height: 40px;
		    		}
		    		&:after{
		    			position: absolute;
		    			content: '';
		    			z-index: -1;
					    border-radius: 100%;
		    			background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
		    			width: 42px;
		    			height: 42px;
		    		}
	
		    		& a{
		    			background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
						-webkit-background-clip: text;
						z-index: 2;
						background-clip: text;
						-webkit-text-fill-color: transparent;
			    	
		    		}
		    	}
		    }
		    &.bio-social-link-website{
		    	background-color: $websitePurple;
		    	&:hover{
		    		background-color: #fff;
		    		border-color: $websitePurple;
		    		& a{
		    			.website-link-icon{
			    			fill: $websitePurple;
			    		}
		    		}
		    	}
		    }
		    a{    
			    width: 40px;
			    height: 40px;
			    border-radius: 100%;
			    align-items: center;
			    justify-content: center;
		    	color: #fff;
		    	display: flex;
		    	.website-link-icon{
		    		fill: #fff;
		    	}
		    	.youtube-link-icon{
		    		fill:#fff;
		    	}
		    }
		}
	}
}

@each $advice-colors, $color in $advice-colors {
  	.topic-page-#{$advice-colors} {
	  	.career-advice-view-all-page-heading{
	  		.tag{
		  		background: #{$color};
		  		color: #fff;
		  	}
	  	}
	  	.article-card-cta-icon{
		  	fill: #{$color}!important;
		}
	  	.entry-article-card-cta-link{
	  		color: #{$color};
	  		
	  		&:hover{
	  			color: $gray;
	  		}
	  	}
    }
}

