// --------------------------------------------------
//
//		OCCUPATION SEARCH PAGE
			//Landing page 
			//Results page
//
// --------------------------------------------------
//Landing page 
.occupation-landing-page{

	.occupation-landing-header{
		background:linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),  url(../img/career-insights-landing-hero.png) no-repeat center center / cover;
		@extend .career-planning-landing-header;

		.occupation-landing-intro{
			@extend .career-planning-landing-intro;
			@extend.career-planning-max-body-width;
		
			.occupation-landing-intro-header{
				@extend .text-left;

				h1{
					@extend .career-planning-landing-intro-header-h1;
				}

				p{
					@extend .career-planning-landing-intro-header-p;
				}
			}
		}
	}
	.alert{
		max-width: 1000px;
		margin: 0 10px 10px 10px;
	}
	.occupation-landing-body{
		@extend .career-planning-landing-body;

		.occupation-landing-body-header{
			@extend .career-planning-body-header;
			@extend .text-center;
			@extend .m-auto;
			max-width: 1000px;
		}
		.occupation-landing-insights{
			@extend .container-fluid;
			max-width: 1250px;
			@extend .d-flex;
			@extend .flex-row;
			@extend .justify-content-center;
			@extend .flex-wrap;
			@extend .py-3;

			.insights-container-block{
				@extend .p-3;
				@extend .mx-3;
				@extend .my-1;
				@extend .my-sm-3;
				@extend .d-flex;
				@extend .flex-column;
				@extend .flex-wrap;
				@extend .justify-content-start;

				@include media-breakpoint-down(xl) { 
					flex: 0 0 auto;
					width: calc(25% - 2rem);
				}

				@include media-breakpoint-down(lg) { 
					flex: 0 0 auto;
					width: calc(33.33% - 2rem);
				}
				@include media-breakpoint-down(md) { 
					flex: 0 0 auto;
					width: calc(50% - 2rem);
				}
				@include media-breakpoint-down(sm) { 
					flex: 0 0 auto;
					width: 100%;
					max-width: 480px;
				}
			}
			.insights-item{
				@extend .mb-2;
			}
			.insights-item-title{
				@extend .ml-2;
			}
		}
	}
}

//occupation search bar
.occupation-search-header{
	box-shadow: $box-shadow-sm;
	background-color: #ffffff;
	@extend .flex-column;
	@extend .d-flex;
	@extend .justify-content-center;
	@extend .align-items-center;

	.occupation-search-form{
		@extend .container-fluid;
		max-width: 1250px;
		@extend .d-flex;
		@extend .form-row;
		@extend .py-3;

		.occupation-search-input-wrapper{
			position: relative;
			@extend .col-md;
			@extend .pl-0;
			@extend .pr-md-3;
			@extend .pr-0;
			@extend .mb-md-0;
			@extend .mb-3;
			
		  	.form-input-group{
			    @extend .form-label-group;
			    margin-bottom: 0;

			    label{
			      @extend .d-flex;
			      height: 2.5rem;
			    }
			    input{
			      	@extend .form-control;
			      	height: 2.5rem;
			      	color: $textMdGrey;
			      	&:focus{
			        	color: $text-muted;
			      	}
			      	&:not(:placeholder-shown) ~ label{
			        	align-items: flex-start;
			      	}
			    }
		    }	
		}
		span{
			@include media-breakpoint-down(sm) { 
				width: 100%;
			}

		}
		button{
		    text-decoration: none !important;
		    padding: 8px 25px !important;
		    cursor: pointer !important;
		    text-align: center !important;
		    margin: 0 !important;
		    line-height: normal !important;
		    border-radius: $border-radius!important;
		    height: 100%;
		    @extend .d-flex;
		    @extend .justify-content-center;
			@extend .align-items-center;
			@extend .col-md-auto;
			@extend .col-12;

		    svg {
			    margin-left: 10px!important;
			}
		}

	}
}

//Results page 
.occupation-results-page{
 	//search bar header 
 	.occupation-base-title{
 		font-size: $font-size-base;
 		font-weight: $headings-font-weight;
 	}
	.occupation-search-main{
		@extend .container-fluid;
		max-width: 1250px;
		//intro
		.occupation-search-intro{
			@extend .mb-3;
			@extend .d-flex;
			@extend .flex-row;
			@extend .align-items-start;
			@extend .justify-content-between;

			.occupation-search-intro-header{
				width: 100%;
				p{
					font-size: $h6-font-size;
					margin-bottom: 10px;
				}
			}
			.occupation-search-intro-actions{
				@extend .d-flex;
				@extend .flex-row;
				@extend .mt-3;
				min-height: 60px;
			}
		}
		.occupation-known-as{
			@extend .d-flex;
			@extend .flex-row;
			@extend .flex-wrap;
			@extend .align-items-center;
			@extend .mb-3;
		}
		//search results
		.occupation-search-results{
			position: relative;
			.occupation-results-row{
				@extend .d-flex;
				@extend .align-self-stretch;
				@extend .align-items-stretch;
				@extend .flex-column;
				@extend .flex-lg-row;
				@extend .mb-3;

				&.adjust-employment-no-outlook{
				    @extend .flex-column;

				    .occupation-employment-video-container{

				    }
				}
				.occupation-wages-results{
					@extend .card-2;
					width:100%;
					@extend .d-flex;
					@extend .justify-content-between;
				    @extend	.flex-column;
				    @extend .mr-lg-3;
				    @extend .mb-3;
				    @extend .mb-lg-0;
					padding: 0;
					position: relative;
					&.adjust-wages-no-video{
						@extend .col-lg-9;
					}
					&.adjust-wages-no-video-no-employment{
						@extend .mb-0;
						@extend .mr-0;
					}
					&.adjust-wages-no-outlook{
						@extend .col-lg-12;
						@extend .mb-3;
						@extend .mr-0;
					}
					&:before{
						content: " ";
						position: absolute;
						width: 100%;
						height: 50px;
						background:$gray-lightest;
						display: inline-block;
						left: 0;
						bottom:0;
						z-index: 0;

					}

					.occupation-wages-header{
						@extend .d-flex;
					    @extend .justify-content-between;
					    @extend .align-items-center;
					    @extend .pt-3;
					    @extend .px-3;
					    @extend .pb-3;
						@extend .pb-lg-1;
					    .occupation-wages-title{
					    	@extend .card-2-title;
					    }

					    .occupation-wages-nav{
					    	@extend .d-flex;
					    	@extend .nav;
					    	border-bottom: none;
					    	li{
					    		border: 1px solid $gray-lightest;
					    		padding: .3rem 1rem;
					    		&:first-child{
					    			border-radius: 0.25rem 0 0 0.25rem;
					    		}
					    		&:last-child{
					    			border-radius: 0 0.25rem 0.25rem 0;
					    		}
					    		&:hover{
					    			background: $gray-lightest; 
					    		}
					    		&:hover a{
				    	 			color: $textMdGrey;
				    	 		}
					    		a{
					    			@extend .nav-link;
					    	 		color: $textMdGrey;
					    	 		padding: 0;
					    	 		
					    		}
					    	}
					    }
					}
					.tab-content{
						position: relative;
						
						.tab-pane {
						    display: block;
						    height: 0;
						    overflow: hidden;
						}
						.tab-pane.active {
						    height: auto;

						}
					}
					.wages-subtitle{
						@extend .card-2-subtitle;
						@extend .pb-3;
						@extend .px-3;
						max-width: 700px;
					}
					.wages-chart-container{
					    position: relative;
					    width: 100%;
					    
					}
					canvas{
						position: relative;
						z-index: 99;
						padding-bottom: 5px;
					}
				}
				.occupation-employment-video-container{
					width:100%;
					@extend .d-flex;
					@extend .px-0;
					@extend .flex-column;


					.occupation-employment-results{
						position: relative;
						@extend .mb-3;
						@extend .d-flex;
						@extend .px-0;
						@extend .flex-column;
						@extend .flex-md-row;

						&.adjust-outlook-no-video{
							@extend .mb-0;
							@extend .flex-lg-column;
							@extend .d-flex;
							flex: 1 1 100%;
							&.occupation-employment-results {
				              .locked-panel .locked-panel-cta{
				                @extend .justify-content-center;
				              }
				            }
							.occupation-outlook-results{
								@extend .mr-lg-0;
								@extend .mb-lg-3;
								@extend .mb-md-0;
								@extend .mb-sm-3;
								

								.occupation-outlook-results-container{
									height: 100%;
									@extend .d-flex;
									@extend .justify-content-around;
									@extend .flex-column;

									.occupation-outlook-title{
										font-size: 1.3rem;
									}
								}
							}
							.occupation-projected-results{
								.occupation-projected-results-text{
									@extend .mb-0;
									@extend .mb-md-3;
								}
							}
						}
						.locked-panel {

						    .locked-panel-cta{
								@extend .justify-content-md-end;
						    }
						}
						.occupation-outlook-results{
							@extend .card-2;
							width:100%;
							flex: 1 1 50%;
							@extend .d-flex;
							@extend .justify-content-between;
						    @extend	.flex-column;
						    @extend .mr-0;
							@extend .mr-md-3;
							@extend .mb-3;
				    		@extend .mb-md-0;
							color: #fff;
							background-color: $system-blue;
							@include media-breakpoint-down(md) { 
								flex-basis: auto;
							}
							
							.occupation-outlook-title-container{
								@extend .d-flex;
								@extend .align-items-center;
								svg{
							    	background: rgba(255, 255, 255, .2);
									width: 52px;
									height: 52px;
									border-radius: 100%;
									padding:10px;
									@extend .mr-3;
									
									.job-outlook-svg{
										fill:#fff;
									}
								}
								
								.occupation-outlook-title{
									font-weight: $font-weight-normal;
								    font-size: $font-size-base;
									span{
										display: block;
										font-weight: $font-weight-bold ;
									}
								}
							}
							.occupation-outlook-description{
								@extend .pt-2;
								@extend .mt-4;
								border-top: 1px solid rgba(255, 255, 255, .2);
							}
						}
						.occupation-projected-results{
							width:100%;
							flex: 1 1 50%;
							@extend .d-flex;
							@extend .mr-0;
							position: relative;
							-webkit-perspective: 800px;
						    -moz-perspective: 800px;
						    -o-perspective: 800px;
						    perspective: 800px;
						    @include media-breakpoint-down(md) { 
								flex-basis: auto;
							}

							.occupation-projected-results-container{
								width: 100%;
							    height: 100%;
							    position: relative;
							    -webkit-transform-style: preserve-3d;
							    transform-style: preserve-3d;
							    -webkit-transition: all 600ms;
							    transition: all 600ms;
							    -webkit-transform-origin: 50% 50%;
							   

								&.flipped {
								    -webkit-transform: rotateY( 180deg );
								    -moz-transform: rotateY( 180deg );
								    -o-transform: rotateY( 180deg );
								    transform: rotateY( 180deg );
								   
								    .occupation-projected-state{
								    	position: absolute;
								    	&:after{
	    									display: none;
										}
								    }
								    .occupation-projected-national{
								    	position: relative;
								    	overflow: hidden;
								    	-webkit-backface-visibility: visible;
								    	-moz-backface-visibility:visible;
								    	-o-backface-visibility: visible;
								    	backface-visibility: visible;
								    	&:after{
	    									position: absolute;
										    content: url(../img/bar-lines.png);
										    bottom: -1.1rem;
										    right: -0.7rem;
										}
								    }
								}
								.occupation-projected-national{
									@extend .card-2;
									position:absolute;
									-webkit-backface-visibility: hidden;
								    -moz-backface-visibility: hidden;
								    -o-backface-visibility: hidden;
								    backface-visibility: hidden;
									-webkit-transform: rotateY( 180deg );
								    -moz-transform: rotateY( 180deg );
								    -o-transform: rotateY( 180deg );
								    transform: rotateY( 180deg );
								    transition-timing-function: ease-in-out;
								    -webkit-transition: -webkit-transform .8s;
								    -moz-transition: -moz-transform .8s;
								    -o-transition: -o-transform .8s;
								    transition: transform .8s;
								    -webkit-transform-style: preserve-3d;
								    -moz-transform-style: preserve-3d;
								    -o-transform-style: preserve-3d;
								    transform-style: preserve-3d;
								    -webkit-transform-origin: 50% 50%;
								    height: 100%;
    								width: 100%;
    								

								}
								.occupation-projected-state{
									@extend .card-2;
									position:relative;
									overflow: hidden;
									-webkit-backface-visibility: hidden;
								    -moz-backface-visibility: hidden;
								    -o-backface-visibility: hidden;
								    backface-visibility: hidden;
								    height: 100%;
    								width: 100%;
    								&:after{
    									position: absolute;
									    content: url(../img/bar-lines.png);
									    bottom: -1.1rem;
									    right: -0.7rem;
									}
								}
								.occupation-projected-results-text{
									font-weight: $font-weight-normal;
									font-size: $h6-font-size;
								}
								.occupation-projected-number{
									@extend .d-flex;
									@extend .flex-row;
									@extend .align-items-center;
									span{
										font-weight: $font-weight-normal;
										font-size: $h2-font-size;
										color:$gray;
										padding-right: 7px;
									}
								}
								button{
									background: transparent;
								    border: none;
								    padding: 0;
								    margin-top: 10px;
								    font-weight: 500;
								    color:$gray;
								    &:hover{

								    }
								}
							}
							
						}
					}
					.occupation-video-results{
						@extend .col-auto;
						@extend .card-2;
						@extend .d-flex;
						@extend .justify-content-between;
						@extend	.flex-column;
						@extend .embed-responsive;
						@extend .embed-responsive-16by9;
						padding: 0;
						position: relative;
						flex: 1;
						aspect-ratio: 2;
						&::before{
							padding-top: 0px;
						}

						iframe{
							width: 100%;
							height: 100%;
							position: relative;
						}
						.occupation-video-heading{
							font-weight: $font-weight-normal;
							font-size: $h6-font-size;
							position: relative;
							@extend .p-3;
						}
					}
				}
				.occupation-details-results{
					@extend .card-2;
					@extend .mr-lg-3;
					@extend .mb-3;
				    @extend .mb-lg-0;
					padding: 0;
					width:100%;
					position: relative;
						
					.occupation-details-nav{
						@extend .d-flex;
						@extend .align-self-stretch;
						@extend .align-items-stretch;
						@extend .flex-row;
						border-bottom: 1px solid $gray-lightest;
						text-align: center; 

						li{
							@extend .col-4;
							@extend .p-3;
							position: relative;

							&:hover a{
								color: $textMdGrey;

								&:after{
									background: $system-pink;
								}
							}
							a{
								color: $textMdGrey;
								width: 100%;

								&:after{
								    height: 3px;
								    transition: .16s all 25ms;
								    position: absolute;
								    bottom: 0;
								    left: 0;
								    display: inline-block;
								    width: 100%;
								    content: "";
								    background: transparent;
								} 
								&.active{
									color: $system-pink;

									&:after{
								    background: $system-pink;
									}
								}
							}
						}
					}
					.occupation-details-subtitle{
						@extend .card-2-subtitle;
						@extend .py-3;
					}
					.occupation-details-content{
						@extend .tab-content;
						@extend .px-3;

						li.occupation-details-list{
							@extend .d-flex;
						    @extend .flex-row;
							@extend .py-3;
							@extend .align-items-start;
							border-bottom: 1px solid $gray-lightest;
							&:last-child{
								border-bottom: none;
							}

							svg.svg-circle-icon{
								margin-top: 5px;
								max-width: 9px;
								max-height: 9px;
							    margin-right: 10px;
							    width: 100%;
							    flex: 0 0 10px;
							    .svg-circle-inner{
							    	fill:$system-pink;
							    }
							}
							span.occupation-details-list-title{
								font-weight: $font-weight-bold;
							}
						}
						.occupation-details-pagination{
							@extend .my-3;
							@extend .mx-auto;
							ul.pagination{
								flex-wrap: nowrap;
								max-width: 200px;
								margin:auto;
								@extend .justify-content-center;
								li.page-item{
									flex-basis: auto;

									&.active a{
										color:$system-pink;
									}
									a {
										padding: 1em;
									}
								}
							}
						}
					}
				}
				.occupation-education-results{
					position: relative;
					@extend .card-2;
					width:100%;
					@include media-breakpoint-up(lg) { min-width: 650px;}
					@include media-breakpoint-up(xl) { min-width: 800px;}
					
					.occupation-education-header{
						@extend .pb-3;
						.occupation-education-title{
							@extend .card-2-title;
						}
						.occupation-education-subtitle{
							@extend .card-2-subtitle;
						}

					}
					.education-charts-container{
						@extend .d-flex;
						@extend .flex-column;
						@extend .flex-md-row;
						.education-doughnut-chart{
							@extend .col-md;
							width:100%;
						}
						.education-percentage-bar{
							@extend .col-md;
							@extend .mt-md-0;
							@extend .mt-3;
							@extend .mx-auto;
							width:100%;
							@include media-breakpoint-down(md) { 
							max-width:500px;
							}

						}

					}
				}
				&.tertiary-content-tile{

				}
				.occupation-jobs-like{
					@extend .card-2;
					@extend .mr-lg-3;
					@extend .mb-3;
				    @extend .mb-lg-0;
				    width: 100%;
					padding: 0;
					@include media-breakpoint-up(lg) { max-width: 70%;}
					&.tertiary-content-tile{
						@extend .ml-0;
						@extend .mt-0;
					}
					&.jobs-full-width{
         				max-width: 100%;
         				@extend .mr-0;
					}
					.tertiary-content-main{
						@extend .p-0;
						border-bottom: none!important;
					}
					.tertiary-content-about{
						@extend .px-3;
						@extend .pb-3;
						@extend .pt-0;
					}
					.occupation-jobs-title{
						font-weight: $font-weight-normal;
						font-size: $font-size-base;
						@extend .pl-3;
						@extend .pt-3;
					}
					.g-scrolling-carousel{
						.items > div{
							@include media-breakpoint-up(lg) { 
								flex: 0 0 51.66667%;
								max-width: 51.66%;
							}
							@include media-breakpoint-down(sm) { 
								flex: 0 0 80%;
								max-width: 80%;
							}
						}
					}
				}
				.occupation-CTA{
					@extend .card-2;
					@extend .d-flex;
					padding: 0;
					color: #fff;
					background-color: $system-purple;
					&.occupation-CTA-full-width {
						width: 100%;

						.occupation-CTA-icon{
							min-width: 15%;
						}
						.occupation-CTA-content{
							padding: 1rem!important;
						}
					}

					.occupation-CTA-icon{
						@extend .d-flex;
						@extend .align-items-center;
						@extend .justify-content-center;
						background-color: rgba(255, 255, 255, .10);
						min-width: 25%;
						svg{
							width:28px;
							height:28px
						}
						.occupation-cta-icon{
							fill:#fff;
						}
					}
					.occupation-CTA-content{
						@extend .d-flex;
						@extend .justify-content-around;
					    @extend	.flex-column;
					    @extend .p-3;
					    @extend .p-md-2;
					    width:100%;

					    .occupation-CTA-heading{
					    	font-weight: $font-weight-normal;
							font-size: $font-size-base;
					    }
					    .occupation-CTA-body{
					    	@extend .py-1;
					    	@extend .py-md-3;
					    }
						.occupation-CTA-link{
							a{
								color: #fff;
								font-weight: $font-weight-bold;

								&:hover{

								}
							}
						}
					}
				}
			}
		}
	}
	.locked-panel {
		border-radius: $border-radius;

		.insights-item{
			position: absolute;

			svg {
			    width: 32px;
			    height: 32px;
			    padding: 7px;
			    
			}
			.insights-item-title{
				font-size:$font-size-sm;
				@extend .ml-2;
			}
		}
		.locked-full-page-insights{
    		@extend .container-fluid;
			max-width: 1250px;
			@extend .d-flex;
			@extend .flex-row;
			@extend .justify-content-center;
			@extend .flex-wrap;
			@extend .py-5;
			max-width: 1100px;

			.insights-item{
				position: relative;
				@extend .py-4;
				@extend .col-lg;
				@extend .flex-column;
				@include media-breakpoint-down(lg) { 
					flex: 1 1 25%;
				}
				@include media-breakpoint-down(md) { 
					flex: 1 1 33.33%;
				}
				@include media-breakpoint-down(sm) { 
					flex: 1 1 50%;
				}
				svg {
			    width: 52px;
			    height: 52px;
			    padding: 12px;
			    
				}
				.insights-item-title{
					@extend .ml-auto;
					@extend .mr-auto;
				}
			}
		}
		&.locked-full-page{
			background:url(../img/full-locked-page-bg.png) no-repeat center center;
			background-size: cover;
			box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.87);
			position: relative!important;
			@extend .justify-content-start;	
			@extend .py-5;
			@extend .mb-3;

			.locked-panel-heading{
			    .locked-panel-title{
			    	font-size: $h4-font-size;
			    	@extend .py-1;
			    	@extend .px-2;
			    }
			}
		    .locked-panel-sub-heading{
		    	font-size: $h6-font-size;
		    }
		}
		.locked-panel-buttons{

			.secondary{
				text-decoration: none !important;
			    padding: 10px 25px !important;
			    cursor: pointer !important;
			    text-align: center !important;
			    line-height: normal !important;
			    border-radius: $border-radius!important;

			    &.white-secondary{		    	
				    color: #fff !important;
				    border-color: #fff !important;
				    border: 1px solid;
				    background: transparent !important;

				    &:hover{
					color: $gray !important;
				    border-color: #fff !important;
				    background: #fff !important;
				    }
			    }
			}
		}
	}
	.occupation-results-end{
		@extend .d-flex;
		@extend .flex-column;
		background-color: $gray-lightest;
		text-align: center;

		.similar-occupations{
			@extend .container-fluid;
			max-width: 1250px;
			@extend .my-3;

			.similar-occupations-header{
				@extend .my-3;
				font-size: $font-size-lg;
				font-weight: $font-weight-normal;
			}

			.similar-occupations-links{
				.chip{
					margin:4px;
				}
			}
		}
		.occupation-results-disclaimer{
			@extend .container-fluid;
			max-width: 1250px;
			@extend .my-2;

		}
	}
}


// insights item background with svg
.insights-item{
	@extend .d-flex;
	@extend .align-items-center;
	@extend .flex-row;

	svg{
		width: 45px;
	    height: 45px;
	    padding: 10px;
	}
	.insights-item-svg{
		fill: #fff;
	}
	.insights-item-title{
		font-size: $font-size-base;
		@extend .my-2;
		@extend .text-center;
	}
}
//mixin for insight icon backgrounds 
@each $occupation-color, $value in $occupation-colors {
  .insight-#{$occupation-color} {
    @include label-variant($value);
    @extend .rounded-circle;
  }
}  


/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
 background: rgba(0, 0, 0, .92)!important;
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .locked-panel {
    background: rgba(0, 0, 0, .92)!important;
}

}


